import { createAction,  } from "@reduxjs/toolkit";
import { CardModel } from "../../../model/response/card_model";
import { SortType } from "./feed_state";

export const loadMore = createAction(
    'feed/loadMore',
    function prepare(limit:number){
        return {
            payload: {
                limit:limit
            }
        }
    }
);

export const fetchFeed = createAction(
    'feed/fetchFeed',
    function prepare(feed:CardModel[]){
        return {
            payload: {
                feed:feed
            }
        }
    }
);

export const filterByChannel = createAction(
    'feed/filterByChannel',
    function prepare(channelId:string, channelName:string){
        return {
            payload: {
                channelId:channelId,
                channelName:channelName
            }
        }
    }
);

export const sortByCondition = createAction(
    'feed/sortByCondition',
    function prepare(sortType:SortType){
        return {
            payload: {
                sortType:sortType
            }
        }
    }
);