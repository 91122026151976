import { configureStore } from '@reduxjs/toolkit';
import channelReducer from '../features/channel/api/channel_slice';
import createChannelReducer from  '../features/create_channel/api/create_channel_slice';
import channelMemberReducer from '../features/channel_members/api/channel_member_slice';
import channelDiscussionReducer from '../features/channel_discussion/api/channel_discussion_slice';
import channelChatReducer from '../features/channel_chat/api/channel_chat_slice';
import channelAnnouncementReducer from '../features/channel_announcement/api/channel_announcement_slice';
import authReducer from '../features/auth/api/authSlice';
import navigationSlice from '../features/navigation/navigation_slice';
import threadSlice from '../features/thread/api/thread_slice';
import announcementSlice from '../features/announcement/api/announcement_slice'
import searchSlice from '../features/search/api/search_slice';
import profileSlice from '../features/profile/api/profile_slice';
import expertiseSlice from '../features/expertise/api/expertise_slice';
import userThreadSlice from '../features/user_thread/api/user_thread_slice';
import userFollowedThreadSlice from '../features/user_followed_thread/api/user_followed_thread_slice';
import notificationSlice from '../features/notification/api/notification_slice';
import expertSlice from '../features/expert/api/expert_slice';
import feedSlice from '../features/feed/api/feed_slice';
import introductionSlide from  '../features/introduction/api/introduction_slice';
import chatSlice from '../features/chat/api/chat_slice';
import leaderboard from '../features/leaderboard/api/leaderboard_slice';
import dialogSlice from '../features/dialog/dialog_slice';
import gptSlice from '../features/gpt/api/gpt_slice';
import documentSlice from '../features/channel_document/api/document_slice';
import organizationSlice from '../features/organization/organization_slice';
import cardCreationSlice from '../features/card_creation/api/card_creation_slice';
import customerServiceSlice from '../features/customer_service/api/customer_service_slice';
import errorSlice from '../features/error/api/error_slice';
const store = configureStore({
  reducer: {
    auth: authReducer,
    navigation: navigationSlice,
    channel: channelReducer,
    createChannel: createChannelReducer,
    channelMember: channelMemberReducer,
    channelDiscussion: channelDiscussionReducer,
    channelAnnouncement: channelAnnouncementReducer,
    channelChat:channelChatReducer,
    dialog: dialogSlice,
    thread: threadSlice,
    announcement: announcementSlice,
    search: searchSlice,
    profile: profileSlice,
    expertise: expertiseSlice,
    userThread: userThreadSlice,
    userFollowedThread: userFollowedThreadSlice,
    notification:notificationSlice,
    expert: expertSlice,
    feed: feedSlice,
    introduction: introductionSlide,
    chat: chatSlice,
    document: documentSlice,
    leaderboard: leaderboard,
    gpt: gptSlice,
    org:organizationSlice,
    cardCreation: cardCreationSlice,
    customerService: customerServiceSlice,
    error: errorSlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

