import { createSlice } from "@reduxjs/toolkit";
import { ChannelMemberState } from "./channel_member_state";
import { addChannelMember, addMembersFromChannel, clearChannelMembersCache, fetchMembers, promoteOrDemoteUser, removeChannelMember, removeMembersFromChannel, searchNewMembers } from "./channel_member_action";
import { ChannelMemberModel } from "../../../model/response/channel_member_model";

const initialState: ChannelMemberState = {
    channelMembers: [],
    lastMemberSeen: undefined,
    hasMoreMembers: true,
    channelMembersAreLoading:false,
    
    membersToAdd: [],
    suggestedMembers: [],
    suggestedMembersAreLoading: false,
    suggestedMembersAreUploading:false,
}

const channelMemberSlice = createSlice({
    name: 'channelMember',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(clearChannelMembersCache,(state) => {
            state.channelMembers = [];
            state.lastMemberSeen = undefined;
            state.hasMoreMembers = true;
            state.membersToAdd = [];
            state.suggestedMembers = [];
        })
        .addCase(fetchMembers.fulfilled, (state, payload) => {
            const currentMembers = state.channelMembers;
            state.channelMembersAreLoading = false;
            payload.payload.members.map((member) => 
                currentMembers.push(member)
            );

            state.channelMembers = currentMembers;
            state.lastMemberSeen = payload.payload.lastElement;
            state.hasMoreMembers = payload.payload.continueToScroll;
        })
        .addCase(fetchMembers.pending, (state) => {
            if(state.channelMembers.length === 0){
                state.channelMembersAreLoading = true;
            }
        })
        .addCase(fetchMembers.rejected, (state) => {
            state.channelMembersAreLoading = false;
        })
        .addCase(searchNewMembers.fulfilled, (state, action) => {
            state.suggestedMembers = action.payload.users;
            state.suggestedMembersAreLoading = false;  
        })
        .addCase(searchNewMembers.pending,(state) => {
            state.suggestedMembersAreLoading = true;
            state.suggestedMembers = [];
          })
        .addCase(searchNewMembers.rejected,(state) => {
            state.suggestedMembersAreLoading = false;
            state.suggestedMembers = [];
        })
        .addCase(addChannelMember,(state, payload) => {
            const currentMembers = state.membersToAdd;
            
            const filteredMembers = currentMembers.filter((member) => member.id === payload.payload.member.id);
            
            if(filteredMembers.length === 0){
                currentMembers.push(payload.payload.member)
            }
                
            state.membersToAdd = currentMembers;

        })
        .addCase(removeChannelMember,(state, payload) => {
            const currentMembers = state.membersToAdd;
            state.membersToAdd = currentMembers.filter((member) => member.id !== payload.payload.member.id);
        })
        .addCase(addMembersFromChannel.fulfilled, (state, payload) => {
            state.suggestedMembersAreUploading = false;
        })
        .addCase(addMembersFromChannel.pending, (state, payload) => {
            state.suggestedMembersAreUploading = true;
        })
        .addCase(addMembersFromChannel.rejected, (state, payload) => {
            state.suggestedMembersAreUploading = false;
        })
        
        .addCase(promoteOrDemoteUser.fulfilled, (state, action) => {
            const updatedMembers = state.channelMembers.map((member) => {
                if (member.id !==  action.payload.channelMemberId ){
                    return member
                }else{
                    const updatedMember:ChannelMemberModel = {
                        ...member,
                        role: action.payload?.role
                    }
                    return updatedMember
                } 
            });

            state.channelMembers = updatedMembers;
        })
        .addCase(removeMembersFromChannel.fulfilled, (state, payload) => {
            const currentMembers = state.channelMembers;
            state.channelMembers = currentMembers.filter((member) => !payload.payload.channelMembers.includes(member.userId));
        }) 
    }
});

export default channelMemberSlice.reducer;