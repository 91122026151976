import { Avatar, Badge, Box, Chip, Stack, Typography } from "@mui/material"
import { TicketModel, TicketType } from "../models/response/TicketModel";
import { deepOrange } from "@mui/material/colors";


export interface CustomerServiceTicketElementProps {
    ticket: TicketModel;
    onClick: () => void;
    isClicked: boolean;
}

export const CustomerServiceTicketElement: React.FC<CustomerServiceTicketElementProps> = (props: CustomerServiceTicketElementProps) => {
    return (
        <Box
            display='flex'
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            onClick={props.onClick}
            borderRadius={'10px'} p={1}

            sx={{
                backgroundColor: props.isClicked ? 'rgba(245, 245, 245, 1)' : undefined,
                '&:hover': {
                    backgroundColor: 'rgba(245, 245, 245, 1)', cursor: 'pointer'
                }
            }}
        >
            <Stack direction='column'>
                <Typography>{props.ticket.title}</Typography>
                <Box>
                    <Chip size={'small'}

                        sx={{
                            color: 'white',
                            backgroundColor: props.ticket.type === TicketType.ISSUE ? 'rgba(77, 160, 217, 1)' : 'rgba(140, 26, 106, 1)',
                            '&:hover': { cursor: 'pointer' }
                        }}
                        label={props.ticket.type === TicketType.ISSUE ? 'Issue' : 'Enhancement'} />
                </Box>
            </Stack>
            <Badge
                sx={{marginRight: '10px'}} 
                badgeContent={props.ticket.chatBalance.notificationCounter} 
                color='primary'/>
        </Box>);
}