import { Paper, InputBase, IconButton, styled, useTheme, Box, Typography, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { sendGPTFetchMessage, updateQuery } from '../api/gpt_action';
import { GPTMessage } from '../../../model/request/GPT_message';
import { UserModel } from '../../../model/response/user_model';
import { AIConversation, ConversationTurn } from '../../../model/response/gpt_conversation';
import { TextareaAutosize } from '@mui/base';
import { LoadingDots } from '../../../components/loading-dots/loading_dots';
import { useTranslation } from 'react-i18next';
import { GPTPinnedDocument } from './gpt_pinned_document';



const StyledPaper = styled(Paper)(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  width: '10eve0%',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
  borderRadius:'10px'
}));

const StyledInput = styled(TextareaAutosize)(({ theme }) => ({
  flex: 1,
  marginLeft: theme.spacing(1),
  border: 'none', // Remove border
  outline: 'none',
  fontFamily: 'Roboto', // Set the font-family
  fontSize: '16px', // Set the font size
}));

export const GPTFooter = () => {

  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const {t, i18n} = useTranslation();
  const query: string = useSelector((state: RootState) => state.gpt.query);
  const currentConversation: AIConversation | null = useSelector((state: RootState) => state.gpt.currentConversation);
  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  const waitingForResponse: boolean = useSelector((state: RootState) => state.gpt.waitingForSylloBot);
  const messages: ConversationTurn[] = useSelector((state: RootState) => state.gpt.turns);
  const handleDisableButton = (message: string) => {
    if (message.replace(/\s/g, '').length) {
      return false;
    } else {
      return true
    }
  }


  const sendMessageToGPT = () => {
    if (user) {
      if (currentConversation) {
        const data: GPTMessage = {
          channelIds:[],
          message: query,
          userId: user.id,
          conversationId: currentConversation.id,
          document:currentConversation.document,
        }
        dispatch(sendGPTFetchMessage(data));
      } else {
        const data: GPTMessage = {
          channelIds:[],
          message: query,
          userId: user.id,
          conversationId: ''
        }
        dispatch(sendGPTFetchMessage(data));
      }
    }
  }
  
  return (
    <Stack width='80%'>
      <StyledPaper elevation={3} >
        {messages.length === 0 && <GPTPinnedDocument/>}
          <Box 
              display ='flex'
              flexDirection= 'row'
              justifyContent= 'space-between'
              alignItems='center'>
          <StyledInput maxRows={5}
          onKeyDown={(event) => {

            if (event.key === 'Enter' && event.shiftKey) {
              // Previeni il comportamento di default (invio del form, ecc.)
              event.preventDefault();
        
              // Aggiungi una nuova riga alla posizione corrente nel testo
              // Calcola la nuova posizione del cursore dopo l'inserimento della nuova riga
              const target = event.target as HTMLTextAreaElement;
              const cursorPosition = target.selectionStart; // o HTMLInputElement se è un input
              const textBeforeCursor = query.substring(0, cursorPosition);
              const textAfterCursor = query.substring(cursorPosition);
        
              // Aggiorna il valore includendo la nuova riga
              dispatch(updateQuery(textBeforeCursor + "\n" + textAfterCursor));
              // Opzionalmente, puoi anche gestire il focus e la posizione del cursore dopo l'aggiornamento
            }else if (event.key === 'Enter' && !handleDisableButton(query)) {
              event.preventDefault();
              sendMessageToGPT();
            }
          }}
          onChange={(event) => {
            dispatch(updateQuery(event.target.value));
          }}
          placeholder={t('gptPage.footerHelperText')}
          value={query}
        />
          <Box sx={{}}
            display='flex'
            color={theme.palette.textColor?.main}
            justifyContent='center'
            justifyItems='center'
            alignItems='center'
            alignContent='center'
          >
            {
              waitingForResponse ?
                <LoadingDots />
                :
                <IconButton
                  disabled={handleDisableButton(query)}
                  onClick={() => {
                    sendMessageToGPT();
                  }}>
                  <SendIcon />
                </IconButton>
            }
          </Box>
          </Box>
      </StyledPaper>
      <Typography variant='caption'color={theme.palette.textColor?.main}>
        {t('gptPage.gptDiscalimer')}
      </Typography>
    </Stack>
  );
};