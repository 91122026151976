import { Box, Stack, Typography, useTheme } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from "react-i18next";


export interface ChannelButtonProps {
    title: string;
    isClicked: boolean;
    onClick: () => void;
}


export const ChannelButton: React.FC<ChannelButtonProps> = (props: ChannelButtonProps) => {

    const theme = useTheme();
    const {t} = useTranslation();

    
    
    return (
        <Box
            p={1}
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            
            onClick={props.onClick}
            sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(217, 217, 217, 0.3)'  }, backgroundColor:props.isClicked?'rgba(217, 217, 217, 0.3)' : 'transparent' }}
            >
            <Stack direction='row' alignItems='center' spacing={1} >
                <Box width={20} color={theme.palette.textColor?.main} display='flex'>
                </Box>
                    <Typography noWrap variant='body1'
                        color={theme.palette.textColor!.main} fontWeight={500}
                        >
                        {props.title}
                    </Typography>
            </Stack>
            <Box
                color={theme.palette.textColor!.main}>
                <KeyboardArrowRightIcon />
            </Box>
        </Box>
    );
}