import { createAsyncThunk } from "@reduxjs/toolkit";
import { FetchOrganizationModel } from "../../model/request/organization_model";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { OrganizationModel } from "../../model/response/organization_model";

export const loadCurrentOrganization = createAsyncThunk(
    'org/loadCurrentOrganization',
    async (data:FetchOrganizationModel, {rejectWithValue}) => {
        try{

            const db = getFirestore();
            const organizationQuery = doc(db, "Organizations", data.organizationId);

            const organizationDoc = await getDoc(organizationQuery);

            return {
                currentOrganization: organizationDoc.data() as OrganizationModel,
            }


        }catch(error:any){
            return rejectWithValue(error.toString());
        }
    }
);