import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { DeleteDocumentModel, FetchDocumentModel, UploadDocumentModel } from "../../../model/request/documents_model";
import { DocumentData, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, orderBy, query, setDoc, where } from "firebase/firestore";
import { DocumentModel, DocumentStatus, DocumentsSource } from "../../../model/response/document_model";
import { debugPrint } from "../../../utils/debug_print";
import { getStorage, ref, uploadString, getDownloadURL, getMetadata } from "firebase/storage";
import { OrganizationModel } from "../../../model/response/organization_model";
import { AnalyticsEvent, updloadedDocument } from "../../../model/request/analytics_model";
import { sendEvent } from "../../analitycs/analitycs_action";

export const fetchDocuments = createAsyncThunk(
    'admin/fetchDocuments',
    async (data:FetchDocumentModel, { rejectWithValue }) => {
        try{
            const db = getFirestore();
            const documentQuery = query(collection(
                db, 
                "Organizations", 
                data.organizationId, 
                "Documents"),
                where("channelId","==", data.channelId),
                orderBy('createdAt','desc'));
            const documents:DocumentModel[] = []
            const documentDocs = await getDocs(documentQuery);
            
            documentDocs.docs.map((doc) => {
                return documents.push(doc.data() as DocumentModel);
            });

            return {
                documents
            }

        }catch(error:any){
            return rejectWithValue(error.toString());
        }
    }
);

export const deleteDocument = createAsyncThunk(
    'document/deleteDocument',
    async (data:DeleteDocumentModel, {rejectWithValue}) => {
        try{

            if (data.documentId){
                const db = getFirestore();
                const docQuery = doc(db, 'Organizations', data.organizationId,'Documents', data.documentId);
                await deleteDoc(docQuery);  
            
            }

            return {
                documentId: data.documentId,
            }
        }catch(error:any){
            debugPrint(error.toString(),'admin/deleteDocument');
            return rejectWithValue(error.message);
        }
    }
);

export const uploadDocuments = createAsyncThunk(
    'document/uploadDocuments',
    async (data:UploadDocumentModel,{dispatch, rejectWithValue}) => {
        try{
            const storage = getStorage();
            const db = getFirestore();
            const fileNames: string[] | undefined = data.fileNames;
            const fileSizes: string[] | undefined = data.sizes;
            const types: string[] = data.types;
            const sizeInBytes: number[] = data.sizeInBytes;
            const uploaderUser = data.uploaderUser;

            const organizationQuery = doc(db, 'Organizations', data.organizationId);
            const organizationSnapshot = await getDoc(organizationQuery);
            const organization: DocumentData | undefined = organizationSnapshot.data();
            
            if (organization && data.fileContents && fileNames && fileSizes && uploaderUser){
                
                const currentOrganization = organization as OrganizationModel;
                const documents:DocumentModel[] = [];
                await Promise.all(
                    data.fileContents.map(async (dataUrl, index) => {
                       
                        const documentsRef = ref(storage, 'organizations/' + currentOrganization.name + '/documents/' + fileNames[index]);
                        await uploadString(documentsRef, dataUrl, 'data_url');
    
                        const filePath = await getDownloadURL(documentsRef);
                        const metadata = await getMetadata(documentsRef);
                        const fileSizeBytes = metadata.size;

                        const documentQuery = doc(collection(db, 'Organizations', data.organizationId, 'Documents'));
    
                        const document: DocumentModel = {
                            googleCloudStorageName:fileNames[index],
                            fileName: fileNames[index],
                            fileSize: (fileSizeBytes/ (1024 * 1024)).toFixed(2) + 'Mb',
                            type: DocumentsSource.MANUAL,
                            mimeType: types[index],
                            fileSizeInBytes: sizeInBytes[index],
                            url: filePath,
                            uploaderUser: uploaderUser,
                            documentStatus:DocumentStatus.PENDING,
                            createdAt: new Date().toISOString(),
                            updatedAt: new Date().toISOString(),
                            id: documentQuery.id,
                            channelId: data.channelId,

                        };
                        documents.push(document);
                        return await setDoc(documentQuery, document);
    
                    })
                );

                const log: AnalyticsEvent = {
                    event: {timestamp: new Date().toISOString()},
                    eventName: updloadedDocument
                };
    
                dispatch(sendEvent(log));
                
                return {
                    documents:documents
                }
            
            }else{
                return {
                    documents:[]
                }
            }
        }catch(error:any){
            debugPrint(error.toString(),'admin/uploadDocuments');
            return rejectWithValue(error.toString());
        }
    }

);

export const updateDocumentQuery = createAction(
    'document/updateDocumentQuery',
    function prepare (query:string) {
        return {
            payload: {
                query: query
            },
          } 
});

