import { createSlice } from "@reduxjs/toolkit";
import { ProfileState } from "./profile_state"
import { updateUser } from "./profile_action";

const initialState: ProfileState = {
    userProfile: undefined,
    
}
const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(updateUser, (state, payload) => {
            state.userProfile = payload.payload.user;
        })
    }
});

export default profileSlice.reducer;
