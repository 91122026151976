import { Avatar, Box, Button, Card, CardContent, Stack, Typography, useTheme } from "@mui/material"
import ReactMarkdown from "react-markdown"
import { DocReference } from "../../../model/response/gpt_conversation";
import {  useSelector } from "react-redux";
import {  RootState } from "../../../app/store";
import { GPTWaitingAnimation } from "./gpt_waiting_animation";
import { useTranslation } from "react-i18next";
import { GPTReference } from "./gpt_message_reference";
import { GPTMessageFooter } from "./gpt_message_footer";
import { GPTMessageBubble } from "./gpt_message_bubble";

export interface GPTMessageProps {
    userPhoto: string;
    query:string;
    answer:string;
    
    currentIndex:number;
    conversationLength: number;

    docsReferences?: DocReference[];
    isComplete?:boolean;

}
export interface DocumentData{
    pages: number[];
    url: string;
    source:string;
}

export interface UniqueDocRefence{
    [key: string]: DocumentData;
}

export const GPTMessage = (message:GPTMessageProps) => {
    const theme = useTheme();
    const {t,i18n} = useTranslation();

    const waitingForSylloBot: boolean = useSelector((state:RootState) => state.gpt.waitingForSylloBot);
    const isLastMessage: boolean = message.currentIndex === message.conversationLength;
    
    return (
        <Stack  spacing={1} >
            <GPTMessageBubble 
                isUser={true} 
                animate={false} 
                photo={message.userPhoto} 
                message={message.query}/>
            <GPTMessageBubble 
                isUser={false} 
                animate={waitingForSylloBot && isLastMessage} 
                photo={message.userPhoto} 
                message={message.answer}/>
            <GPTReference docsReferences={message.docsReferences}/>
            <GPTMessageFooter isComplete={message.isComplete}/>     
        </Stack>
    )
}