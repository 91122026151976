import { createAction } from "@reduxjs/toolkit";
import { CardModel } from "../../../model/response/card_model";

export const clearChannelAnnouncements = createAction(
    'channelAnnouncement/clearChannelAnnouncements',
    function prepare() {
        return {
            payload: {
                
            }
        }
    }
);


export const fetchAnnouncements = createAction(
    'channelAnnouncement/fetchAnnouncements',
    function prepare(announcements: CardModel[]) {
        return {
            payload: {
                announcements: announcements
            }
        }
    }
);

export const loadMoreAnnouncements = createAction(
    'channelAnnouncement/loadMoreAnnouncements',
    function prepare(limit: number) {
        return {
            payload: {
                limit: limit
            }
        }
    }
);