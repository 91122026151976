import { Box, List, ListItem, Stack, Typography} from "@mui/material";
import { useEffect } from "react";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { DocumentData, Query, collection, getFirestore, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { CardModel, CardType } from "../../../model/response/card_model";
import { fetchFeed, loadMore } from "../api/feed_action";
import InfiniteScroll from "react-infinite-scroll-component";
import { CardTile } from "../../../components/card-tile/card-tile";
import { CardPost } from "../components/card_post";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { FilterAndSort } from "../components/filter_and_sort";
import { SortType } from "../api/feed_state";
import { EmptyComponent } from "../../../components/empty-component/empty_component";
import { useTranslation } from "react-i18next";
import { setCardType, setChannel } from "../../card_creation/api/card_creation_action";

const HomePage = () => {
  
  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  

  const currentFeed: CardModel[] = useSelector((state: RootState) => state.feed.feed);
  const hasMore: boolean = useSelector((state: RootState) => state.feed.hasMore);
  const feedIsLoading: boolean = useSelector((state: RootState) => state.feed.feedIsLoading);
  const currentLimit: number = useSelector((state: RootState) => state.feed.limit);
  
  const sortType: SortType = useSelector((state: RootState) => state.feed.sortBy);
  const currentFilters: string[] = useSelector((state:RootState) => state.feed.filterByChannels )

  const dispatch = useDispatch<AppDispatch>();

  const fetch = () => dispatch(loadMore(currentLimit));
  const {t} = useTranslation();
  
  

  
  useEffect(
    () => {
      if(user != undefined){
        const db = getFirestore();
        var q: Query<DocumentData>;
        if(currentFilters.length === 0){
          if(sortType === SortType.mostRecent){
              q = query(
                collection(db, 'Users', user.id, 'Threads'),
                where('type', '==', CardType.discussion),
                where('isClosed', '==', false),
                where('hasLeftDiscussion', '==',false),
                where('isVisible','==', true),
                orderBy('updatedAt','desc'),
                limit(currentLimit)
            );
          }else{
            q = query(
              collection(db, 'Users', user.id, 'Threads'),
              where('type', '==', CardType.discussion),
              where('isClosed', '==', false),
              where('hasLeftDiscussion', '==',false),
              orderBy('notificationBalance.notificationCounter','desc'),
              where('isVisible','==', true),
              limit(currentLimit)
          );
          }
            
        }else{
          if(sortType === SortType.mostRecent){
            q = query(
              collection(db, 'Users', user.id, 'Threads'),
              where('type', '==', CardType.discussion),
              where('hasLeftDiscussion', '==',false),
              where('isClosed', '==', false),
              where('channel.channelId','in',currentFilters ),
              orderBy('updatedAt','desc'),
              limit(currentLimit)
          );
        }else{
          q = query(
            collection(db, 'Users', user.id, 'Threads'),
            where('type', '==', CardType.discussion),
            where('isClosed', '==', false),
            where('hasLeftDiscussion', '==',false),
            where('channel.channelId','in',currentFilters ),
            orderBy('notificationBalance.notificationCounter','desc'),
            limit(currentLimit)
        );
        }
        }
        

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const feed: CardModel[] = querySnapshot.docs.map(doc => doc.data() as CardModel);
                dispatch(fetchFeed(feed));
            });

            return () => {
                unsubscribe();
            };
      }
    },[user,currentLimit, currentFilters.length,sortType]
  );

  return (
    <Box
      display={'flex'} 
      flexDirection={'column'}
      alignItems={'center'}
      flexGrow={1}
      sx={{backgroundColor:'#F7F9FC'}}>
      
      <Box 
        id={'feedScrollable'} 
        display={'flex'} 
        flexDirection={'column'} 
        height={'90vh'} 
        sx={{
          overflowY: "scroll",
          '&::-webkit-scrollbar': {
              display: 'none', // Customize the width of the scrollbar
          }
        }}>
          <InfiniteScroll
            dataLength={currentFeed.length}
            next={fetch}
            hasMore={hasMore}
            scrollableTarget={'feedScrollable'}
            loader={<></>}
            >
             <List sx={{
                width: 'inherit',
                "&:hover": {
                  cursor: "pointer",
                  }
                }}>
                  <ListItem>
                      <CardPost photo={user?user.photo:''} 
                      onClick={() => {
                        dispatch(setChannel(undefined));
                        dispatch(changeDialog({dialog: DialogType.createDiscussionDialog,}));
                        dispatch(setCardType(CardType.discussion));
                      }}/>
                  </ListItem>
                  
                  <ListItem>
                    <FilterAndSort/>
                  </ListItem>
                  
                {
                  (currentFeed.length === 0 && !feedIsLoading) && 
                    <EmptyComponent title={t('feedPage.emptyTitle')} subtitle={t('feedPage.emptyDescription')} paddingTop={25}/>
                }
                {
                currentFeed.map((feed) => {
                  return(
                    <ListItem key={feed.id}>
                      <CardTile  stretch={false} isSearchedCard={false} {...feed}/>
                    </ListItem>
                    
                  )
                })
               }   
               
              </List>
              </InfiniteScroll>
            </Box>
    
    </Box>
  );
}

export default HomePage;
