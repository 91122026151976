import { CardChannelModel } from "./card_channel_model";
import { CardTags } from "./card_tags";
import { CommentModel } from "./comment_model";
import { FileType } from "./thread_draft_model";

export enum CardType{
    announcement = 0,
    discussion = 1,
}

export interface Attachment {
    name: string;
    url: string;
    storageRef:string;
    type:FileType,
    createdAt: string;
    updatedAt: string;
  }

export interface CardModel {
    id: string;
    cardId: string;
    threadId?: string;
    cardText: string;
    cardTitle: string;
    cardRichText: string;
    helpingHand: boolean;
    
    isFollowed: boolean;
    isAnswered: boolean;
    isOwned: boolean;
    isClosed: boolean;
    isVisible:boolean;
    isAnonymous:boolean;

    hasLeftDiscussion:boolean;
    
    channel: CardChannelModel;
    comments?: CommentModel[];

    organizationId: string;
    senderUser: {
        senderId: string;
        senderDisplayName: string;
        senderPhoto: string;
    };

    attachments?: Attachment[];

    notificationBalance?: {
        hasNotificationComments: boolean;
        hasNotificationAnswers: boolean;
        notificationCounter: number;
      };
      
    tags: CardTags[];
    totalAnswers: number;
    totalContributor: number;
    totalViews: number;
    type: CardType;

    createdAt: string;
   
}