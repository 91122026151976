import { createSlice } from "@reduxjs/toolkit";
import { LeaderboardState } from "./leaderboard_state";
import { fetchLeaderboard } from "./leaderboard_action";

const initialState: LeaderboardState = {
    currentLeaderboard: '',
    leaderboard: [],
    lastSeenUser: undefined,
    scrollLeaderboard: false
}

const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(fetchLeaderboard.fulfilled, (state, payload) => {
            const currentLeaderboard = state.leaderboard;
                payload.payload.leaderboard.map((user) => currentLeaderboard.push(user))
                state.leaderboard = currentLeaderboard;
                state.lastSeenUser = payload.payload.lastElement;
                state.scrollLeaderboard = payload.payload.continueToScroll;
        })
    }
});


export default leaderboardSlice.reducer;