import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { Box, List, ListItem, Stack, Typography, alpha, useTheme } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { DocumentData, Query, collection, getFirestore, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { CardModel, CardType } from "../../../model/response/card_model";
import { fetchAnnouncements, loadMore } from "../api/announcement_action";
import { useEffect } from "react";
import { AnnouncementNotification } from "../components/announcement_notification";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { setCardType } from "../../card_creation/api/card_creation_action";
import { EmptyComponent } from "../../../components/empty-component/empty_component";
import { ActionButton } from "../../../components/action-button/action_button";
import { useTranslation } from "react-i18next";
export const AnnouncementPage = () => {

    const dispatch = useDispatch<AppDispatch>();
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const announcements: CardModel[] = useSelector((state: RootState) => state.announcement.announcements);
    const currentLimit: number = useSelector((state: RootState) => state.announcement.limit);
    const hasMore: boolean =  useSelector((state: RootState) => state.announcement.hasMore);
    const theme = useTheme();
    const {t, i18n} = useTranslation();

    const fetch = () => dispatch(loadMore(currentLimit));


    useEffect(
        () => {
          if(user != undefined){
            const db = getFirestore();
            const q: Query<DocumentData> = query(
                collection(db, "Users", user.id, 'Threads'), 
                where('type', '==', CardType.announcement),
                where('isVisible','==', true),
                orderBy('isClosed', 'asc'), 
                orderBy('createdAt', 'desc'), 
                limit(currentLimit))
            
            
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const feed: CardModel[] = querySnapshot.docs.map(doc => doc.data() as CardModel);
                    dispatch(fetchAnnouncements(feed));
                });
    
                return () => {
                    unsubscribe();
                };
          }
        },[user,currentLimit]
      );

    return (
        <Stack  width={500} >
            <Box display={'flex'} flexDirection='row' justifyContent={'space-between'} padding={2} alignItems={'center'} >
                <Typography variant='h5' fontWeight={'600'} color={theme.palette.textColor?.main}>{t('announcementPopover.title')}</Typography>
                <ActionButton 
                    onClick={() => {
                        dispatch(setCardType(CardType.announcement));
                        dispatch(changeDialog({ dialog: DialogType.createAnnouncementDialog, }));
                    } } 
                    isLoading={false}
                     disabled={false} title={t('announcementPopover.actionButton')}                
                />
                    
            </Box>
                <Box>
                <InfiniteScroll
                    dataLength={announcements.length}
                    next={fetch}
                    hasMore={hasMore}
                    height={'50vh'}
                    loader={<></>}
                >
                    {
                        announcements.length == 0 &&
                        <EmptyComponent 
                        title={t('announcementPopover.emptyPageTitle')} 
                        subtitle={t('announcementPopover.emptyPageDescription')}/>
                        
                    }
                    <List sx={{padding: 0}}>

                        {
                            announcements.map((announcement) => 
                            <ListItem key={announcement.id}>
                                <AnnouncementNotification {...announcement}/>
                            </ListItem>)
                        }    
                    </List>
                </InfiniteScroll>
            </Box>
        </Stack>
    );
}

