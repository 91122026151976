import { createAction } from "@reduxjs/toolkit";
import { CardModel } from "../../../model/response/card_model";

export const fetchFollowedThreads = createAction(
    'userFollowedThread/fetchFollowedThreads',
    function prepare(threads:CardModel[]){
        return{
            payload:{
                threads:threads
            }
        }
    }
);

export const loadMoreFollowedThreads = createAction(
    'userFollowedThread/loadMoreFollowedThreads',
    function prepare(limit:number){
        return {
            payload: {
                limit:limit
            }
        }
    }
);