import { Avatar, Badge, Box, Stack, Typography, useTheme } from "@mui/material";
import { Page } from "../../features/navigation/navigation_state";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface SidebarElementProps {
    icon: ReactNode;
    badge?: number;
    hasNotifications?: boolean;
    referencePage: Page;
    onClick: () => void;
}


export const SidebarElement: React.FC<SidebarElementProps> = (props: SidebarElementProps) => {
    const currentPage: Page = useSelector((state: RootState) => state.navigation.currentPage);
    const theme = useTheme();
    const {t} = useTranslation();
    
    const defineCaption = () => {
        var caption:string = ''
        switch (props.referencePage) {
            case Page.HomePage:
                caption = t('sideBar.homeCaption')
                break;
            case Page.GPTPage:
                caption = t('sideBar.gptCaption')
                break;
            case Page.ChannelPage:
                caption = t('sideBar.channelCaptions')
                break;
            
            case Page.FollowedThreadPage:
                caption = t('sideBar.followedThreadCaption')
                break;
            
            case Page.YourThreadPage:
                caption = t('sideBar.yourThreadCaption')
                break;
            case Page.ChatPage:
                caption = t('sideBar.chatCaption')
                break;
            
            case Page.CustomerServicePage:
                caption = t('sideBar.customerServiceCaption')
                break;
            default:
                caption = '';
                break;
        }

        return caption;
    }
    
    const caption = defineCaption();
    
    return (
        <Box flexGrow={1} display='flex'  width={'100%'} onClick={props.onClick} sx={{'&:hover':{cursor:'pointer'}}}>
        <Stack direction={'row'} spacing={0.5} width={'100%'}>
            <Box sx={{
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,    // Position from the state
                width: '3px', // Rectangle width
                height: '40px', // Rectangle height
                backgroundColor: currentPage === props.referencePage ? '#4A4A4A' : 'transparent', // Rectangle color
            }} />
                <Stack direction='column' alignItems={'center'} width={'100%'} spacing={0}>
                    
                    <Badge badgeContent={props.badge} variant={props.hasNotifications && props.hasNotifications?'dot':undefined} color="primary">
                        <Box >
                                { props.icon}
                        </Box> 
                    </Badge>
                    <Typography variant={'caption'}color={theme.palette.textColor?.main}>
                        {caption}
                    </Typography>
                    
                    
                </Stack>    
        </Stack>
        </Box>
    );
}