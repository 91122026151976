import { Avatar, Box, Chip, Popover, Typography } from "@mui/material";
import { ChannelModel } from "../../../model/response/channel_model";
import { channelColors } from "../../../utils/channel_theme";

import DoneIcon from '@mui/icons-material/Done';
import { AppDispatch } from "../../../app/store";
import { useDispatch } from "react-redux";
import { addOrRemoveFilter } from "../api/search_action";
export interface SearchOnSelectedChannelPopOverProps {
    open: boolean;
    anchor: HTMLSpanElement | null;
    channels: ChannelModel[];
    currentFilters: string[];
    onClose?: () => void;
}

export const SearchOnSelectedChannelPopOver: React.FC<SearchOnSelectedChannelPopOverProps> = (props: SearchOnSelectedChannelPopOverProps) => {

    const dispatch = useDispatch<AppDispatch>();
    
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={props.onClose}
        >
            <Box display='flex' flexDirection='column' p={2} overflow='hidden'
                sx={{
                    overflowY: 'scroll',
                    maxHeight: '250px'
                }}>
                {
                    props.channels.map((channel) => {
                        const firstLetter = channel.channelName[0];
                        const channelColor = channelColors[firstLetter];
                        return (
                            <Box key={channel.id} p={0.5} >
                                <Chip variant="outlined"
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer'
                                        }
                                    }}
                                    onClick={() => dispatch(addOrRemoveFilter(channel.channelId))}
                                    avatar={
                                        !props.currentFilters.includes(channel.channelId)?
                                        <Avatar
                                            sx={{
                                                bgcolor: channelColor,
                                            }}>
                                            <Typography color={'white'}>
                                                {firstLetter}
                                            </Typography>
                                        </Avatar>
                                        :
                                        <Avatar
                                            >
                                            <Box color={'white'}>
                                                <DoneIcon/>
                                            </Box>
                                        </Avatar>
                                        
                                    
                                    } label={channel.channelName} />
                            </Box>
                        )
                    })
                }
            </Box>
        </Popover>
    );
}