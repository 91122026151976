import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { AnnouncementsChannelPermissions, AnswersChannelPermissions, ChannelModel, ChannelsType, DiscussionsChannelPermissions, DocumentsChannelPermissions } from "../../../model/response/channel_model";
import { useTranslation } from "react-i18next";
import { ChannelRecapOptions } from "../../create_channel/components/channel_recap";
import { Box, Dialog, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography, useTheme } from "@mui/material";
import { DialogType } from "../../dialog/dialog_state";
import { changeDialog } from "../../dialog/dialog_action";
import { useEffect, useState } from "react";
import { changeChannelSettings } from "../api/channel_action";
import { UserModel } from "../../../model/response/user_model";
import { ActionButton } from "../../../components/action-button/action_button";


export const ChannelSettingsDialog: React.FC = () => {

    const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);
    const channel: ChannelModel | undefined = useSelector((state: RootState) => state.dialog.channel);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const permissions = channel?.permissions;



    const [discussionPermission, setDiscussionPermission] = useState<DiscussionsChannelPermissions | undefined>(permissions !== undefined ? permissions[0] as DiscussionsChannelPermissions : undefined);
    const [answerPermission, setAnswerPermission] = useState<AnswersChannelPermissions | undefined>(permissions !== undefined ? permissions[1] as AnswersChannelPermissions : undefined);
    const [announcementPermission, setAnnouncementPermission] = useState<AnnouncementsChannelPermissions | undefined>(permissions !== undefined ? permissions[2] as AnnouncementsChannelPermissions : undefined);
    const [documentPermission, setDocumentPermission] = useState<DocumentsChannelPermissions | undefined>(permissions !== undefined ? permissions[3] as DocumentsChannelPermissions : undefined);

    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const onClose = () => {
        dispatch(changeDialog({ dialog: DialogType.none }));
    }

    useEffect(() => {

        if(user && channel && 
            discussionPermission !== undefined &&
            answerPermission !== undefined &&
            announcementPermission !== undefined &&
            documentPermission !== undefined){

            dispatch(changeChannelSettings({
                organizationId: user.organizationId,
                channelId: channel?.channelId,
                permissions: [discussionPermission,answerPermission,announcementPermission,documentPermission]
            }))
        }

        

    },[discussionPermission,answerPermission,announcementPermission,documentPermission])

    if (permissions === undefined)
        return <></>;

    if (channel === undefined)
        return <></>;

    const renderDiscussionOption = () => {
        const optionTitle = t('createChannelPage.discussionSettingsTitle')
        var description = t('createChannelPage.automaticRoutingOptionTitle');

        if (permissions === undefined)
            return <></>;

        switch (permissions[0]) {
            case DiscussionsChannelPermissions.ONLY_ROUTED:
                description = t('createChannelPage.automaticRoutingOptionTitle');
                break;
            case DiscussionsChannelPermissions.ONLY_ADMINS:
                description = t('createChannelPage.adminRoutingOptionTitle');
                break;
            case DiscussionsChannelPermissions.EVERYONE:
                description = t('createChannelPage.allMembersRoutingOptionTitle');
                break;

            default:
                break;
        }

        return (
            <ChannelRecapOptions title={optionTitle} description={description} />
        )
    }

    const renderAnswerOption = () => {
        const optionTitle = t('createChannelPage.answerSettingsTitle')
        var description = t('createChannelPage.adminAnswerSettingOption');

        if (permissions === undefined)
            return <></>;

        switch (permissions[1]) {
            case AnswersChannelPermissions.ONLY_ADMINS:
                description = t('createChannelPage.adminAnswerSettingOption');
                break;
            case AnswersChannelPermissions.EVERYONE:
                description = t('createChannelPage.allMembersAnswerSettingOption');
                break;

            default:
                break;
        }

        return (
            <ChannelRecapOptions title={optionTitle} description={description} />
        )
    }

    const renderAnnouncementOption = () => {
        const optionTitle = t('createChannelPage.announcementSettingsTitle')
        var description = t('createChannelPage.adminAnnouncementSettingOption');

        if (permissions === undefined)
            return <></>;

        switch (permissions[2]) {
            case AnnouncementsChannelPermissions.ONLY_ADMINS:
                description = t('createChannelPage.adminAnnouncementSettingOption');
                break;
            case AnnouncementsChannelPermissions.EVERYONE:
                description = t('createChannelPage.allMembersAnnouncementSettingOption');
                break;

            default:
                break;
        }

        return (
            <ChannelRecapOptions title={optionTitle} description={description} />
        )
    }

    const renderDocumentOption = () => {
        const optionTitle = t('createChannelPage.documentSettingsTitle')
        var description = t('createChannelPage.adminDocumentSettingOption');

        if (permissions === undefined)
            return <></>;

        switch (permissions[3]) {
            case DocumentsChannelPermissions.ONLY_ADMINS:
                description = t('createChannelPage.adminDocumentSettingOption');
                break;
            case DocumentsChannelPermissions.EVERYONE:
                description = t('createChannelPage.allMembersDocumentSettingOption');
                break;

            default:
                break;
        }

        return (
            <ChannelRecapOptions title={optionTitle} description={description} />
        )
    }
    if (!channel?.isAdmin) {

        return (
            <Dialog
                PaperProps={{ sx: { borderRadius: "10px" } }}
                fullWidth
                open={dialog === DialogType.channelSettingsDialog}
                sx={{
                    overflowY: 'hidden'
                }}
                onClose={onClose}
            >
                <Stack spacing={2} p={2}>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Typography color={theme.palette.textColor?.main} variant='h5' fontWeight='600'>
                            {t('channelSettingsDialog.title')}
                        </Typography>
                    </Box>
                    {renderDiscussionOption()}
                    <Divider light />
                    {renderAnswerOption()}
                    <Divider light />
                    {renderAnnouncementOption()}
                    <Divider light />
                    {renderDocumentOption()}
                </Stack>

            </Dialog>
        );
    }

    return (
        <Dialog
            PaperProps={{ sx: { borderRadius: "10px" } }}
            fullWidth
            open={dialog === DialogType.channelSettingsDialog}
            sx={{
                overflowY: 'hidden'
            }}
            onClose={onClose}>
            <Stack spacing={2} p={2}>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography color={theme.palette.textColor?.main} variant='h5' fontWeight='600'>
                        {t('channelSettingsDialog.title')}
                    </Typography>
                    <ActionButton 
                    isLoading={false} 
                    disabled={false} 
                    title={t('channelSettingsDialog.saveButtonText')} 
                    onClick={onClose}/>    
                </Box>
                <FormControl>
                    <FormLabel>
                        <Typography color={theme.palette.textColor?.main} fontWeight='600'>
                            {t('createChannelPage.discussionSettingsTitle')}
                        </Typography>
                    </FormLabel>
                    <RadioGroup value={discussionPermission} onChange={(event) => setDiscussionPermission(event.target?.value as DiscussionsChannelPermissions)}>
                        <FormControlLabel value={DiscussionsChannelPermissions.ONLY_ROUTED} control={<Radio size="small" />}
                            label={
                                <Typography color={theme.palette.textColor?.main}>
                                    {t('createChannelPage.automaticRoutingOptionTitle')}
                                </Typography>
                            } />
                        <FormControlLabel value={DiscussionsChannelPermissions.ONLY_ADMINS} control={<Radio size="small" />}
                            label={
                                <Typography color={theme.palette.textColor?.main}>
                                    {t('createChannelPage.adminRoutingOptionTitle')}
                                </Typography>
                            } />
                        <FormControlLabel value={DiscussionsChannelPermissions.EVERYONE} control={<Radio size="small" />}
                            label={
                                <Typography color={theme.palette.textColor?.main}>
                                    {t('createChannelPage.allMembersRoutingOptionTitle')}
                                </Typography>
                            } />
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel>
                        <Typography color={theme.palette.textColor?.main} fontWeight='600'>
                            {t('createChannelPage.answerSettingsTitle')}
                        </Typography>
                    </FormLabel>
                    <RadioGroup value={answerPermission} onChange={(event) => setAnswerPermission(event.target?.value as AnswersChannelPermissions)}>
                        <FormControlLabel value={AnswersChannelPermissions.ONLY_ADMINS} control={<Radio size="small" />}
                            label={
                                <Typography color={theme.palette.textColor?.main}>
                                    {t('createChannelPage.adminAnswerSettingOption')}
                                </Typography>
                            } />
                        <FormControlLabel value={AnswersChannelPermissions.MEMBERS} control={<Radio size="small" />}
                            label={
                                <Typography color={theme.palette.textColor?.main}>
                                    {t('createChannelPage.allMembersAnswerSettingOption')}
                                </Typography>
                            } />
                        {
                            channel.type === ChannelsType.PUBLIC &&
                            <FormControlLabel value={AnswersChannelPermissions.EVERYONE} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.everyoneAnswerSettingOption')}
                                    </Typography>
                                } />
                        }
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel>
                        <Typography color={theme.palette.textColor?.main} fontWeight='600'>
                            {t('createChannelPage.announcementSettingsTitle')}
                        </Typography>
                    </FormLabel>
                    <RadioGroup value={announcementPermission} onChange={(event) => setAnnouncementPermission(event.target?.value as AnnouncementsChannelPermissions)}>
                        <FormControlLabel value={AnnouncementsChannelPermissions.ONLY_ADMINS} control={<Radio size="small" />}
                            label={
                                <Typography color={theme.palette.textColor?.main}>
                                    {t('createChannelPage.adminAnnouncementSettingOption')}
                                </Typography>
                            } />
                        <FormControlLabel value={AnnouncementsChannelPermissions.EVERYONE} control={<Radio size="small" />}
                            label={
                                <Typography color={theme.palette.textColor?.main}>
                                    {t('createChannelPage.allMembersAnnouncementSettingOption')}
                                </Typography>
                            } />
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel>
                        <Typography color={theme.palette.textColor?.main} fontWeight='600'>
                            {t('createChannelPage.documentSettingsTitle')}
                        </Typography>
                    </FormLabel>
                    <RadioGroup value={documentPermission} onChange={(event) => setDocumentPermission(event.target?.value as DocumentsChannelPermissions)}>
                        <FormControlLabel value={DocumentsChannelPermissions.ONLY_ADMINS} control={<Radio size="small" />}
                            label={
                                <Typography color={theme.palette.textColor?.main}>
                                    {t('createChannelPage.adminDocumentSettingOption')}
                                </Typography>
                            } />
                        <FormControlLabel value={DocumentsChannelPermissions.EVERYONE} control={<Radio size="small" />}
                            label={
                                <Typography color={theme.palette.textColor?.main}>
                                    {t('createChannelPage.allMembersDocumentSettingOption')}
                                </Typography>
                            } />
                    </RadioGroup>
                </FormControl>
            </Stack>
        </Dialog>
    );
}