import { getFirestore } from '@firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { MessagePayload, getMessaging, getToken, onMessage} from 'firebase/messaging';

import { initializeApp, setLogLevel } from 'firebase/app';

export const firebaseDevelopmentConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_DEV,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_DEV
};

export const firebaseProductionConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PROD,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_PROD,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PROD
};

var currentConfig = firebaseProductionConfig;

if (process.env.REACT_APP_ENV === "development"){
  currentConfig = firebaseDevelopmentConfig;
}

export const firebaseApp = initializeApp(currentConfig);

if(process.env.REACT_APP_ENV === 'development') {
  //setLogLevel('debug');
}


export const firestore =  getFirestore();
export const firebaseAnalitycs = getAnalytics();

export const messaging = getMessaging();

export const onMessageListener = () =>
  new Promise<MessagePayload>((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });