import { Box, Button, Chip, DialogContentText, Slider, Stack, styled, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { ActionButton } from "../../../components/action-button/action_button";
import { SendGiftModel } from "../../../model/request/send_gift_model";
import { UserModel, UserTag } from "../../../model/response/user_model";
import { addTag, removeTag, sendGift, updateSliderValue } from "../api/expert_action";
import { TagModel } from "../../../model/response/tag_model";


const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 10,
    width: 10,
    backgroundColor: '#fff',
    boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: 30,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));

export const SendGiftDialog = () => {

  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const expert: UserModel | undefined = useSelector((state: RootState) => state.expert.expert);
  const user: UserModel | undefined = useSelector((state: RootState) => state.profile.userProfile);

  const expertises: TagModel[] =  useSelector((state:RootState) => state.expert.expertises);
  const tagsToKeep: UserTag[] = useSelector((state: RootState) => state.expert.tagsToKeep);
  const sliderValue: number = useSelector((state: RootState) => state.expert.sliderValue);

  const marks: any = []



  useEffect(
    () => {
      if (user) {
        const weeklyPoints: number = user.weeklyBudget;
        var total: number = 0;

        if (weeklyPoints > 0) {
          marks.push({ value: 0 });

          while (total < weeklyPoints) {
            total += 5;
            marks.push({ value: total })
          }
        }
      }
    }
  )



  return (
    <Stack>
      <Stack display={'flex'} fontWeight={600} flexDirection={'column'} alignItems={'center'} >
        <Typography
          variant="body1"
          align="center"
          color={theme.palette.textColor ?.main}
        >{t('expertPage.firstTitle')} {expert ?.displayName} {t('expertPage.secondTitle')}</Typography>
        <Typography>
          {t('expertPage.firstDescription')} <Typography color={theme.palette.primary.main} component={'span'}>{user ?.weeklyBudget}</Typography> {t('expertPage.secondDescription')}
        </Typography>
        <Box width={'80%'}>
          <IOSSlider
            onChange={(event, value) => {
              if (typeof value === 'number') {
                dispatch(updateSliderValue(value))
              }
            }}
            value={sliderValue}
            max={user ?.weeklyBudget}
            marks={marks}
            valueLabelDisplay="auto" />
        </Box>
        <DialogContentText>
          {t('expertPage.helperText')}
        </DialogContentText>
      </Stack>
      <Box
        component="div"
        justifyContent={'center'}
        display={'flex'}
        flexDirection={'column'}
      >
        <Box height={'30vh'} sx={{ overflowY: 'scroll' }} m={1}>
          {
            expert !== undefined &&
              expertises.map((doc, index) =>
                <Chip size={'small'} key={index} sx={{

                  margin: 0.2,
                  border: tagsToKeep.includes(doc) ? null : '1px solid ' + theme.palette.textColor ?.main,
                  backgroundColor: tagsToKeep.includes(doc) ? '#fdc673' : '#ffffff',
                  color: tagsToKeep.includes(doc) ? '#ffffff' : theme.palette.textColor ?.main
                            }}
                  label={doc.tagName}
                  onClick={() => {

                    if (tagsToKeep.includes(doc)) {
                      dispatch(removeTag(doc));
                    } else if (tagsToKeep.length < 3) {
                      dispatch(addTag(doc));
                    }

                  }}
                />
              )
                        }

        </Box>
        <ActionButton
          disabled={tagsToKeep.length < 3}
          onClick={() => {
            if (user && expert) {
              const data: SendGiftModel = {
                userId: user.id,
                userIdToReward: expert.id,
                pointsToGive: sliderValue,
                tagIdList: tagsToKeep.map((tag) => tag.tagId)
              };
              dispatch(sendGift(data));
            }

          } } isLoading={false} title={t('expertPage.sendGiftButton', {displayName: expert!.displayName})}/>
      </Box>
    </Stack>
  )
}
