import React from 'react';
import { TagProps } from '../tag/tag';
import { Avatar, Box, Card, CardContent, CardHeader, Chip, Divider, Stack, Typography, useTheme } from '@mui/material';
import { leaveThread, setCardId, setThreadId } from '../../features/thread/api/thread_action';
import { goForward } from '../../features/navigation/navigation_action';
import { Page } from '../../features/navigation/navigation_state';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { UserModel } from '../../model/response/user_model';
import { useNavigate } from 'react-router-dom';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { CardChannelModel } from '../../model/response/card_channel_model';
import { channelColors } from '../../utils/channel_theme';
import { fetchExpert } from '../../features/expert/api/expert_action';
import { changeDialog } from '../../features/dialog/dialog_action';
import { DialogDeleteAction, DialogType } from '../../features/dialog/dialog_state';
import { useTranslation } from 'react-i18next';

export interface CardTileProps {
    className?: string;

    id: string;
    cardId: string;
    organizationId: string;
    cardTitle: string;
    cardText: string;
    cardRichText: string;
    createdAt: string;

    tags: TagProps[];

    senderUser: {
        senderId: string;
        senderDisplayName: string;
        senderPhoto: string;
    };

    channel: CardChannelModel;

    isSearchedCard: boolean;
    stretch:boolean;
    isOwned: boolean;
    isClosed: boolean;

    totalAnswers:number;

    notificationBalance?: {
        hasNotificationComments: boolean;
        hasNotificationAnswers: boolean;
        notificationCounter: number;
    };
    
    onProfileClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onCardClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const CardTile: React.FC<CardTileProps> = (props: CardTileProps) => {

    const dispatch = useDispatch<AppDispatch>();
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();
    const theme = useTheme();
    const {t} = useTranslation();
    const getElapsedTime = (date: string) => {

        const creationDate = new Date(date);
        const difference = new Date().getTime() - creationDate.getTime();
        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const month = Math.floor(days / 31);
        const years = Math.floor(days / 365);
        if (seconds < 0) {
            return 'now';
        } else if (seconds < 60) {
            return `${seconds}s ago`;
        } else if (minutes < 60) {
            return `${minutes}m ago`;
        } else if (hours < 24) {
            return `${hours}h ago`;
        } else if (days < 31) {
            return `${days}d ago`;
        } else if (month < 12) {
            return `${month}d ago`;
        } else {
            return `${years}y ago`;
        }
    }

    const firstLetter = props.channel.channelName[0];
    const channelColor = channelColors[firstLetter];


    const deleteThreadAction = (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        if (user) {
            const data = {
                userId: user?.id,
                threadId: props.id
            };
            dispatch(changeDialog({
                dialog: DialogType.deleteDialog, 
                deleteAction:  props.isOwned?DialogDeleteAction.closeDiscussion:DialogDeleteAction.leaveDiscussion,
                threadId: props.id,
            }))
        }
    }

    return (
        <Card elevation={0}
            onClick={props.onCardClick
                ?props.onCardClick:() => {
                if (user) {
                    dispatch(goForward(Page.ThreadPage));
                    if (props.id) {
                        dispatch(setThreadId(props.id));
                        navigate('/thread/thread=' + props.id);
                    } else {
                        dispatch(setCardId(props.cardId));
                        navigate('/thread/card=' + props.cardId);
                    }
                }
            }}

            sx={{
                width: props.stretch?null:'60vw',
                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                borderRadius: '8px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                
                '&:hover': {
                    cursor: 'pointer'
                }
            }}>
            <Box display={'flex'} flexDirection='row' alignItems='center' justifyContent='space-between'>

                <CardHeader
                    sx={{
                        paddingBottom: 0,
                        '.MuiCardHeader-title': {
                            fontWeight: 600,
                            color: theme.palette.textColor?.main
                        }
                    }}

                    onClick={
                        props.onProfileClick
                        ?props.onProfileClick:(event) => {
                        event.stopPropagation();
                        dispatch(fetchExpert({expertId:props.senderUser.senderId}));
                        dispatch(changeDialog({dialog: DialogType.expertDialog,}));
                    }}
                    avatar={
                        <Avatar
                            src={props.senderUser.senderPhoto} aria-label="recipe" />
                    }
                    title={props.senderUser.senderDisplayName}
                    subheader={getElapsedTime(props.createdAt)}

                />
                <Box paddingRight={2}>
                    <Chip variant="outlined" avatar={<Avatar
                        sx={{
                            bgcolor: channelColor,
                        }}>
                        <Typography color={'white'}>
                            {firstLetter}
                        </Typography>
                    </Avatar>} label={props.channel.channelName} />
                </Box>
            </Box>
            <CardContent>
                <Typography style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', // Add ellipsis for overflow text
                    whiteSpace: 'nowrap' // Keep the text in a single line
                }}
                    fontWeight={600}
                    variant={'h6'}
                    color={theme.palette.textColor!.main}
                >
                    {props.cardTitle}
                </Typography>
                <Typography style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', // Add ellipsis for overflow text
                    whiteSpace: 'nowrap' // Keep the text in a single line
                }}
                    color={theme.palette.textColor!.main}
                >
                    {props.cardText}
                </Typography>

            </CardContent>
            {
                !props.isSearchedCard &&
                <Box paddingLeft={2} paddingRight={2}>
                    <Divider light />
                </Box>
            }

            {
                !props.isSearchedCard &&
                <Box display='flex' flexDirection='row' justifyContent='space-between' p={1} paddingLeft={2} paddingRight={2}>
                    {
                        props.notificationBalance && props.notificationBalance.notificationCounter > 0 && props.totalAnswers > 0 ?
                            <Typography color='#5876FF'>
                                 {t('feedPage.newUpdateCaption',{value:props.notificationBalance.notificationCounter})} 
                            </Typography> :
                            props.notificationBalance && props.notificationBalance.notificationCounter > 0 && props.totalAnswers === 0?
                            <Typography color='#5876FF'>
                                {t('feedPage.newDiscussionCaption')} 
                            </Typography>
                            :
                            <Box>
                            </Box>
                    }

                    <Stack spacing={1} direction='row'
                        onClick={deleteThreadAction}>
                        <Box color={props.isClosed?'grey':'red'}>
                            <HighlightOffOutlinedIcon />
                        </Box>
                        <Typography color={props.isClosed?'grey':'red'}>
                            {props.isClosed?t('feedPage.closedDiscussionCaption')
                            :props.isOwned?t('feedPage.deleteDiscussionAction'):
                            t('feedPage.leaveDiscussionAction')}
                        </Typography>
                    </Stack>
                </Box>
            }
        </Card>
    );
};