import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    "announcementPopover": {
                        "title": "Announcements",
                        "actionButton": "Post",
                        "emptyPageTitle": "Nothing to see here!",
                        "emptyPageDescription": "Announcements will be visible in this dialog.",
                        "viwedDescription": "viewed",
                        "gotItDescription": "got it!",
                        "contactActionButton": "Contact {{value}}",
                        "deleteActionButton": "Delete announcement",
                    },
                    "authPage": {
                        "title": "Access to SylloTips",
                        "nextStepButton": "Continue",
                        "forgotYourPasswordCaption": "Forgot your password? Click here to create a new one",
                        "resetPasswordCaption": "We sent an email to {{email}}. Follow the instructions to reset your password!",
                        "signInButton": "Sign In",
                        "loginWithProvider": "or login with",
                        "microsoftProvider": "Continue with Microsoft",
                        "eulaCaption": "By proceeding with the access, you accept the Terms and Conditions of Use and the Privacy Policy of the app."
                        
                    },
                    "cardCreationPage": {
                        "discussionTitle": "Create discussion",
                        "announcementTitle": "Create announcement",
                        "actionButton": "Post",
                        "selectChannelButton": "Select a channel",
                        "cardTitlePlaceHolder": "Title",
                        "submitAnywayActionButton": "Submit anyway"
                    },
                    "channelSideBar": {
                        "channelCaption": "Your channels",
                        "mutedChannelCaption": "Muted channels",
                        "muteChannelAction":"Mute channel",
                        "unmuteChannelAction": "Unmute channel",
                        "openChannelSettings":"Open channel settings",
                        "leaveChannelAction": "Leave channel",
                        "title": "Channels",
                        "chatTitle": "Chat",
                        "announcementTitle": "Announcements",
                        "discussionTitle": "Discussions",
                        "documentTitle": "Documents",
                        "memberTitle": "See members",
                        "yourDiscussionCaption": "All your discussions",
                        "followedCaption": "Followed"
                    },
                    "channelAnnouncementPage": {
                        "title": "{{channelName}}'s announcements",
                        "actionButton": "Create announcement",
                        "emptyCaption": "No announcements yet",
                        "emptyDescription": "Announcement from this channel will be visible here."
                    },
                    "channelChatPage": {
                        "title": "{{channelName}}'s chat",
                        "welcomeMessage": "Welcome to {{channelName}}'s chat",
                        "welcomeMessageDescription": "This is the place where you can chat with your colleagues",
                        "replyingToMessageDesctiption": "Replying to ",
                        "reactButton": "React",
                        "replyButton": "Reply",
                        "editButton": "Edit",
                        "deleteButton": "Delete",
                        "inputBasePlaceHolder": "Write a message in {{channelName}}",
                        "modifyMessageHelperText": "Press esc to cancel • Press enter to save"
                    },
                    "channelDiscussionPage": {
                        "title": "{{channelName}}'s discussions",
                        "actionButton": "Create discussion",
                        "discussionForYouTab": "For you",
                        "allDiscussionTab": "All",
                        "emptyCaption": "No discussions for you!",
                        "emptyDescriptionActiveDiscussion": "As soon as a discussion will be open you will find it here.",
                        "emptyDescriptionCloseDiscussion": "When a discussion will be closed, you wil find it here.",
                        "emptyCaptionAllChannel": "No discussions in this channel",
                        "emptyDescriptionActiveDiscussionAllChannel": "Open discussion meant for you will appear here.",
                        "emptyDescriptionCloseDiscussionAllChannel": "As soon as a discussion will be open you will find it here.",
                        "activeDiscussionButton": "Active discussions",
                        "closeDiscussionButton": "Closed discussions"
                    },
                    "channelDocumentPage": {
                        "title": "{{channelName}}'s documents",
                        "fileNameTableLabel": "File name",
                        "uploadedByTableLabel": "Uploaded by",
                        "createAdtTableLabel": "Added on",
                        "sizeTableLabel": "Size",
                        "statusTableLabel": "Status",
                        "actionTableLabel": "Action",
                        "searchHelperText": "Search documents...",
                        "actionButton": "Upload documents",
                        "uploadErrorCode": "ERROR",
                        "uploadSuccessCode": "UPLOADED",
                        "uploadPendingCode": "PENDING",
                        "noRowCaption": "No document found, change filter or upload new documents",
                        "chatDocumentCaption": "Chat with document",
                        "downloadDocumentCaption": "Download document",
                        "deleteDocumentCaption": "Delete document"
                    },
                    "channelMemberPage": {
                        "title": "{{channelName}}'s members",
                        "actionButton": "Add members",
                        "titleAddMember": "Add members to {{channelName}}",
                        "addMembersActionButton": "Done",
                        "removeMemberActionButton": "Remove",
                        "addMemberActionButton": "Add",
                        "searchMemberHelperText": "Search member..."
                    },
                    
                    "chatPage": {
                        "sideBarChatTitle": "Chats",
                        "sideBarSearchHelperText": "Search chats...",
                        "sideBarChatDescription": "All Chats",
                        "dialogTitle": "New message",
                        "dialogSearchHelperText": "Search users...",
                        "createConversationButton": "Start a conversation",
                        "chatFooterHelperText": "Write a message..."
                    },
                    "createChannelPage": {
                        
                        "channelTypeTitle": "What kind of channel do you want to create?",
                        "publicChannelGroupButton": "Public channel",
                        "publicChannelDescription": "For broader group discussions, with content visible in search results and discussions forwarded to relevant members.",             
                        "privateChannelGroupButton": "Private channel",
                        "privateChannelDescription": "For small team discussions, with content visible only to members and notifications for all channel members.",
                        
                        "channelSettingTitle": "Some more details about your channel",
                        "channelName": "Channel name",
                        "channelSettingButton": "Channel settings",
                        "channelSettingsDisclaimer": "You will be able to change these later",
                        "discussionSettingsTitle":"Discussions recipients",
                        "automaticRoutingOptionTitle": "Only Relevant Members (Automatic Routing)",
                        "adminRoutingOptionTitle": "Only Channel Admins",
                        "allMembersRoutingOptionTitle":"All Channel Members",

                        "answerSettingsTitle":"Who can write answers to discussions:",
                        "adminAnswerSettingOption":"Only channel admins (those who publish a discussion can always contribute)",
                        "allMembersAnswerSettingOption":"All channel members",
                        "everyoneAnswerSettingOption":"Everyone",

                        "announcementSettingsTitle":"Announcement Posting Permissions",
                        "adminAnnouncementSettingOption":"Only channel admins",
                        "allMembersAnnouncementSettingOption":"All channel members",

                        "documentSettingsTitle":"Document Upload Permissions",
                        "adminDocumentSettingOption":"Only channel admins",
                        "allMembersDocumentSettingOption":"All channel members",

                        "selectMembersActionButton": "Select members",
                        "selectMemberTitle": "Select member",
                        "selectMemberFieldHelperText": "Search member...",
                        "createChannelButton": "Create channel",
                        "backButton": "Back"
                    },
                    "customerServicePage":{
                        "title": "How can we help you?",
                        "titleFieldHelperText": "Title",
                        "descriptionFieldHelperText": "Tell us what you think...",
                        "actionButton": "Send",
                        "sidebarTitle": "Ticket",
                        "sidebarDescription": "All tickets",
                        "ticketTypeSelectionButton": "Select ticket type",
                        "ticketTypeOne": "Enhancement",
                        "ticketTypeTwo": "Issue",
                        "ticketSentDescription": "Thank you for your time! We always value your useful feedback!",
                        "ticketSentActionButton": "Go to your tickets",
                        "markAsSolvedAction": "Mark as solved",
                        "deleteAction": "Delete ticket",
                        "customerServiceHelperText":"Here you can let us know anything related to bugs or features you’d like to see inside SylloTips",
                        "openTicketActionButton": "open",
                        "closedTicketActionButton": "closed",
                    },
                    "expertPage": {
                        "goBackButton": "Go Back",
                        "sendRewardButton": "Send reward",
                        "firstTitle": "Let",
                        "secondTitle": "know you appreciate the help",
                        "firstDescription": "You have",
                        "secondDescription": "points left",
                        "helperText": "Please choose at most 3 expertise",
                        "sendGiftButton": "Send gift to {{displayName}}",
                        "contactButton": "Contact",
                        "dashboardTitle": "Dashboard",
                        "dashboardPointDescription": "Total points",
                        "dashboardPositionDescription": "Latest placing",
                        "dashboardTrophyDescription": "Times in top 3",
                        "barPlotTitle": "Activity",
                        "weeklyDate": "week",
                        "monthlyDate": "month",
                        "yearlyDate": "year",
                        "medalTitle": "Medals",
                        "achievementTitle": "Achievements",
                        "levelDescription": "Level {{level}}",
                        "bestExpertiseTitle": "Best expertise",
                        "expertiseTitle": "Expertise"
                    },
                    "expertisePopOver": {
                        "title": "Expertise",
                        "expertiseNameTableLabel": "Expertise",
                        "showAll": "show all",
                        "expertiseScoreTableLabel": "Score",
                        "searchHelperText": "Search expertises...",
                        "multipleSelection": "selected"
                    },
                    "feedPage": {
                        "actionButton": "Create a discussion",
                        "filterButton": "Filter by",
                        "filterButtonCaption": "Select channel",
                        "sortButton": "Sort by",
                        "sortFirstOption": "Most recent update",
                        "sortSecondOption": "Most discussed",
                        "emptyTitle": "Nothing to see here!",
                        "emptyDescription": "New and updated discussions will appear here.",
                        "leaveDiscussionAction": "leave discussion",
                        "deleteDiscussionAction": "close discussion",
                        "newUpdateCaption": "{{value}} new updates",
                        "newDiscussionCaption": "new discussion",
                        "closedDiscussionCaption": "closed discussion"
                    },
                    "gptPage": {
                        "allChatTitle": "All Chats",
                        "gptHelperText": "Hi! How can I assist you today?",
                        "footerHelperText": "Type your message...",
                        "gptMessageReferences": "References",
                        "gptWaitingMessage": "Please hold on for a moment while I gather and analyse the necessary documents to address your questions",
                        "gptCreateAQuestionDescription": "Not satisfied of your answer?",
                        "gptCreateAQuestionButton": "Create a discussion",
                        "gptDiscalimer": "Sybil can make mistakes. Always verify important information."
                    },
                    "introductionPage": {
                        "titleFirstStep": "About you",
                        "addProfilePicDescription": "Add a profile picture",
                        "tellAboutYouDescription": "Tell us something about you",
                        "tellAboutYouHelperText": "Sum up your role in your company",
                        "continueButton": "continue",
                        "titleSecondStep": "Tell us what you are expert on",
                        "searchHelperText": "Search expertise in your organization",
                        "doneButton": "Update your profile"
                    },
                    "searchPage": {
                        "filterButton": "Filter by",
                        "filterButtonCaption": "Select channels",
                        "helperText": "Search inside SylloTips",
                        "discussionTab": "Discussions ({{value}})",
                        "announcementTab": "Announcements ({{value}})",
                        "expertTab": "Experts ({{value}})",
                        "colleaguesTab": "Colleagues ({{value}})",
                        "documentTab": "Documents ({{value}})",
                        "documentDescription": "Uploaded by {{value}}"
                    },
                    "sideBar":{
                        "homeCaption": "Home",
                        "yourThreadCaption": "Discussions",
                        "followedThreadCaption": "Followed",
                        "chatCaption":"Chats",
                        "gptCaption": "Sybil",
                        "customerServiceCaption": "Feedback",
                        "channelCaptions": "Spaces"

                    },
                    "threadPage": {
                        "replyCaption":"Add a reply...",
                        "replyBuutton": "Reply",
                        "repliesIconDescription": "replies",
                        "contributorsIconDescription": "contributors",
                        "replyIconDescription": "Reply",
                        "totalRepliesCaption": "replies",
                        "answersIconDescription": "answers",
                        "deleteThread": "Delete discussion",
                        "editThread": "Edit discussion",
                        "followThread": "Follow discussion",
                        "unfollowThread": "Unfollow discussion",
                        "saveButton": "save",
                        "sendButton": "Send answer"
                    },
                    "userThreadPage":{
                        "emptyTitle": "Nothing to see here!",
                        "emptyDescription":"Your discussions will appear here."
                    },
                    "userFollowedPage":{
                        "emptyTitle": "Nothing to see here!",
                        "emptyDescription":"Followed discussions will appear here."
                    },
                    "notFoundPage": {
                        "title": "404 - Not Found",
                        "description": "Sorry, the thread you are looking for does not exist."
                    },
                    "notificationPopOver": {
                        "title": "Notification"
                    },
                    "leaderboardPopOver": {
                        "title": "Leaderboard"
                    },
                    "profilePopOver": {
                        "profileTitle": "Profile",
                        "dashboard": "Dashboard"
                    },
                    "alertDialog": {
                        "alertTitle": "Oops, it appears there was an issue!",
                        "sizeMessage": "the size of the question body is larger then 1 Mb",
                        "documentUploadLimit": "You have selected too many documents. Please limit your selection to a maximum of 10 documents at a time.",
                        "documentSizeLimit": "The total size of documents exeeds the maximum size allowed (50Mb)"
                    },
                    "deleteDialog": {
                        "deleteMessageTitle": "Delete message",
                        "deleteMessageDescription": "Are you sure you want to delete this message? This action can't be undone.",
                        "deleteMessageAction": "Delete message",
                        
                        "deleteDiscussionTitle": "Delete discussion",
                        "deleteDiscussionDescription": "Are you sure you want to delete this discussion? This action can't be undone.",
                        "deleteDiscussionAction": "Delete discussion",
                        
                        "leaveDiscussionTitle": "Leave discussion",
                        "leaveDiscussionDescription": "Are you sure you want to leave this discussion? This action can't be undone.",
                        "leaveDiscussionAction": "Leave discussion",
                        
                        "closeDiscussionTitle": "Close discussion",
                        "closeDiscussionDescription": "Are you sure you want to close this discussion? This action can't be undone.",
                        "closeDiscussionAction": "Close discussion",
                        
                        "deleteAnswerTitle": "Delete answer",
                        "deleteAnswerDescription": "Are you sure you want to delete this answer? This action can't be undone.",
                        "deleteAnswerAction": "Delete answer",
                        
                        "deleteChannelTitle": "Leave channel",
                        "deleteChannelDescription": "Are you sure you want to leave this channel? This action can't be undone.",
                        "deleteChannelAction": "Leave channel",
                        
                        "deleteAnnouncementTitle": "Delete Announcement",
                        "deleteAnnouncementDescription": "Are you sure you want to delete this announcement? This action can't be undone.",
                        "deleteAnnouncementAction": "Delete Announcement"

                    },
                    "channelSettingsDialog":{
                        "title": "Channel settings",
                        "saveButtonText": "Save"
                    },
                    "changelogDialog":{
                        "title":"What’s new in SylloTips",
                        "releaseCaption": "NEW RELEASE",
                        "buttonCaption": "Got it",
                        "description": "- We moved **Your Discussions** and **Followed Discussion** inside the new **Spaces** section, to make the sidebar more clean.\n\n- We added some new tooltips for first-time users.\n\n- We added confirmation dialogs for irreversible actions.\n\n- We solved some minor performance issues."
                    },
                    "toolTips": {
                        "skipButton": "Skip",
                        "backButton": "Back",
                        "forwardButton": "Next",
                        "first": "Welcome to SylloTips! Let’s take a quick tour of the app’s key features and sections.",
                        "second": "Start using SylloTips and create your very first Discussion! Want to share something? Need answers? Your colleagues will receive your post and get you all the help you need.",
                        "third": "Big news from your colleagues! Check your Announcements and let them know you have seen it.",
                        "forth": "The more you help, the more people give you points and the more you become the expert your company needs. See how others ranked your Expertise over time!",
                        "fifth": "You interacted a lot with the platform already and offered your help. Check how good everyone is doing on the Leaderboard and try to better your score!",
                        "sixth": "Anything happening on the platform, you’ll receive a Notification!",
                        "seventh": "Want to check your stats? Check your Profile!"
                    },
                    "snackBar":{
                        "snackbarErrorMessage": "Qualcosa è andato storto. Riprova più tardi."
                    },
                    "date":{
                        "jan": "January",
                        "feb": "February",
                        "mar": "March",
                        "apr": "April",
                        "may": "May",
                        "jun": "June",
                        "jul": "July",
                        "aug": "August",
                        "sep": "September",
                        "oct": "October",
                        "nov": "November",
                        "dec": "December"
                    }   
                }
            },
            it: {
                translation: {
                    "announcementPopover": {
                        "title": "Annunci",
                        "actionButton": "Crea",
                        "emptyPageTitle": "Ancora nulla da vedere qui!",
                        "viwedDescription": "visualizzato",
                        "gotItDescription": "got it!",
                        "emptyPageDescription": "Gli annunci saranno visibili in questa sezione",
                        "contactActionButton": "Contatta {{value}}",
                        "deleteActionButton": "Elimina annuncio",
                    },
                    "authPage": {
                        "title": "Accedi a SylloTips",
                        "nextStepButton": "Continua",
                        "forgotYourPasswordCaption": "Hai dimenticato la tua password? Clicca qui per impostarne una nuova",
                        "resetPasswordCaption": "Abbiamo inviato un'email a {{email}}. Segui le istruzioni per reimpostare la tua password!",
                        "signInButton": "Accedi",
                        "loginWithProvider": "o accedi con",
                        "microsoftProvider": "Continua con Microsoft",
                        "eulaCaption": "Procedendo con l'accesso, accetti i Termini e Condizioni di Utilizzo e l'Informativa sulla Privacy dell'app."
                    },
                    "cardCreationPage": {
                        "discussionTitle": "Crea una nuova discussione",
                        "announcementTitle": "Pubblica un annuncio",
                        "actionButton": "Pubblica",
                        "selectChannelButton": "Seleziona un canale",
                        "cardTitlePlaceHolder": "Titolo",
                        "submitAnywayActionButton": "Invia comunque"
                    },
                    "channelSideBar": {
                        "chatTitle": "Chat",
                        "announcementTitle": "Annunci",
                        "discussionTitle": "Discussioni",
                        "documentTitle": "Documenti",
                        "memberTitle": "Visualizza membri",
                        "title": "Canali",
                        "leaveChannelAction": "Lascia il canale",
                        "openChannelSettings":"Apri settings del canale",
                        "unmuteChannelAction": "Attiva notifiche",
                        "muteChannelAction": "Disattiva notifiche",
                        "mutedChannelCaption": "Canali silenziati",
                        "channelCaption": "I tuoi canali",
                        "yourDiscussionCaption": "Le tue discussioni",
                        "followedCaption": "Discussioni seguite"
                    },
                    "channelAnnouncementPage": {
                        "title": "Annunci di {{channelName}}",
                        "actionButton": "Pubblica un annuncio",
                        "emptyCaption": "Non ci sono ancora annunci in questo canale",
                        "emptyDescription": "Gli annunci di questo canale saranno visibili qui"
                    },
                    "channelChatPage": {
                        "title": "Chat di {{channelName}}",
                        "welcomeMessage": "Benvenuto nella chat di {{channelName}}",
                        "welcomeMessageDescription": "Questo è il posto dove puoi chattare con i tuoi colleghi",
                        "replyingToMessageDesctiption": "Stai rispondendo a ",
                        "reactButton": "Reagisci",
                        "replyButton": "Rispondi",
                        "editButton": "Modifica",
                        "deleteButton": "Elimina",
                        "inputBasePlaceHolder": "Invia un messaggio in {{channelName}}",
                        "modifyMessageHelperText": "Premi esc per annullare • Premi invio per salvare"
                    },
                    "channelDiscussionPage": {
                        "title": "Discussioni di {{channelName}}",
                        "actionButton": "Crea una nuova discussione",
                        "discussionForYouTab": "Per te",
                        "allDiscussionTab": "Tutte",
                        "emptyCaption": "Nessuna discussione per te!",
                        "emptyDescriptionActiveDiscussion": "Appena una discussione sarà aperta la troverai qui.",
                        "emptyDescriptionCloseDiscussion": "Quando una discussione sarà chiusa, la troverai qui.",
                        "emptyCaptionAllChannel": "Nessuna discussione in questo canale",
                        "emptyDescriptionActiveDiscussionAllChannel": "Le discussioni aperte per te appariranno qui.",
                        "emptyDescriptionCloseDiscussionAllChannel": "Appena una discussione sarà aperta la troverai qui.",
                        "activeDiscussionButton": "Discussioni attive",
                        "closeDiscussionButton": "Discussioni chiuse"
                    },
                    "channelDocumentPage": {
                        "title": "Documenti di {{channelName}}",
                        "fileNameTableLabel": "Nome del file",
                        "uploadedByTableLabel": "Caricato da",
                        "createAdtTableLabel": "Aggiunto il",
                        "sizeTableLabel": "Dimensioni",
                        "statusTableLabel": "Stato",
                        "actionTableLabel": "Azione",
                        "searchHelperText": "Cerca documenti...",
                        "actionButton": "Carica documenti",
                        "uploadErrorCode": "ERRORE",
                        "uploadSuccessCode": "CARICATO",
                        "uploadPendingCode": "IN SOSPESO",
                        "noRowCaption": "Nessun documento trovato, cambia filtro o carica nuovi documenti",
                        "chatDocumentCaption": "Chatta con il documento",
                        "downloadDocumentCaption": "Scarica il documento",
                        "deleteDocumentCaption": "Elimina il documento",
                    },
                    "channelMemberPage": {
                        "title": "Membri del canale {{channelName}}",
                        "actionButton": "Aggiungi nuovi membri",
                        "titleAddMember": "Aggiungi membri a {{channelName}}",
                        "addMembersActionButton": "Fatto",
                        "removeMemberActionButton": "Rimuovi",
                        "addMemberActionButton": "Aggiungi",
                        "searchMemberHelperText": "Cerca membro..."
                    },
                    "chatPage": {
                        "sideBarChatTitle": "Chat",
                        "sideBarSearchHelperText": "Cerca nelle chat...",
                        "sideBarChatDescription": "Tutte le chat",
                        "dialogTitle": "Nuovo messaggio",
                        "dialogSearchHelperText": "Cerca tra gli utenti...",
                        "createConversationButton": "Inizia una conversazione",
                        "chatFooterHelperText": "Scrivi un messaggio..."
                    },
                    "createChannelPage": {
                        
                        "channelTypeTitle": "Che tipo di canale vuoi creare?",
                        "publicChannelGroupButton": "Canale pubblico",
                        "publicChannelDescription": "I canali pubblici sono visibili e accessibili da tutti i membri dell'organizzazione.",             
                        "privateChannelGroupButton": "Canale privato",
                        "privateChannelDescription": "Il contenuto all'interno dei canali privati è visibile solo dai membri inclusi nel canale.",
                        
                        "channelSettingTitle": "Alcuni dettagli in più sul tuo canale",
                        "channelName": "Nome del canale",
                        "channelSettingButton": "Impostazioni di canale",
                        "channelSettingsDisclaimer": "Sarai in grado di modificarli in seguito",
                        "discussionSettingsTitle":"Destinatari delle discussioni",
                        "automaticRoutingOptionTitle": "Solo i membri rilevanti",
                        "adminRoutingOptionTitle": "Solo gli amministratori del canale",
                        "allMembersRoutingOptionTitle":"Tutti i membri del canale",

                        "answerSettingsTitle":"Chi può scrivere risposte alle discussioni:",
                        "adminAnswerSettingOption":"Solo gli amministratori del canale",
                        "allMembersAnswerSettingOption":"Tutti i membri del canale",
                        "everyoneAnswerSettingOption":"Tutti i membri dell'organizazzione",

                        "announcementSettingsTitle":"Permessi per il Caricamento degli annunci",
                        "adminAnnouncementSettingOption":"Solo gli amministratori del canale",
                        "allMembersAnnouncementSettingOption":"Tutti i membri del canale",

                        "documentSettingsTitle":"Permessi per il Caricamento di Documenti",
                        "adminDocumentSettingOption":"Solo gli amministratori del canale",
                        "allMembersDocumentSettingOption":"Tutti i membri del canale",

                        "selectMembersActionButton": "Seleziona membri",
                        "selectMemberTitle": "Seleziona membri",
                        "selectMemberFieldHelperText": "Cerca membro...",
                        "createChannelButton": "Crea canale",
                        "backButton": "Indietro"
                    },
                    "customerServicePage": {
                        "title": "Come posso aiutarti?",
                        "titleFieldHelperText": "Titolo",
                        "descriptionFieldHelperText": "Facci sapere cosa pensi...",
                        "actionButton": "Invia",
                        "sidebarTitle": "Ticket",
                        "sidebarDescription": "Tutti i ticket",
                        "ticketTypeOne": "Richiesta di funzionalità",
                        "ticketTypeTwo": "Problema",
                        "ticketTypeSelectionButton": "Seleziona la tipologia di ticket",
                        "ticketSentDescription": "Grazie per il tuo tempo!  Apprezziamo sempre il feedback dei nostri utenti!",
                        "ticketSentActionButton": "Vai ai tuoi ticket",
                        "markAsSolvedAction": "Segna come risolto",
                        "deleteAction": "Elimina",
                        "customerServiceHelperText": "Qui puoi farci sapere tutto ciò che riguarda bug o funzionalità che vorresti vedere all'interno di SylloTips.",
                        "openTicketActionButton": "aperto",
                        "closedTicketActionButton": "chiuso"
                    },
                    "expertPage": {
                        "goBackButton": "Torna indietro",
                        "sendRewardButton": "Invia ricompensa",
                        "firstTitle": "Fai sapere ",
                        "secondTitle": "che apprezzi il suo aiuto",
                        "firstDescription": "Ti rimangono",
                        "secondDescription": "punti",
                        "helperText": "Scegli al massimo 3 competenze",
                        "sendGiftButton": "Invia regalo a {{displayName}}",
                        "contactButton": "Contatta",
                        "dashboardTitle": "Dashboard",
                        "dashboardPointDescription": "Punti totali",
                        "dashboardPositionDescription": "Ranking",
                        "dashboardTrophyDescription": "Medaglie ottenute",
                        "barPlotTitle": "Attività",
                        "weeklyDate": "settimana",
                        "monthlyDate": "mese",
                        "yearlyDate": "anno",
                        "medalTitle": "Medaglie",
                        "achievementTitle": "Traguardi",
                        "levelDescription": "Livello {{level}}",
                        "bestExpertiseTitle": "Migliori competenze",
                        "expertiseTitle": "Competenza"
                    },
                    "expertisePopOver": {
                        "title": "Competenza",
                        "expertiseNameTableLabel": "Competenza",
                        "showAll": "mostra tutto",
                        "expertiseScoreTableLabel": "Punteggio",
                        "searchHelperText": "Cerca competenze...",
                        "multipleSelection": "selezionate"
                    },
                    "feedPage": {
                        "actionButton": "Crea una discussione",
                        "filterButton": "Filtra per",
                        "filterButtonCaption": "Seleziona un canale",
                        "sortButton": "Ordina per",
                        "sortFirstOption": "Aggiornamenti più recenti",
                        "sortSecondOption": "Più discusse",
                        "emptyTitle": "Niente da vedere qui!",
                        "emptyDescription": "Le discussioni nuove e aggiornate appariranno qui.",
                        "leaveDiscussionAction": "Lascia la discussione",
                        "deleteDiscussionAction": "Chiudi la discussione",
                        "newUpdateCaption": "{{value}} nuovi aggiornamenti",
                        "newDiscussionCaption": "nuova discussione",
                        "closedDiscussionCaption": "La discussione è chiusa"
                    },
                    "gptPage": {
                        "allChatTitle": "Tutte le chat",
                        "gptHelperText": "Ciao! Come posso aiutarti oggi?",
                        "footerHelperText": "Digita il tuo messaggio...",
                        "gptMessageReferences": "Riferimenti",
                        "gptWaitingMessage": "Per favore attendi un momento mentre raccolgo e analizzo i documenti necessari per rispondere alle tue domande.",
                        "gptCreateAQuestionDescription": "Non sei soddisfatto della risposta?",
                        "gptCreateAQuestionButton": "Apri una nuova discussione",
                        "gptDiscalimer": "Sybil può commettere errori.  Verifica sempre le informazioni importanti."
                    },
                    "introductionPage": {
                        "titleFirstStep": "Su di te",
                        "addProfilePicDescription": "Aggiungi una foto del profilo",
                        "tellAboutYouDescription": "Dicci qualcosa su di te",
                        "tellAboutYouHelperText": "Sintetizza il tuo ruolo nell'organizzazione",
                        "continueButton": "continua",
                        "titleSecondStep": "Dicci in cosa sei esperto",
                        "searchHelperText": "Cerca competenze nella tua organizzazione",
                        "doneButton": "Aggiorna il tuo profilo"
                    },
                    "searchPage": {
                        "filterButton": "Filtra per",
                        "filterButtonCaption": "Seleziona canali",
                        "helperText": "Cerca all'interno di SylloTips",
                        "discussionTab": "Discussioni ({{value}})",
                        "announcementTab": "Annunci ({{value}})",
                        "expertTab": "Esperti ({{value}})",
                        "colleaguesTab": "Colleghi ({{value}})",
                        "documentTab": "Documenti ({{value}})",
                        "documentDescription": "Caricato da {{value}}"
                    },
                    "sideBar": {
                        "homeCaption": "Home",
                        "yourThreadCaption": "Discussioni",
                        "followedThreadCaption": "Seguiti",
                        "chatCaption": "Chat",
                        "gptCaption": "Sybil",
                        "customerServiceCaption": "Feedback",
                        "channelCaptions": "Spazi"
                    },
                    "threadPage": {
                        "replyCaption":"Aggiungi una risposta...",
                        "replyBuutton": "Rispondi",
                        "repliesIconDescription": "risposte",
                        "contributorsIconDescription": "collaboratori",
                        "replyIconDescription": "Rispondi",
                        "totalRepliesCaption": "risposte",
                        "answersIconDescription": "risposte",
                        "deleteThread": "Elimina la discussione",
                        "editThread": "Modifica la discussione",
                        "followThread": "Segui la discussione",
                        "unfollowThread": "Smetti di seguire la discussione",
                        "saveButton": "salva",
                        "sendButton": "Invia la risposta"
                    },
                    "userThreadPage": {
                        "emptyTitle": "Niente da vedere qui!",
                        "emptyDescription": "Le tue discussioni  appariranno qui."
                    },
                    "userFollowedPage": {
                        "emptyTitle": "Niente da vedere qui!",
                        "emptyDescription": "Le discussioni seguite appariranno qui."
                    },
                    "notFoundPage": {
                        "title": "404 - Non trovato",
                        "description": "Spiacenti, la discussione che stai cercando non esiste."
                    },
                    "notificationPopOver": {
                        "title": "Notifiche"
                    },
                    "leaderboardPopOver": {
                        "title": "Classifica"
                    },
                    "profilePopOver": {
                        "profileTitle": "Profilo",
                        "dashboard": "Dashboard"
                    },
                    "alertDialog": {
                        "alertTitle": "Ops, sembra ci sia stato un problema!",
                        "sizeMessage": "la dimensione del corpo della domanda è maggiore di 1 Mb",
                        "documentUploadLimit": "Hai selezionato troppi documenti. Limita la selezione a un massimo di 10 documenti alla volta.",
                        "documentSizeLimit": "La dimensione totale dei documenti supera la dimensione massima consentita (50Mb)"
                    },
                    "deleteDialog": {
                        "deleteMessageTitle": "Elimina messaggio",
                        "deleteMessageDescription": "Sei sicuro di voler eliminare questo messaggio? Questa azione non può essere annullata.",
                        "deleteMessageAction": "Elimina messaggio",
                        
                        "deleteDiscussionTitle": "Elimina discussione",
                        "deleteDiscussionDescription": "Sei sicuro di voler eliminare questa discussione? Questa azione non può essere annullata.",
                        "deleteDiscussionAction": "Elimina discussione",
                        
                        "leaveDiscussionTitle": "Lascia la discussione",
                        "leaveDiscussionDescription": "Sei sicuro di voler lasciare questa discussione? Questa azione non può essere annullata.",
                        "leaveDiscussionAction": "Lascia la discussione",
                        
                        "closeDiscussionTitle": "Chiudi la discussione",
                        "closeDiscussionDescription": "Sei sicuro di voler chiudere questa discussione? Questa azione non può essere annullata.",
                        "closeDiscussionAction": "Chiudi la discussione",
                        
                        "deleteAnswerTitle": "Elimina la risposta",
                        "deleteAnswerDescription": "Sei sicuro di voler eliminare questa risposta? Questa azione non può essere annullata.",
                        "deleteAnswerAction": "Elimina la risposta",
                        
                        "deleteChannelTitle": "Elimina il canale",
                        "deleteChannelDescription": "Sei sicuro di voler lasciare questo canale? Questa azione non può essere annullata.",
                        "deleteChannelAction": "Lascia il canale",
                        
                        "deleteAnnouncementTitle": "Elimina l'annuncio",
                        "deleteAnnouncementDescription": "Sei sicuro di voler eliminare questo annuncio? Questa azione non può essere annullata",
                        "deleteAnnouncementAction": "Elimina l'annuncio"
                    },
                    "channelSettingsDialog":{
                        "title": "Impostazioni del canale",
                        "saveButtonText": "Salva"
                    },
                    "toolTips": {
                        "skipButton": "Salta",
                        "backButton": "Indietro",
                        "forwardButton": "Avanti",
                        "first": "Benvenuto in SylloTips! Facciamo un breve tour delle funzionalità principali dell'app e delle varie sezioni.",
                        "second": "Inizia a usare SylloTips e crea la tua prima discussione! Vuoi condividere qualcosa? Hai bisogno di risposte? I tuoi colleghi riceveranno il tuo post e ti daranno tutto l'aiuto di cui hai bisogno.",
                        "third": "Grandi novità dai tuoi colleghi! Controlla le tue Comunicazioni e fagli sapere di averle viste.",
                        "forth": "Più aiuti, più le persone ti danno punti e più diventi l'esperto di cui la tua azienda ha bisogno. Controlla come gli altri hanno valutato le tue competenze nel tempo!",
                        "fifth": "Hai già interagito molto con la piattaforma e offerto il tuo aiuto. Controlla come stanno andando gli altri nella Classifica e cerca di migliorare il tuo punteggio!",
                        "sixth": "Qualsiasi cosa accada sulla piattaforma, riceverai una Notifica!",
                        "seventh": "Vuoi controllare le tue statistiche? Consulta il tuo Profilo!"
                    },
                    "snackBar":{
                        "snackbarErrorMessage": "Qualcosa è andato storto. Riprova più tardi."
                    },
                    "date":{
                        "jan": "Gennaio",
                        "feb": "Febbraio",
                        "mar": "Marzo",
                        "apr": "Aprile",
                        "may": "Maggio",
                        "jun": "Giugno",
                        "jul": "Luglio",
                        "aug": "Agosto",
                        "sep": "Settembre",
                        "oct": "Ottobre",
                        "nov": "Novembre",
                        "dec": "Dicembre"
                    },
                    "changelogDialog":{
                        "title":"What’s new in SylloTips",
                        "releaseCaptioon": "NEW RELEASE", 
                        "description": "- Abbiamo spostato **Le tue Discussioni** e **Discussioni Seguite** nella nuova sezione **Spazi**, per rendere la barra laterale più ordinata.\n\n- Abbiamo aggiunto nuovi suggerimenti per gli utenti al primo accesso.\n\n- Abbiamo inserito le richieste di conferma per le azioni irreversibili.\n\n- Abbiamo aggiustato una serie di errori di minor rilevanza."
                    }
                }
            }
        }
    });

export default i18n;