import { createSlice } from "@reduxjs/toolkit";
import { ExpertiseState } from "./expertise_state";
import { cancelSelection, changeExpertisePageNumber, changeExpertiseRowNumber, deleteExpertise, fetchYourExpertise, selectExpertise, updateExpertiseSearch } from "./expertise_action";
import { TagModel } from "../../../model/response/tag_model";
const initialState: ExpertiseState = {
    expertise: [],
    expertiseSelected: [],
    expertiseSearch: "",
    lastSeenExpertise: undefined,
    scrollExpertise: true,
    expertiseTablePage: 1,
    expertiseTableNumberOfRows: 10
}

const expertiseSlice = createSlice({
    name: 'expertise',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(fetchYourExpertise.fulfilled,(state, payload) => {

            if (payload.payload.fetchAllExpertise){
                state.expertise = payload.payload.tags;
                state.lastSeenExpertise =  payload.payload.lastElement;
                state.scrollExpertise = payload.payload.continueToScroll;
                state.expertiseSelected = [];
                
            }else{
                const currentExpertise = state.expertise;
                payload.payload.tags.map((tag) => currentExpertise.push(tag))
                state.expertise = currentExpertise;
                state.lastSeenExpertise = payload.payload.lastElement;
                state.scrollExpertise = payload.payload.continueToScroll;
            }
            

        })
        .addCase(changeExpertisePageNumber,(state,payload) => {
            state.expertiseTablePage = payload.payload.page;
        })
        .addCase(changeExpertiseRowNumber,(state,payload) => {
            state.expertiseTableNumberOfRows = payload.payload.row;
        })
        .addCase(selectExpertise, (state,payload) => {
            const expertiseSelected = state.expertiseSelected;
            if(expertiseSelected.includes(payload.payload.expertise)){
                state.expertiseSelected = expertiseSelected.filter((expertise)=> expertise !== payload.payload.expertise)
            }else{
                expertiseSelected.push(payload.payload.expertise)
                state.expertiseSelected = expertiseSelected;
            }

        })
        .addCase(cancelSelection, (state, payload) => {
            state.expertiseSelected = [];
        })
        
        .addCase(deleteExpertise.fulfilled, (state,payload) => {
            const filteredExpertise: TagModel[] =  []

            state.expertise.map((expertise) => {
                if(!payload.payload.deletedExpertise.includes(expertise.id)){
                    filteredExpertise.push(expertise);
                }
            });
            state.expertiseSelected = [];
            state.expertise = filteredExpertise;
        })
        .addCase(updateExpertiseSearch,(state,payload) => {
            state.expertiseSearch = payload.payload.search   
        })
    }
});

export default expertiseSlice.reducer;