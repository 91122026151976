import React, { useEffect, useState } from "react";
import { InputBase,Autocomplete, Paper, IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from "react-redux";
import { UserModel } from "../../model/response/user_model";
import { AppDispatch, RootState } from "../../app/store";
import { computeSemanticSearch, setSearchQuery } from "../../features/search/api/search_action";
import { SearchModel } from "../../model/request/search_model";
import { goForward } from "../../features/navigation/navigation_action";
import { Page } from "../../features/navigation/navigation_state";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";




export const SearchBar: React.FC = () => {
  
  const [showClearIcon, setShowClearIcon] = useState(false)
  
  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  const channelFilters: string[] =  useSelector((state: RootState) => state.search.filters);
  const searchedText: string =  useSelector((state: RootState) => state.search.searchQuery);
  
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()
  
  
  const handleTextChange = (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (value != null){
      if(value.target.value.trim() !== ""){
        setShowClearIcon(true);
      }else{
        
      }
      dispatch(setSearchQuery(value.target.value))
    }
  }
  
  const clearText = () => {
    dispatch(setSearchQuery(''));
    setShowClearIcon(false);
  }

  const search = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter'){
      if (user && searchedText !== ''){
        const searchModel: SearchModel = {
          userId: user.id,
          query: searchedText,
          channelIds: channelFilters
        }
        dispatch(goForward(Page.SearchPage));
        dispatch(computeSemanticSearch(searchModel));
        navigate('/search/' + searchedText.replace(/\//g, ''));
      }
    }
  }

  useEffect(
    () => {
      if (user && searchedText !== ''){
        const searchModel: SearchModel = {
          userId: user.id,
          query: searchedText,
          channelIds: channelFilters
        }
        dispatch(goForward(Page.SearchPage));
        dispatch(computeSemanticSearch(searchModel));
        navigate('/search/' + searchedText);
      }
    },[channelFilters.length]
  );


  const normalStyle = {
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
    display:'flex',
    borderRadius: '10px',
    width: '60vw',
  }

  const autocompleteStyle = {
    display:'flex',
    //borderRadius: '25px 25px 0 0', 
    borderRadius: '90px',
    width: '50%',
  }

  return (
    <Paper id={'header_search_bar'}
    sx={
      normalStyle
    }>
      <InputBase
        sx={
          {
            fontSize: '16px',
            padding: '0px 0px 0px 20px',
            width: '100%',
          }
        }
        placeholder={t('searchPage.helperText')}
        
        onChange={(event) => handleTextChange(event)}
        onKeyDown={(event) => search(event)}
        value={searchedText}
        endAdornment={
          !showClearIcon ? (
          <IconButton>
            <SearchIcon style={
              {
                alignSelf:'center',
              }
            }/>
          </IconButton>): 
          <IconButton onClick={() => clearText()}>
          <ClearIcon 
          style={
            {
              alignSelf:'center',
            }
          }/>
        </IconButton>
        }
      />
     </Paper>
  )
};

