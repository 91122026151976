import { Avatar, Box, Button, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { ActionButton } from "../../../components/action-button/action_button";
import { useFilePicker } from "use-file-picker";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { ImageFileSizeValidator } from "../../../utils/file_picker_validator/image_picker_validator";
import { updateBio, updatePhoto } from "../api/introduction_action";
import { useTranslation } from "react-i18next";


export interface UpdateProfileDialogProps{
    onContinueClick: () => void
}

export const UpdateProfileDialog: React.FC<UpdateProfileDialogProps> = (props:UpdateProfileDialogProps) => {

    const bio: string = useSelector((state: RootState) => state.introduction.bio);
    const photo: string | undefined = useSelector((state: RootState) => state.introduction.photo);
    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [openFileSelector, { filesContent, loading }] = useFilePicker({
        accept: 'image/*',
        readAs: "DataURL",
        limitFilesConfig: { min: 1, max:1 },
        validators: [
            new ImageFileSizeValidator()
        ],
        onFilesRejected: (data) => {
            const maxLimitExceeded = data.errors[0].maxLimitExceeded;
            const fileSize = data.errors[0].fileSizeToolarge;
            
            if (maxLimitExceeded) {
                dispatch(changeDialog(
                    {
                        dialog: DialogType.alertDialog,
                        alertTitle: 'Oops, it appears there was an issue!',
                        alertMessage: 'You have selected too many documents. Please limit your selection to a maximum of 10 documents at a time.',
                    }
                ));
            } else if (fileSize) {
                dispatch(changeDialog(
                    {
                        dialog: DialogType.alertDialog,
                        alertTitle: 'Oops, it appears there was an issue!',
                        alertMessage: 'The total size of  documents exeeds the maximum size allowed (50Mb)',
                    }
                ));

            }
        },
        
        onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
            filesContent.map((fileContent, index) => {
                dispatch(updatePhoto(fileContent.content))
            })
        },
    });



    return (
        <Stack padding={2} spacing={2} justifyContent='space-between'alignItems='center' sx={{
        }}>
            <Box sx={{ flex:'1'}} display='flex'  justifyContent='center'alignItems='center'>
                <Typography color={theme.palette.textColor?.main}variant='h5'>{t('introductionPage.titleFirstStep')}</Typography>
            </Box>
            <Stack spacing={1} width={'100%'} alignItems='center'>
                <Typography color={theme.palette.textColor?.main} variant='h6'>{t('introductionPage.addProfilePicDescription')}</Typography>
                <Avatar src={photo} onClick={openFileSelector}
                    sx={{ width:60, height:60, '&:hover':{ cursor:'pointer'  } }}
                />
            </Stack>
            <Stack width={'100%'} alignItems='center' spacing={2}>
                <Typography color={theme.palette.textColor?.main} variant='h6'>{t('introductionPage.tellAboutYouDescription')}</Typography>
                <TextField 
                    fullWidth 
                    label={t('introductionPage.tellAboutYouHelperText')}
                    value={bio}
                    onChange={(value) => dispatch(updateBio(value.currentTarget.value))}
                    />
            </Stack>
            <ActionButton isLoading={false} disabled={bio.trim() === ''} title={t('introductionPage.continueButton')}onClick={props.onContinueClick}/>
        </Stack>
    );
}