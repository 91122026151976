import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAnalytics, logEvent } from "firebase/analytics";
import { AnalyticsEvent } from "../../model/request/analytics_model";
import { debugPrint } from "../../utils/debug_print";

export const sendEvent = createAsyncThunk(
    'analytics/sendEvent',
    async (data:AnalyticsEvent, {rejectWithValue}) => {
        try{
            const analytics = getAnalytics();
            logEvent(analytics,data.eventName, data.event);

        }catch(error:any){
            debugPrint(error.toString(),'analytics/sendEvent');
            return rejectWithValue(error.toString());
        }
    }
);