import { Box, Stack } from "@mui/material"
import { GPTFooter } from "./gpt_footer"
import { GPTChatRoom } from "./gpt_room"
import { useEffect, useRef } from "react";
import { AIConversation, ConversationTurn } from "../../../model/response/gpt_conversation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { sendGPTFetchMessage, setIsSearchMessage } from "../api/gpt_action";
import { GPTMessage } from "../../../model/request/GPT_message";
import { UserModel } from "../../../model/response/user_model";

export const GPTBody = () => {
    
    const conversationRef = useRef<any>(null);
    const messages: ConversationTurn[] | null = useSelector((state: RootState) => state.gpt.turns);
    
    const isSearchMessage: boolean = useSelector((state: RootState) => state.gpt.isSearchMessage);
    const searchQuery: string = useSelector((state: RootState) => state.search.searchQuery);
    
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch<AppDispatch>()
    
    useEffect(() => {
        if (conversationRef !== null){
            conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
        }
    },[messages.length]);

    useEffect(() => {

        if(isSearchMessage && user && messages.length === 0){
            const data:GPTMessage ={
                channelIds: [],
                message: searchQuery,
                userId: user.id,
                conversationId: "",
            }
            dispatch(sendGPTFetchMessage(data))
            dispatch(setIsSearchMessage(false));
        }
       
    },[isSearchMessage])


    return (
        <Stack
            direction='column'
            alignItems={'center'}
            justifyContent={'center'}
            width={`calc(96vw - 320px )`}
            maxWidth={`calc(96vw - 320px )`}
            maxHeight={'92vh'}
            
        >
            <Box ref={conversationRef}  width={`80%`} 
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    position: "relative",
                    flexGrow: 1,
                    overflowY:'scroll',
                        '&::-webkit-scrollbar': {
                            display: 'none', // Customize the width of the scrollbar
                        },
                            
                    }}>
                <GPTChatRoom/>
            </Box>
            <Box width={`100%`} justifyContent={'center'} display={'flex'}>
                <GPTFooter/>
            </Box>
        </Stack>
    )
}