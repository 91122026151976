import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../app/store"
import { DialogType } from "../../dialog/dialog_state"
import { Box, CardMedia, Dialog, InputBase, Stack, TextField, Typography, useTheme } from "@mui/material"
import { changeDialog } from "../../dialog/dialog_action"
import { ActionButton } from "../../../components/action-button/action_button"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { FileType } from "../../../model/response/thread_draft_model"
import { useDropzone } from "react-dropzone"
import { Attachment } from "../../../model/response/card_model"
import { CardImages } from "../../../components/card-images/card_images"
import { UserModel } from "../../../model/response/user_model"
import { sendTicket, updateTicketStatus } from "../api/customer_service_action"
import { OrganizationModel } from "../../../model/response/organization_model"
import { SendTicketModel } from "../models/request/send_ticket_model"
import { ChooseTicketType } from "./choose_ticket_type"
import { TicketSentStatus } from "../api/customer_service_state"
import logo from '../../../assets/Logox3.png';

export const CreateTicketDialog: React.FC = () => {

    const [title,setTitle] = useState<string>('');
    const [description,setDescription] = useState<string>('');
    const [attachments, setAttachments] =useState<Attachment[]>([]);
    
    const dialog = useSelector((state: RootState) => state.dialog.currentDialog);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const organization: OrganizationModel | null = useSelector((state: RootState) => state.org.currentOrganization);

    const ticketSentStatus:TicketSentStatus = useSelector((state: RootState) => state.customerService.ticketSentStatus);
    const ticketType = useSelector((state: RootState) => state.customerService.ticketType);
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const {t} = useTranslation();

    const send = () => {
        if(user && organization){
            
            const data:SendTicketModel = {
                organizationName: organization.name,
                organizationId: organization.id,
                title: title,
                description: description,
                type: ticketType,
                user: {
                    senderId: user.id,
                    senderDisplayName: user.displayName,
                    senderPhoto: user.photo,
                },
                attachments: attachments
            }
            dispatch(sendTicket(data))
        }
    }

    const onDrop = useCallback(async acceptedFiles =>  {

        await Promise.all(
            acceptedFiles.map(async (file) => {
                
                const data:Attachment = {
                    name: file.name,
                    url: await readFileAsDataURL(file),
                    storageRef: "",
                    type: file.type.startsWith('image/')?FileType.IMAGE:FileType.DOCUMENT,
                    createdAt: "",
                    updatedAt: ""
                }
                setAttachments([...attachments, data]);
            })
        );
        
    }, [attachments.length]);

    const {getRootProps,} = useDropzone({onDrop,noClick:true});

    const readFileAsDataURL = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
                if (loadEvent.target?.result) {
                    resolve(loadEvent.target.result as string);
                }
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const onPaste = async (event: React.ClipboardEvent<HTMLInputElement>) => {
        const clipboardData = event.clipboardData;
        let files: File[] = [];

        if (clipboardData && clipboardData.items) {
            event.preventDefault();

            const text = clipboardData.getData("text/plain");
            if (text !== "") {
                setDescription(description + text);
            }

            for (let i = 0; i < clipboardData.items.length; i++) {
                if (clipboardData.items[i].kind === 'file') {
                    const file = clipboardData.items[i].getAsFile();
                    if (file) files.push(file)
                }
            }

            await Promise.all(
                files.map(async (file) => {

                    const data:Attachment = {
                        name: file.name,
                        url: await readFileAsDataURL(file),
                        storageRef: "",
                        type: file.type.startsWith('image/')?FileType.IMAGE:FileType.DOCUMENT,
                        createdAt: "",
                        updatedAt: ""
                    }
                    
                    setAttachments([...attachments, data]);
                })
            );
        }
    }

    if(ticketSentStatus === TicketSentStatus.sent){
        return (
            <Dialog
                PaperProps={{ sx: { borderRadius: "10px" } }}
                fullWidth
                open={dialog === DialogType.createTicketDialog}
                sx={{
                    overflowY: 'hidden'
                }}
                onClose={() => {
                    dispatch(changeDialog({dialog: DialogType.none,}));
                    dispatch(updateTicketStatus(TicketSentStatus.none));
                }}
            >
            <Stack justifyContent={'center'} alignItems={'center'} spacing={2} p={2}>
                <CardMedia
                component="img"
                image={logo}
                sx={{
                    width: '50px',
                    objectFit: 'contain', // Fit the image to the dimensions while maintaining aspect ratio
                }}
                />
                <Typography variant="body1" textAlign='center'>
                {t('customerServicePage.ticketSentDescription')}
                </Typography>
                <ActionButton 
                isLoading={false} 
                disabled={false} title={t('customerServicePage.ticketSentActionButton')} 
                onClick={() => {
                    dispatch(changeDialog({dialog: DialogType.none,}));
                    dispatch(updateTicketStatus(TicketSentStatus.none));
                }}/>
            </Stack>
      </Dialog>
        )
    }

    return (
        <Dialog
                PaperProps={{ sx: { borderRadius: "10px" } }}
                fullWidth
                open={dialog === DialogType.createTicketDialog}
                sx={{
                    overflowY: 'hidden'
                }}
                onClose={() => dispatch(changeDialog({dialog: DialogType.none,}))}
            >
                <Stack p={2} overflow={'hidden'} flexDirection={'column'} spacing={2} >
                    <Box display='flex' flexDirection='row' justifyContent='space-between' >
                        <Typography
                            variant="h6"
                            align="center"
                            color={theme.palette.textColor?.main}>
                            {t('customerServicePage.title')}
                        </Typography>
                    </Box>
                    <ChooseTicketType/>
                    <TextField 
                    InputProps={{ sx: { borderRadius: '10px' } }}
                    placeholder={ t('customerServicePage.titleFieldHelperText')}   
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}/>
                    <Box 
                        display= 'flex' flexDirection='column' {...getRootProps()} 
                        border={1} borderRadius={'10px'} borderColor={'#D9D9D9'} p={1}
                        sx={{'&:hover': {border: '1px solid #000', }}}
                    >
                        <InputBase
                            fullWidth
                            onPaste={onPaste}
                            onChange={(event) => setDescription(event.target.value)}
                            maxRows={5}
                            minRows={5}
                            multiline
                            placeholder={ t('customerServicePage.descriptionFieldHelperText')}  
                            value={description}
                            />
                    <CardImages images={attachments} isDeleteable={true} onDelete={
                        (image) => {
                            const currentAttachments = attachments.filter((attachment) => !attachment.url.includes(image.url));
                            setAttachments(currentAttachments);
                        }
                    }/>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='flex-end'>
                        <ActionButton
                            disabled={title.trim() === '' || description.trim() === ''}
                            isLoading={ticketSentStatus === TicketSentStatus.pending}
                            title={t('customerServicePage.actionButton')} 
                            onClick={send}/>
                    </Box>
                </Stack>
            </Dialog>
    )
}