import { useEffect } from "react";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import { ChatModel } from "../../../model/response/chat_model";
import { fetchCurrentChat, updateNotification } from "../api/chat_action";
import { MessageModel, MessageType } from "../../../model/response/message";
import { Stack } from "@mui/material";
import { ChatBubbleType, MediaMsg, TextMessage } from "./message";

export const ChatRoom = () => {
    
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const chats: ChatModel[] = useSelector((state: RootState) => state.chat.chats);
    const currentChat: ChatModel | null = useSelector((state: RootState) => state.chat.currentChat);
    const messages: MessageModel[] = useSelector((state: RootState) => state.chat.messages);
    const dispatch = useDispatch<AppDispatch>();
    
    


    useEffect( () => {

        
        if (user !== undefined && currentChat !== null && messages.length == 0 && currentChat.id !== ''){
            
            const db = getFirestore();
            const q = query(collection(db, "Users",user.id, "Chats",currentChat.id, "Messages",),orderBy('createdAt','asc'),);
            
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                dispatch(fetchCurrentChat(querySnapshot.docs.map(message => message.data() as MessageModel)))
                dispatch(updateNotification({
                    chat: currentChat,
                    userId: user.id
                }));
            });
    
            return () => {
                unsubscribe(); 
              };
        }

    },[currentChat,chats.length]);

    return (
        <Stack  height={'60vh'} spacing={0.5} p={1}>
        {messages.map((message,index) => {

            var bubble = ChatBubbleType.IsAlone;

            if (index === 0 && index === messages.length -1){
                bubble = ChatBubbleType.IsAlone;
            }else if (index === messages.length -1){
                const isAlone = messages[index].writerId !== messages[index - 1].writerId;
                if (!isAlone){
                    bubble = ChatBubbleType.IsLast;
                }else{
                    bubble = ChatBubbleType.IsAlone;
                }

            
            }else if (index === 0){
                const isAlone = messages[index].writerId !== messages[index + 1].writerId;

                if (!isAlone){
                    bubble = ChatBubbleType.IsFirst;
                }else{
                    bubble = ChatBubbleType.IsAlone;
                }

            }else{
                const isAlone = 
                    messages[index].writerId !== messages[index + 1].writerId && 
                    messages[index].writerId !== messages[index -1].writerId;
                
                if (!isAlone){
                    const inTheMiddle = 
                        messages[index].writerId === messages[index + 1].writerId && 
                        messages[index].writerId === messages[index -1].writerId;
                    
                    if(!inTheMiddle){
                        const isFirst = messages[index].writerId === messages[index + 1].writerId;

                        if (isFirst){
                            bubble = ChatBubbleType.IsFirst;
                        }else{
                            bubble = ChatBubbleType.IsLast;
                        }

                    } else{
                        bubble = ChatBubbleType.IsInTheMiddle;
                    }

                }else{
                    bubble = ChatBubbleType.IsAlone;
                }
            }

            if (message.messageType === MessageType.Text){
                return (
                    <TextMessage key={message.id} incoming={message.writerId !== user!.id} message={message.text} bubble={bubble}/>
                )
            }else{
                return (
                    <MediaMsg key={message.id} incoming={message.writerId !== user!.id} message={message.text} photo={message.photo!}/>
                )
            }    
        }
    )
    }
    </Stack>
    )
}