import {
    Card, Typography, Box, CardContent, styled, InputBase,
    alpha, Button, useTheme, LinearProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { UserModel } from '../../../model/response/user_model';
import { AppDispatch, RootState } from '../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useFilePicker } from 'use-file-picker';
import { changeDialog } from '../../dialog/dialog_action';
import { DialogType } from '../../dialog/dialog_state';
import { useTranslation } from 'react-i18next';
import { fetchDocuments, updateDocumentQuery, uploadDocuments } from '../api/document_action';
import { FetchDocumentModel, UploadDocumentModel } from '../../../model/request/documents_model';
import { ChannelModel, DocumentsChannelPermissions } from '../../../model/response/channel_model';
import { FileSizeValidator } from '../../../utils/file_picker_validator/file_picker_validator';
import { DocumentModel, DocumentStatus } from '../../../model/response/document_model';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FilterButton } from './filter_button';
import { DocumentAction } from './document_action';
import { ActionButton } from '../../../components/action-button/action_button';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        width: "100%",
    },
}));

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: 20,
    backgroundColor: alpha(theme.palette.background.paper, 1),
    marginRight: theme.spacing(2),
    marginLeft: 0,
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));



export interface ChannelDocumentsProps { }

export const ChannelDocuments: React.FC<ChannelDocumentsProps> = () => {

    const user: UserModel | undefined = useSelector((root: RootState) => root.auth.user);
    const channel: ChannelModel | null = useSelector((root: RootState) => root.channel.currentChannel);
    const files: DocumentModel[] = useSelector((root: RootState) => root.document.files);
    const waitingForDocumentUpload: boolean = useSelector((root: RootState) => root.document.waitingForDocumentUpload);
    const documentQuery = useSelector((root: RootState) => root.document.documentQuery);
    const [isPersonClicked, setIsPersonClicked] = useState<boolean>(false);

    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const { t, i18n } = useTranslation();

    var disableSelectionButton = true;
    if (channel){
        if(channel.permissions.includes(DocumentsChannelPermissions.ONLY_ADMINS)){
            disableSelectionButton = !channel.isAdmin;

        }else{
            disableSelectionButton = false;
        }
    }

    const [openFileSelector, { filesContent, loading }] = useFilePicker({
        accept: ['application/pdf', '.txt', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        readAs: "DataURL",
        limitFilesConfig: { min: 1 },
        validators: [
            new FileSizeValidator()
        ],
        onFilesRejected: (data) => {
            const maxLimitExceeded = data.errors[0].maxLimitExceeded;
            const fileSize = data.errors[0].fileSizeToolarge;
            if (user) {
                if (maxLimitExceeded) {
                    dispatch(changeDialog(
                        {
                            dialog: DialogType.alertDialog,
                            alertTitle: 'Oops, it appears there was an issue!',
                            alertMessage: 'You have selected too many documents. Please limit your selection to a maximum of 10 documents at a time.',
                        }
                    ));
                } else if (fileSize) {
                    dispatch(changeDialog(
                        {
                            dialog: DialogType.alertDialog,
                            alertTitle: 'Oops, it appears there was an issue!',
                            alertMessage: 'The total size of  documents exeeds the maximum size allowed (50Mb)',
                        }
                    ));

                }
            }

        },
        onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
            const dataUrls: string[] = [];
            const names: string[] = [];
            const sizes: string[] = [];
            const types: string[] = [];
            const sizeInBytes: number[] = [];
            
            filesContent.map((fileContent, index) => {
                const fileSize = (plainFiles[index].size / (1024 * 1024)).toFixed(2);
                sizeInBytes.push(plainFiles[index].size);
                sizes.push(fileSize);
                names.push(plainFiles[index].name);
                dataUrls.push(fileContent.content);
                types.push(plainFiles[index].type);
            });

            if (user && channel) {
                const data: UploadDocumentModel = {
                    organizationId: user.organizationId,
                    channelId: channel.channelId,
                    fileContents: dataUrls,
                    fileNames: names,
                    types: types,
                    sizes: sizes,
                    uploaderUser: {
                        uploaderDisplayName: user.displayName,
                        uploaderId: user.id
                    },
                    sizeInBytes: sizeInBytes,
                }

                dispatch(uploadDocuments(data));
            }
        },
    });
    useEffect(
        () => {
            if (user && channel) {
                const data: FetchDocumentModel = {
                    organizationId: user.organizationId,
                    channelId: channel.channelId,
                }

                dispatch(fetchDocuments(data));
            }
        }, [user, files.length, channel]
    );

    const getStatus = (documentStatus: DocumentStatus) => {
        var status: string = 'ERROR';
        var color: string = 'red';
        switch (documentStatus) {
            case DocumentStatus.PENDING:
                status = t('channelDocumentPage.uploadPendingCode');
                color = '#F6BE00';
                break;
            case DocumentStatus.UPLOADED:
                status =  t('channelDocumentPage.uploadSuccessCode');
                color = 'green';
                break;
            case DocumentStatus.ERROR:
                status = t('channelDocumentPage.uploadErrorCode');
                color = 'red';
                break;
        }

        return {
            status: status,
            color: color,
        }

    }

    var userDisplayName = '';

    if (user && isPersonClicked) {
        userDisplayName = user.displayName;
    }

    const rows = files.filter((document) => document.fileName.toLowerCase().includes(documentQuery)).
        filter((document) => document.uploaderUser.uploaderDisplayName.includes(userDisplayName)).
        map((file, index) => ({
            id: file.id,
            url: file.url,
            fileName: file.fileName,
            uploaderUser: file.uploaderUser,
            createdAt: file.createdAt,
            fileSize: file.fileSize,
            documentStatus: file.documentStatus,
        }));

    const columns = [
        { field: 'fileName', headerName: t('channelDocumentPage.fileNameTableLabel'), flex: 1, editable: false, disableColumnMenu: true, },
        { field: 'uploaderUser', headerName: t('channelDocumentPage.uploadedByTableLabel'), flex: 1, editable: false, disableColumnMenu: true,
            valueGetter: ({ value }) => value.uploaderDisplayName,
        },
        {
            field: 'createdAt', headerName: t('channelDocumentPage.createAdtTableLabel'), flex: 1, editable: false, disableColumnMenu: true, type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
        },
        { field: 'fileSize', headerName: t('channelDocumentPage.sizeTableLabel'), flex: 1, editable: false, disableColumnMenu: true, },
        {
            field: 'documentStatus',
            headerName: t('channelDocumentPage.statusTableLabel'),
            renderCell: (params) => {
                const status = getStatus(params.row.documentStatus);
                return (
                    <Box color={status.color}>
                        <Typography>{status.status}</Typography>
                    </Box>
                );
            },
            flex: 1,
            editable: false,
            disableColumnMenu: true,
        },
        {
            field: 'action',
            headerName: t('channelDocumentPage.actionTableLabel'),
            renderCell: (params) => (
                <DocumentAction
                    id={params.row.id}
                    url={params.row.url}
                    isOwner={params.row.uploaderUser.uploaderId === user!.id}
                    organizationId={user!.organizationId} 
                    fileName={params.row.fileName} />
            ),
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },

    ];

    return (
        <Card sx={{ boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', marginTop: 2, width: '95%', height: '90vh', borderRadius: 5 }}>
            {waitingForDocumentUpload && <LinearProgress />}
            <CardContent>
                <Typography variant="h5" gutterBottom color={theme.palette.textColor?.main}>
                    {t('channelDocumentPage.title',{channelName: channel?.channelName})}
                </Typography>
            </CardContent>
            <CardContent>
                <Box sx={{ width: "100%" }} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                value={documentQuery}
                                onChange={(event) => {
                                    dispatch(updateDocumentQuery(event.target.value));
                                }}
                                placeholder={t('channelDocumentPage.searchHelperText')}
                                inputProps={{ "aria-label": "search" }}
                            />
                        </Search>
                        <FilterButton
                            onPersonClick={() => setIsPersonClicked(true)}
                            onTeamClick={() => setIsPersonClicked(false)}
                            isPersonClicked={isPersonClicked} />
                    </Box>
                        <ActionButton 
                            isLoading={false} 
                            disabled={disableSelectionButton} 
                            title={t('channelDocumentPage.actionButton')} 
                            onClick={() => openFileSelector()}                        />
                </Box>
            </CardContent>
            <DataGrid
                localeText={{ noRowsLabel: t('channelDocumentPage.noRowCaption') }}

                sx={{
                    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                        outline: "none !important",
                    },
                }}
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                disableDensitySelector


            />
        </Card>
    );
}