import { Box, Stack, Typography, useTheme } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FilterChannelPopOver } from "./filter_channel_pop_over";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { ChannelModel } from "../../../model/response/channel_model";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const FilterComponent: React.FC = () => {
    const theme = useTheme();
    const {t} = useTranslation();

    const channels: ChannelModel[] = useSelector((state:RootState) => state.channel.channels )
    const firstChannelSelected: string | undefined = useSelector((state:RootState) => state.feed.firstChannelSelected )
    const numberOfChannelSelected: string[] = useSelector((state:RootState) => state.feed.filterByChannels )

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [anchorEl, setAnchor] = useState<HTMLSpanElement | null>(null);

    
    const openPopOver = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        setIsOpen(true);
        setAnchor(event.currentTarget);
    };

    const closePopOver = () => {
        setIsOpen(false);
        setAnchor(null);
    }

    var title = t('feedPage.filterButtonCaption');

    if(firstChannelSelected){
        title = firstChannelSelected;
        
        if(numberOfChannelSelected.length -1 > 0){
            title = firstChannelSelected + ' +'+ (numberOfChannelSelected.length -1).toString();
        }
    }

    

    return (
        <Stack direction='row' spacing={2}>
            <Typography color={theme.palette.textColor?.main}>
                {t('feedPage.filterButton')}
            </Typography>
            <Stack direction='row' spacing={1} onClick={(event) => openPopOver(event)}>
                <Typography fontWeight={600} color={theme.palette.textColor?.main} 
                >
                    {title}
                </Typography>
                <Box display={'flex'} color={theme.palette.textColor?.main}>
                    <KeyboardArrowDownIcon />
                </Box>
            </Stack>
            <FilterChannelPopOver
            anchor={anchorEl} 
            channels={channels} 
            open={isOpen} 
            onClose={() => closePopOver()}/>
        </Stack>
    );
}