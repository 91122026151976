import { createAction } from "@reduxjs/toolkit";
import { ErrorType } from "./error_state";

export const visualizeErrorMessage = createAction(
    'error/visualizeErrorMessage',
    (errorType: ErrorType) => ({
        payload: {
            errorType,
        }
    })
);