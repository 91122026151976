import { createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentData, Query, collection, getDocs, getFirestore, orderBy, query, startAfter } from "firebase/firestore";
import { LeaderboardModel } from "../../../model/response/leaderboard_model";
import { FetchUserTagsModel } from "../../../model/request/fetch_profile_models";
import { FetchLeaderboardModel } from "../../../model/request/fetch_leaderboard_model";

export const fetchLeaderboard = createAsyncThunk(
    'leaderboard/fetchLeaderboard',
    async (data:FetchLeaderboardModel, { rejectWithValue }) => {
        try{
            const db = getFirestore();
            const currentleaderboardQuery = query(collection(db, "Organizations", data.organizationId, 'Leaderboards')); 
            const docs = await getDocs(currentleaderboardQuery);
            var currentLeaderboard = ''; 
            
            
            if( docs.docs.length === 1){
                currentLeaderboard = docs.docs[0].data().id;
            }else{
                return rejectWithValue('error');
            }

            var leaderboardQuery:Query<DocumentData>;

            if (data.lastElement){
                leaderboardQuery = query(collection(db, "Organizations", data.organizationId, 'Leaderboards', currentLeaderboard, 'UserInLeaderboards'),orderBy('totalPointsEarned','desc'),startAfter(data.lastElement.totalPointsEarned))
            }else{
                leaderboardQuery = query(collection(db, "Organizations", data.organizationId, 'Leaderboards', currentLeaderboard,'UserInLeaderboards'),orderBy('totalPointsEarned','desc'))
            }
             

            const leaderboardSnapshot = await getDocs(leaderboardQuery);
            const leaderboard: LeaderboardModel[] = []
            leaderboardSnapshot.docs.map((doc) => {
                leaderboard.push(doc.data() as LeaderboardModel);
            });

            if(leaderboard.length == 0){
                return {   
                    leaderboard:[],
                    lastElement: undefined,
                    continueToScroll: false,
                }
            } else if (leaderboard.length < data.size){
                return {
                    leaderboard:leaderboard,
                    lastElement: leaderboardSnapshot.docs[leaderboardSnapshot.docs.length - 1].data(),
                    continueToScroll: false,
                }
            }else{
                return {
                    leaderboard:leaderboard,
                    lastElement: leaderboardSnapshot.docs[leaderboardSnapshot.docs.length - 1].data(),
                    continueToScroll: true,
                }
            }

        }catch(error){
            return rejectWithValue('error');
        }

    });