import React from 'react';
import { Avatar, Box, Card, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';


export interface CardPostProps {
    className?: string;
    photo: string;
    onClick: () => void;

}

export const CardPost: React.FC<CardPostProps> = (props: CardPostProps) => {

    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <Card id={'post_discussion'} elevation={0}
            onClick={props.onClick}
            sx={{
                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                width: '60vw',
                borderRadius: '8px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                    cursor: 'pointer'
                }
            }}>
            <Stack direction='row' alignItems='center' p={2} spacing={2}>
                <Avatar src={props.photo}/>
                <Box sx={{backgroundColor:'#F5F5F5', borderRadius: '8px',}} p={1.2} display='flex' flexGrow={1}>
                    <Typography color={theme.palette.textColor!.main}>
                        {t('feedPage.actionButton')}
                    </Typography>
                </Box>
            </Stack>
        </Card>
    );
};