import './card-editor.css'
import { FileType, ThreadDraftModel } from "../../../model/response/thread_draft_model";
import { saveImages, } from "../api/card_creation_action";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { CardType } from "../../../model/response/card_model";
import { ChannelModel } from "../../../model/response/channel_model";
import { useCallback, useEffect, useMemo, useRef, } from "react";
import { UserMentionModel } from "../../../model/response/user_mention_model";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../../utils/firebase_config";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import Quill, { RangeStatic } from "quill";
import { useDropzone } from 'react-dropzone';


export interface QuillEditorProps{
    expand:boolean;
    setText: (value:string) => void;
    setDelta: (value:string) => void;
}
export const QuillEditor: React.FC<QuillEditorProps> = (props:QuillEditorProps) => {
    
    const draft: ThreadDraftModel | undefined = useSelector((state: RootState) => state.cardCreation.cardDraft);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const type: CardType = useSelector((state: RootState) => state.cardCreation.type);
    const selectedChannel: ChannelModel | undefined = useSelector((state: RootState) => state.cardCreation.selectedChannel);
    
    const currentCursorPosition = useRef<RangeStatic | null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const onDrop = useCallback(async acceptedFiles =>  {

        const dataUrls:string[] = [];
        const filenames:string[] = [];
        const fileTypes:FileType[] = [];

        await Promise.all(
            acceptedFiles.map(async (file) => {
                if (file && file.type.startsWith('image/')) {
                    const dataUrl = await readFileAsDataURL(file);
                    dataUrls.push(dataUrl);
                    filenames.push(file.name);
                    fileTypes.push(FileType.IMAGE);
                } else if (file) {
                    const dataUrl = await readFileAsDataURL(file);
                    dataUrls.push(dataUrl);
                    filenames.push(file.name);
                    fileTypes.push(FileType.DOCUMENT);
                }
            })
        );


        if (user && dataUrls.length > 0) {
            dispatch(saveImages({
                images: dataUrls,
                fileNames: filenames,
                fileTypes: fileTypes,
                userId: user.id,
                type: type,
                selectedChannelId: selectedChannel?.channelId || '',
                draftId: draft?.id
            })) 
        }
        
    }, [draft]);
    const {getRootProps,} = useDropzone({onDrop,noClick:true});

    function debounce(func: Function, wait:number) {
        let timeout: string | number | NodeJS.Timeout | undefined;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    const debouncedSearch = debounce(async (renderList: Function,searchTerm: string) => {
        
        if(user && selectedChannel && !searchTerm.includes(" ")) {
            const data = {
                fullname: searchTerm,
                organizationId: user.organizationId,
                channelId: selectedChannel.channelId,
            };
    
            try {
                const functions = getFunctions(firebaseApp, 'europe-west1');
                const searchUsers = httpsCallable(functions, 'searchUserByFullnameFunction');
    
                const results = await searchUsers({ user: data });
                const users = results.data as UserSuggestedModel[];; // Assuming your environment handles the casting
    
                const userToTags = users.map((user) => ({
                    id: user.id,
                    value: user.displayName,
                    photo: user.photo,
                    belongsToChannel: user.belongsToChannel,
                }));
    
                renderList(userToTags.filter(user => user.belongsToChannel), searchTerm);
            } catch (error:any) {
                console.error(error.toString());
            }
        }
    }, 300);

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: "#toolbar",
            },
            magicUrl: true,
            
            mention: {
                onSelect: (item, insertItem) => {
                    insertItem(item);
                },
                renderItem: (data) => {
                    const div = document.createElement('div');
                    div.className = 'mention-item';

                    const img = document.createElement('img');
                    img.className = 'mention-avatar';
                    img.src = data.photo;

                    const span = document.createElement('span');
                    span.textContent = data.value;

                    div.appendChild(img);
                    div.appendChild(span);

                    return div;
                },
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                mentionDenotationChars: ["@"],
                defaultMenuOrientation: 'top',
                fixMentionsToQuill: true,
                source: async (searchTerm:string, renderList:Function,mentionChar:string) => {
                    
                    if(searchTerm.trim() == ""){
                        renderList([],searchTerm);
                    }
                    
                    debouncedSearch(renderList,searchTerm)                    
                }
            }
        };
    }, [user, selectedChannel]);
    const editorRef = useRef<HTMLDivElement >(null);  // Create a ref for the editor container
    
    const readFileAsDataURL = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
                if (loadEvent.target?.result) {
                    resolve(loadEvent.target.result as string);
                }
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const onPaste = async (event: ClipboardEvent, quill: Quill) => {
        const clipboardData = event.clipboardData;
        let files:File [] = [];
        let dataUrls: string[] = [];
        let filenames: string[] = [];
        let fileTypes: FileType[] = [];
        
        if (clipboardData && clipboardData.items) {
            event.preventDefault();
            
            const text = clipboardData.getData("text/plain");
            if(text !== ""){
                quill.focus();
                const range = quill.getSelection();
                
                if (range) {
                    quill.insertText(range.index, text);
                    setTimeout(() => {
                        quill.setSelection(range.index + text.length, 0,'api');
                    }, 1);
                }
            }

             // Prevent default paste action
            for (let i = 0; i < clipboardData.items.length; i++) {
                if (clipboardData.items[i].kind === 'file') {
                    const file = clipboardData.items[i].getAsFile();
                    if (file) files.push(file)
                } 
            }
            
            await Promise.all(
                files.map(async (file) => {
                    if (file && file.type.startsWith('image/')) {
                            
                        const dataUrl = await readFileAsDataURL(file);
                        dataUrls.push(dataUrl);
                        filenames.push(file.name);
                        fileTypes.push(FileType.IMAGE);
                    } else if (file) {
                        const dataUrl = await readFileAsDataURL(file);
                        dataUrls.push(dataUrl);
                        filenames.push(file.name);
                        fileTypes.push(FileType.DOCUMENT);
        
                    }
                })
            );


            if (user && dataUrls.length > 0) {

                dispatch(saveImages({
                    images: dataUrls,
                    fileNames: filenames,
                    fileTypes: fileTypes,
                    userId: user.id,
                    type: type,
                    selectedChannelId: selectedChannel?.channelId || '',
                    draftId: draft?.id
                })) 
            }
        }
    };

    useEffect(() => {
        const quill = new Quill('#card-editor', {
            theme: 'snow',
            modules: modules
        });
        if (quill) {

            quill.setSelection(currentCursorPosition.current?.index || 0, currentCursorPosition.current?.length || 0, 'api')
            
            quill.root.addEventListener('paste', (e:ClipboardEvent) => onPaste(e, quill));            
            quill.on('text-change', function() {
                const deltaContent = quill.getContents();
                const range = quill.getSelection();

                if (deltaContent){
                    const plainText = quill.getText();
                    const deltaString = JSON.stringify(deltaContent.ops);
                    props.setDelta(deltaString);
                    props.setText(plainText);
                }
              });
        }

        return () => {
            if (quill) {
                quill.root.removeEventListener('paste', (e:ClipboardEvent) => onPaste(e, quill));
            }
        };
    }, [editorRef,modules]);

    useEffect(() => {
       
        const editorElement = document.getElementById("card-editor")
        var editorArea = document.querySelector('#card-editor .ql-editor');
        if (editorElement && editorArea) {
            
            var editorContainer = editorArea as HTMLElement;
            editorContainer.style.height = props.expand?'500px':'200px'; // Set to your desired expanded height
            editorElement.style.height = props.expand?'500px':'200px'; // Set to your desired expanded height
        } 
      }, [editorRef,props.expand, selectedChannel]);

    return (
        <div ref={editorRef} id="card-editor" {...getRootProps()} >
        </div>
    );
}
