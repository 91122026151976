import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ChannelModel, ChannelsType } from "../../../model/response/channel_model";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ChannelAvatar } from "./channel_avatar";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { UserModel } from "../../../model/response/user_model";

export interface ChannelListProps{
    isMuted: boolean;
    channels: ChannelModel[];
}

export const ChannelList: React.FC<ChannelListProps> = (props:ChannelListProps) => {

    const [isVisible, setIsVisible] = useState<boolean>(!props.isMuted);
    const searchQuery: string = useSelector((state: RootState) => state.channel.searchQuery);
    const selectedChannel: ChannelModel | null = useSelector((state: RootState) => state.channel.currentChannel);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const theme = useTheme();
    const {t} = useTranslation();
    const displayVisibleChannels = () => {
        if(!isVisible ) return (<></>);

        if(user === undefined) return (<></>);

        return props.channels.filter((element) => element.channelName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()
            )).map((channel, index) => 
         <ChannelAvatar key={channel.id}
            userId={user.id}
            isSelected={(selectedChannel !== null && selectedChannel.channelId === channel.channelId)}
            channel={channel} 
            hasNotification={
                (channel.chatBalance?.hasNotifications || false) || channel.discussionBalance.hasNotifications}            
        />)
    }

    if (props.channels.length === 0) return (<></>);
    
    return (
        <Box p={1}>
            <Stack direction='column'>
                <Box sx={{'&:hover':{cursor:'pointer'}}} onClick={() => setIsVisible(!isVisible)}>
                    <Stack direction='row'>
                        <Box color={theme.palette.textColor?.main}>
                            {isVisible?<KeyboardArrowDownIcon />:<KeyboardArrowRightIcon/>}
                        </Box>
                        <Typography variant="body1" color={theme.palette.textColor?.main} fontWeight={500}>
                            {props.isMuted?t('channelSideBar.mutedChannelCaption'):t(t('channelSideBar.channelCaption'))}
                        </Typography>
                    </Stack>
                </Box>
                {displayVisibleChannels()}
            </Stack>
        </Box>
    )
}