import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import { MobileDownloadComponent } from "../components/mobile-download/mobile_download_component";
import { useResponsive } from "../hook/use_responsive";

const AuthLayout = () => {
  const { isMobile, isTablet, isDesktop } = useResponsive();

  if (isMobile) {
    return (
      <MobileDownloadComponent />
    );
  } else {

    return (
      <>
        <Container sx={{ mt: 5 }} maxWidth="sm">
          <Outlet />
        </Container>
      </>
    );
  }
};

export default AuthLayout;