import { Box, Typography, useTheme } from "@mui/material"
import { ChannelModel } from "../../../model/response/channel_model";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";

export const ChannelChatHeader: React.FC = () => {

    const theme = useTheme();
    const {t} = useTranslation();
    const selectedChannel: ChannelModel | null = useSelector((state: RootState) => state.channel.currentChannel);

    return (
        <Box p={1} sx={{ backgroundColor: '#F0F2F5' }}>
            <Typography variant='h6' fontWeight={600} color={theme.palette.textColor?.main}>
                {t('channelChatPage.title',{channelName: selectedChannel?.channelName})}
            </Typography>
        </Box>
    )

}

