import { createSlice } from "@reduxjs/toolkit";
import { CreateChannelState } from "./create_channel_state";
import { addSuggestedMember, changeAnnouncementPermission, changeAnswerPermission, changeChannelStep, changeChannelTitle, changeChannelType, changeDiscussionPermission, changeDocumentPermission, clearCreateChannelState, createChannel, removeSuggestedMember } from "./create_channel_action";
import { AnnouncementsChannelPermissions, AnswersChannelPermissions, DiscussionsChannelPermissions, DocumentsChannelPermissions } from "../../../model/response/channel_model";

const initialState: CreateChannelState = {
    currentStep: 0,
    isPrivate: false,
    membersToAdd: [],
    title:'',
    discussionPermission: DiscussionsChannelPermissions.ONLY_ROUTED,
    answerPermission: AnswersChannelPermissions.MEMBERS,
    announcementPermission: AnnouncementsChannelPermissions.EVERYONE,
    documentPermission: DocumentsChannelPermissions.EVERYONE
}

const createChannelSlice = createSlice({
    name: 'createChannel',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(clearCreateChannelState, (state) => {
            state.currentStep = 0;
            state.isPrivate = false;
            state.membersToAdd = [];
            state.title = '';
            state.discussionPermission = DiscussionsChannelPermissions.ONLY_ROUTED;
            state.answerPermission = AnswersChannelPermissions.MEMBERS;
            state.announcementPermission = AnnouncementsChannelPermissions.EVERYONE;
            state.documentPermission = DocumentsChannelPermissions.EVERYONE;
            

        })
        .addCase(changeChannelStep, (state, action) => {
            state.currentStep = action.payload.step;
        })
        .addCase(changeChannelType, (state, action) => {
            state.isPrivate = action.payload.isPrivate;
            state.currentStep = 1;

            if(state.isPrivate){
                state.discussionPermission = DiscussionsChannelPermissions.EVERYONE;
            }
        })
        .addCase(changeChannelTitle, (state, action) => {
            state.title = action.payload.title;
        } )
        .addCase(changeDiscussionPermission, (state, action) => {
            state.discussionPermission = action.payload.permission;
        })
        .addCase(changeAnswerPermission, (state, action) => {
            state.answerPermission = action.payload.permission;
        })
        .addCase(changeAnnouncementPermission, (state, action) => {
            state.announcementPermission = action.payload.permission;
        })
        .addCase(changeDocumentPermission, (state, action) => {
            state.documentPermission = action.payload.permission;
        })
        .addCase(createChannel.fulfilled, (state) => {
        })
        .addCase(addSuggestedMember,(state, payload) => {
            const currentMembers = state.membersToAdd;
            
            const filteredMembers = currentMembers.filter((member) => member.id === payload.payload.member.id);
            
            if(filteredMembers.length === 0){
                currentMembers.push(payload.payload.member)
            }
                
            state.membersToAdd = currentMembers;

        })
        .addCase(removeSuggestedMember,(state, payload) => {
            const currentMembers = state.membersToAdd;
            state.membersToAdd = currentMembers.filter((member) => member.id !== payload.payload.member.id);
        })
        
    }
});

export default createChannelSlice.reducer;