import { Box, List, ListItem, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { collection, getFirestore, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { CardModel, CardType } from "../../../model/response/card_model";
import InfiniteScroll from "react-infinite-scroll-component";
import { CardTile } from "../../../components/card-tile/card-tile";
import { DiscussionToggle } from "../../../components/discussion-toggle/discussion_toggle";
import { fetchUserThreads, loadMoreUserThreads } from "../api/user_thread_action";
import { EmptyComponent } from "../../../components/empty-component/empty_component";
import { useTranslation } from "react-i18next";


const YourThreadsPage = () => {

    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const yourThreads: CardModel[] = useSelector((state: RootState) => state.userThread.userThreads);
    const hasMore: boolean = useSelector((state: RootState) => state.userThread.scrollUserThreads);
    const currentLimit: number = useSelector((state: RootState) => state.userThread.userThreadLimit);

    const dispatch = useDispatch<AppDispatch>();
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const fetch = () => dispatch(loadMoreUserThreads(currentLimit));
    
    const {t} = useTranslation();
    const theme = useTheme();
    
    useEffect(
        () => {
            if (user != undefined) {
                const db = getFirestore();
                const q = query(
                    collection(db, 'Users', user.id, 'Threads'),
                    where('type', '==', CardType.discussion),
                    where('isOwned', '==', true),
                    where('isClosed', '==', isClosed),
                    orderBy('updatedAt', 'desc'),
                    limit(currentLimit)
                );

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const feed: CardModel[] = querySnapshot.docs.map(doc => doc.data() as CardModel);
                    dispatch(fetchUserThreads(feed));
                });

                return () => {
                    unsubscribe();
                };
            }
        }, [user, currentLimit, isClosed]
    );

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            flexGrow={1}
            sx={{ backgroundColor: '#F7F9FC',}}>
                <Box
                display={'flex'}
                width={'60vw'}
                flexDirection={'column'}
                alignItems={'center'}>
                <Box
                    marginTop={2}
                    marginBottom={3}
                    display={'flex'}
                    width='100%'
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Typography variant='h5' color={theme.palette.textColor?.main}>
                       {t('channelSideBar.yourDiscussionCaption')}
                    </Typography>
                </Box>
            </Box>
            <Box
                id={'feedScrollable'}
                display={'flex'}
                flexDirection={'column'}
                height={'90vh'}
                sx={{
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {
                        display: 'none', // Customize the width of the scrollbar
                    }
                }}>
                <InfiniteScroll
                    dataLength={yourThreads.length}
                    next={fetch}
                    hasMore={hasMore}
                    scrollableTarget={'feedScrollable'}
                    loader={<></>}
                >
                    <List sx={{
                        width: 'inherit',
                        "&:hover": {
                            cursor: "pointer",
                        }
                    }}>
                        <ListItem>
                            <DiscussionToggle isClosed={isClosed}
                                onActiveClick={function (): void {
                                    setIsClosed(false)
                                }}
                                onClosedClick={function (): void {
                                    setIsClosed(true)
                                }} />
                        </ListItem>
                        {
                            yourThreads.length === 0 && 
                            <EmptyComponent 
                                paddingTop={25}
                                title={t('userThreadPage.emptyTitle')} 
                                subtitle={t('userThreadPage.emptyDescription')} />
                        }
                        {
                            yourThreads.map((thread) => {
                                return (
                                    <ListItem key={thread.id}>
                                        <CardTile stretch={false} isSearchedCard={false} {...thread} />
                                    </ListItem>

                                )
                            })
                        }
                    </List>
                </InfiniteScroll>
            </Box>
        </Box>
    );
}

export default YourThreadsPage;


