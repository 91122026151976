import { CardModel } from "../../model/response/card_model";
import { ChannelModel } from "../../model/response/channel_model";


export enum DialogType{

    alertDialog,
    deleteDialog,
    announcementDialog,
    createDiscussionDialog,
    channelSettingsDialog,
    createAnnouncementDialog,
    createChannelDialog,
    createTicketDialog,
    channelMembersDialog,
    expertDialog,
    updateProfileDialog,
    directMessageDialog,
    introductionDialog,
    changelogDialog,
    none
}

export enum DialogDeleteAction {
    deleteChatMessage,
    deleteDiscussion,
    leaveDiscussion,
    closeDiscussion,
    deleteAnswer,
    deleteChannel,
    deleteAnnouncement,
} 

export interface DialogState{
    
    currentDialog: DialogType;

    channel?: ChannelModel;
    announcement?: CardModel;
    expertId?: string;
    
    alertTitle?: string;
    alertMessage?:string;

    deleteAction?: DialogDeleteAction;
    messageId?: string;
    threadId?:string;
    cardId?: string;
    answerId?: string;
    channelId?: string;

}