import { Box, Popover, Stack, Typography, useTheme } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { TicketModel } from "../models/response/TicketModel";
import { UserModel } from "../../../model/response/user_model";
import { deleteTicket, markTicketAsSolved } from "../api/customer_service_action";
import { DeleteTicketModel, MarkAsSolveTicketModel } from "../models/request/delete_ticket";

export interface CustomerServiceChatActionProps{
    onClose: () => void;
    anchor: HTMLDivElement | null;
    open: boolean;
}


export const CustomerServiceChatAction: React.FC<CustomerServiceChatActionProps> = (props: CustomerServiceChatActionProps) => {
    
    const currentTicket:TicketModel | undefined = useSelector((state: RootState) => state.customerService.currentTicket);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    
    const deleteCurrentTicket = () => {
        if(user && currentTicket){
            const data:DeleteTicketModel = {
                organizationId: user.organizationId,
                ticketId: currentTicket.id
            }
            
            dispatch(deleteTicket(data));
            props.onClose();
        }
    }

    const solveCurrentTicket = () => {

        
        if(user && currentTicket){
            
            const data:MarkAsSolveTicketModel = {
                organizationId: user.organizationId,
                ticketId: currentTicket.id
            }
            
            dispatch(markTicketAsSolved(data));
            props.onClose();
        }
    }
    
    return (
        <Popover
                elevation={1}
                onClose={props.onClose}
                slotProps={{
                    paper:{
                        sx: {
                            borderRradius: '8px',
                            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                          },
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                anchorEl={props.anchor}
                open={props.open}>
                    <Stack direction={'column'} spacing={1} p={1}>
                        {
                            !currentTicket?.isSolved &&
                            <Box sx={{'&:hover':{cursor:'pointer'}}} onClick={solveCurrentTicket}>
                                <Typography>{t('customerServicePage.markAsSolvedAction')}</Typography>
                            </Box>
                        }
                        <Box sx={{'&:hover':{cursor:'pointer'}}} onClick={deleteCurrentTicket}>
                            <Typography>{t('customerServicePage.deleteAction')}</Typography>    
                        </Box>   
                    </Stack>
                </Popover>
    );
}

export const CustomerServiceChatHeader: React.FC = () => {
    
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const currentTicket = useSelector((state:RootState) => state.customerService.currentTicket);
    
    const openTicketActionPopOver = (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    }

    const closeTicketActionPopOver = () => {
        setAnchorEl(null);
    }

    if(currentTicket)
        return (
            <Box p={2} display='flex' flexDirection='row' justifyContent={'space-between'}
                sx={{ boxShadow: '0px 4px 2px -4px rgba(0,0,0,0.25) '}}
            >
                <Typography variant='body1' color={theme.palette.textColor?.main} fontWeight={600}>
                    {currentTicket.title}
                </Typography>
                    <Box 
                    onClick={openTicketActionPopOver}
                    color={theme.palette.textColor?.main} sx={{'&:hover':{cursor:'pointer'}}}>
                        <MoreVertIcon/>
                    </Box>
                <CustomerServiceChatAction 
                onClose={closeTicketActionPopOver} 
                anchor={anchorEl} open={anchorEl !== null}/>
            </Box>
        );
    
    return <></>;
}