import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getFunctions, httpsCallable } from "firebase/functions";
import { debugPrint } from "../../../utils/debug_print";
import { firebaseApp } from "../../../utils/firebase_config";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { AnalyticsEvent, createdChannelEvent } from "../../../model/request/analytics_model";
import { sendEvent } from "../../analitycs/analitycs_action";
import { DiscussionsChannelPermissions, AnswersChannelPermissions, AnnouncementsChannelPermissions, DocumentsChannelPermissions } from "../../../model/response/channel_model";
import { CreateChannelModel } from "../models/request/create_channel_model";
import { visualizeErrorMessage } from "../../error/api/error_actions";
import { ErrorType } from "../../error/api/error_state";

export const clearCreateChannelState = createAction(
    'createChannel/clearCreateChannelState',
    function prepare() {
        return {
            payload: {}
        }
    });

export const changeChannelType = createAction(
    'createChannel/changeChannelType',
    function prepare(isPrivate: boolean) {
        return {
            payload: {
                isPrivate: isPrivate
            },
        }
    });

export const changeChannelStep = createAction(
    'createChannel/changeChannelStep',
    function prepare(step: number) {
        return {
            payload: {
                step: step
            },
        }
    });

export const changeChannelTitle = createAction(
    'createChannel/changeChannelTitle',
    function prepare(title: string) {
        return {
            payload: {
                title: title
            },
        }
    });

export const changeDiscussionPermission = createAction(
    'createChannel/changeDiscussionPermission',
    function prepare(permission: DiscussionsChannelPermissions) {
        return {
            payload: {
                permission: permission
            },
        }
    });

export const changeAnswerPermission = createAction(
    'createChannel/changeAnswerPermission',
    function prepare(permission: AnswersChannelPermissions) {
        return {
            payload: {
                permission: permission
            },
        }
    });

export const changeAnnouncementPermission = createAction(
    'createChannel/changeAnnouncementPermission',
    function prepare(permission: AnnouncementsChannelPermissions) {
        return {
            payload: {
                permission: permission
            },
        }
    });

export const changeDocumentPermission = createAction(
    'createChannel/changeDocumentPermission',
    function prepare(permission: DocumentsChannelPermissions) {
        return {
            payload: {
                permission: permission
            },
        }
    });


export const addSuggestedMember = createAction(
    'createChannel/addSuggestedMember',
    function prepare(member: UserSuggestedModel) {
        return {
            payload: {
                member: member
            },
        }
    });


export const removeSuggestedMember = createAction(
    'createChannel/removeSuggestedMember',
    function prepare(member: UserSuggestedModel) {
        return {
            payload: {
                member: member
            },
        }
    });

    
export const createChannel = createAsyncThunk(
    'createChannel/createChannel',
    async (data: CreateChannelModel, { dispatch, rejectWithValue }) => {
        try {
            const functions = getFunctions(firebaseApp, 'europe-west1');
            const createChannelFunction = httpsCallable(functions, 'createChannelFunction');

            const start = performance.now();


            await createChannelFunction({
                channel: data
            });

            const log: AnalyticsEvent = {
                event: {timestamp: new Date().toISOString()},
                eventName: createdChannelEvent
            };

            dispatch(sendEvent(log));
            dispatch(clearCreateChannelState());

        } catch (error: any) {
            dispatch(visualizeErrorMessage(ErrorType.general));
            debugPrint(error.toString(), 'channel/createChannel');
            rejectWithValue(error.toString());
        }
    })