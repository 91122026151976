import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from "../../../app/store";
import { DateMode } from "../../../model/request/user_chart_model";
import { changeDateMode } from "../api/expert_action";

export interface BarChartProps {
    upvotes: number[];
    accolades: number[];
    openDiscussions: number[];
    answers: number[];
    attributions: number[];
    categories: string[];
}

export const BarChart = (props: BarChartProps) => {

    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const chartMode: DateMode = useSelector((state: RootState) => state.expert.chartMode);
    const { t, i18n } = useTranslation();
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    
    const months: string[] = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
    const chartOptions: ApexOptions = {
        colors: [
            '#8c1a6a',
            '#80cfa9',
            '#f7bfb4',
            '#7189ff',
            '#f58a07'
        ],
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        tooltip: {
            x: {
                show: true,
                format: 'dd MMM',
                formatter:  chartMode === DateMode.month?(val: number, opts?: any) => val.toString() + ' of ' + months[currentMonth]:undefined,
            },

        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,

            },
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: props.categories,
            tickAmount:chartMode === DateMode.month ?5: undefined
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'left',
            itemMargin: {
                horizontal: 10
            }

        },
        fill: {
            opacity: 1
        }
    };

    const chartData = [{
        name: 'upvotes',
        data: props.upvotes
    },
    {
        name: 'discussions',
        data: props.openDiscussions
    },
    {
        name: 'accolades',
        data: props.accolades
    },
    {
        name: 'answers',
        data: props.answers
    },
    {
        name: 'attributions',
        data: props.attributions
    }
    ]

    return (
        <Box p={2} sx={{
            borderRadius: '10px',
            backgroundColor: '#F7F9FC',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
        }}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                <Typography color={theme.palette.textColor?.main} fontWeight={600}>
                    {t('expertPage.barPlotTitle')}
                </Typography>
                <Stack direction={'row'} spacing={1} p={1}>
                    <Chip
                        sx={{
                            padding: '0px 8px',
                            margin: '1px',
                            color: chartMode === DateMode.week ? 'white' : null,
                            backgroundColor: chartMode === DateMode.week ? 'black' : null
                        }}
                        label={t('expertPage.weeklyDate')}
                        size='small'
                        variant={chartMode === DateMode.week ? 'filled' : 'outlined'}
                        onClick={() => { dispatch(changeDateMode(DateMode.week)) }}
                    />
                    <Chip
                        sx={{
                            padding: '0px 8px',
                            margin: '1px',
                            color: chartMode === DateMode.month ? 'white' : null,
                            backgroundColor: chartMode === DateMode.month ? 'black' : null
                        }}
                        label={t('expertPage.monthlyDate')}
                        size='small'
                        variant={chartMode === DateMode.month ? 'filled' : 'outlined'}
                        onClick={() => { dispatch(changeDateMode(DateMode.month)) }}
                    />
                    <Chip
                        sx={{
                            padding: '0px 8px',
                            margin: '1px',
                            color: chartMode === DateMode.year ? 'white' : null,
                            backgroundColor: chartMode === DateMode.year ? 'black' : null
                        }}
                        variant={chartMode === DateMode.year ? 'filled' : 'outlined'}
                        label={t('expertPage.yearlyDate')}
                        size='small'
                        onClick={() => { dispatch(changeDateMode(DateMode.year)) }}
                    />
                </Stack>
            </Box>
            <ReactApexChart
                    options={chartOptions}
                    series={chartData}
                    type="bar"
                    height="285"
                />
        </Box>
    );
};