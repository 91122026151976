import { Box, ListItemText, Typography, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { MouseEventHandler, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { deleteGPTChat } from "../api/gpt_action";
import { DeleteChatModel } from "../../../model/request/delete_chat";


export interface GPTElementPreferencesProps {
  isVisible: boolean;
  id: string;
  userId: string;
  isRenaming: boolean;
  onRenameClick: () => void;
}

export const GPTElementPreferences: React.FC<GPTElementPreferencesProps> = (props: GPTElementPreferencesProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const onDeleteClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    const data: DeleteChatModel = {

      userId: props.userId,
      chatId: props.id
    }

    dispatch(
      deleteGPTChat(data));
  }


  if (props.isVisible) {
    return (
      <Box onClick={onDeleteClick} color={theme.palette.textColor?.main}>
        <DeleteIcon />
      </Box>

    )
  } else {
    return (<></>);
  }
}


export interface GPTElement {
  title: string;
  caption?: string;
  id: string;
  userId: string;
  onClick?: () => void;
}

export const GPTElement = (props: GPTElement) => {

  const theme = useTheme();
  const [isHover, setIsHover] = useState(false);
  const [rename, setRename] = useState<boolean>(false);

  return (
    <Box
      p={1}
      borderRadius={'10px'}
      onMouseEnter={() => { setIsHover(true) }}
      onMouseLeave={() => { setIsHover(false) }}
      onClick={props.onClick}
      display='flex'
      flexDirection='row'
      justifyContent={'space-between'}
      alignItems='center'
      sx={{
        backgroundColor: (isHover) ? 'rgba(245, 245, 245, 1)' : undefined,
        '&:hover': {
          backgroundColor: 'rgba(245, 245, 245, 1)', cursor: 'pointer'
        }
      }}
    >
      <ListItemText
        style={
          {
            whiteSpace: 'nowrap',
            color: theme.palette.textColor?.main
          }}
        primary={
          <Typography
            color={theme.palette.textColor?.main}
            noWrap
          >
            {props.title}
          </Typography>
        }

        secondary={
          <Typography variant="body2"
            color={theme.palette.textColor?.main}
            noWrap
          >
            {props.caption}
          </Typography>
        }
      />
      <GPTElementPreferences
        isRenaming={rename}
        isVisible={isHover}
        id={props.id}
        userId={props.userId} onRenameClick={() => setRename(!rename)} />
    </Box>
  )
}