import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';

export interface AnswerVotesProps {
    className?: string;
    vote: number;
    isUpvoted: boolean;
    onUpvoteClick?: () => void;
}

export const AnswerVotes: React.FC<AnswerVotesProps> = (props: AnswerVotesProps) => {
    
    const theme = useTheme();
    
    return (
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
            <Box onClick={props.onUpvoteClick}>
                <ThumbUpOutlinedIcon sx={{color:props.isUpvoted?'#5876FF':'#848484'}}/>
            </Box>
            <Typography color={theme.palette.textColor?.main}>{props.vote}</Typography>
        </Stack>
    );
}