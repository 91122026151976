import { Dialog, Stack } from "@mui/material"
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { DialogType } from "../../dialog/dialog_state";
import { changeDialog } from "../../dialog/dialog_action";
import { UserModel } from "../../../model/response/user_model";
import { Medals } from "../components/medals";
import { ProfileExpertise } from "../components/profile_expertise";
import { TagModel } from "../../../model/response/tag_model";
import {  clearExpert, fetchAchievements, fetchExpert, fetchExpertise, fetchPosition, getUserChart } from "../api/expert_action";
import { useEffect } from "react";
import { FetchUserTagsModel } from "../../../model/request/fetch_profile_models";
import { ContentType } from "../api/expert_state";
import { MedalModel } from "../../../model/response/medal_model";
import { ProfileBadges } from "../components/profile_badges";
import { ProfileGeneral } from "../components/profile_general";
import { DateMode, UserChartModel } from "../../../model/request/user_chart_model";
import { BarChart } from "../components/bar_chart";
import { SendGiftDialog } from "../components/send_gift";
import { ProfileAvatar } from "../components/profile_avatar";
import { ProfileButton } from "../components/profile_buttons";
export const ExpertDialog = () => {
    
    const currentDialog: DialogType = useSelector((state:RootState) => state.dialog.currentDialog);
    const expertId: string | undefined = useSelector((state:RootState) => state.dialog.expertId);

    const expert: UserModel | undefined  = useSelector((state:RootState) => state.expert.expert);
    const position: number = useSelector((state:RootState) => state.expert.positionInLeaderboard);
    const user:UserModel | undefined  = useSelector((state:RootState) => state.profile.userProfile);
    const expertises: TagModel[] =  useSelector((state:RootState) => state.expert.expertises);
    const medals: MedalModel[] =  useSelector((state:RootState) => state.expert.medals);

    const contentType: ContentType = useSelector((state:RootState) => state.expert.contentType);
    
    const chartMode: DateMode = useSelector((state:RootState) => state.expert.chartMode);
    const upvotes: number[] = useSelector((state:RootState) => state.expert.upvotes);
    const accolades: number[] = useSelector((state:RootState) => state.expert.accolades);
    const answers: number[] = useSelector((state:RootState) => state.expert.answers);
    const attributions: number[] = useSelector((state:RootState) => state.expert.attributions);

    const openDiscussions: number[] = useSelector((state:RootState) => state.expert.openDiscussions);
    const categories: string[] = useSelector((state:RootState) => state.expert.categories);

    
    const dispatch = useDispatch<AppDispatch>();

    useEffect(
        () => {
            if(expertId) dispatch(fetchExpert({expertId:expertId}));
        },[expertId]
    );
    

    useEffect(() => {
        if(expert && expertises.length == 0 && medals.length == 0){
            const data:FetchUserTagsModel = {
                userId: expert.id,
                size: 10,
                fetchAllExpertise:true,
                lastElement:undefined
            }
            dispatch(fetchExpertise(data));
            dispatch(fetchAchievements(data));
            dispatch(fetchPosition(data));
        }
    },[expert]);


    useEffect(
        () => {
            if(expert && contentType === ContentType.dashboard){
                const data:UserChartModel = {
                    userId: expert.id,
                    mode: chartMode
                }
                dispatch(getUserChart(data));
            }

        },[chartMode, contentType]
    );


    if(expert && user){
       
        return (
            <Dialog open={currentDialog === DialogType.expertDialog}
            maxWidth='sm'
            fullWidth
            PaperProps={{ sx: { borderRadius: "10px" } }}
            onClose={() => {
                dispatch(clearExpert());
                dispatch(changeDialog({dialog: DialogType.none,}))
            }}>
                <Stack spacing={1} p={2} >
                    <ProfileAvatar isUser={expert.id === user.id} user={expert}/>
                    <ProfileButton 
                        isVisible={(expert.id !== user.id && contentType !== ContentType.sendGift)} 
                        expert={expert} userId={user.id}/>
                    { contentType === ContentType.sendGift && <SendGiftDialog/>}
                    { (contentType === ContentType.general || contentType === ContentType.dashboard) && <ProfileGeneral points={expert.totalPointsEarned} latestPosition={position} timesInTopThreePositions={expert!.prizesBalance.bronzePositions +  expert!.prizesBalance.silverPositions + expert!.prizesBalance.goldPositions}/>}
                    { contentType === ContentType.dashboard && <Medals {...expert!.prizesBalance}/>}
                    { contentType === ContentType.dashboard && <BarChart upvotes={upvotes} openDiscussions={openDiscussions} categories={categories} accolades={accolades} answers={answers} attributions={attributions}/>}
                    { (contentType === ContentType.general || contentType === ContentType.badges) && <ProfileBadges medals={medals}/>}
                    { (contentType === ContentType.general || contentType === ContentType.expertise) &&  <ProfileExpertise expertises={expertises} />}
                </Stack>
            </Dialog>
        )
    }else{
        return<></>
    }
}


