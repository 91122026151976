import { createSlice } from "@reduxjs/toolkit";
import { AnswerState, ThreadState } from "./thread_state";
import { addOptimisticVote, deleteAnswer, deleteAnswerImage, displayOptimisticAnswer, displayOptimisticComment, editAnswer, editAnswerOptimisticUpdate, editThread, editThreadOptimisticUpdate, followThread, leaveThread, loadThread, saveAnswerChanges, saveThreadChanges, sendComment, setCardId, setDraft, setThreadId, submitAnswer, unfollowThread, updateAnswerDraft, updateImagesOnAnswer, waitForAttachmentsToBeUploaded } from "./thread_action";
import { VotingType } from "../../../model/request/voting_model";
import { CommentModel } from "../../../model/response/comment_model";
import { SendCommentModel } from "../../../model/request/send_comment_model";
import { CardModel } from "../../../model/response/card_model";
import { AnswerModel } from "../../../model/response/answer_model";

const initialState: ThreadState = {
    cardId: undefined,
    threadId: undefined,
    card: undefined,
    pendingFiles:[],
    answerDraft:undefined,
    answerState:AnswerState.done,
    editThread:false,
    editAnswer: undefined,
    answers: [],
    swipers: [],
    senderUser: undefined,
    channel: undefined,
    threadIsLoading: false,
    threadIsCreating: false,

}
const threadSlice = createSlice({
    name: 'thread',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(setCardId, (state, payload) => {
            state.cardId = payload.payload.cardId;
            
            if (state.cardId === ''){
                state.channel = undefined;
                state.threadId = undefined;
                state.card = undefined;
                state.answerDraft = undefined;
                state.answers = [];
                state.swipers = [];
                state.senderUser = undefined;
                state.threadIsLoading = false;
                state.threadIsCreating = false;
            }
        })
        .addCase(setThreadId, (state, payload) => {
            state.threadId = payload.payload.threadId;
        })
        .addCase(displayOptimisticAnswer, (state, payload) => {
            const answers = state.answers;
            const card = state.card;
            
            if (card){
                card.isAnswered = true;
            }

            const optimisticAnswer = payload.payload.answer;
            answers.push(optimisticAnswer);

            state.answers = answers;
            state.card = card;
            
        })
        .addCase(editAnswerOptimisticUpdate, (state, payload) => {
            const currentAnswer = state.answers;
            const updatedAnswers: AnswerModel[] = [];
            currentAnswer.map((answer) => {
                const updatedAnswer:AnswerModel = {
                    ...answer,
                    plainText: answer.id == payload.payload.answer.answerId? payload.payload.answer.plainText:
                        answer.plainText,
                    richText: answer.id ==payload.payload.answer.answerId? payload.payload.answer.richText:
                        answer.richText,
                }

                updatedAnswers.push(updatedAnswer);
            });

            state.answers = updatedAnswers;
            
        })
        .addCase(leaveThread.fulfilled, (state) => {
            if(state.card){
                const currentThread = state.card;
                const updatedThread: CardModel = {
                    ...currentThread,
                    isClosed:true,
                };
                state.card = updatedThread;
            }  
        })
        .addCase(displayOptimisticComment, (state, payload) => {
            const comment = payload.payload.comment;
            const commentToSend = comment as CommentModel;
            const answerId = comment.answerId;
            const answerIndex = comment.answerIndex;

            if((answerId !== undefined) && (answerIndex !== undefined)){
                const answers = state.answers;
                if (answers[answerIndex!].comments !== undefined){
                    answers[answerIndex!].comments?.push(commentToSend);
                    answers[answerIndex!].totalComments = answers[answerIndex!].comments?.length!;
                }else{
                    answers[answerIndex!].comments = [commentToSend];
                    answers[answerIndex!].totalComments = answers[answerIndex!].comments?.length!;
                }

                state.answers = answers;
            
            }else{
                const card = state.card;
                if(card){
                    if (card.comments !== undefined){
                        card.comments?.push(commentToSend);
                    }else{
                        card.comments = [commentToSend];
                    }

                    state.card = card;
                }
                
            }

        })
        .addCase(addOptimisticVote, (state, payload) => {
            const vote = payload.payload.vote;
            const answers = state.answers;

            if (vote.type === VotingType.upVote){
                answers[vote.answerIndex].totalVotes += 1;
                answers[vote.answerIndex].upVotes.push(vote.userId);
                
            }else{
                    answers[vote.answerIndex].totalVotes -= 1;
                    answers[vote.answerIndex].upVotes = answers[vote.answerIndex].upVotes.filter(userId => userId !== vote.userId);
                
            }

            state.answers = answers;  
        })
        .addCase(loadThread.fulfilled, (state, payload) => {
            state.channel = payload.payload.channel;
            state.card = payload.payload.thread;
            state.answers = payload.payload.answers;
            state.swipers = payload.payload.swipers;
            state.senderUser = payload.payload.senderUser;
            state.threadIsLoading = false;
            state.answerDraft = payload.payload.draft;
        })
        .addCase(loadThread.pending, (state,) => {
            state.threadIsLoading = true;
        })
        .addCase(loadThread.rejected, (state,) => {
            state.threadIsLoading = false;
        })
        .addCase(editThread,(state,payload) => {
            state.editThread = payload.payload.edit;
        })
        .addCase(saveThreadChanges.fulfilled, (state, payload) => {
            if (state.card){
                const currentCard = state.card;
                const updatedCard:CardModel = {
                    ...currentCard,
                    cardText: payload.payload.cardText,
                    cardTitle: payload.payload.cardTitle,
                    cardRichText: payload.payload.cardRichText,
                }

                state.card = updatedCard;
            }
            
        })
        .addCase(editThreadOptimisticUpdate, (state, payload) => {
            if (state.card){
                const currentCard = state.card;
                const updatedCard:CardModel = {
                    ...currentCard,
                    cardText: payload.payload.thread.cardText,
                    cardTitle: payload.payload.thread.cardTitle,
                    cardRichText: payload.payload.thread.cardRichText,
                }

                state.card = updatedCard;
            }
        })
        .addCase(editAnswer,(state, payload) => {
            state.editAnswer = payload.payload.edit;
        })
        .addCase(saveAnswerChanges.fulfilled, (state, payload) => {
            const currentAnswer = state.answers;
            const updatedAnswers: AnswerModel[] = [];
            currentAnswer.map((answer) => {
                const updatedAnswer:AnswerModel = {
                    ...answer,
                    plainText: answer.id == payload.payload.answerId? payload.payload.plainText:
                        answer.plainText,
                    richText: answer.id ==payload.payload.answerId? payload.payload.richText:
                        answer.richText,
                }

                updatedAnswers.push(updatedAnswer);
            });

            state.answers = updatedAnswers;
        })
        .addCase(updateImagesOnAnswer, (state, payload) => {
            const currentDraft = state.answerDraft;
            
            if(currentDraft){
                const images = currentDraft.attachments;
                payload.payload.images.map((image) =>  images.push(image));
                currentDraft.attachments = images;

                state.answerDraft = currentDraft;
                state.pendingFiles = [];
            }   
            
        })
        .addCase(waitForAttachmentsToBeUploaded, (state, action) => {
            state.pendingFiles = action.payload.filesInLoading;

        })
        .addCase(deleteAnswerImage,(state, payload) => {

            const currentDraft = state.answerDraft;
            if(currentDraft){
                
                const images =  currentDraft.attachments.filter((image) => image.storageRef !== payload.payload.imageRef);
                currentDraft.attachments = images;

                state.answerDraft = currentDraft;
            }

        })
        .addCase(deleteAnswer.fulfilled, (state, payload) => {
            const currentAnswer = state.answers;
            const updatedAnswers: AnswerModel[] = [];
            const currentCard = state.card;
           
            if (currentCard){
                const updatedCard:CardModel = {
                    ...currentCard,
                    isAnswered:false,
                }

                state.card = updatedCard;
            }
            currentAnswer.map((answer) => {
                if(answer.id !== payload.payload.answerId){
                    updatedAnswers.push(answer);
                }
            });

            state.answers = updatedAnswers;
            
        })
        .addCase(updateAnswerDraft.fulfilled, (state, payload) => {
            
        })
        .addCase(setDraft, (state, payload) => {
            state.answerDraft = payload.payload.draft;
        })
        .addCase(submitAnswer.fulfilled, (state, payload) => {
            state.answerDraft = undefined;
            state.answerState = AnswerState.done;
        })
        .addCase(submitAnswer.pending, (state,) => {
            state.answerState = AnswerState.pending;
        })
        .addCase(submitAnswer.rejected, (state,) => {  
            const answers = state.answers;
            const filteredAnswers = answers.filter(answer => answer.id !== 'optimistic_answer');
            const card = state.card;
            
            if (card){
                card.isAnswered = false;
            }

            state.answers = filteredAnswers;
            state.card = card;
            state.answerState = AnswerState.done;
        })

        .addCase(sendComment.fulfilled, (state, payload) => {
        })
        .addCase(sendComment.pending, (state,) => {
        })
        .addCase(sendComment.rejected, (state,payload) => { 
            const comment = payload.payload as SendCommentModel;
            const answerId = comment.answerId;
            const answerIndex = comment.answerIndex;

            if((answerId !== undefined) && (answerIndex !== undefined)){
                const answers = state.answers;
                if (answers[answerIndex!].comments !== undefined){
                    answers[answerIndex!].comments = answers[answerIndex!].comments?.filter(answerComment => answerComment.id !== comment.id);
                    answers[answerIndex!].totalComments -= 1
                }
                state.answers = answers;
            }
        })
        .addCase(followThread.fulfilled, (state, payload) => {
            const card = state.card;
            if(card){
                const updatedCard: CardModel = {
                    ...card,
                    isFollowed: true,
                }
                state.card = updatedCard;
            }
        })
        .addCase(followThread.pending, (state, payload) => {
            const card = state.card;
            if(card){
                const updatedCard: CardModel = {
                    ...card,
                    isFollowed: true,
                }
                state.card = updatedCard;
            }
        })
        .addCase(followThread.rejected, (state, payload) => {
            const card = state.card;
            if(card){
                const updatedCard: CardModel = {
                    ...card,
                    isFollowed: false,
                }
                state.card = updatedCard;
            }
        })
        .addCase(unfollowThread.fulfilled, (state, payload) => {
            const card = state.card;
            if(card){
                const updatedCard: CardModel = {
                    ...card,
                    isFollowed: false,
                }
                state.card = updatedCard;
            }
        })
        .addCase(unfollowThread.pending, (state, payload) => {
            const card = state.card;
            if(card){
                const updatedCard: CardModel = {
                    ...card,
                    isFollowed: false,
                }
                state.card = updatedCard;
            }
        })
        .addCase(unfollowThread.rejected, (state, payload) => {
            const card = state.card;
            if(card){
                const updatedCard: CardModel = {
                    ...card,
                    isFollowed: true,
                }
                state.card = updatedCard;
            }
        })
    }
});

export default threadSlice.reducer;
