import { Box, Stack, Typography, useTheme } from "@mui/material";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { use } from "i18next";
import { useTranslation } from "react-i18next";

export interface BackButtonProps {
    onClick: () => void;
}

export const BackButton: React.FC<BackButtonProps> = (props: BackButtonProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    
    return (
        <Stack direction='row' onClick={props.onClick}
            sx={{ '&:hover': { cursor: 'pointer' } }}
        >
            <Box color={theme.palette.textColor?.main}>
                <KeyboardArrowLeftOutlinedIcon />
            </Box>
            <Typography >{t('createChannelPage.backButton')}</Typography>
        </Stack>
    );
}