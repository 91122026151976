import { Box } from "@mui/material";
import { ChannelDocuments } from "../components/channel_document";


const DocumentPage = () => {
  
  return (
    <Box
      display={'flex'} 
      flexDirection={'column'}
      alignItems={'center'}
      flexGrow={1}
      sx={{backgroundColor:'#F7F9FC'}}>
        <ChannelDocuments/>
    </Box>
  );
}

export default DocumentPage;


