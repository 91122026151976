import { createSlice } from "@reduxjs/toolkit";
import { ChannelChatAction, ChannelChatState } from "./channel_chat_state";
import { changeChannelChatAction, clearChannelChatCache, fetchChannelChatMessages, pullToRefreshMessages, removeAttachment, selectChannelMessage, selectMessageToAnswer, selectMessageToEdit, sendChatMessage, sendChatMessageOptimisticUpdate, uploadAttachment } from "./channel_chat_action";
import { ChannelBubbleModel } from "../../../model/response/channel_message_model";

const initialState: ChannelChatState = {
    messages: [],
    currentMessage: undefined,

    attachmentsToSend: [],
    messageToAnswer: undefined,
    messageToEdit: undefined,

    limit: 50,
    isRefreshing: false,
    isFirstMessage: false,
    scrollHeight: 0,
    action: ChannelChatAction.send,
}

const channelChatSlice = createSlice({
    name: 'channelChatSlice',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(clearChannelChatCache,(state) => {
            state.messages = [];
            state.currentMessage = undefined;
            state.attachmentsToSend = [];
            state.messageToAnswer = undefined;
            state.messageToEdit = undefined;
            state.limit = 50;
            state.isRefreshing = false;
            state.isFirstMessage = false;
            state.scrollHeight = 0;
            state.action = ChannelChatAction.send;
        
        })
        .addCase(changeChannelChatAction, (state, action) => {
            state.action = action.payload.action;
        })
        .addCase(fetchChannelChatMessages, (state, payload) => {
            state.isRefreshing = false;
            state.isFirstMessage = payload.payload.messages.length < state.limit;
            if(payload.payload.messages.length > 0){
                const messages:ChannelBubbleModel[] = [];
                
                const initialDate:ChannelBubbleModel = {
                    ...payload.payload.messages[0],
                    isDate: true,
                    isOptimistic: false
                }
                const initialMessage:ChannelBubbleModel = {
                    ...payload.payload.messages[0],
                    isDate: false,
                    isOptimistic: false
                }
                messages.push(initialDate);
                messages.push(initialMessage);

                for (var i = 1; i < payload.payload.messages.length; i++){
                    const previousMessage = payload.payload.messages[i -1];
                    const currentMessage = payload.payload.messages[i];
                    
                    const previousDate = new Date(previousMessage.createdAt);
                    const currentDate = new Date(currentMessage.createdAt);

                    if(
                        previousDate.getFullYear() === currentDate.getFullYear() &&
                        previousDate.getMonth() === currentDate.getMonth() &&
                        previousDate.getDate() === currentDate.getDate()
                    ){
                        const extendedMessage:ChannelBubbleModel = {
                            ...currentMessage,
                            isDate: false,
                            isOptimistic: false
                        }
                        messages.push(extendedMessage);
                    }else{
                        const currentDate:ChannelBubbleModel = {
                            ...currentMessage,
                            isDate: true,
                            isOptimistic: false
                        }
                        const extendedMessage:ChannelBubbleModel = {
                            ...currentMessage,
                            isDate: false,
                            isOptimistic: false
                        }
                        messages.push(currentDate);
                        messages.push(extendedMessage);

                    }
                    
                }
                state.messages = messages; 
            }else{
                state.messages = [];
            }
        })
        .addCase(pullToRefreshMessages, (state,action) => {
            state.isRefreshing = true;
            state.limit = state.limit + 20;
            state.action = ChannelChatAction.pull;
            state.scrollHeight = action.payload;
        })
        .addCase(selectChannelMessage, (state, action) => {
            state.currentMessage = action.payload.message;
        })
        .addCase(uploadAttachment, (state, action) => {
            const currentAttachments = state.attachmentsToSend;

            action.payload.attachments.forEach((attachment) => {
                currentAttachments.push(attachment);
            });

            state.attachmentsToSend = currentAttachments;
        
        })
        .addCase(removeAttachment, (state, action) => {
            const currentAttachments = state.attachmentsToSend;
            const newAttachments = currentAttachments.filter((attachment) => {
                return attachment.url !== action.payload.attachment.url;
            });
            state.attachmentsToSend = newAttachments;
        })
        .addCase(selectMessageToAnswer, (state, action) => {
            state.messageToAnswer = action.payload.message;
        })
        .addCase(selectMessageToEdit, (state, action) => {
            state.messageToEdit = action.payload.message;
        })
        .addCase(sendChatMessage.fulfilled, (state, action) => {
            state.attachmentsToSend = [];
            state.messageToAnswer = undefined;
            state.action = ChannelChatAction.send;
        })
        .addCase(sendChatMessageOptimisticUpdate, (state, action) => {
            const bubbleMessage:ChannelBubbleModel = {
                ...action.payload.message,
                isDate: false,
                isOptimistic: true,
            }
            state.messages.push(bubbleMessage);
        })
    }
});

export default channelChatSlice.reducer;