import React from 'react';
import { Box, Card, CardContent, CardHeader, Divider, List, ListItem, Skeleton, Stack } from '@mui/material';


export const SkeletonCardTile: React.FC = () => {

    return (
        <Card  sx={{
            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
            overflow: 'hidden',
            width: '60vw',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <CardHeader 
                    avatar={
                        <Skeleton variant="circular" width={40} height={40} />
                    }
                    title={<Skeleton variant="rounded" animation="wave" height={10} width={100} sx={{ marginBottom: 1 }} />}
                    subheader={<Skeleton variant="rounded" animation="wave" height={8} width={100}/>}
                    sx={{
                        paddingBottom: 0
                    }}
                />
                <Skeleton variant="rounded" animation="wave" width={'10%'} height={30} sx={{ borderRadius: '16px', marginRight:2 }}/>
            </Box>
            <CardContent sx={{
            display:'flex',
            flexDirection:'column',
            height:'100%',
            paddingBottom:0,
            
            }}>
                <Stack spacing={1}>
                    <Skeleton variant="rounded" animation="wave" height={10} width={'30%'}/>
                    <Skeleton variant="rounded" animation="wave" height={10} width="60%"/>
                    <Divider light/>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} paddingTop={1}>
                        <Skeleton variant="rounded" animation="wave" height={10} width="10%"/>
                        <Skeleton variant="rounded" animation="wave" height={10} width="10%"/>

                    </Box>
                </Stack>      
            </CardContent>       
        </Card>
    );
};




export const SkeletonCardTiles: React.FC = () => {
    const tiles = Array.from([1,2,3,4,5] , (_, index) => 
    <ListItem key={index}>
        <SkeletonCardTile  />
    </ListItem>
    );

    return (
        <List children={tiles}/> 
    );
};