import React from 'react';

import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SchoolIcon from '@mui/icons-material/School';

import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';

import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';

import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

import { SearchBar } from '../search-bar/search-bar';
import { Avatar, Badge, Box, IconButton, Popover, Stack } from '@mui/material';
import { UserModel } from '../../model/response/user_model';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { ExpertisePopover } from '../../features/expertise/components/expertise_popover';
import { NotificationPage } from '../../features/notification/view/notfication_page';
import { ProfilePopOver } from '../../features/profile/components/profile_page';
import { AnnouncementPage } from '../../features/announcement/view/announcement_page';
import LeaderboardPage from '../../features/leaderboard/view/leaderboard_page';
import logotype from '../../assets/logotype.png';
import { useNavigate } from 'react-router-dom';
import { goForward, openChannelDrawer } from '../../features/navigation/navigation_action';
import { Page } from '../../features/navigation/navigation_state';

export interface HeaderProps {
    className?: string;
}

export enum HeaderAction {
    announcement,
    expertise,
    leaderboard,
    notification,
    none,
}

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {

    const user: UserModel | undefined = useSelector((state: RootState) => state.profile.userProfile);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | HTMLDivElement | null>(null);
    const [currentAction, setCurrentAction] = React.useState<HeaderAction>(HeaderAction.none);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();


    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);

        switch (event.currentTarget.id) {
            case 'expertise-button':
                setCurrentAction(HeaderAction.expertise);
                break;
            case 'notification-button':
                setCurrentAction(HeaderAction.notification);
                break;
            case 'announcement-button':
                setCurrentAction(HeaderAction.announcement);
                break;
            case 'leaderboard-button':
                setCurrentAction(HeaderAction.leaderboard);
                break;

            default:
                setCurrentAction(HeaderAction.none);
                break;
        }

    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentAction(HeaderAction.none);
    };

    const openExpertise = anchorEl !== null && anchorEl.id === 'expertise-button';
    const openNotification = anchorEl !== null && anchorEl.id === 'notification-button';
    const openProfile = anchorEl !== null && anchorEl.id === 'profile-button';
    const openAnnouncement = anchorEl !== null && anchorEl.id === 'announcement-button';
    const openLeaderboard = anchorEl !== null && anchorEl.id === 'leaderboard-button';


    return (
        <Box
            sx={{
                backgroundColor: 'rgba(247, 249, 252, 1)',
                border: '1px solid rgba(231, 231, 231, 1)'
            }}

            display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} p={1}>
            <Box
                sx={{ '&:hover': { cursor: 'pointer' } }}
                onClick={() => {
                    navigate('/home');
                    dispatch(openChannelDrawer(false));
                    dispatch(goForward(Page.HomePage));
                }}>
                <img src={logotype} width={'230px'} />
            </Box>
            <SearchBar />
            <Stack direction='row' spacing={1} >
                <IconButton id='announcement-button' onClick={handleClick}>
                    <Badge badgeContent={user?.announcementBalance.notificationCounter} color="primary">
                        {currentAction === HeaderAction.announcement ? <CampaignIcon /> : <CampaignOutlinedIcon />}
                    </Badge>
                </IconButton>
                <IconButton id='expertise-button' onClick={handleClick}>
                    {currentAction === HeaderAction.expertise ? <SchoolIcon /> : <SchoolOutlinedIcon />}
                </IconButton>
                <IconButton id='leaderboard-button' onClick={handleClick}>
                    {currentAction === HeaderAction.leaderboard ? <WorkspacePremiumIcon /> : <WorkspacePremiumOutlinedIcon />}
                </IconButton>
                <IconButton id='notification-button' onClick={handleClick}>
                    <Badge badgeContent={user?.notificationBalance.notificationCounter} color="primary">
                        {currentAction === HeaderAction.notification ? <NotificationsIcon /> : <NotificationsOutlinedIcon />}
                    </Badge>
                </IconButton>
                <Avatar id='profile-button' sx={{
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }} src={user !== undefined ? user.photo : ''} onClick={handleClick} />
            </Stack>
            <Popover
                open={openExpertise || openNotification || openProfile || openAnnouncement || openLeaderboard}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{ height: 500 }}
                elevation={1}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            borderRradius: '8px',
                            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                        },
                    }
                }}
            >
                {
                    openLeaderboard && <LeaderboardPage />
                }
                {
                    openExpertise && <ExpertisePopover />
                }
                {
                    openNotification && <NotificationPage />
                }
                {
                    openProfile && <ProfilePopOver onClose={handleClose} />
                }
                {
                    openAnnouncement && <AnnouncementPage />
                }

            </Popover>
        </Box>
    );
}