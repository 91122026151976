import { Box, Divider, Typography, useTheme } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

export interface SidebarTitleProps {
    onClick: () => void;
    title: string;
}

export const SidebarTitle: React.FC<SidebarTitleProps> = (props: SidebarTitleProps) => {
    
    const theme = useTheme();
    
    return (
        <Box>
            <Box p={1} display='flex' flexDirection='row' justifyContent='space-between'>
                <Typography variant="h5" color={theme.palette.textColor?.main}>
                    {props.title}
                </Typography>
                <Box
                    onClick={props.onClick}
                    color={theme.palette.textColor?.main} sx={{ '&:hover': { cursor: 'pointer' } }}>
                    <AddIcon />
                </Box>
            </Box>
            <Divider light />
        </Box>
    )
}