import { Box, Divider, InputAdornment, InputBase, Typography, useTheme } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import SearchIcon from '@mui/icons-material/Search';
import { changeSearchQuery } from "../api/channel_action";
import ClearIcon from '@mui/icons-material/Clear';
export interface SidebarTitleProps {
    onClick: () => void;
    onFilterClick?: () => void;
    title: string;
}

export const ChannelSidebarTitle: React.FC<SidebarTitleProps> = (props: SidebarTitleProps) => {
    
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const displaySearch: boolean = useSelector((state: RootState) => state.channel.displaySearch);
    const searchQuery: string = useSelector((state: RootState) => state.channel.searchQuery);
    if(displaySearch){
        return (
            <Box>
                <Box p={1} display='flex' flexDirection='row' justifyContent='space-between'>
                    <InputBase value={searchQuery} startAdornment={
                        <InputAdornment position="start">
                        <SearchIcon />
                        </InputAdornment>
                    } onChange={(event) => dispatch(changeSearchQuery(event.target.value))}/>
                    <Box 
                    onClick={props.onFilterClick}
                    color={theme.palette.textColor?.main} sx={{ '&:hover': { cursor: 'pointer' } }}>
                        <ClearIcon/>
                    </Box>
                </Box>
                <Divider light />
            </Box>
        )
    }
    
    
    return (
        <Box>
            <Box p={1} display='flex' flexDirection='row' justifyContent='space-between'>
                <Typography variant="h5" color={theme.palette.textColor?.main}>
                    {props.title}
                </Typography>
                <Box display='flex' flexDirection={'row'}>
                    <Box 
                    onClick={props.onFilterClick}
                    color={theme.palette.textColor?.main} sx={{ '&:hover': { cursor: 'pointer' } }}>
                        <SearchIcon/>
                    </Box>
                    <Box
                        onClick={props.onClick}
                        color={theme.palette.textColor?.main} sx={{ '&:hover': { cursor: 'pointer' } }}>
                        <AddIcon />
                    </Box>
                </Box>
                
            </Box>
            <Divider light />
        </Box>
    )
}