import { Box, Popover, Stack, Typography, useTheme } from "@mui/material"
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupIcon from '@mui/icons-material/Group';
import { useTranslation } from "react-i18next";
export interface ChannelPreferencesProps {

    open:boolean;
    isMuted: boolean;
    isAgora: boolean;
    anchorEl: HTMLButtonElement | HTMLDivElement | null;
    onClose: () => void;
    muteChannel?: () => void;
    deleteChannel?: () => void;
    openChannelSettings?: () => void;
    openChannelMembers?: () => void;
}

export const ChannelPreferences: React.FC<ChannelPreferencesProps> = (props: ChannelPreferencesProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
            <Popover 
            
            slotProps={{
                paper:{
                    sx: {
                        borderRradius: '8px',
                        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                      },
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={props.onClose}
            open={props.open} 
            anchorEl={props.anchorEl}>
                <Stack direction={'column'}  >
                    <Stack 
                    sx={{'&:hover':{cursor:'pointer'}}}
                    direction='row'onClick={props.muteChannel} p={1} spacing={1}>
                        <Box color={theme.palette.textColor?.main} >
                            {props.isMuted? <NotificationsIcon/>: <NotificationsOffIcon />}
                        </Box>
                        <Typography color={theme.palette.textColor?.main} >
                            {props.isMuted?t('channelSideBar.unmuteChannelAction'):t('channelSideBar.muteChannelAction')}
                        </Typography>
                    </Stack>
                    <Stack 
                    sx={{'&:hover':{cursor:'pointer'}}}
                    direction='row'onClick={props.openChannelMembers} p={1} paddingTop={0} spacing={1}>
                        <Box color={theme.palette.textColor?.main} >
                            <GroupIcon/>
                        </Box>
                        <Typography color={theme.palette.textColor?.main} >
                            {t('channelSideBar.memberTitle')}
                        </Typography>
                    </Stack>
                    {
                        !props.isAgora && 
                        <Stack 
                        sx={{'&:hover':{cursor:'pointer'}}}
                        onClick={props.openChannelSettings} direction='row' p={1} paddingTop={0} spacing={1}>
                            <Box color={theme.palette.textColor?.main}>
                                <SettingsOutlinedIcon />
                            </Box>
                            <Typography color={theme.palette.textColor?.main} >
                                {t('channelSideBar.openChannelSettings')}
                            </Typography>
                        </Stack>
                    }
                    
                    {
                        !props.isAgora && 
                        <Stack 
                        sx={{'&:hover':{cursor:'pointer'}}}
                        onClick={props.deleteChannel} direction='row' p={1} paddingTop={0} spacing={1}>
                            <Box color={theme.palette.error.main}>
                                <LogoutSharpIcon />
                            </Box>
                            <Typography color={theme.palette.error.main} >
                                {t('channelSideBar.leaveChannelAction')}
                            </Typography>
                        </Stack>
                    }
            </Stack>
            </Popover>
    )
}