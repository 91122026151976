import { Avatar, Box, Button, CardHeader, ListItem } from "@mui/material";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { ActionButton } from "../../../components/action-button/action_button";
import { useTranslation } from "react-i18next";
export interface MemberToAddProps{
    channelMember: UserSuggestedModel;
    toAdd:boolean;
    remove: () => void;
    add: () => void;
}
export const MemberToAdd: React.FC<MemberToAddProps> = (props: MemberToAddProps) => {

    const {t} = useTranslation();

    return (
        <ListItem key={props.channelMember.id} sx={{ padding: 0 }} >
            <Box width='100%' display='flex' flexDirection='row'
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <CardHeader
                    avatar={<Avatar src={props.channelMember.photo} />}
                    title={props.channelMember.displayName} />
                {
                    !props.channelMember.belongsToChannel &&
                    <Box>
                        <ActionButton 
                        isLoading={false} 
                        disabled={false} 
                        title={!props.toAdd?t('channelMemberPage.removeMemberActionButton'):t('channelMemberPage.addMemberActionButton')} 
                        onClick={!props.toAdd?props.remove:props.add }/>
                    </Box>

                }
            </Box>
        </ListItem>
    );
}