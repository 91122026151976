import React, { useState } from 'react';
import { TagProps } from '../tag/tag';
import { Avatar, Box, Card, CardContent, CardHeader, Chip, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { CardChannelModel } from '../../model/response/card_channel_model';
import { channelColors } from '../../utils/channel_theme';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { CardModel, CardType } from '../../model/response/card_model';
import { changeDialog } from '../../features/dialog/dialog_action';
import { DialogType } from '../../features/dialog/dialog_state';
import { useTranslation } from 'react-i18next';
export interface AnnouncementTileProps {
    className?: string;

    id: string;
    cardId: string;
    organizationId: string;
    cardTitle: string;
    cardText: string;
    cardRichText: string;
    createdAt: string;

    isClosed: boolean;

    tags: TagProps[];

    senderUser: {
        senderId: string;
        senderDisplayName: string;
        senderPhoto: string;
    };

    channel: CardChannelModel;
    totalViews: number;





}

export const AnnouncementTile: React.FC<AnnouncementTileProps> = (props: AnnouncementTileProps) => {


    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();


    const openDialog = () => {
        const announcement: CardModel = {
            ...props,
            helpingHand: false,
            isFollowed: false,
            isAnswered: false,
            isOwned: false,
            isAnonymous: false,
            hasLeftDiscussion: false,
            isVisible:true,
            totalAnswers: 0,
            totalContributor: 0,
            type: CardType.announcement
        }
        
        dispatch(changeDialog({dialog: DialogType.announcementDialog,announcement:announcement}));
    }
    
    
    const getElapsedTime = (date: string) => {

        const creationDate = new Date(date);
        const difference = new Date().getTime() - creationDate.getTime();
        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const month = Math.floor(days / 31);
        const years = Math.floor(days / 365);
        if (seconds < 0) {
            return 'now';
        } else if (seconds < 60) {
            return `${seconds}s ago`;
        } else if (minutes < 60) {
            return `${minutes}m ago`;
        } else if (hours < 24) {
            return `${hours}h ago`;
        } else if (days < 31) {
            return `${days}d ago`;
        } else if (month < 12) {
            return `${month}d ago`;
        } else {
            return `${years}y ago`;
        }
    }

    const firstLetter = props.channel.channelName[0];
    const channelColor = channelColors[firstLetter];
    const {t} = useTranslation();

    

    return (
        <Card elevation={0}

            sx={{
                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                width: '60vw',
                borderRadius: '8px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                    cursor: 'pointer'
                }
            }}>
            <Box onClick={openDialog}>

                <Box display={'flex'} flexDirection='row' alignItems='center' justifyContent='space-between'

                >

                    <CardHeader
                        sx={{
                            paddingBottom: 0,
                            '.MuiCardHeader-title': {
                                fontWeight: 600,
                                color: theme.palette.textColor?.main
                            }
                        }}

                        onClick={(event) => {
                            event.stopPropagation();
                            dispatch(changeDialog({dialog: DialogType.expertDialog,expertId:props.senderUser.senderId}));
                        }}
                        avatar={
                            <Avatar
                                src={props.senderUser.senderPhoto} aria-label="recipe" />
                        }
                        title={props.senderUser.senderDisplayName}
                        subheader={getElapsedTime(props.createdAt)}

                    />
                    <Box paddingRight={2}>
                        <Chip variant="outlined" avatar={<Avatar
                            sx={{
                                bgcolor: channelColor,
                            }}>
                            <Typography color={'white'}>
                                {firstLetter}
                            </Typography>
                        </Avatar>} label={props.channel.channelName} />
                    </Box>
                </Box>
                <CardContent>
                    <Typography style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', // Add ellipsis for overflow text
                        whiteSpace: 'nowrap' // Keep the text in a single line
                    }}
                        fontWeight={600}
                        variant={'h6'}
                        color={theme.palette.textColor!.main}
                    >
                        {props.cardTitle}
                    </Typography>
                    <Typography style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', // Add ellipsis for overflow text
                        whiteSpace: 'nowrap' // Keep the text in a single line
                    }}
                        color={theme.palette.textColor!.main}
                    >
                        {props.cardText}
                    </Typography>

                </CardContent>
                {
                    props.isClosed !== undefined &&
                    <Box paddingLeft={2} paddingRight={2}>
                        <Divider light />
                    </Box>
                }
                {
                    props.isClosed !== undefined && props.isClosed ?
                        <Box display='flex' justifyContent='center' p={1}>
                            <Stack direction='row'>
                                <DoneAllOutlinedIcon sx={{ color: '#5876FF' }} />
                                <Typography color='#5876FF'>
                                    {t('announcementPopover.viwedDescription')}
                                </Typography>
                            </Stack>
                        </Box>
                        :
                        props.isClosed !== undefined && !props.isClosed?
                        <Box display='flex' justifyContent='center' p={1}>
                            <Stack direction='row' spacing={1}>
                                <CampaignOutlinedIcon sx={{ color: '#5876FF' }} />
                                <Typography color='#5876FF'>
                                {t('announcementPopover.gotItDescription')}
                                </Typography>
                            </Stack>
                        </Box>:
                        <></>

                }
            </Box>
            
        </Card>
    );
};