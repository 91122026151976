import { Card, CardHeader, List, ListItem, Skeleton } from "@mui/material";

export const SkeletonProfile = () => {
    return (
            <CardHeader
                avatar={
                    <Skeleton variant="circular" width={40} height={40} />
                }
                title={
                    <Skeleton variant="rounded" animation="wave" height={10}  width={200} sx={{ marginBottom: '10px' }} />
                }
                subheader={
                    <Skeleton variant="rounded" animation="wave" height={10}  width={100}/>
                }
            />
    )
}

export const SkeletonProfiles: React.FC = () => {
    const tiles = Array.from([1, 2, 3, 4, 5], (_, index) =>
        <ListItem key={index} sx={{padding:0}}>
            <SkeletonProfile />
        </ListItem>
    );

    return (
        <List children={tiles} />
    );
};
