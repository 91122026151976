import { Navigate, useRoutes } from "react-router-dom";
import AuthLayout from "../layouts/auth_layout";
import LoginView from "../features/auth/view/login";
import { DashboardLayout } from "../layouts/dashboard_layout";
import HomePage from "../features/feed/view/home_page";
import ThreadPage from "../features/thread/view/thread_page";
import ChatPage from "../features/chat/view/chat_page";
import { PATH_DASHBOARD } from "./path";
import GPTPage from "../features/gpt/view/gpt_page";
import ExpertisePage from "../features/expertise/view/expertise_page";
import DocumentPage from "../features/channel_document/view/channel_document_page";
import SearchPage from "../features/search/view/search_page";
import ChannelDiscussionPage from "../features/channel_discussion/view/channel_discussion_page";
import ChannelAnnouncementPage from "../features/channel_announcement/view/channel_announcements_page";
import ChannelChatPage from "../features/channel_chat/view/channel_chat_page";
import YourThreadsPage from "../features/user_thread/view/your_thread_page";
import FollowedThreadsPage from "../features/user_followed_thread/view/followed_thread_page";
import CustomerServicePage from "../features/customer_service/view/customer_service_page"
export default function Router() {

    return useRoutes([
        {
          path: "/auth",
          element: <AuthLayout />,
          children: [
            { path: "login", element: <LoginView /> },
          ],
        },
        {
          path: "/",
          element: <DashboardLayout />,
          children: [
            {element: <Navigate to={PATH_DASHBOARD.general.app} replace />, index: true },
            {path: "home", element: <HomePage /> },
            {path: "search/:query", element: <SearchPage /> },
            {path: "channel/:channelId/documents", element: <DocumentPage /> },
            {path:"channel/:channelId/discussions", element: <ChannelDiscussionPage/>},
            {path:"channel/:channelId/announcements", element: <ChannelAnnouncementPage/>},
            {path:"channel/:channelId/chat", element: <ChannelChatPage/>},
            {path:"your-threads", element: <YourThreadsPage/>},
            {path:"followed-threads", element: <FollowedThreadsPage/>},
            {path:"chat", element:<ChatPage/> },
            {path:'sybil', element:<GPTPage/>},
            {path:'expertise', element:<ExpertisePage/>},
            {path: 'customer_service', element: <CustomerServicePage/>},       
            {path:"thread/:threadId", element:<ThreadPage/> },
          ]
        },    
    ]);
  }