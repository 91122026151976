import React, { useEffect, useMemo, useRef, useTransition } from 'react';
import { Paper, IconButton, styled, Box, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { UserModel } from '../../../model/response/user_model';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { ChannelModel } from '../../../model/response/channel_model';
import { ChannelMessageModel } from '../../../model/response/channel_message_model';
import { removeAttachment, sendChatMessage, uploadAttachment } from '../api/channel_chat_action';
import Quill from 'quill';
import { AttachmentModel, FileType } from '../../../model/response/thread_draft_model';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseApp } from '../../../utils/firebase_config';
import { UserMentionModel } from '../../../model/response/user_mention_model';
import { UserSuggestedModel } from '../../../model/response/user_suggested_model';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useFilePicker } from 'use-file-picker';
import { changeDialog } from '../../dialog/dialog_action';
import { DialogType } from '../../dialog/dialog_state';
import { ImageFileSizeValidator } from '../../../utils/file_picker_validator/image_picker_validator';
import { CardImages } from '../../../components/card-images/card_images';
import { ReplyToComponent } from './reply_to_component';
import { Attachment } from '../../../model/response/card_model';
import { useTranslation } from 'react-i18next';
import './channel_chat_footer';
const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  alignItems: 'center',
  padding: theme.spacing(1),
}));


const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export const ChannelChatFooter: React.FC = () => {

  const user: UserModel | undefined = useSelector((state: RootState) => state.profile.userProfile);
  const selectedChannel: ChannelModel | null = useSelector((state: RootState) => state.channel.currentChannel);
  const attachments: AttachmentModel[] = useSelector((state: RootState) => state.channelChat.attachmentsToSend);
  const messageToAnswer: ChannelMessageModel | undefined = useSelector((state: RootState) => state.channelChat.messageToAnswer);
  const editorRef = useRef(null);
  const quillRef = useRef<Quill | undefined>(undefined);
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();


  const sendMessage = () => {
    if (quillRef.current && user && selectedChannel) {
      const deltaContent = quillRef.current.getContents();
      const plainText = quillRef.current.getText();
      
      if (plainText.trim() !== '' || attachments.length > 0) {
       
        if (deltaContent) {
          const deltaString = JSON.stringify(deltaContent.ops);
          const date = new Date().toISOString();
          
          const data: ChannelMessageModel = {
            id: '',
            plainText: plainText,
            text: deltaString,
            writerId: user.id,
            attachments: [],
            writerDisplayName: user.displayName,
            writerPhoto: user.photo,
            rtdbMessageId: '',
            isDraft: false,
            updatedAt: date,
            createdAt: date,
            reactions: {}
          }

          
          
          dispatch(sendChatMessage({
            message: data,
            messageToAnswer: messageToAnswer,
            attachments: attachments,
            organizationId: user.organizationId,
            channelId: selectedChannel.channelId,
            channelChatId: selectedChannel.chatChannelId
          }));

          if (quillRef.current) {
            quillRef.current.deleteText(0, quillRef.current.getLength());
          }
        }
      }

    }
  }

  var bindings = {
    enter: {
      key: 'enter',
      handler: function () {
        sendMessage();
      }
    },
    tab: {
      key: 9,
      handler: function () {

      }
    }
  }
  const chatModules = useMemo(() => {
    return {
      toolbar: false,
      magicUrl: true,
      keyboard: {
        bindings: bindings
      },
      mention: {
        onSelect: (item, insertItem) => {
          insertItem(item);
        },
        renderItem: (data) => {
          const div = document.createElement('div');
          div.className = 'mention-item';

          const img = document.createElement('img');
          img.className = 'mention-avatar';
          img.src = data.photo;

          const span = document.createElement('span');
          span.textContent = data.value;

          div.appendChild(img);
          div.appendChild(span);

          return div;
        },
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        defaultMenuOrientation: 'top',
        source: async (searchTerm: string, renderList: Function, mentionChar: string) => {

          if (searchTerm.trim() == "") {
            renderList([], searchTerm);
          }

          if (user && !searchTerm.includes(" ")) {
            const data = {
              fullname: searchTerm,
              organizationId: user.organizationId,
              channelId: selectedChannel?.channelId,
            };

            const functions = getFunctions(firebaseApp, 'europe-west1');
            const searchUsers = httpsCallable(functions, 'searchUserByFullnameFunction');

            const results = await searchUsers({ user: data });
            const users = results.data as UserSuggestedModel[];

            const usertToTags = users.map((user) => {

              const mentionModel: UserMentionModel = {
                id: user.id,
                value: user.displayName,
                photo: user.photo,
                belongsToChannel: user.belongsToChannel
              }
              return mentionModel;
            });

            renderList(usertToTags.filter(user => user.belongsToChannel), searchTerm)

          }

        }
      }
    };
  }, [selectedChannel, user,messageToAnswer, attachments.length]);

  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: ['image/*', '.pdf'],
    readAs: "DataURL",
    limitFilesConfig: { min: 1 },
    validators: [
      new ImageFileSizeValidator()
    ],
    onFilesRejected: (data) => {
      const maxLimitExceeded = data.errors[0].maxLimitExceeded;
      const fileSize = data.errors[0].fileSizeToolarge;
      if (user) {
        if (maxLimitExceeded) {
          dispatch(changeDialog(
            {
              dialog: DialogType.alertDialog,
              alertTitle: 'Oops, it appears there was an issue!',
              alertMessage: 'You have selected too many pictures. Please limit your selection to a maximum of 10 pictures at a time.',
            }
          ));
        } else if (fileSize) {
          dispatch(changeDialog(
            {
              dialog: DialogType.alertDialog,
              alertTitle: 'Oops, it appears there was an issue!',
              alertMessage: 'The total size of  pictures exeeds the maximum size allowed (2Mb)',
            }
          ));

        }
      }

    },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      const attachments:Attachment[] = [];
          
          filesContent.map((fileContent, index) => {
            const fileName = plainFiles[index].name;
            const dataUrl = fileContent.content;

            const attachment: Attachment = {
              name: fileName,
              type: fileContent.content.startsWith('data:image/')?FileType.IMAGE:FileType.DOCUMENT,
              url: dataUrl,
              storageRef: "",
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            }

            attachments.push(attachment)
          });

          dispatch(uploadAttachment(attachments))
    },
  });



  const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
        if (loadEvent.target?.result) {
          resolve(loadEvent.target.result as string);
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const onPaste = async (event: ClipboardEvent, quill: Quill) => {
    const clipboardData = event.clipboardData;
    let files: File[] = [];
    let dataUrls: string[] = [];
    let filenames: string[] = [];
    let fileTypes: FileType[] = [];

    if (clipboardData && clipboardData.items) {
      event.preventDefault();

      const text = clipboardData.getData("text/plain");
      if (text !== "") {
        quill.focus();
        const range = quill.getSelection();

        if (range) {
          quill.insertText(range.index, text);
          setTimeout(() => {
            quill.setSelection(range.index + text.length, 0, 'api');
          }, 1);
        }
      }

      // Prevent default paste action
      for (let i = 0; i < clipboardData.items.length; i++) {
        if (clipboardData.items[i].kind === 'file') {
          const file = clipboardData.items[i].getAsFile();
          if (file) files.push(file)
        }
      }

      await Promise.all(
        files.map(async (file) => {
          if (file && file.type.startsWith('image/')) {

            const dataUrl = await readFileAsDataURL(file);
            dataUrls.push(dataUrl);
            filenames.push(file.name);
            fileTypes.push(FileType.IMAGE);
          } else if (file) {
            const dataUrl = await readFileAsDataURL(file);
            dataUrls.push(dataUrl);
            filenames.push(file.name);
            fileTypes.push(FileType.DOCUMENT);

          }
        })
      );
    }
  };
  useEffect(() => {
    if (editorRef && editorRef.current) {
      const quill = new Quill('#editor', {
        modules: chatModules,
        theme: 'bubble',
        placeholder:t('channelChatPage.inputBasePlaceHolder',{channelName:selectedChannel?.channelName}),
      });

      if (quill && editorRef && editorRef.current) {
        quillRef.current = quill;

        quill.root.addEventListener('paste', (e: ClipboardEvent) => onPaste(e, quill));

        quill.on('text-change', function () {
          const deltaContent = quill.getContents();
          if (deltaContent) {
            const deltaString = JSON.stringify(deltaContent.ops);
          }
        })
      }

      return () => {
        if (quill) {
          quill.root.removeEventListener('paste', (e: ClipboardEvent) => onPaste(e, quill));
        }
      };
    }

  }, [editorRef, chatModules, selectedChannel]);


  if (selectedChannel)
    return (
      <Box p={2}>
        <ReplyToComponent messageToAnswer={messageToAnswer}/>
        <StyledPaper>
          <Stack width='100%' direction='column'>
            <Box width={`calc(100vw - 500px)`}>
              
              <CardImages 
              onDelete={(attachment: AttachmentModel) => {dispatch(removeAttachment(attachment))}}
              images={attachments} 
              isDeleteable={true}/> 
            </Box>
            <Box width='100%' display='flex' flexDirection ='row' alignItems='center'>
              <Box sx={{ '&:hover': { cursor: 'pointer' } }} onClick={openFileSelector} >
                <AttachFileIcon />
              </Box>
              <Box sx={{'&:hover': {cursor: 'text'}}} 
                display="flex" flexDirection="row" flexGrow={1}
                onClick={() => {
                  if (quillRef.current) {
                    quillRef.current.focus();
                  }
                }}
                ref={editorRef} id="editor">
              </Box>
              <Box sx={{ '&:hover': { cursor: 'pointer' } }} onClick={sendMessage} marginRight={1}>
                <SendIcon />
              </Box>
            </Box>
          </Stack>
        </StyledPaper>
      </Box>
    );
  else
    return (<></>)
};

