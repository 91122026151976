import { Box, Chip, CircularProgress, InputBase, Stack, Typography, alpha, styled, useTheme } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { searchTags, updateProfile, updateTag, updateTagQuery } from "../api/introduction_action";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { SearchTagModel } from "../../../model/request/search_model";
import { UserModel } from "../../../model/response/user_model";
import { ActionButton } from "../../../components/action-button/action_button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from "react-i18next";
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(1)})`,
        width: "100%",
    },
}));

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: 20,
    backgroundColor: alpha(theme.palette.background.paper, 1),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

export interface ExpertiseToClickProps{
    isCliccked: boolean;
    title: string;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onDelete?: () => void;
}

export const ExpertiseToClick: React.FC<ExpertiseToClickProps> = (props: ExpertiseToClickProps) => {
    const theme = useTheme();
    return (
        <Chip size='small' sx={{

            margin: 0.2,
            border: props.isCliccked ? null : '1px solid ' + theme.palette.textColor?.main,
            backgroundColor: props.isCliccked  ? '#fdc673' : '#ffffff',
            color: props.isCliccked  ? '#ffffff' : theme.palette.textColor?.main
        }}
            label={props.title}
            onClick={props.onClick}
            onDelete={props.onDelete}  
        />
    );
}

export interface UpdateTagsProps{
    onBack: () => void;
}

export const UpdateTags:React.FC<UpdateTagsProps> = (props: UpdateTagsProps) => {
    const tagQuery = useSelector((state: RootState) => state.introduction.tagQuery);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const debounceTagQuery = useDebounce(tagQuery, 300);
    const tagsAreLoading: boolean = useSelector((state: RootState) => state.introduction.tagsAreLoading);

    const organizationExperties: string[] = useSelector((state: RootState) => state.introduction.organizationExperties);
    const tagsToKeep: string[] = useSelector((state: RootState) => state.introduction.tagsToKeep);
    const profileIsUploading: boolean = useSelector((state: RootState) => state.introduction.profileIsUploading);
    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const clickOnTag = (element:string) => {
        dispatch(updateTag(element));
    }
    const createNewTag = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            if (tagsToKeep.length < 10) {
                dispatch(updateTag(tagQuery));
                dispatch(updateTagQuery(''));
            }
        }
    }


    useEffect(
        () => {
            if(user && debounceTagQuery.trim() !== ''){
                const data:SearchTagModel = {
                    organizationId: user.organizationId,
                    tagName: debounceTagQuery,
                }
                dispatch(searchTags(data))
            }
        },[debounceTagQuery]
    );

    const onUpdateProfileClick = () => {
       if(user && !profileIsUploading){
        dispatch(updateProfile({
            userId: user.id,
            organizationId: user.organizationId,
        }))
       }
    }

    return (
        <Stack padding={2} spacing={1} justifyContent='space-between'alignItems='center' sx={{
        }}>
            <Box width={'100%'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Box color={theme.palette.textColor?.main} onClick={props.onBack}>
                    <ArrowBackIosIcon/>
                </Box>
                <Typography color={theme.palette.textColor?.main} variant='h5'>{t('introductionPage.titleSecondStep')}</Typography>
                <Box color={'transparent'}>
                    <ArrowBackIosIcon/>
                </Box>
            </Box>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase 
                fullWidth 
                onKeyDown={(event) => createNewTag(event)}
                value={tagQuery} 
                placeholder={t('introductionPage.searchHelperText')}
                onChange={(event) => dispatch(updateTagQuery(event.currentTarget.value))}
                />
            </Search>
            <Box width={'100%'} alignContent='start' p={tagsToKeep.length>0?1:undefined} >
            {
                tagsToKeep.map(
                    (element,index) => <ExpertiseToClick 
                        key={index} 
                        isCliccked={tagsToKeep.includes(element)} 
                        title={element} 
                        onClick={() => clickOnTag(element)}
                        onDelete={() => clickOnTag(element)}
                        />
                        )
                }
            </Box>
            <Box width={'100%'} maxHeight={'120px'} p={1} sx={{ overflowY: 'scroll'}} >
                {
                tagsAreLoading && 
                    <Box display={'flex'} justifyContent={'center'}>
                        <CircularProgress/>
                    </Box>
                }
                {
                 !tagsAreLoading &&   organizationExperties.map(
                        (element,index) => <ExpertiseToClick 
                        key={index} 
                        isCliccked={tagsToKeep.includes(element)} 
                        title={element} 
                        onClick={() => clickOnTag(element)}/>)
                }
            </Box>
            <Box  >
                <ActionButton isLoading={profileIsUploading} disabled={tagsToKeep.length === 0} title={t('introductionPage.doneButton')} onClick={onUpdateProfileClick}/>
            </Box>
        </Stack>
    )
}