import React, {useState, useEffect} from 'react'
import toast, { Toaster } from  'react-hot-toast';
import { onMessageListener } from '../../../utils/firebase_config';
import { AppDispatch, RootState } from '../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { requestToken } from '../api/push_notification_action';
import { NotificationTokenModel } from '../models/request/token_model';
import { UserModel } from '../../../model/response/user_model';
import { Avatar, Stack, Typography, useTheme } from '@mui/material';
import logo from '../../../assets/logo.svg';
export const PushNotification = () => {
  const [notification, setNotification] = useState<{title: string | undefined, body: string | undefined}>({title: '', body: ''});
  const theme = useTheme();
  const user: UserModel | undefined = useSelector((root: RootState) => root.auth.user);
  const dispatch = useDispatch<AppDispatch>();
  
  const notify = () =>  toast(<ToastDisplay/>);
  
  useEffect(() => {

    if(user){
      const data:NotificationTokenModel = {
        platform: 'web',
        deviceId: 'none',
        token: '',
        userId: user.id,
        createdAt: new Date().toISOString()
      }
      dispatch(requestToken(data));
    }
    
  }, [dispatch,user]);

  useEffect(() => {
    if (notification?.title ){
     notify()
    }
  }, [notification]);



  function ToastDisplay() {
    return (
         <Stack direction='row'>
            <Avatar src={logo}/>
            <Stack direction='column'>
                <Typography variant='body2' color={theme.palette.textColor?.main}>
                  {notification.title}
                </Typography>
                <Typography variant='body2' color={theme.palette.textColor?.main}>
                  {notification.body}
                </Typography>
            </Stack>
          </Stack>
    );
  };

  onMessageListener()
    .then((payload) => {
      setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
    })
    .catch((err) => console.log('failed: ', err));


  return (
     <Toaster  position="top-right"/>
  )
}
