import { Avatar, Button, CardMedia, Stack, Typography } from "@mui/material";
import logo from '../../assets/Logox3.png';
import { useTranslation } from "react-i18next";
import { Placement } from "react-joyride";
export enum ToolTipType {
  post,
  announcments,
  expertise,
  leaderboard,
  notification,
  profile,
  none
}

export interface ToolTipProps {
  isFirst: boolean;
  toolTipType: ToolTipType;

}

export const SkipButton = () => {
  const { t, i18n } = useTranslation();
  return (
    <Typography>{t('toolTips.skipButton')}</Typography>
  )
}

export const BackButton = () => {
  const { t, i18n } = useTranslation();
  return (
    <Typography>{t('toolTips.backButton')}</Typography>
  )
}

export const ForwardButton = () => {
  const { t, i18n } = useTranslation();
  return (
    <Typography>{t('toolTips.forwardButton')}</Typography>
  )
}

export const ToolTip = (props: ToolTipProps) => {

  const { t, i18n } = useTranslation();
  var translation = '';

  switch (props.toolTipType) {
    case ToolTipType.post:
      translation = t('toolTips.second');
      break;
    case ToolTipType.announcments:
      translation =t('toolTips.third');
      break;
    case ToolTipType.expertise:
      translation = t('toolTips.forth');
      break;
    case ToolTipType.leaderboard:
      translation =t('toolTips.fifth');
      break; 
    case ToolTipType.notification:
      translation =t('toolTips.sixth');
      break;
      case ToolTipType.profile:
        translation =t('toolTips.seventh');
        break;    
    default:
      break;
  }

  if (props.isFirst) {
    return (
      <Stack justifyContent={'center'} alignItems={'center'} spacing={2}>
        <CardMedia
          component="img"
          image={logo}
          sx={{
            width: '30%',
            objectFit: 'contain', // Fit the image to the dimensions while maintaining aspect ratio
          }}
        />
        <Typography variant="h5">
          {t('toolTips.first')}
        </Typography>
      </Stack>
    );
  } else {
    return (
      <Typography>
        {translation}
      </Typography>
    )
  }
}

export const toolTipsSteps = [
  {
    content:
      <ToolTip isFirst={true} toolTipType={ToolTipType.none}/>,
    placement: 'center' as Placement,
    target: 'body',
    spotlightPadding: 0,
    showProgress: false,
    styles: {
      options: {
        width: 500,
        primaryColor: '#ffffff',
      },
    },
    locale: { next: <Button>Let's start</Button> , skip: <SkipButton/>}
  },
  {
    content: <ToolTip isFirst={false} toolTipType={ToolTipType.post}/>,
    placement: 'bottom' as Placement,
    target: '#post_discussion',
    loaterProps: {
      disableAnimation: true,
    },
    locale: { skip: <SkipButton/>, back:<BackButton/>, next: <ForwardButton/>}
  },
  {
    content: <ToolTip isFirst={false} toolTipType={ToolTipType.announcments}/>,
    placement: 'bottom' as Placement,
    target: '#announcement-button',
    loaterProps: {
      disableAnimation: true,
    },
    locale: { skip: <SkipButton/>, back:<BackButton/>, next: <ForwardButton/>}
  },
  {
    content: <ToolTip isFirst={false} toolTipType={ToolTipType.expertise}/>,
    placement: 'bottom' as Placement,
    target: '#expertise-button',
    loaterProps: {
      disableAnimation: true,
    },
    locale: { skip: <SkipButton/>, back:<BackButton/>, next: <ForwardButton/>}
  },
  {
    content: <ToolTip isFirst={false} toolTipType={ToolTipType.leaderboard}/>,
    placement: 'bottom' as Placement,
    target: '#leaderboard-button',
    loaterProps: {
      disableAnimation: true,
    },
    locale: { skip: <SkipButton/>, back:<BackButton/>, next: <ForwardButton/>}
  },
  {
    content: <ToolTip isFirst={false} toolTipType={ToolTipType.notification}/>,
    placement: 'bottom' as Placement,
    target: '#notification-button',
    loaterProps: {
      disableAnimation: true,
    },
    locale: { skip: <SkipButton/>, back:<BackButton/>, next: <ForwardButton/>}
  },
  {
    content: <ToolTip isFirst={false} toolTipType={ToolTipType.profile}/>,
    placement: 'bottom' as Placement,
    target: '#profile-button',
    loaterProps: {
      disableAnimation: true,
    },
    locale: { skip: <SkipButton/>, back:<BackButton/>, next: <ForwardButton/>}
  },

]