import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { AIConversation } from "../../../model/response/gpt_conversation";

export const GPTPinnedDocument:React.FC = () => {
    
    const currentConversation: AIConversation | null = useSelector((state: RootState) => state.gpt.currentConversation);
    const theme = useTheme();
    
    if(currentConversation){
        if(currentConversation.document){
          return (
            <Stack direction={'row'}>
              <Box  
                display='flex'
                border={1}
                borderRadius={'8px'}
                height={50}
                justifyContent='center'
                alignItems='center'
                color='#5876FF'
                paddingLeft={1}
                paddingRight={1}
                borderColor={'#D9D9D9'}>
                <Typography color={theme.palette.textColor?.main} >
                    {currentConversation.document.documentName}
                </Typography>
              </Box>
            </Stack>
          );
        }
      }

      return (<></>);
}