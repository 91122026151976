import { Box, IconButton, Stack, TextField, styled } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useState } from "react";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { selectDocumentToSend, sendChatMessage, sendMessageOptimisticUpdate, updateMessageText } from "../api/chat_action";
import { MessageModel, MessageType, MirrorType } from "../../../model/response/message";
import { v4 as uuidv4 } from 'uuid';
import { useFilePicker } from 'use-file-picker';
import { ChatDocumentModel } from "../../../model/request/chat_document_model";
import { ChatModel } from "../../../model/response/chat_model";
import { useTranslation } from "react-i18next";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "12px !important",
    paddingBottom: "12px !important",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "90px",
  }
}));

export interface ChatFooterProps{
  organizationId: string;
  receiverId: string;
  writerId: string;
}
export const ChatFooter = (props:ChatFooterProps) => {

  const [disableSendButton, setDisableButton] = useState(true);
  const currentChat:ChatModel | null = useSelector((state: RootState) => state.chat.currentChat);
  
  const dispatch = useDispatch<AppDispatch>();
  const {t} = useTranslation();
  const textMessage:string = useSelector((state: RootState) => state.chat.messageText);

  const isSending:boolean = useSelector((state: RootState) => state.chat.isSending);
  const isFirstMessage:boolean = useSelector((state: RootState) => state.chat.isFirstMessage);
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: '.png',
    readAs: "DataURL",
    limitFilesConfig: { min: 1, max: 1 },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      if (filesContent.length == 1){
        const data: ChatDocumentModel = {
          fileIsSelected: true,
          filePath: filesContent[0].content,
        };
        dispatch(selectDocumentToSend(data));
      }
    },
  });
  
  const sendMessage = () => {
    
    if(currentChat !== null && textMessage.trim() !== "" ){
      const currentDate = new Date().toISOString();
    const message:MessageModel = {
      id: uuidv4(),
      organizationId: props.organizationId,
      text: textMessage,
      messageType: MessageType.Text,
      receiverId: props.receiverId,
      writerId: props.writerId,
      createdAt: currentDate,
      updatedAt: currentDate,
      mirrorStatus:MirrorType.Real
    }
    dispatch(sendMessageOptimisticUpdate(message));
    dispatch(sendChatMessage({
      message:message,
      chat:currentChat!
    }));
    dispatch(updateMessageText(''));
    }
  }

  const send = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter'){
      if(isSending && isFirstMessage){
        
      }else{
        sendMessage();
      }
    }
  }

  const handleDisableButton = (message:string) => {
    if (message.replace(/\s/g, '').length){
      setDisableButton(false);
    }else{
      setDisableButton(true);
    }
  }

  return (
    <Box
      p={2}
      maxWidth={"100%"}
      sx={{
        boxShadow: "0px -4px 2px -4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack direction="row" alignItems={"center"} spacing={3}>
        <Stack sx={{ width: "100%" }}>
          <StyledInput
            onChange={(event) => {
              dispatch(updateMessageText(event.target.value));
              handleDisableButton(event.target.value);
            }}
            onKeyDown={(event) => send(event)}
            fullWidth
            placeholder={t('chatPage.chatFooterHelperText')}
            variant="filled"
            value={textMessage}
            InputProps={{
              value:textMessage,
              disableUnderline: true,
              sx: { borderRadius: 50 },
              startAdornment:
                <IconButton onClick={() => openFileSelector()}>
                  <AttachFileIcon />
                </IconButton>,
            }
          }
          />
        </Stack>
        <Box
          sx={{
            height: 48,
            width: 48,
            borderRadius: 1.5,
          }}
        >
          <Stack
            sx={{ height: "100%" }}
            alignItems={"center"}
            justifyContent="center"
          >
            {!disableSendButton && 
            <IconButton onClick={() => {
              if(isSending && isFirstMessage){
                  
              }else{
                sendMessage();
              }
             
            }}>
              <SendIcon />
            </IconButton>}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

