import { Avatar, Box, Stack, Typography, useTheme } from "@mui/material";
import { GPTWaitingAnimation } from "./gpt_waiting_animation";
import ReactMarkdown from "react-markdown";
import logoSmall from '../../../assets/logo.svg';
import './gpt_message_bubble.css'
import { GPTPinnedDocument } from "./gpt_pinned_document";
export interface GPTMessageBubbleProps {
    isUser: boolean;
    animate: boolean;
    photo: string;
    message: string;
}


export const GPTMessageBubble: React.FC<GPTMessageBubbleProps> = (props: GPTMessageBubbleProps) => {

    const theme = useTheme();

    

    if (props.isUser) {
        return (
            <Stack direction='row' spacing={1}>
                <Avatar src={props.photo} sx={{ width: '30px', height: '30px' }} />
                <Stack>
                    <Box height={'5px'}></Box>
                    <Typography color={theme.palette.textColor?.main} fontWeight={'600'}>
                        You
                    </Typography>
                    <GPTPinnedDocument/>
                    <ReactMarkdown className="markdown">{props.message}</ReactMarkdown>
                </Stack>
            </Stack>
        )
    } else {
        return (
            <Stack direction='row' spacing={1}>
                <Avatar src={logoSmall} sx={{ width: '30px', height: '30px' }} />
                <Stack>
                    <Box height={'5px'}></Box>
                    <Typography color={theme.palette.textColor?.main} fontWeight={'600'}>
                        Sybil
                    </Typography>
                    {
                        props.animate ? <GPTWaitingAnimation/>:
                        <ReactMarkdown className="markdown">{props.message}</ReactMarkdown>
                    }
                </Stack>
            </Stack>
        )
    }
}