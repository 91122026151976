import { createSlice } from "@reduxjs/toolkit";
import { changeSearchQuery, displaySearchComponent, fetchChannels, leaveChannel, selectChannel } from "./channel_action";
import { ChannelState } from "./channel_state";

const initialState: ChannelState = {
    channels: [],
    mutedChannels: [],
    currentChannel: null,
    notificationNumber:0,
    displaySearch: false,
    searchQuery: '',
}

const channelSlice = createSlice({
    name: 'channel',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(displaySearchComponent, (state, action) => {
            state.displaySearch = action.payload.display;
            if(state.displaySearch === false) state.searchQuery = '';
        })
        .addCase(changeSearchQuery, (state, action) => {
            state.searchQuery = action.payload.query;
        })
        .addCase(fetchChannels,(state, action) => {
            state.channels = action.payload.channels.filter((element) => !element.isMuted); 
            state.mutedChannels = action.payload.channels.filter((element) => element.isMuted);
            
            var notificationNumber = 0
            action.payload.channels.map((channel) => {
                if(channel.chatBalance) notificationNumber += channel.chatBalance.notificationCounter;
                
                notificationNumber += channel.discussionBalance.notificationCounter;
            }
            );

            state.notificationNumber = notificationNumber;


        })
        .addCase(selectChannel.fulfilled,(state, action) => {
            const previousChannel = state.currentChannel;
            const currentChannel = action.payload.channel;
            
            if(previousChannel?.id !== currentChannel?.id){
                state.currentChannel = action.payload.channel;
            }
            
        })
        
        
    }  
});

export default channelSlice.reducer;