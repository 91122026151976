import { createSlice } from "@reduxjs/toolkit";
import { NotificationState } from "./notification_state";
import { fetchNotifications, markAllNotification, updateNotification } from "./notification_action";
import { NotificationModel } from "../../../model/response/notification_model";

const initialState:NotificationState = {
    notifications: [],
    lastSeenNotification: undefined,
    scrollNotifications: true
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(fetchNotifications.fulfilled, (state, payload) => {
            if (payload.payload.fetchedFirstTime) {
                state.notifications = payload.payload.notifications
            }else{
                const currentNotifications = state.notifications;
                payload.payload.notifications.map((notification) => currentNotifications.push(notification))
                state.notifications = currentNotifications;
            }
            
            state.lastSeenNotification = payload.payload.lastElement;
            state.scrollNotifications = payload.payload.continueToScroll;
        })
        .addCase(updateNotification.fulfilled, (state,payload) => {
            const currentNotifications = state.notifications;
            
            const updatedNotification = currentNotifications.map((notification) => {
                
                var viewed = notification.viewed;
                
                if (notification.id === payload.payload.notificationId){
                    viewed = true;
                }

                const data:NotificationModel={
                    ...notification,
                    viewed:viewed
                }
                return data;
            });

            state.notifications = updatedNotification;
        })
        .addCase(markAllNotification.fulfilled, (state, payload) => {
            const currentNotifications = state.notifications;
            const updatedNotification = currentNotifications.map((notification) => {
                const data:NotificationModel={
                    ...notification,
                    viewed:true
                }
                return data;
            });
            state.notifications = updatedNotification;
        })
    }
});

export default notificationSlice.reducer;