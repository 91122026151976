export enum DocumentStatus {
    PENDING = 0,
    ERROR = 1,
    UPLOADED = 2,
}

export interface SendDocumentModel{
    fileName: string;
    fileSize:number;
    arrayBuffer:ArrayBuffer;

    uploaderUser:{
        uploaderDisplayName:string;
        uploaderId: string;
    };
}

export enum DocumentsSource {
    MANUAL = 'manual',
    SHAREPOINT = 'sharepoint',
    CONFLUENCE = 'confluence',
    GOOGLE_DRIVE = 'google_drive',
  }

export interface DocumentModel{

    id: string;
    channelId: string;
    fileName: string;
    fileSize:string;
    googleCloudStorageName:string;
    type: DocumentsSource;
    mimeType: string;
    fileSizeInBytes?: number;
    url: string;
    
    uploaderUser:{
        uploaderDisplayName:string;
        uploaderId: string;
    };

    documentStatus:DocumentStatus;
    
    createdAt: string;
    updatedAt: string;
}