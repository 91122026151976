import React from 'react';
import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {

    const {t, i18n} = useTranslation();
  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
      }}
    >
      <Typography variant="h4" align="center">
        {t('notFoundPage.title')}
      </Typography>
      <Typography variant="body1" align="center">
        {t('notFoundPage.description')}
      </Typography>
    </Container>
  );
};

