import * as React from 'react';
import {  Typography, Avatar, Stack, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CardImages } from '../../../components/card-images/card_images';
import { AttachmentModel } from '../../../model/response/thread_draft_model';


const UserName = styled(Typography)({
  fontWeight: 'bold',
});

const MessageText = styled(Typography)({
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  marginTop: 0,
});

const MessageTimestamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  marginLeft: 'auto',
}));

// Add a style prop to UserAvatar to control opacity
const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
}));


export interface CustomerServiceChatBubbleProps{
  author: string;
  photo: string;
  attachments: AttachmentModel[]
  timestamp:string;
  isLeading: boolean;
  text:string;
}

export const CustomerServiceChatBubble: React.FC<CustomerServiceChatBubbleProps> = (props: CustomerServiceChatBubbleProps) => {
  
  function convertIsoToHoursMinutes(isoString: string): string {
    const date = new Date(isoString);

    // Get hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format hours and minutes to ensure two digits (e.g., '04:09')
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Combine hours and minutes
    return `${formattedHours}:${formattedMinutes}`;
}
  
  return (
      <Stack direction="row" spacing={2} paddingTop={props.isLeading?1:0}>
          {
            props.isLeading? 
            <Box>
               <UserAvatar src={props.photo}/>
            </Box>
           :<Box width={40}></Box>

          }
           
        <Stack spacing={0}>
          {
            props.isLeading &&
            <Stack direction="row" alignItems="center" spacing={2}>
              <UserName variant="subtitle2">{props.author}</UserName>
              <MessageTimestamp>{convertIsoToHoursMinutes(props.timestamp)}</MessageTimestamp>
            </Stack>
          }
          <Box maxWidth={'calc(100vw - 500px)'}>
            <MessageText variant="body2" >{props.text}</MessageText>
          </Box>
         { props.attachments.length > 0 && <CardImages images={props.attachments} isDeleteable={false}/>}
        </Stack>
      </Stack>
  );
};

