import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const GPTWaitingAnimation = () => {
    const {t,i18n} = useTranslation();
    
    const gptText: string = t('gptPage.gptWaitingMessage');
    const [dots, setDots] = useState('.');
    
    const animationInterval = setInterval(() => {
        // Add one more dot to the state
          if(dots.length < 3){
              setDots(dots + '.');
          }else{
              setDots('.');
          }          
      }, 500);
    
    return(
        <Typography>{gptText + dots}</Typography>
    )
}

