import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentData, Query, collection, doc, getDocs, getFirestore, limit, orderBy, query, startAfter, updateDoc, where } from "firebase/firestore";
import { ChannelMemberModel, ChannelMembersType } from "../../../model/response/channel_member_model";
import { UserModel } from "../../../model/response/user_model";
import { debugPrint } from "../../../utils/debug_print";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { AddToChannelModel, RemoveToChannelModel } from "../../../model/request/create_channel_model";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../../utils/firebase_config";
import { UserSearchModel } from "../../../model/request/search_model";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { RootState } from "../../../app/store";


export const clearChannelMembersCache = createAction(
    'channelMember/clearChannelMembersCache',
    function prepare() {
        return {
            payload: {

            },
        }
    });
export const fetchMembers = createAsyncThunk(
    'channelMember/fetchMembers',
    async (data: {
        organizationId: string;
        channelId?: string;
        lastElement: DocumentData | undefined;
        size: number;
    }, { rejectWithValue, }) => {
        try {
            const db = getFirestore();
            var membersQuery: Query<DocumentData>;

            const organizationId = data.organizationId;
            const channelId = data.channelId;
            
            if (channelId) {
                if (data.lastElement) {
                    membersQuery = query(collection(db, "Organizations", organizationId, 'Channels', channelId, 'ChannelMembers'), limit(data.size), orderBy('role', 'asc'),orderBy('memberDisplayName', 'asc'), startAfter(data.lastElement.role,data.lastElement.memberDisplayName));
                } else {
                    membersQuery = query(collection(db, "Organizations", organizationId, 'Channels', channelId, 'ChannelMembers'), limit(data.size), orderBy('role', 'asc'), orderBy('memberDisplayName', 'asc'));
                }

            } else {
                if (data.lastElement) {
                    membersQuery = query(collection(db, "Users"), where('organizationId', '==', organizationId), limit(data.size), orderBy('displayName', 'asc'), startAfter(data.lastElement.displayName));
                } else {
                    membersQuery = query(collection(db, "Users"), where('organizationId', '==', organizationId), limit(data.size), orderBy('displayName', 'asc'));
                }
            }

            const membersSnapshot = await getDocs(membersQuery);
            const members: ChannelMemberModel[] = []
            if (organizationId && channelId) {
                membersSnapshot.docs.map((doc) => {
                    members.push(doc.data() as ChannelMemberModel);
                });
            } else {
                membersSnapshot.docs.map((doc) => {
                    const user = doc.data() as UserModel;

                    const member: ChannelMemberModel = {
                        id: user.id,
                        userId: user.id,
                        memberDisplayName: user.displayName,
                        memberPhoto: user.photo,
                        memberBio: user.bio!,
                        role: 1,
                        totalPointsEarned: 0,
                        createdAt: user.createdAt
                    };

                    members.push(member);
                });
            }
            

            if (members.length == 0) {
                return {
                    members: [],
                    lastElement: undefined,
                    continueToScroll: false,
                }
            } else if (members.length < data.size) {
                return {
                    members: members,
                    lastElement: membersSnapshot.docs[membersSnapshot.docs.length - 1].data(),
                    continueToScroll: false,
                }
            } else {
                return {
                    members: members,
                    lastElement: membersSnapshot.docs[membersSnapshot.docs.length - 1].data(),
                    continueToScroll: true,
                }
            }

        } catch (error: any) {
            debugPrint(error.toString(), 'channel/fetchMembers')
            return rejectWithValue(error.toString());
        }

    });

export const searchNewMembers = createAsyncThunk(
    'member/searchNewMembers',
    async (data: UserSearchModel, { rejectWithValue }) => {
        try {
            const functions = getFunctions(firebaseApp, 'europe-west1');
            const searchUsers = httpsCallable(functions, 'searchUserByFullnameFunction');

            const results = await searchUsers({ user: data });
            const users = results.data as UserSuggestedModel[];

            return {
                users: users
            }

        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    }
);

export const addChannelMember = createAction(
    'channelMember/addChannelMember',
    function prepare(member: UserSuggestedModel) {
        return {
            payload: {
                member: member
            },
        }
    });

export const removeChannelMember = createAction(
    'channelMember/removeChannelMember',
    function prepare(member: UserSuggestedModel) {
        return {
            payload: {
                member: member
            },
        }
    });

export const addMembersFromChannel = createAsyncThunk(
    'channelMember/addMembersFromChannel',
    async (data: AddToChannelModel, { dispatch, rejectWithValue }) => {
        try {

            const functions = getFunctions(firebaseApp, 'europe-west1');
            const addToChannelFunction = httpsCallable(functions, 'addToChannelFunction');
            await addToChannelFunction({ channel: data });

            dispatch(clearChannelMembersCache());
            dispatch(changeDialog({dialog: DialogType.none}));

        } catch (error: any) {
            rejectWithValue(error.toString());
        }

    }
);

export const promoteOrDemoteUser = createAsyncThunk(
    'channelMember/leaveChannel',
    async (data: {
        organizationId: string;
        channelId: string;
        channelMemberId: string;
        role: ChannelMembersType;
    }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const documentReference = doc(db, 'Organizations', data.organizationId, 'Channels', data.channelId, 'ChannelMembers', data.channelMemberId);

            await updateDoc(documentReference, {
                role: data.role,
            });

            return {
                channelMemberId: data.channelMemberId,
                role: data.role,
            }

        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    });

export const removeMembersFromChannel = createAsyncThunk(
    'channelMember/removeMembersFromChannel',
    async (data: RemoveToChannelModel, { rejectWithValue }) => {
        try {
            const functions = getFunctions(firebaseApp, 'europe-west1');
            const removeFromChannelFunction = httpsCallable(functions, 'removeToChannelFunction');
            const result = await removeFromChannelFunction({ channel: data });

            return {
                channelMembers: data.members,
            }
        } catch (error: any) {
            debugPrint(error.toString(), 'channel/removeMembersFromChannel')
            return rejectWithValue(error.toString());
        }
    }
);