import { Chip } from '@mui/material';
import React from 'react';

export interface TagProps {
    className?: string;
    id: string;
    tagName: string;

}

export const Tag: React.FC<TagProps> = (props: TagProps) => (
    <Chip 
        sx={{
            padding:'0px 8px',
            fontWeight:500,
            backgroundColor: 'rgba(255, 154, 52, 0.15)',
            borderColor: 'rgba(255, 154, 52, 1)',
            border:1,
            margin: '1px',
            color:'rgba(255, 154, 52, 1)'
        }}
    label={props.tagName}
    size='small' 
    />

);
