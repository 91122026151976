import { Box, Typography, useTheme } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';
import { ChannelMessageModel } from "../../../model/response/channel_message_model";
import { AppDispatch } from "../../../app/store";
import { useDispatch } from "react-redux";
import { selectMessageToAnswer } from "../api/channel_chat_action";
import { useTranslation } from "react-i18next";


export interface ReplyToComponentProps{
    messageToAnswer: ChannelMessageModel | undefined;
}

export const ReplyToComponent:React.FC<ReplyToComponentProps> = (props: ReplyToComponentProps) => {
    
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();
    
    if(!props.messageToAnswer){
        return <></>
    }
    return (
        <Box paddingLeft={1} paddingRight={1} display='flex' flexDirection='row' justifyContent='space-between'>
           <Box  display='flex' flexDirection='row'>
              <Typography>
                {t('channelChatPage.replyingToMessageDesctiption')}
              </Typography>
              <Typography marginLeft={1} fontWeight={600}>{props.messageToAnswer.writerDisplayName}</Typography> 
            </Box> 
            <Box onClick = {(e) => dispatch(selectMessageToAnswer(undefined))}
            sx={{'&:hover':{cursor:'pointer'}}}color={theme.palette.textColor?.main}>
              <ClearIcon fontSize='small'/>
            </Box>
        </Box>
    );
}