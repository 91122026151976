import { Page } from "../../features/navigation/navigation_state";
export const currentPageEvent: string = 'current_page';
export const createdCardEvent:string = 'card_sent';
export const createdChannelEvent:string = 'channel_created';

export const createdAnswerEvent:string = 'answer_sent';
export const sentGptPrompt:string = 'sent_gpt_prompt';
export const sentSearch: string = 'sent_search_query';
export const updloadedDocument: string = 'uploaded_document';

export interface EventModel{
    timestamp: string;
    page?: Page;
}

export interface AnalyticsEvent{
    event: EventModel,
    eventName: string;
}