import { Stack } from "@mui/material";
import { Chats } from "../components/chats";
import { ChatComponent } from "../components/chat_body";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { ChatPicker } from "../components/chat_picker";
import { useEffect } from "react";
import { clearCharCache } from "../api/chat_action";

const ChatPage = () => {
    
    const fileIsSelected:boolean = useSelector((state: RootState) => state.chat.fileIsSelected);
    const dispatch = useDispatch<AppDispatch>()
    useEffect(
        () => {
            return () => {
                dispatch(clearCharCache(true));
              };
        },[]
    )
    
    return (
        <>
        <Stack direction="row" sx={{ width: "100%",height:'100%' }}>
            <Chats/>
            {!fileIsSelected && <ChatComponent/>}
            {fileIsSelected && <ChatPicker/>}
        </Stack>
        </>
    )
}


export default ChatPage;