import { createSlice } from '@reduxjs/toolkit';
import { AuthType, AuthenticationState, LoginStep } from './authentication_state';
import { alreadyLoggedUser, loginUser, loginWithMicrosoft, logout, resetYourPassword, retrieveTenantConfiguration, updateAccessToken, updateUserNotification, updateUserProfile } from './authAction';
import { UserModel } from '../../../model/response/user_model';

const initialState: AuthenticationState = {
  loading: false,
  loginStep: LoginStep.first,
  user: undefined,
  userToken: null,
  error: null,
  success: AuthType.Pending,

  email: '',
  currentTenant: '',
  loginWithMicrosoft: false,
  microsoftAccessToken: undefined,
  
  loginWithPassword: false,
  forgotYourPassword: false,
  
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveTenantConfiguration.fulfilled, (state, payload) => {
        state.loginStep = LoginStep.second;
        state.email = payload.payload.email;
        state.currentTenant = payload.payload.tenantId;
        state.loginWithMicrosoft = payload.payload.loginWithMicrosoft;
        state.loginWithPassword = payload.payload.loginWithPassword;
        state.loading = false;
        state.error = null;
      })
      .addCase(retrieveTenantConfiguration.pending, (state, payload) => {
        state.loading = true;
      })
      .addCase(retrieveTenantConfiguration.rejected, (state, payload) => {
        state.loading = false;
        const error = payload.payload as string;
        state.error = error;
        if (error.includes('422')){
          state.error = 'Insert a valid email address'
        }
      })
      .addCase(resetYourPassword.fulfilled, (state, payload) => {
        state.forgotYourPassword = true;
      })

      .addCase(loginUser.fulfilled, (state, payload) => {
        state.loading = false;
        state.success = AuthType.Success;
        state.user = payload.payload;
      })
      .addCase(loginUser.pending, (state, payload) => {
        state.loading = true;
        state.success = AuthType.Pending;
      })
      .addCase(loginUser.rejected, (state, payload) => {
        state.loading = false;
        state.success = AuthType.Fail;
        state.error = 'be sure your password is correct';
      })
      .addCase(alreadyLoggedUser.fulfilled, (state, payload) => {
        state.loading = false;
        state.success = AuthType.Success;
        
        state.user = payload.payload;

        if (state.user === undefined){
          state.success = AuthType.Fail;
        }
      })
      .addCase(alreadyLoggedUser.pending, (state, payload) => {
        state.loading = true;
        state.success = AuthType.Pending;
      })
      .addCase(alreadyLoggedUser.rejected, (state, payload) => {
        state.loading = false;
        state.success = AuthType.Fail;
        state.error = payload.error.message || 'Login failed';
      })
      .addCase(updateUserNotification, (state, payload) => {
        var currentUser = state.user;
        const notificationCounter = payload.payload.notificationCounter;
        
        if (currentUser){
          const user:UserModel={
            ...currentUser,
            notificationBalance:{
              hasNotifications:currentUser.notificationBalance.hasNotifications,
              notificationCounter: notificationCounter
            }
          }
          state.user = user;
        }
      })
      .addCase(logout.fulfilled, (state, payload) => {
        state.user = undefined;
        state.loginStep = LoginStep.first;
        state.success = AuthType.Fail;
        state.forgotYourPassword = false;
      })
      .addCase(loginWithMicrosoft.fulfilled, (state, payload) => {
        state.success = AuthType.Success;
        state.user = payload.payload;
        state.loading = false;

        if (state.user === undefined){
          state.success = AuthType.Fail;
        }
      })
      .addCase(updateAccessToken, (state, action) => {
        state.microsoftAccessToken = action.payload.accessToken;
      })
      .addCase(loginWithMicrosoft.pending, (state) => {
        state.loading = true;
        state.success = AuthType.Pending;
      })
      .addCase(loginWithMicrosoft.rejected, (state, payload) => {
        state.success = AuthType.Fail;
        state.loading = false;
      })

      .addCase(updateUserProfile, (state, action) => {
        var currentUser = state.user;
        
        if(currentUser){
          const user:UserModel={
            ...currentUser,
            ...(action.payload.bio !== undefined && { bio: action.payload.bio }),
            ...(action.payload.photo !== undefined && { photo: action.payload.photo }),
            ...(action.payload.isFirstLogin !== undefined && { isFirstLogin: action.payload.isFirstLogin }),
            ...(action.payload.isFirstTutorial !== undefined && { isFirstLogin: action.payload.isFirstTutorial }),
          }
  
          state.user = user
        }  
      })
  }
});


export default authSlice.reducer;

