import { FileWithPath } from 'file-selector';
import { UseFilePickerConfig, Validator } from "use-file-picker";

export class ImageFileSizeValidator implements Validator {
    async validateBeforeParsing(config: UseFilePickerConfig, plainFiles: File[]) {
        return new Promise<void>((res, rej) => {
            const maxFileSize = 10 * 1024 * 1024;
            var currentSize = 0;
            plainFiles.forEach((file) => {
                currentSize += file.size;
            });

            if (currentSize > maxFileSize) {
                return rej({
                    fileSizeToolarge: true
                });
            } else {
                return res();
            }




        });
    }
    async validateAfterParsing(config: UseFilePickerConfig, file: FileWithPath, reader: FileReader) {
        return new Promise<void>((res, rej) =>
            res()
        );
    }
}