import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getFirestore, DocumentData, query, collection, where, orderBy, limit, startAfter, getDocs, doc, deleteDoc, Query } from "firebase/firestore";
import { FetchUserTagsModel, DeleteserTagsModel } from "../../../model/request/fetch_profile_models";
import { TagModel } from "../../../model/response/tag_model";
import { debugPrint } from "../../../utils/debug_print";

export const fetchYourExpertise = createAsyncThunk(
    'expertise/loadExpertise',
    async (data: FetchUserTagsModel, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            var tagQuery: Query<DocumentData>;

            if (data.fetchAllExpertise) {
                tagQuery = query(collection(db, "Users", data.userId, 'UserTags'), where('userRelativeScore', '>', 0), orderBy('userRelativeScore', 'desc'));
            } else if (data.lastElement) {
                tagQuery = query(collection(db, "Users", data.userId, 'UserTags'), limit(data.size), where('userRelativeScore', '>', 0), orderBy('userRelativeScore', 'desc'), startAfter(data.lastElement.userRelativeScore));
            } else {
                tagQuery = query(collection(db, "Users", data.userId, 'UserTags'), limit(data.size), where('userRelativeScore', '>', 0), orderBy('userRelativeScore', 'desc'));
            }


            const tagSnapshot = await getDocs(tagQuery);
            const tags: TagModel[] = []
            tagSnapshot.docs.map((doc) => {
                tags.push(doc.data() as TagModel);
            });

            if (data.fetchAllExpertise) {
                return {
                    tags: tags,
                    lastElement: undefined,
                    continueToScroll: false,
                    fetchAllExpertise: data.fetchAllExpertise,
                }

            } else if (tags.length == 0) {
                return {
                    tags: [],
                    lastElement: undefined,
                    continueToScroll: false,
                }
            } else if (tags.length < data.size) {
                return {
                    tags: tags,
                    lastElement: tagSnapshot.docs[tagSnapshot.docs.length - 1].data(),
                    continueToScroll: false,
                }
            } else {
                return {
                    tags: tags,
                    lastElement: tagSnapshot.docs[tagSnapshot.docs.length - 1].data(),
                    continueToScroll: true,
                }
            }

        } catch (error:any) {
            debugPrint(error.toString(), 'error');
            return rejectWithValue(error.toString());
        }

    });
export const selectExpertise = createAction(
    'expertise/selectExpertise',
    function prepare(expertise: string) {
        return {
            payload: {
                expertise: expertise
            },
        }
    });
export const changeExpertisePageNumber = createAction(
    'expertise/changeExpertisePageNumber',
    function prepare(page: number) {
        return {
            payload: {
                page: page
            },
        }
    });

export const changeExpertiseRowNumber = createAction(
    'expertise/changeExpertiseRowNumber',
    function prepare(row: number) {
        return {
            payload: {
                row: row
            },
        }
    });

export const updateExpertiseSearch = createAction(
    'expertise/updateExpertiseSearch',
    function prepare(search: string) {
        return {
            payload: {
                search: search
            },
        }
    });

export const cancelSelection = createAction(
    'expertise/cancelSelection',
    function prepare(cancel: true) {
        return {
            payload: {
                cancel: cancel
            },
        }
    });

export const deleteExpertise = createAsyncThunk(
    'expertise/deleteExpertise',
    async (data: DeleteserTagsModel, { rejectWithValue }) => {
        try {
            const db = getFirestore();

            await Promise.all(data.selectedExpertise.map(async (docId) => {
                const docRef = doc(db, 'Users', data.userId, 'UserTags', docId);
                return await deleteDoc(docRef);
            })
            );


            return {
                deletedExpertise: data.selectedExpertise
            }

        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    });