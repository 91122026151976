import { AnswerDraftModel } from "../../../model/response/answer_draft_model";
import { AnswerModel } from "../../../model/response/answer_model";
import { CardModel } from "../../../model/response/card_model";
import { ChannelModel } from "../../../model/response/channel_model";
import { SwiperModel } from "../../../model/response/swiper_model";
import { UserModel } from "../../../model/response/user_model";
import { MentionOrientation } from "../../card_creation/api/card_creation_state";

export enum AnswerState{
    done,
    pending,
}

export interface ThreadState{
    cardId: string | undefined;
    threadId: string | undefined;
    
    card: CardModel | undefined;
    
    answerDraft: AnswerDraftModel | undefined;
    pendingFiles: string[];
    answerState: AnswerState;
    
    editThread: boolean;
    editAnswer: string | undefined;
    
    senderUser: UserModel | undefined;
    channel: ChannelModel | undefined;
    
    answers: AnswerModel[];
    swipers: SwiperModel[];
    
    threadIsLoading: boolean;
    threadIsCreating: boolean;

}