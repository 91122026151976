import { createSlice } from "@reduxjs/toolkit";
import { ChannelDiscussionState } from "./channel_discussion_state";
import { clearChannelDiscussionState, fetchAllDiscussions, fetchDiscussionsForYou, loadMoreDiscussions } from "./channel_discussion_action";

const initialState: ChannelDiscussionState = {
    
    discussionsForYou: [],
    limit: 5,
    hasMoreDiscussions: true,
    
    allChannelDiscussions: [],
    hasMoreAllDiscussions: true,
    pageIndex:1,
    
    allDiscussionAreLoading: true,
    discussionsForYouAreLoading:true,
}


const channelDiscussionSlice = createSlice({
    name: 'channelDiscussion',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(clearChannelDiscussionState,(state, action) => {
            state.discussionsForYou = action.payload.discussionsForYou;
            state.limit= action.payload.limit;
            state.hasMoreDiscussions = action.payload.hasMoreDiscussions;
            
            state.allChannelDiscussions = action.payload.allChannelDiscussions;
            state.hasMoreAllDiscussions = action.payload.hasMoreAllDiscussions;
            state.pageIndex =  action.payload.pageIndex;
            
            state.allDiscussionAreLoading =  action.payload.allDiscussionAreLoading;
            state.discussionsForYouAreLoading =  action.payload.discussionsForYouAreLoading;
            
        })
        .addCase(fetchDiscussionsForYou,(state, action) => {
            state.discussionsForYou =  action.payload.discussions;
            state.discussionsForYouAreLoading = false;
            if (state.discussionsForYou.length < state.limit){
                state.hasMoreDiscussions = false;
            }
        })
        .addCase(loadMoreDiscussions, (state, action) => {
            state.limit = state.limit + action.payload.limit;
        })
        .addCase(fetchAllDiscussions.fulfilled, (state, action) => {
            
            const currentDiscussions = state.allChannelDiscussions;
            action.payload.discussions.map((card) => 
                currentDiscussions.push(card)
                );
            state.hasMoreAllDiscussions = action.payload.scrollFeed;
            state.allChannelDiscussions = currentDiscussions;
            state.pageIndex = state.pageIndex + 1
            state.allDiscussionAreLoading = false;
        })
        .addCase(fetchAllDiscussions.pending, (state) => {
            state.allDiscussionAreLoading = true;
        })
        .addCase(fetchAllDiscussions.rejected, (state) => {
            state.allDiscussionAreLoading = false;
        })
    }
});

export default channelDiscussionSlice.reducer;