import { Box, CircularProgress, Stack } from "@mui/material";
import { Sidebar } from "../components/sidebar/sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { Header } from "../components/header/header";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { AuthType } from "../features/auth/api/authentication_state";
import { UserModel } from "../model/response/user_model";
import { MobileDownloadComponent } from "../components/mobile-download/mobile_download_component";
import { useResponsive } from "../hook/use_responsive";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { updateUser } from "../features/profile/api/profile_action";
import { ChannelSidebar } from "../features/channel/view/channel_sidebar";
import { CreateCardDialog } from "../features/card_creation/view/create_discussion";
import { CreateChannelDialog } from "../features/create_channel/view/create_channel";
import { MemberChannelDialog } from "../features/channel_members/view/channel_members_dialog";
import { DialogType } from "../features/dialog/dialog_state";
import { fetchCurrentChannel } from "../features/channel/api/channel_action";
import { ExpertDialog } from "../features/expert/view/expert_dialog";
import { NewMessageDialog } from "../features/chat/components/new_message_dialog";
import { AlertDialog } from "../components/alert_dialog/alert_dialog";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { toolTipsSteps } from "../components/tool-tips/tool_tips";
import { IntroductionTutorialDialog } from "../features/introduction/view/introduction_tutorial";
import { changeDialog } from "../features/dialog/dialog_action";
import { updateTutorial } from "../features/introduction/api/introduction_action";
import { setUserProperties } from "firebase/analytics";
import { firebaseAnalitycs } from "../utils/firebase_config";
import { loadCurrentOrganization } from "../features/organization/organization_actions";
import { FetchOrganizationModel } from "../model/request/organization_model";
import { OrganizationModel } from "../model/response/organization_model";
import { Page } from "../features/navigation/navigation_state";
import { sendEvent } from "../features/analitycs/analitycs_action";
import { AnalyticsEvent, currentPageEvent } from "../model/request/analytics_model";
import { CreateTicketDialog } from "../features/customer_service/components/create_ticket_dialog";
import { goForward, openChannelDrawer } from "../features/navigation/navigation_action";
import { PushNotification } from "../features/push_notification/view/notification";
import { AnnouncementDialog } from "../components/announcement/announcement";
import { ChannelSettingsDialog } from "../features/channel/components/channel_settings_dialog";
import { ErrorType } from "../features/error/api/error_state";
import { SnackbarError } from "../features/error/view/snackbar_error";
import { DeleteDialog } from "../components/delete-dialog/delete_dialog";
import { ChangelogDialog } from "../components/changelog-dialog/changelog_dialog";


export const DashboardLayout = () => {

  const drawerIsOpen: boolean = useSelector((state: RootState) => state.navigation.drawerIsOpen);

  const success = useSelector((root: RootState) => root.auth.success);

  const user: UserModel | undefined = useSelector((root: RootState) => root.auth.user);
  const userProfile: UserModel | undefined = useSelector((root: RootState) => root.profile.userProfile);
  const currentOrganization: OrganizationModel | null = useSelector((state: RootState) => state.org.currentOrganization);
  const currentPage: Page = useSelector((state: RootState) => state.navigation.currentPage);
  const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);
  const { isMobile, } = useResponsive();


  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();


  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      if (user) {
        dispatch(updateTutorial({ userId: user.id }))
      }
    }
  };

  if (userProfile) {
    const notificationCounter =
      userProfile.notificationBalance.notificationCounter +
      userProfile.chatBalance.notificationCounter +
      userProfile.announcementBalance.notificationCounter;

    if (notificationCounter > 0) {
      document.title = `SylloTips (${notificationCounter})`;
    } else {
      document.title = 'SylloTips';
    }
  }

  useEffect(
    () => {
      const data: AnalyticsEvent = {
        event: {
          timestamp: new Date().toISOString(),
          page: currentPage,
        },
        eventName: currentPageEvent,
      }
      dispatch(sendEvent(data));
    }, [currentPage]
  )

  useEffect(
    () => {
      if (success == AuthType.Fail) {
        navigate('/auth/login')
      }
    }, [success]
  );
  useEffect(() => {
    if (user) {

      const currentURL = window.location.pathname;
      const isChannelPath: boolean = currentURL.includes('channel'); // true
      
      const page = currentURL.split('/')[1]; // Splits the URL by '/'
      
      if(page.includes('home')){
        dispatch(goForward(Page.HomePage))
      }else if(page.includes('chat')){
        dispatch(goForward(Page.ChatPage))
      }else if (page.includes('followed-threads')){
        dispatch(goForward(Page.FollowedThreadPage))
      }else if (page.includes('your-threads')){
        dispatch(goForward(Page.YourThreadPage))
      }else if(page.includes('sybil')){
        dispatch(goForward(Page.GPTPage));
      }else if(page.includes('channel')){
        dispatch(goForward(Page.ChannelPage))
        dispatch(openChannelDrawer(true));
      }else if(page.includes('thread')){
        dispatch(goForward(Page.ThreadPage))
      }else if(page.includes('search')){
        dispatch(goForward(Page.SearchPage))
      }

      if (isChannelPath) {
        const parts = currentURL.split('/');
        const stringVariable = parts[parts.length - 2];
        dispatch(fetchCurrentChannel(
          {
            userId: user.id,
            channelId: stringVariable,
          }
        ))
      }

    }

  }, [user]);


  useEffect(() => {
    if (user !== undefined) {

      if (user.isFirstLogin) {
        dispatch(changeDialog({ dialog: DialogType.introductionDialog }));
      }

      if(user.seenChangelog){
        dispatch(changeDialog({dialog: DialogType.changelogDialog}));
      }

      const db = getFirestore();
      const q = doc(db, "Users", user.id);

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const currentUser: UserModel = querySnapshot.data() as UserModel;
        dispatch(updateUser(currentUser))
      });

      return () => {
        unsubscribe();
      };
    }

  }, [dispatch, user])


  useEffect(
    () => {
      if (user) {
        const data: FetchOrganizationModel = {
          organizationId: user.organizationId
        }
        dispatch(loadCurrentOrganization(data))
      }
    }, [user]
  );

  useEffect(
    () => {
      if (user && currentOrganization) {
        setUserProperties(firebaseAnalitycs,
          {
            device: 'web',
            user_display_name: user.displayName,
            current_user_id: user.id,
            organization_id: user.organizationId,
            organization_name: currentOrganization.name,
          });
      }
    }, [user, currentOrganization]
  );


  if (isMobile) {
    return (
      <MobileDownloadComponent />
    );
  } else {

    if (success == AuthType.Pending) {
      return (
        <Box component='div' overflow={'hidden'} width={'100vw'} height={'100vh'}
          display='fleex' alignItems='center' justifyContent='center' alignContent={'center'}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box component='div' overflow={'hidden'} width={'100vw'} height={'100vh'}>
        {
          user && <Joyride callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={!user!.isFirstLogin && user!.isFirstTutorial}
            scrollToFirstStep
            showProgress={false}
            showSkipButton
            steps={toolTipsSteps}
          />
        }
        <PushNotification/>
        <SnackbarError milliseconds={5000}/>
        {
          (dialog === DialogType.createDiscussionDialog || dialog === DialogType.createAnnouncementDialog) &&
          <CreateCardDialog />
        }
        {
          dialog === DialogType.createChannelDialog && <CreateChannelDialog />
        }
        {
          dialog === DialogType.channelMembersDialog && <MemberChannelDialog />
        }
        {
          dialog === DialogType.expertDialog && <ExpertDialog />
        }
        {
          dialog === DialogType.introductionDialog && <IntroductionTutorialDialog />
        }
        {
          dialog === DialogType.directMessageDialog && <NewMessageDialog />
        }
        {
          dialog === DialogType.createTicketDialog && <CreateTicketDialog />
        }
        {
          dialog === DialogType.alertDialog && <AlertDialog />
        }
        {
          dialog === DialogType.deleteDialog && <DeleteDialog/>
        }
        {
          dialog===DialogType.announcementDialog && <AnnouncementDialog />
        }
        {
          dialog === DialogType.channelSettingsDialog && <ChannelSettingsDialog />
        }
        {
          dialog === DialogType.changelogDialog && <ChangelogDialog />
        }
        <Box display='flex' flexDirection='column' height={'100%'}>
          <Header />
          <Stack direction="row" height={'100%'}>
            <Sidebar className="sidebar" />
            {drawerIsOpen && <ChannelSidebar />}
            <Outlet />
          </Stack>
        </Box>
      </Box>
    );
  }
}