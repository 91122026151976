export enum MessageType{
    Text,
    Image
}

export enum MirrorType{
    Real,
    Mirrored
}

export interface MessageModel{
    id:string;
    organizationId:string;
    text:string;
    messageType: MessageType;
    receiverId:string;
    writerId:string;

    photo?: string;
    mirrorStatus: MirrorType;

    createdAt: string;
    updatedAt: string;
}