import { Box, Stack, Typography } from "@mui/material";

import { ChannelChatFooter } from "../components/channel_chat_footer";
import { ChannelChatBody } from "../components/channel_chat_body";
import { ChannelChatHeader } from "../components/channel_chat_header";

const ChannelChatPage = () => {
    
    const remainingHeight = 50;
    
    return (
        <Box flexGrow={1} height={`calc(100vh - ${remainingHeight}px)`} sx={{ backgroundColor:'#F7F9FC'}}>
        <Stack direction="column" height='inherit'>
            <ChannelChatHeader/>
            <ChannelChatBody/>
            <ChannelChatFooter/>
        </Stack>
        </Box>
    )
}


export default ChannelChatPage;