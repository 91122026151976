export enum NotificationType {
    answers = 'answers',
    commentOnAnswers = 'comment_answers',
    upVotes = 'upvotes',
    dm = 'dm',
    tag = 'tag',
    badges = 'badges',
  }

export interface NotificationModel {
    id: string;
    title: string;
    body: string;
    viewed: boolean;
    actorPhoto: string;
    threadId: string;
    cardId?: string;
    answerId?: string;
    type: NotificationType;
  }