import { Avatar, Box, Chip, Popover, Typography } from "@mui/material";
import { ChannelModel } from "../../../model/response/channel_model";
import { channelColors } from "../../../utils/channel_theme";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { filterByChannel } from "../api/feed_action";
import DoneIcon from '@mui/icons-material/Done';
export interface FilterChannelPopOverProps {
    open: boolean;
    anchor: HTMLSpanElement | null;
    channels: ChannelModel[];
    onClose?: () => void;
}

export const FilterChannelPopOver: React.FC<FilterChannelPopOverProps> = (props: FilterChannelPopOverProps) => {

    const dispatch = useDispatch<AppDispatch>();
    const currentFilters: string[] = useSelector((state:RootState) => state.feed.filterByChannels )
    const sortedChannels = [...props.channels].sort((a, b) => a.channelName.localeCompare(b.channelName));

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            slotProps={{
                paper:{
                    sx: {
                        borderRradius: '8px',
                        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                      },
                }
            }}
            onClose={props.onClose}
        >
            <Box display='flex' flexDirection='column' p={2} overflow='hidden'
                sx={{
                    overflowY: 'scroll',
                    maxHeight: '250px'
                }}>
                {
                    sortedChannels.map((channel) => {
                        const firstLetter = channel.channelName[0];
                        const channelColor = channelColors[firstLetter];
                        return (
                            <Box key={channel.id} p={0.5} >
                                <Chip variant="outlined"
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer'
                                        }
                                    }}
                                    onClick={() => dispatch(filterByChannel(channel.channelId, channel.channelName))}
                                    avatar={
                                        !currentFilters.includes(channel.channelId)?
                                        <Avatar
                                            sx={{
                                                bgcolor: channelColor,
                                            }}>
                                            <Typography color={'white'}>
                                                {firstLetter}
                                            </Typography>
                                        </Avatar>
                                        :
                                        <Avatar
                                            >
                                            <Box color={'white'}>
                                                <DoneIcon/>
                                            </Box>
                                        </Avatar>
                                        
                                    
                                    } label={channel.channelName} />
                            </Box>
                        )
                    })
                }
            </Box>
        </Popover>
    );
}