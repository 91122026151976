import { Box, Card, CardContent, CardHeader, LinearProgress, List, ListItem, Stack, Typography, styled, useTheme } from "@mui/material";
import { MedalModel } from "../../../model/response/medal_model";
import { useTranslation } from 'react-i18next';
import { ContentType } from "../api/expert_state";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { changeContent } from "../api/expert_action";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 10,
  width: '85%',
  backgroundColor:'#E7E7E7',
  '& .MuiLinearProgress-bar': {
    borderRadius: 10,
    background: '#FF9A34',
  },
}));

export interface BadgesProps {
  medals: MedalModel[];
}

export const ProfileBadges = (props: BadgesProps) => {

  const theme = useTheme();
  const { t } = useTranslation();
  const contentType: ContentType = useSelector((state: RootState) => state.expert.contentType);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Box p={2} sx={{
      borderRadius: '10px',
      backgroundColor: '#F7F9FC',
      boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'

    }}
    onClick={() => dispatch(changeContent(ContentType.badges))}
    >
      <Stack spacing={1}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography fontWeight='600' color={theme.palette.textColor?.main}>
            {t('expertPage.achievementTitle')}
          </Typography>
          {contentType === ContentType.general ? <Box sx={{'&:hover':{cursor:'pointer'}}}><NavigateNextOutlinedIcon /></Box> : <></>}

        </Box>
        {
          contentType === ContentType.general ?
            <Box display='flex' flexDirection={'row'} justifyContent={'space-between'}>
              {
                props.medals.map((medal) =>
                  <Box key={medal.badgePhoto} position="relative" display="inline-block">
                    <img src={medal.badgePhoto} width={'60px'} />
                    <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            width:'100%',
                            position: 'absolute',
                            bottom: 0, // Position at the bottom
                            left: '0%',
                            zIndex:2}}>
                            <Typography
                              variant="caption"
                              color={'white'}
                              fontWeight={600}
                              gutterBottom
                              style={{
                                padding:0,
                                 // Center horizontally
                              }}
                            >
                              {t('expertPage.levelDescription', {level:medal.progressLevel + 1})}
                            </Typography>
                          </Box>
                  </Box>
                )
              }
            </Box>
            :
            <Box
            display={'flex'}
            flexDirection={'column'}
            maxHeight={'300px'}
            sx={{
                overflowY: "scroll",
                '&::-webkit-scrollbar': {
                    display: 'none', // Customize the width of the scrollbar
                }
            }}>
              {
                props.medals.map(
                  (medal) =>
                      <Stack direction={'row'} width={'100%'} spacing={2}>
                        <Box key={medal.badgePhoto} position="relative" display="inline-block">
                          <img src={medal.badgePhoto} width={'60px'} />
                          <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            width:'100%',
                            position: 'absolute',
                            bottom: 0, // Position at the bottom
                            left: '0%',
                            zIndex:2}}>
                            <Typography
                              variant="caption"
                              color={'white'}
                              fontWeight={600}
                              gutterBottom
                              style={{
                                padding:0,
                                 // Center horizontally
                              }}
                            >
                              {t('expertPage.levelDescription', {level:medal.progressLevel + 1})}
                            </Typography>
                          </Box>
                        </Box>
                        <Box width={'90%'} >
                          <Typography color={theme.palette.textColor!.main} >{medal.name}</Typography>
                          <Typography variant='body2' color={theme.palette.textColor!.main} >{medal.description}</Typography>
                          <Box display={'flex'} alignItems={'center'} justifyContent='space-between'>
                            <BorderLinearProgress sx={{ width: '80%', color: 'red' }} value={medal.progress / medal.limit * 100} variant="determinate" />
                            <Typography color={theme.palette.textColor!.main}>{medal.progress + '/' + medal.limit}</Typography>
                          </Box>
                        </Box>
                      </Stack>
                )
              }
            </Box>
        }
      </Stack>
    </Box>
  );
}