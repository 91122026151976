import { createSlice } from "@reduxjs/toolkit"
import { ContentType, ExpertState } from "./expert_state"
import { addTag, changeContent, changeDateMode, clearExpert, fetchAchievements, fetchExpert, fetchExpertise, fetchPosition, getUserChart, removeTag, updatePicture, updateProfilePhoto, updateSliderValue } from "./expert_action";
import { DateMode } from "../../../model/request/user_chart_model";
import { UserModel } from "../../../model/response/user_model";

const initialState: ExpertState = {
    expert: undefined,
    positionInLeaderboard:0,
    contentType: ContentType.general,
    chartMode: DateMode.week,
    openDiscussions: [],
    upvotes: [],
    accolades: [],
    categories: [],
    answers:[],
    attributions:[],
    medals: [],
    expertises: [],
    lastSeenExpertise: undefined,
    scrollExpertise: true,

    tagsToKeep: [],
    sliderValue: 0,
}
const expertSlice = createSlice({
    name: 'expert',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(fetchExpert.fulfilled, (state, payload) => {
            state.expert = payload.payload.expert;
        })
        .addCase(fetchExpertise.fulfilled, (state, payload) => {
            const currentExpertise = state.expertises;
            payload.payload.tags.map((tag) => currentExpertise.push(tag))
            state.expertises = currentExpertise;
            state.lastSeenExpertise = payload.payload.lastElement;
            state.scrollExpertise = payload.payload.continueToScroll;
        })
        .addCase(fetchAchievements.fulfilled, (state, payload) => {
            const currentMedals= state.medals;
            payload.payload?.medals.map((tag) => currentMedals.push(tag))
            state.medals = currentMedals;
        })
        .addCase(fetchPosition.fulfilled, (state, action) => {
            state.positionInLeaderboard = action.payload.position;
        })
        .addCase(clearExpert, (state) => {
            state.expert = undefined;
            state.expertises = [];
            state.medals = [];
            state.upvotes = [];
            state.categories = [];
            state.openDiscussions = [];
            state.answers = [];
            state.positionInLeaderboard = 0;
            state.accolades = [];
            state.attributions = [];
            state.chartMode = DateMode.week;
            state.contentType = ContentType.general;
            state.lastSeenExpertise= undefined;
            state.scrollExpertise = false;
        })
        .addCase(changeContent, (state, payload) => {
            state.contentType = payload.payload.type;
        })
        .addCase(changeDateMode,(state, action) => {
            state.chartMode = action.payload.dateMode;
        })
        .addCase(getUserChart.fulfilled, (state, action) => {
            state.openDiscussions = action.payload.openDiscussions;
            state.categories = action.payload.categories;
            state.accolades = action.payload.accolades;
            state.attributions = action.payload.attributions;
            state.upvotes = action.payload.upvotes;
            state.answers = action.payload.answers;
        })
        .addCase(addTag, (state, payload) => {
            const currentTag = state.tagsToKeep;
            currentTag.push(payload.payload.tag);
            state.tagsToKeep = currentTag;
        })
        .addCase(removeTag, (state, payload) => {
            const currentTag = state.tagsToKeep.filter((tag) => tag.tagId !== payload.payload.tag.tagId);
            state.tagsToKeep = currentTag;
            
        })
        .addCase(updateSliderValue, (state, payload) => {
            state.sliderValue = payload.payload.value;  
        })
        .addCase(updateProfilePhoto, (state, action) => {
            var currentExpert = state.expert;
        
            if(currentExpert){
            const user:UserModel={
                ...currentExpert,
                ...(action.payload.photo !== undefined && { photo: action.payload.photo }),
            }
    
            state.expert = user
            }
        })
        .addCase(updatePicture.fulfilled, (state, action) => {
            const expert = state.expert;

            if (expert){
                const updatedExpert:UserModel  = {
                    ...expert,
                    photo: action.payload.url,
                }

                state.expert = updatedExpert;
            }
            
        })
}});

export default expertSlice.reducer;