export enum ChannelsType {
    PRIVATE = 0,
    PUBLIC = 1,
}

export enum DiscussionsChannelPermissions {
  ONLY_ROUTED = 'Discussion.Routed',
  ONLY_ADMINS = 'Discussion.Admin',
  EVERYONE = 'Discussion.All',
}

export enum AnswersChannelPermissions {
  ONLY_ADMINS = 'Answers.Admin',
  MEMBERS = 'Answers.Members',
  EVERYONE = 'Answers.All',
}

export enum AnnouncementsChannelPermissions {
  ONLY_ADMINS = 'Announcements.Admin',
  EVERYONE = 'Announcements.All',
}

export enum DocumentsChannelPermissions {
  ONLY_ADMINS = 'Documents.Admin',
  EVERYONE = 'Documents.All',
}

export type ChannelPermissions = DiscussionsChannelPermissions | AnswersChannelPermissions | AnnouncementsChannelPermissions | DocumentsChannelPermissions;
export type ChannelPermissionsArray = Array<ChannelPermissions>;

export interface ChannelModel {
    id: string;
    channelId: string;
    channelName: string;
    channelPhoto: string;
    channelDescription: string;
    chatChannelId: string;
    discussionBalance: {
      hasNotifications: boolean;
      notificationCounter: number;
    };
    isAdmin: boolean;
    isMuted: boolean;
    type: ChannelsType;
    chatBalance?: {
      hasNotifications: boolean;
      notificationCounter: number;
    };
    permissions: ChannelPermissions[];
    totalMembers: number;
    createdAt: string;
    updatedAt: string;
  }