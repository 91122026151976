import { DiscussionsChannelPermissions, AnswersChannelPermissions, AnnouncementsChannelPermissions, DocumentsChannelPermissions } from "../../../model/response/channel_model";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";

export enum ChannelCreationStep{
    ChannelType,
    ChannelDetails,
    Recap,
    SelectMembers,
    
}


export interface CreateChannelState{
    
    title: string;

    discussionPermission: DiscussionsChannelPermissions;
    answerPermission: AnswersChannelPermissions;
    announcementPermission: AnnouncementsChannelPermissions;
    documentPermission: DocumentsChannelPermissions;
    
    currentStep: ChannelCreationStep;
    isPrivate: boolean;
    membersToAdd: UserSuggestedModel[];
}