import { Box, Chip, Popover, Stack, Typography } from "@mui/material";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { TicketType } from "../models/response/TicketModel";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { chooseTicketType } from "../api/customer_service_action";

export interface ChooseTicketTypePopOverProps {
    open: boolean;
    anchor: HTMLDivElement | null;
    onClose: () => void;
}

export const ChooseTicketTypePopOver: React.FC<ChooseTicketTypePopOverProps> = (props: ChooseTicketTypePopOverProps) => {

    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const chooseTicket = (type: TicketType) => {
        dispatch(chooseTicketType(type));
        props.onClose();
    }

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: {
                        borderRradius: '8px',
                        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                    },
                }
            }}
            onClose={props.onClose}
        >
            <Stack  direction='column' p={2} spacing={1}
                sx={{ overflowY: 'scroll', maxHeight: '250px' }}>
                <Chip  size={'small'}
                    sx={{color:'white', backgroundColor:'rgba(140, 26, 106, 1)', '&:hover': { cursor: 'pointer' } }}
                    label={t('customerServicePage.ticketTypeOne')} onClick={() => chooseTicket(TicketType.HINT)}/>
                <Chip size={'small'} onClick={() => chooseTicket(TicketType.ISSUE)}
                    sx={{color:'white', backgroundColor:'rgba(77, 160, 217, 1)', '&:hover': { cursor: 'pointer' } }}
                    label={t('customerServicePage.ticketTypeTwo')} />
            </Stack>
        </Popover>
    );
}


export const ChooseTicketType: React.FC = () => {

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [openedPopOver, setOpenedPopOver] = useState(false);
    
    const handleChannelSelectionClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenedPopOver(true);
    };

    const handleCloseChannelSelectionClick = () => {
        setAnchorEl(null);
        setOpenedPopOver(false);
    };

    const ticketType = useSelector((state: RootState) => state.customerService.ticketType);
    const { t } = useTranslation();
    
    if (ticketType === TicketType.GENERAL) {
        return (
            <Box>
                <Box onClick={handleChannelSelectionClick}>
                    <Stack direction='row' spacing={1} display='flex' >
                        <Typography>
                            {t('customerServicePage.ticketTypeSelectionButton')}
                        </Typography>
                        <KeyboardArrowDownIcon />
                    </Stack>
                </Box>
                
                <ChooseTicketTypePopOver 
                    open={openedPopOver} 
                    anchor={anchorEl} 
                    onClose={handleCloseChannelSelectionClick} 
                    />
            </Box>
        )
    } else {
        return (
            <Box>
                <Box onClick={handleChannelSelectionClick}>
                    <Chip  size={'small'} 
                    sx={{
                        border:'none',
                        color:'white', 
                        backgroundColor:ticketType === TicketType.ISSUE?'rgba(77, 160, 217, 1)':'rgba(140, 26, 106, 1)', 
                        '&:hover': { cursor: 'pointer' } }}
                    
                    label={ticketType === TicketType.ISSUE?t('customerServicePage.ticketTypeTwo'):t('customerServicePage.ticketTypeOne')} />
                </Box>
                <ChooseTicketTypePopOver 
                    open={openedPopOver} 
                    anchor={anchorEl} 
                    onClose={handleCloseChannelSelectionClick} 
                    />
        </Box> 
        )
    }
    
}