import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { editMessage, selectMessageToEdit } from '../api/channel_chat_action';
import { useEffect, useRef } from 'react';
import Quill from 'quill';
import './channel_chat_bubble.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseApp } from '../../../utils/firebase_config';
import { UserSuggestedModel } from '../../../model/response/user_suggested_model';
import { UserMentionModel } from '../../../model/response/user_mention_model';


export interface ChannelChatBubbleEditorProps {

  text: string;
  index: number;
  isEditing: boolean;
  isOptimistic: boolean;
}

export const ChannelChatBubbleEditor: React.FC<ChannelChatBubbleEditorProps> = (props: ChannelChatBubbleEditorProps) => {

  const user = useSelector((state: RootState) => state.auth.user);
  const selectedChannel = useSelector((state: RootState) => state.channel.currentChannel);
  const editorRef = useRef(null);
  const deltaRef = useRef<string | undefined>(undefined);
  const plainTextRef = useRef<string | undefined>(undefined);
  const messageToEdit = useSelector((state: RootState) => state.channelChat.messageToEdit);
  const dispatch = useDispatch<AppDispatch>();

  const modifyMessage = () => {
    if (!props.isEditing) return;
    if (
      messageToEdit &&
      deltaRef.current &&
      user &&
      selectedChannel &&
      plainTextRef.current) {


      dispatch(editMessage({
        messageId: messageToEdit.id,
        plainText: plainTextRef.current,
        text: deltaRef.current,
        organizationId: user.organizationId,
        channelId: selectedChannel.channelId,
        channelChatId: selectedChannel.chatChannelId,
      }));
    }

  }


  var bindings = React.useMemo(() => {
    return {
      enter: {
        key: 'enter',
        handler: function () {
          modifyMessage();
        }
      },
      escape: {
        key: 'escape',
        handler: function () {
          dispatch(selectMessageToEdit(undefined));
        }
      }
    }
  }, [props.isEditing,])

  
  const chatModules = React.useMemo(() => {
    return {
      theme: 'bubble',
      readOnly: true,
      modules: {
        toolbar: false,
        
        magicUrl: true,
        mention: {
          onSelect: (item, insertItem) => {
              insertItem(item);
          },
          renderItem: (data) => {
              const div = document.createElement('div');
              div.className = 'mention-item';

              const img = document.createElement('img');
              img.className = 'mention-avatar';
              img.src = data.photo;

              const span = document.createElement('span');
              span.textContent = data.value;

              div.appendChild(img);
              div.appendChild(span);

              return div;
          },
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@"],
          defaultMenuOrientation: 'top',
          fixMentionsToQuill: false,
          source: async (searchTerm:string, renderList:Function,mentionChar:string) => {
              
              if(searchTerm.trim() == ""){
                  renderList([],searchTerm);
              }
              
              if(user && selectedChannel && !searchTerm.includes(" ")){
                  const data = {
                      fullname: searchTerm,
                      organizationId: user.organizationId,
                      channelId: selectedChannel.channelId,
                  };

                  const functions = getFunctions(firebaseApp,'europe-west1');
                  const searchUsers = httpsCallable(functions, 'searchUserByFullnameFunction');
                  
                  const results =  await searchUsers({user:data});
                  const users = results.data as UserSuggestedModel[];
                  
                  const usertToTags = users.map((user) => {

                          const mentionModel:UserMentionModel = {
                              id: user.id,
                              value:user.displayName,
                              photo: user.photo,
                              belongsToChannel: user.belongsToChannel
                          }
                          return mentionModel;
                      
                      
                  });
                  renderList(usertToTags.filter(user => user.belongsToChannel),searchTerm)    
              }                     
          }
      },
        keyboard: {
          bindings: bindings
        },
      }
    }
  }, [bindings, user, selectedChannel]);

  useEffect(() => {

    if (editorRef && editorRef.current) {
      const quill = new Quill(
        '#chat-' + props.index + props.isEditing,
        chatModules);

      quill.setContents(JSON.parse(props.text));

      if (props.isEditing) {
        
        quill.enable(props.isEditing);
        
        quill.on('text-change', function (delta, oldDelta, source) {
          const deltaContent = quill.getContents();
          const deltaString = JSON.stringify(deltaContent.ops);
          deltaRef.current = deltaString;

          plainTextRef.current = quill.getText();
        });
      }

      if(props.isOptimistic){
        const editorElement = document.getElementById('chat-' + props.index + props.isEditing)
        var editorArea = document.querySelector('#chat-' + props.index + props.isEditing + ' .ql-editor');
        if (editorElement && editorArea) {
            
            var editorContainer = editorArea as HTMLElement;
            editorContainer.style.opacity = '0.5';
        } 
      }
    }
  }, [editorRef, chatModules]);



  return (
    <div ref={editorRef} id={'chat-' + props.index + props.isEditing} />
  );
};

