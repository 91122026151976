import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ChannelChatDateProps{
    date: string;
  }

export const ChannelChatDate: React.FC<ChannelChatDateProps> = (props: ChannelChatDateProps) => {
    
    const date = new Date(props.date);
    const theme = useTheme();
    const {t} = useTranslation();
    
    const monthNames = [
        t('date.jan'), // January
        t('date.feb'), // February
        t('date.mar'), // March
        t('date.apr'), // April
        t('date.may'), // May
        t('date.jun'), // June
        t('date.jul'), // July
        t('date.aug'), // August
        t('date.sep'), // September
        t('date.oct'), // October
        t('date.nov'), // November
        t('date.dec'), // December
      ];
    
    return (
        <Box display={'flex'} flexDirection={'row'} alignItems='center' justifyContent={'center'} p={1}>
            <Box flexGrow={1} marginRight={2}>
                <Divider flexItem />
            </Box>
            <Typography color={theme.palette.textColor?.main} variant='body2'>
                {date.getDate()} {monthNames[date.getMonth()]} {date.getFullYear()}
            </Typography>
            <Box flexGrow={1} marginLeft={2}>
                <Divider flexItem />
            </Box>
        </Box>
    );
}