import { AttachmentModel } from "../../../../model/response/thread_draft_model";


export enum TicketType{
    HINT = 0,
    ISSUE = 1,
    GENERAL = 2,
}

export interface TicketModel{
    id: string;
    title:string;
    attachments?: AttachmentModel[];
    
    chatBalance: {
        hasNotification: boolean;
        notificationCounter: number;
    },
    
    user:{
        senderId:string;
        senderDisplayName: string;
        senderPhoto: string;
    }

    type: TicketType;
    isSolved: boolean;

    updatedAt: string;
    createdAt: string;
}