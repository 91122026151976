import { createSlice } from "@reduxjs/toolkit";
import { SearchState } from "./search_state";
import { addOrRemoveFilter, autocompleteSearchQuery, clearCache, computeSemanticSearch, searchSimilarCards, searchUsers, setSearchQuery, setTabIndex } from "./search_action";

const initialState: SearchState = {
  searchQuery: '',

  filters:[],
  autocompleteIsLoading: false,
  options: [],

  tabIndex: 0,

  searchResults: null,
  searchResultsIsLoading: false,

  searchedUsers:[],

  similarResults: undefined,
  similarCardLoading: false,

  error: '',
  
}

const searchSlice = createSlice({
    name: 'search',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(clearCache, (state,) => {

          state.searchQuery = '';

          state.filters = [];
          state.autocompleteIsLoading = false;
          state.options = [];

          state.tabIndex = 0;

          state.searchResults = null;
          state.searchResultsIsLoading = false;

          state.searchedUsers = [];

          state.similarResults = undefined;
          state.similarCardLoading = false;

          state.error = '';
        })
        .addCase(setTabIndex, (state, action) => {
          state.tabIndex = action.payload.index;
        })
        .addCase(setSearchQuery, (state, payload) => {
          state.searchQuery = payload.payload.query;
        })
        .addCase(addOrRemoveFilter, (state, action) => {
          const filter = action.payload.filter;
          const currentFilters = state.filters;

          if(currentFilters.includes(filter)){
            state.filters = currentFilters.filter((currentFilter) => currentFilter != filter)
          }else{
            currentFilters.push(filter);
            state.filters = currentFilters;
          }
        })
        .addCase(autocompleteSearchQuery.fulfilled, (state, payload) => {
          state.autocompleteIsLoading = false;
          state.options = payload.payload.options;
          
        })
        .addCase(autocompleteSearchQuery.pending, (state) => {
            state.autocompleteIsLoading = true;
        })
        .addCase(autocompleteSearchQuery.rejected, (state, payload) => {
          state.error = payload.error.message || 'Impossible to update feed';
          state.autocompleteIsLoading = false;
        })
        .addCase(searchSimilarCards.fulfilled, (state, payload) => {
          state.similarResults = payload.payload;
          state.similarCardLoading = false;
        })
        .addCase(searchSimilarCards.pending, (state) => {
          state.similarCardLoading = true;
        })
        .addCase(searchSimilarCards.rejected, (state, payload) => {
          state.similarCardLoading = false;
        })
        .addCase(computeSemanticSearch.fulfilled,(state,payload) => {
          state.searchResults = payload.payload.searchResults;
          state.searchResultsIsLoading = false;
        })
        .addCase(computeSemanticSearch.pending,(state,payload) => {
          state.searchResultsIsLoading = true;
          state.searchResults = null;
        })
        .addCase(computeSemanticSearch.rejected,(state,payload) => {
          state.searchResultsIsLoading = false;
          state.searchResults = null;
        })
        .addCase(searchUsers.fulfilled,(state,payload) => {
          state.searchedUsers = payload.payload.users;
          state.searchResultsIsLoading = false;
        })
        .addCase(searchUsers.pending,(state,payload) => {
          state.searchResultsIsLoading = true;
          state.searchResults = null;
        })
        .addCase(searchUsers.rejected,(state,payload) => {
          state.searchResultsIsLoading = true;
        })
        
    }
  });

  export default searchSlice.reducer;