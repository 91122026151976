import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {deleteDoc, doc, getFirestore, updateDoc } from "firebase/firestore";
import { viewChannelAnnouncement } from "../../channel/api/channel_action";
import { CardModel } from "../../../model/response/card_model";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";

export const viewAnnouncement = createAsyncThunk(
    'announcement/viewAnnouncement',
    async (data:{
        userId: string;
        threadId: string;
    }, {dispatch, rejectWithValue}) => {
        try{
            const db = getFirestore();

            const docRef = doc(db,'Users', data.userId,'Threads', data.threadId);
            dispatch(viewChannelAnnouncement(data.threadId));
            dispatch(viewAnnouncemntInNotification(data.threadId));
            await updateDoc(docRef, {'isClosed':true});
        
        }catch(error:any){
            rejectWithValue(error.toString());
        }
    }
);
export const viewAnnouncemntInNotification = createAction(
    'channel/viewChannelAnnouncement',
    function prepare(threadId:string){
        return {
            payload: {
                threadId,
            }
        }
    }
);

export const deleteAnnouncement = createAsyncThunk(
    'announcement/deleteAnnouncement',
    async (data:{
        userId: string;
        threadId: string;
    }, {rejectWithValue, dispatch}) => {
        try{
            const db = getFirestore();
            dispatch(changeDialog({dialog: DialogType.none}));
            const docRef = doc(db,'Users', data.userId,'Threads', data.threadId);
            await deleteDoc(docRef);
        
        }catch(error:any){
            rejectWithValue(error.toString());
        }
    }
);

export const fetchAnnouncements = createAction(
    'announcement/fetchAnnouncements',
    function prepare(feed:CardModel[]){
        return {
            payload: {
                feed:feed
            }
        }
    }
);

export const loadMore = createAction(
    'announcement/loadMore',
    function prepare(limit:number){
        return {
            payload: {
                limit:limit
            }
        }
    }
);
