import { Box, CardMedia, Stack, Typography, useTheme } from "@mui/material"
import playStore from '../../assets/PlayStore.png';
import appStore from '../../assets/app_store.png';

export const MobileDownloadComponent = () => {
    const theme = useTheme();
    return (
        <Box display='flex' flexDirection={'column'} justifyContent={'space-between'} flex={1} sx={{
            backgroundColor: 'white',
            height: '100vh'
        }}>
            <Stack spacing={2} p={2}>
                <CardMedia
                    component="img"
                    image={'https://firebasestorage.googleapis.com/v0/b/syllotips-dev.appspot.com/o/utils%2Flogotype.png?alt=media&token=c2758ba0-8089-4c6c-8814-204574e834fe'}
                    sx={{
                        
                        width: '70%',
                        objectFit: 'contain', // Fit the image to the dimensions while maintaining aspect ratio
                    }}
                />

                <Typography
                    variant="h2"
                    fontWeight='500'
                    sx={{
                        
                        backgroundImage: 'linear-gradient(to right, #d461a1, #f4b117)',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                    }}
                >
                    To unlock the full potential of SylloTips, download the mobile app from the link below

                </Typography>
            </Stack>
            <Stack justifyContent='center' alignContent='center' alignItems='center' spacing={2}>
                <CardMedia onClick={
                    () => window.open('https://play.google.com/store/apps/details?id=com.syllotips.app&hl=en_US', '_blank')
                }
                    component="img"
                    image={playStore}
                    sx={{
                        width: '60%',
                        objectFit: 'contain', // Fit the image to the dimensions while maintaining aspect ratio
                    }}
                />
                <CardMedia onClick={
                    () => window.open('https://apps.apple.com/it/app/syllotips/id6446053759', '_blank')
                }
                    component="img"
                    image={appStore}
                    sx={{
                        width: '60%',
                        objectFit: 'contain', // Fit the image to the dimensions while maintaining aspect ratio
                    }}
                />

            </Stack>
            <Stack p={2} alignItems='center'>
                <Typography onClick={
                    () => window.open('https://www.syllotips.com/contact-us/', '_blank')
                }
                    variant="h5"
                    color={theme.palette.textColor?.main}

                >
                    Get in touch!

                </Typography>
            </Stack>

        </Box>
    )
}