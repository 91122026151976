import React, { useEffect, } from 'react';
import { Page } from '../../features/navigation/navigation_state';
import { goForward, openChannelDrawer } from '../../features/navigation/navigation_action';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { Box, Stack, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import { UserModel } from '../../model/response/user_model';
import { collection, getFirestore, onSnapshot, orderBy, query } from 'firebase/firestore';
import { ChannelModel } from '../../model/response/channel_model';
import { fetchChannels, fetchLastChannel, selectChannel } from '../../features/channel/api/channel_action';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { setIsSearchMessage } from '../../features/gpt/api/gpt_action';
import { clearCache } from '../../features/search/api/search_action';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { SidebarElement } from './sidebar_element';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
export interface SideBarProps {
    className: string;
}


export const Sidebar: React.FC<SideBarProps> = (props: SideBarProps) => {


    const dispatch = useDispatch<AppDispatch>();
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const profile: UserModel | undefined = useSelector((state: RootState) => state.profile.userProfile);
    const notificationNumber: number = useSelector((state: RootState) => state.channel.notificationNumber);

    const navigate = useNavigate();
    const page: Page = useSelector((state: RootState) => state.navigation.currentPage);

    useEffect(() => {
        if (user !== undefined) {
            const db = getFirestore();
            const q = query(collection(db, "Users", user.id, "ChannelSubscriptions"), orderBy('createdAt', 'asc'));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const channels: ChannelModel[] = querySnapshot.docs.map(doc => doc.data() as ChannelModel);
                dispatch(fetchChannels(channels));
            });

            return () => {
                unsubscribe();
            };
        }

    }, [dispatch, user]);

    const handleForwardClick = (currentPage: Page) => {
        dispatch(clearCache())
        dispatch(goForward(currentPage));
        changeScreen(currentPage);
    };

    const changeScreen = (currentPage: Page) => {

        if (page === currentPage) return;
        dispatch(goForward(currentPage));
        switch (currentPage) {
            case Page.HomePage:
                navigate('/home');
                dispatch(openChannelDrawer(false));
                dispatch(selectChannel({ channel: null, clearCache: true }));
                break;
            case Page.ChatPage:
                navigate('/chat');
                dispatch(openChannelDrawer(false));
                dispatch(selectChannel({ channel: null, clearCache: true }));
                break;
            case Page.GPTPage:
                dispatch(setIsSearchMessage(false));
                navigate('/sybil');
                dispatch(openChannelDrawer(false));
                dispatch(selectChannel({ channel: null, clearCache: true }));
                break;
            case Page.CustomerServicePage:
                navigate('/customer_service');
                dispatch(openChannelDrawer(false));
                dispatch(selectChannel({ channel: null, clearCache: true }));
                break;
            case Page.ChannelPage:
                dispatch(fetchLastChannel({ userId: user!.id, channelId: 'AGORA' }))
                navigate('channel/AGORA/discussions')
                dispatch(openChannelDrawer(true));
                break;
            default:
                break;
        }
    }



    return (
        <Box height={'100%'} paddingTop={1} paddingRight={0.8} sx={{
            backgroundColor: 'rgba(247, 249, 252, 1)',
            boxShadow: '0px 0px 4px 0px #00000040',
            zIndex: 3,

        }}>
            <Box overflow={'scroll'} height={'98%'} className={props.className}  >
                <Stack spacing={2} alignItems={'center'}>
                    <SidebarElement
                        icon={<HomeOutlinedIcon sx={{ color: '#4A4A4A' }} />}
                        referencePage={Page.HomePage}
                        onClick={() => { handleForwardClick(Page.HomePage); }} />
                    <SidebarElement
                        icon={<SpaceDashboardOutlinedIcon sx={{ color: '#4A4A4A' }} />}
                        referencePage={Page.ChannelPage}
                        badge={notificationNumber}
                        onClick={() => { handleForwardClick(Page.ChannelPage); }} />
                    <SidebarElement
                        icon={<ChatOutlinedIcon sx={{ color: '#4A4A4A' }} />}
                        referencePage={Page.ChatPage}
                        badge={profile?.chatBalance.notificationCounter}
                        onClick={() => { handleForwardClick(Page.ChatPage); }} />
                    <SidebarElement
                        icon={<SmartToyOutlinedIcon sx={{ color: '#4A4A4A' }} />}
                        referencePage={Page.GPTPage}
                        onClick={() => { handleForwardClick(Page.GPTPage); }} />
                    <SidebarElement
                        icon={<ConfirmationNumberOutlinedIcon sx={{ color: '#4A4A4A' }} />}
                        referencePage={Page.CustomerServicePage}
                        hasNotifications={profile?.feedbackBalance.hasNotifications}
                        onClick={() => { handleForwardClick(Page.CustomerServicePage); }} />
                </Stack>
            </Box>
        </Box>
    );
}

