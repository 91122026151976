
import AttachFileIcon from '@mui/icons-material/AttachFile';
import './discussion_editor_toolbar.css'
import { useFilePicker } from 'use-file-picker';
import { ImageFileSizeValidator } from '../../../utils/file_picker_validator/image_picker_validator';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { saveImages } from '../api/card_creation_action';
import { FileType, ThreadDraftModel } from '../../../model/response/thread_draft_model';
import { ChannelModel } from '../../../model/response/channel_model';
import { UserModel } from '../../../model/response/user_model';
import { changeDialog } from '../../dialog/dialog_action';
import { DialogType } from '../../dialog/dialog_state';
import { CardType } from '../../../model/response/card_model';

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

export interface ToolBarProps {
  id?: string;
  uploadImage?: () => void;
}

export const DiscussionEditorToolbar: React.FC = (props: ToolBarProps) => {

  const draft: ThreadDraftModel | undefined = useSelector((state: RootState) => state.cardCreation.cardDraft);
  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  const selectedChannel: ChannelModel | undefined = useSelector((state: RootState) => state.cardCreation.selectedChannel);
  const type: CardType = useSelector((state: RootState) => state.cardCreation.type);

  const dispatch = useDispatch<AppDispatch>();
  
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: ['image/*','.pdf'],
    readAs: "DataURL",
    limitFilesConfig: { min: 1 },
    validators: [
        new ImageFileSizeValidator()
    ],
    onFilesRejected: (data) => {
        const maxLimitExceeded = data.errors[0].maxLimitExceeded;
        const fileSize = data.errors[0].fileSizeToolarge;
        if (user) {
            if (maxLimitExceeded) {
                dispatch(changeDialog(
                    {
                        dialog: DialogType.alertDialog,
                        alertTitle: 'Oops, it appears there was an issue!',
                        alertMessage: 'You have selected too many pictures. Please limit your selection to a maximum of 10 pictures at a time.',
                    }
                ));
            } else if (fileSize) {
                dispatch(changeDialog(
                    {
                        dialog: DialogType.alertDialog,
                        alertTitle: 'Oops, it appears there was an issue!',
                        alertMessage: 'The total size of  pictures exeeds the maximum size allowed (2Mb)',
                    }
                ));

            }
        }

    },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
        const dataUrls: string[] = [];
        const names: string[] = [];
        const sizes: string[] = [];
        const fileTypes: FileType[] = [];
        filesContent.map((fileContent, index) => {
            const fileSize = (fileContent.size / (1024 * 1024)).toFixed(2);
            sizes.push(fileSize);
            names.push(plainFiles[index].name);
            dataUrls.push(fileContent.content);
            if (fileContent.content.startsWith('data:image/')) {
                fileTypes.push(FileType.IMAGE);
            }else{
                fileTypes.push(FileType.DOCUMENT);
            }
        });

        if (user) {
            dispatch(saveImages({
                images: dataUrls,
                fileNames: names,
                fileTypes:fileTypes,
                userId: user.id,
                type: type,
                selectedChannelId: selectedChannel?.channelId || '',
                draftId: draft?.id
            }));
        }
    },
});

  return (
    <div id={props.id ? props.id : "toolbar"} className="create-discussion-toolbar">
      <script src="https://cdn.quilljs.com/1.3.6/quill.js"></script>
      <span className="ql-div">
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
        </span>
        <span className="ql-formats">
          <button className="ql-blockquote" />
        </span>
        <span className="ql-formats">
        <button className="ql-link" />
        <button onClick={openFileSelector}>
          <AttachFileIcon />
        </button>
        </span>
        <span className="ql-formats">
          <button className="ql-code-block" />
        </span>
      </span>

    </div>
  )
}
