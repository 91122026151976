import { Box, Badge, Stack, Avatar, Typography, IconButton } from "@mui/material";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { useState } from "react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { DeleteChatModel } from "../../../model/request/delete_chat";
import { deleteChat } from "../api/chat_action";

const truncateText = (string, n) => {
  return string?.length > n ? `${string?.slice(0, n)}...` : string;
};

const StyledChatBox = styled(Box)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export interface ChatProps {
  id: string;

  organizationId: string;
  lastMessage: string;

  chatBalance: {
    hasNotifications: boolean;
    notificationCounter: number;
  },

  user: {
    responderDisplayName: string;
    responderId: string;
    responderPhoto: string;
  }

  updatedAt: string;

  isSelected: boolean;
  onClick?: () => void;

}
export const ChatElement = (props: ChatProps) => {
  const online = false;
  const user: UserModel | undefined  = useSelector((state:RootState) => state.auth.user);
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [isHover, setIsHover] = useState(false);
  const getTime = (date: string) => {
    const creationDate = new Date(date);
    var hour = creationDate.getHours().toString();
    var minute = creationDate.getMinutes().toString();

    if (hour.length === 1) {
      hour = '0' + hour
    }

    if (minute.length === 1) {
      minute = '0' + minute
    }

    return hour + ':' + minute;
  }

  return (
    <StyledChatBox
      onMouseEnter={() => { setIsHover(true) }}
      onMouseLeave={() => setIsHover(false)}
      onClick={props.onClick}
      sx={{
        width: "90%",

        borderRadius: 1,

        backgroundColor: props.isSelected
          ? theme.palette.mode === "light"
            ? alpha(theme.palette.primary.main, 0.5)
            : theme.palette.primary.main
          : theme.palette.mode === "light"
            ? "#fff"
            : theme.palette.background.paper,
      }}
      p={2}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2}>
          {" "}
          {online ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar alt={props.user.responderDisplayName} src={props.user.responderPhoto} />
            </StyledBadge>
          ) : (
            <Avatar alt={props.user.responderDisplayName} src={props.user.responderPhoto} />
          )}
          <Stack spacing={0.3}>
            <Typography variant="subtitle2" color={props.isSelected ? 'white' : theme.palette.textColor?.main} >{props.user.responderDisplayName}</Typography>
            <Typography variant="caption" color={props.isSelected ? 'white' : theme.palette.textColor?.main} >{truncateText(props.lastMessage, 20)}</Typography>
          </Stack>
        </Stack>
        {
          !isHover ?
            <Stack spacing={2} alignItems={"center"}>
              <Typography variant="caption" color={props.isSelected ? 'white' : theme.palette.textColor?.main}>
                {getTime(props.updatedAt)}
              </Typography>
              <Badge
                className="unread-count"
                color="primary"
                badgeContent={props.chatBalance.notificationCounter}
              />
            </Stack> :
            <Box>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  if (user){
                    const data: DeleteChatModel = {
                      chatId: props.id,
                      userId: user.id
                    }
                    dispatch(deleteChat(data));
                  }
                }}>
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
        }

      </Stack>
    </StyledChatBox>
  );
};