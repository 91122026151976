
import { Box } from "@mui/material";
import { ExpertiseTable } from "../components/expertise_table";

const ExpertisePage = () => {

    return( 
        <Box sx={{ width: "100%" }}>
            <ExpertiseTable/>
        </Box>
    );
}
export default ExpertisePage;