import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ChatModel } from "../../../model/response/chat_model";
import { MessageModel, MirrorType } from "../../../model/response/message";
import { ChatDocumentModel } from "../../../model/request/chat_document_model";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from "firebase/firestore";
import { ChangeChatModel } from "../../../model/request/change_chat_model";
import { getFunctions, httpsCallable } from "firebase/functions";
import  { firebaseApp } from "../../../utils/firebase_config";
import { UserSearchModel } from "../../../model/request/search_model";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { DeleteChatModel } from "../../../model/request/delete_chat";
import { debugPrint } from "../../../utils/debug_print";

export const fetchChats = createAction(
    'chat/fetchChats',
    function prepare(chats: ChatModel[]) {
        return {
            payload: {
                chats: chats
            },
        }
    });

export const updateMessageText = createAction(
    'chat/updateMessageText',
    function prepare(message: string) {
        return {
            payload: {
                message: message
            },
        }
    });

export const fetchCurrentChat = createAction(
    'chat/fetchCurrentChat',
    function prepare(messages: MessageModel[]) {
        return {
            payload: {
                messages: messages
            },
        }
    });

export const selectCurrentChat = createAsyncThunk(
    'chat/selectCurrentChat',
    async (data: ChangeChatModel, { rejectWithValue }) => {
        try {

            if (data.chat.id !== '') {
                return {
                    selectedChat: data.chat
                }
            }

            const db = getFirestore();
            const chatQuery = query(collection(db, 'Users', data.userId, 'Chats'), where('user.responderId', '==', data.chat.user.responderId));
            const docs = await getDocs(chatQuery);
            if (docs.docs.length === 1) {
                return {
                    selectedChat: docs.docs[0].data() as ChatModel
                }
            } else {
                return {
                    selectedChat: data.chat
                }
            }
        } catch (error:any) {
            return rejectWithValue(error.toString());
        }
    });

export const searchChat = createAction(
    'chat/searchChat',
    function prepare(query: string) {
        return {
            payload: {
                query: query
            },
        }
    });

export const clearSearchDirectMessages = createAction(
    'chat/clearSearchDirectMessages',
    function prepare(clear:boolean){
        return {
            payload:{
                clear:clear
            }
        }
    }
)

export const selectDocumentToSend = createAction(
    'chat/selectDocumentToSend',
    function prepare(data: ChatDocumentModel) {
        return {
            payload: data,
        }
    });

export const updateNotification = createAsyncThunk(
    'chat/updateNotification',
    async (data: {
        chat: ChatModel,
        userId: string,
    }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const chatRef = doc(db, "Users", data.userId, "Chats", data.chat.id);
            await updateDoc(chatRef,
                {
                    chatBalance: {
                        hasNotifications: false,
                        notificationCounter: 0,
                    },
                });

        } catch (error) {
            rejectWithValue('error');
        }
    });

export const sendMessageOptimisticUpdate = createAction(
    'chat/sendMessageOptimisticUpdate',
    function prepare(message: MessageModel) {
        return {
            payload: {
                message: message
            },
        }
    });

export const sendChatMessage = createAsyncThunk(
    'chat/sendChatMessage',
    async (data: {
        message: MessageModel,
        chat: ChatModel
    }, { dispatch, rejectWithValue }) => {
        try {

            const message = data.message;
            const chat = data.chat;
            const db = getFirestore();
            let currentChat:ChatModel;
            if (chat.id === ''){
                const chatQuery = doc(collection(db, 'Users', message.writerId, 'Chats'));
                const docRef = chatQuery.id;

                currentChat = {
                    ...chat,
                    id:docRef
                }

                const chatDoc = await setDoc(chatQuery, {
                    ...chat,
                    id: docRef
                });

                
            }else{
                currentChat = chat;
            }

            if (message.photo != undefined) {
                const storage = getStorage();
                const imagesRef = ref(storage, message.writerId + '/pictures/' + uuidv4() + '.png');
                await uploadString(imagesRef, message.photo, 'data_url');

                const filePath = await getDownloadURL(imagesRef);

                const messageRef = doc(collection(db, "Users", message.writerId, "Chats", chat.id, "Messages"));
                const messageToSend: MessageModel = {
                    id: messageRef.id,
                    organizationId: message.organizationId,
                    text: message.text,
                    messageType: message.messageType,
                    receiverId: message.receiverId,
                    writerId: message.writerId,
                    mirrorStatus: message.mirrorStatus,
                    createdAt: message.createdAt,
                    updatedAt: message.updatedAt,
                    photo: filePath
                }
                await setDoc(messageRef, messageToSend)
            } else {
                const messageRef = doc(collection(db, "Users", message.writerId, "Chats", currentChat.id, "Messages"));
                const messageToSend: MessageModel = {
                    id: messageRef.id,
                    organizationId: message.organizationId,
                    text: message.text,
                    messageType: message.messageType,
                    receiverId: message.receiverId,
                    writerId: message.writerId,
                    mirrorStatus: message.mirrorStatus,
                    createdAt: message.createdAt,
                    updatedAt: message.updatedAt,
                }
                await setDoc(messageRef, messageToSend);
                const chatRef = doc(db, "Users", message.writerId, "Chats", currentChat.id);
                await updateDoc(chatRef,
                    {
                        'lastMessage': message.photo != null ? '📸 Photo' : message.text,
                        'updatedAt': message.updatedAt,
                        'lastModifierId': message.writerId
                    });
            }

            return {
                chat: currentChat
            }

        } catch (error:any) {
            debugPrint(error.toString(),'chat/sendChatMessage');
            return rejectWithValue(error.toString());
        }
    });

export const searchUserToChat = createAsyncThunk(
    'chat/searchUserToChat',
    async (data:UserSearchModel, {rejectWithValue}) => {
        try{
            const functions = getFunctions(firebaseApp,'europe-west1');
            const searchUsers = httpsCallable(functions, 'searchUserByFullnameFunction');
            
            const results =  await searchUsers({user:data});
            const users = results.data as UserSuggestedModel[];

            return {
                users:users
            }
            
        }catch(error:any){
            return rejectWithValue(error.toString());
        }

    }
);

export const deleteChat = createAsyncThunk(
    'chat/deleteChat',
    async (data:DeleteChatModel, {rejectWithValue}) => {
        const functions = getFunctions(firebaseApp,'europe-west1');
        const deleteChat = httpsCallable(functions, 'deleteChatForUserFunction');    
        const results =  await deleteChat({chat:data});

        try{
        }catch(error:any){
            return rejectWithValue(error.toString());
        }
    }
);

export const clearCharCache = createAction(
    'chat/clearCharCache',
    function prepare(clear: boolean) {
        return {
            payload: {
                clear: clear
            },
        }
    });