import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseApp, messaging } from "../../../utils/firebase_config";
import { NotificationTokenModel } from "../models/request/token_model";

export const requestToken = createAsyncThunk(
    'push_notification/requestToken',
    async (
        data:NotificationTokenModel,{rejectWithValue}
    ) => {
        var vapidKey = process.env.REACT_APP_VAPID_KEY_DEV;
    if(process.env.REACT_APP_ENV !== "development"){
      vapidKey = process.env.REACT_APP_VAPID_KEY_PROD;
    }
    
    const functions = getFunctions(firebaseApp, 'europe-west1');
    
  
    return getToken(messaging, { vapidKey: vapidKey })
      .then(async (currentToken) => {
        if (currentToken) {
            const token:NotificationTokenModel ={
                ...data,
                token: currentToken
            }
            const sendToken = httpsCallable(functions, 'sendTokenFunction');
            await sendToken({'token': token});

        } else {
        }
      })
      .catch((err:any) => {
        return rejectWithValue(err.toString())});
    }
);

