import Quill from "quill";

import MagicUrl from "quill-magic-url";
import QuillMention from "quill-mention";
Quill.register('modules/mention', QuillMention);
Quill.register('modules/magicUrl', MagicUrl)
Quill.prototype.getText = function (this: any, index: number, length: number): string {
    return this.getContents(index, length)
        .filter(op => typeof op.insert === 'string' || op.insert?.mention instanceof DOMStringMap)
        .map(op => typeof op.insert === 'string' ? op.insert : `@${op.insert.mention.value}`)
        .join('');
};