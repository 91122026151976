import { Box, Button, CardMedia, Stack } from "@mui/material";
import { useTheme } from "styled-components";
import { ChatHeader } from "./chat_header";
import { ChatFooter } from "./chat_footer";
import { ChatModel } from "../../../model/response/chat_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { ChatRoom } from "./chat_room";
import { UserModel } from "../../../model/response/user_model";
import { useEffect, useRef } from "react";
import { MessageModel } from "../../../model/response/message";
import noChat from "../../../assets/selectChat.png";
import { useTranslation } from "react-i18next";
export const ChatComponent = () => {
    const chats: ChatModel[] = useSelector((state: RootState) => state.chat.chats);
    const currentChat: ChatModel | null = useSelector((state: RootState) => state.chat.currentChat);
    const messages: MessageModel[] = useSelector((state: RootState) => state.chat.messages);

    const user:UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const conversationRef = useRef<any>(null);

    const dispatch = useDispatch<AppDispatch>();
    const {t, i18n} = useTranslation();
    
    useEffect(() => {
        if (conversationRef !== null && currentChat !== null){
            conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
        }
    },[messages.length])

    

    
    if (user && currentChat!== null){
        return (
            <Stack
                height={"100%"}
                maxHeight={"92vh"}
                width={`calc(100vw - 400px )`}
                maxWidth={`calc(100vw - 400px )`}
            >
                <ChatHeader userDisplayName={currentChat.user.responderDisplayName} userPhoto={currentChat.user.responderPhoto}/>
                <Box 
                        ref={conversationRef}
                        width={"100%"}
                        marginBottom={1}
                        sx={{
                            position: "relative",
                            flexGrow: 1,
                            overflowY:'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none', // Customize the width of the scrollbar
                            },
                            boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.25)",
                            
                        }}>
                    <ChatRoom/>
                </Box>
                <ChatFooter 
                    organizationId={currentChat.organizationId}
                    writerId={user.id} 
                    receiverId={currentChat.user.responderId}/>
            </Stack>
        );
    }else if (user && currentChat === null){

        return(
            <Stack
                height={"100%"}
                maxHeight={"95vh"}
                width={`calc(96vw - 320px )`}
                maxWidth={`calc(96vw - 320px )`}
                alignItems='center'
                spacing={2}
            >
                <Box width={'50%'}>
                    <CardMedia
                        component="img"
                        image={noChat}
                        sx={{   
                            objectFit: 'contain', // Fit the image to the dimensions while maintaining aspect ratio
                        }}
                    />
                </Box>
            </Stack>
        )
    }else{
        return (
            <></>
        )
    }
    
};

