import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getFirestore, doc, collection, setDoc, updateDoc, getDocs, orderBy, query, deleteDoc, getDoc } from "firebase/firestore";
import { FileType, AttachmentModel, ThreadDraftModel } from "../../../model/response/thread_draft_model";
import { debugPrint } from "../../../utils/debug_print";
import { LoadThreadDraft } from "../../../model/request/retrieve_thread_model";
import { SendCardModel } from "../../../model/request/send_card_model";
import { deleteObject, getBytes, getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable, uploadString } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { CardType } from "../../../model/response/card_model";
import { ChannelModel } from "../../../model/response/channel_model";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../../utils/firebase_config";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { UserSearchModel } from "../../../model/request/search_model";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { UserMentionModel } from "../../../model/response/user_mention_model";
import { AnalyticsEvent, createdCardEvent } from "../../../model/request/analytics_model";
import { sendEvent } from "../../analitycs/analitycs_action";
import { MentionOrientation } from "./card_creation_state";
import { visualizeErrorMessage } from "../../error/api/error_actions";
import { ErrorType } from "../../error/api/error_state";

export const createThreadDraft = createAsyncThunk(
    'cardCreation/createThreadDraft',
    async (data: ThreadDraftModel, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const docRef = doc(collection(db, "Users", data.userId, "ThreadDrafts"));
            const draft: ThreadDraftModel = {
                ...data,
                id: docRef.id
            }
            await setDoc(docRef, draft);
            return {
                draft
            };

        } catch (error: any) {
            debugPrint(error.toString(), 'cardCreation/createThreadDraft');
            return rejectWithValue(error.toString());
        }
    });

export const clearCardCreationCache = createAction(
    'cardCreation/clearCardCreationCache',
    function prepare(){
        return {
            payload: {}
        }
    }
);


export const setDraft = createAction(
    'cardCreation/setDraft',
    function prepare(draft:ThreadDraftModel | undefined){
        return {
            payload:{
                draft
            }
        }
    } 
);

export const setCardType = createAction(
    'cardCreation/setCardType',
    function prepare(type: CardType){
        return {
            payload:{
                type
            }
        }
    } 
);


export const setChannel = createAction(
    'cardCreation/setChannel',
    function prepare(channel:ChannelModel | undefined){
        return {
            payload:{
                channel:channel
            }
        }
    } 
);


export const updateThreadDraft = createAsyncThunk(
    'cardCreation/updateThreadDraft',
    async (data: ThreadDraftModel, { dispatch,rejectWithValue }) => {
        try {
            const db = getFirestore();

            if (data.id === ''){
                
                const docRef = doc(collection(db, "Users", data.userId, "ThreadDrafts"));
                const draft: ThreadDraftModel = {
                    ...data,
                    id: docRef.id
                }

                dispatch(setDraft(draft));
                await setDoc(docRef, draft);


                return {}
                
            }
            const docRef = doc(db, "Users", data.userId, "ThreadDrafts", data.id);
            await updateDoc(docRef, {
                'cardTitle': data.cardTitle,
                'cardText': data.cardText,
                'cardRichText': data.cardRichText,
                'type': data.type,
                'channelId': data.channelId,
                'attachments': data.attachments,
                'updatedAt': data.updatedAt,
            });
            return data;

        } catch (error: any) {
            debugPrint(error.toString(), 'cardCreation/updateThreadDraft');
            return rejectWithValue(error.toString());
        }
    });

export const loadThreadDraft = createAsyncThunk(
    'cardCreation/loadThreadDraft',
    async (data: LoadThreadDraft, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const draftQuery = query(collection(db, 'Users', data.userId, 'ThreadDrafts'), orderBy('createdAt', 'desc'));
            const draftDocs = await getDocs(draftQuery);
            if (draftDocs.docs.length > 0) {
                return {
                    draft: draftDocs.docs[0].data() as ThreadDraftModel,
                }
            } else {
                return {
                    draft: undefined
                }
            }
        } catch (error: any) {
            debugPrint(error.toString(), 'cardCreation/loadThreadDraft');
            return rejectWithValue(error.toString());
        }
    }
);

export const addImage = createAction(
    'cardCreation/addImage',
    function prepare(url:string,ref: string,fileName,fileType:FileType){

        const img:AttachmentModel = {
            url: url,
            type:fileType,
            storageRef: ref,
            name:fileName
        }
        return {
            payload: {
                img
            }
        }
    }
);


export const updateImages = createAction(
    'cardCreation/updateImages',
    function prepare(images: AttachmentModel[]){

        return {
            payload: {
                images
            }
        }
    }
);

export const deleteImage = createAction(
    'cardCreation/deleteImage',
    function prepare(imageRef: string){

        return {
            payload: {
                imageRef
            }
        }
    }
);

export const deleteImageFromFirestore = createAsyncThunk(
    'cardCreation/deleteImageFromFirestore',
    async (data:{
        firebaseStorageRef: string;
    }, {dispatch, rejectWithValue}) => {
        try{
            const storage = getStorage();
            const documentsRef = ref(storage,data.firebaseStorageRef);

            dispatch(deleteImage(data.firebaseStorageRef))

            await deleteObject(documentsRef);

        }catch(error:any){
            debugPrint(error.toString(),'cardCreation/deleteImageFromFirestore');
            rejectWithValue(error.toString());
        }
    });


export const saveImages = createAsyncThunk(
    'cardCreation/saveImage',
    async (data:{
        images: string[];
        fileNames:string[];
        fileTypes: FileType[];
        userId: string;
        type: CardType,
        selectedChannelId:string;
        draftId: string | undefined;
    }, {dispatch, rejectWithValue}) => {
        try{
            const storage = getStorage();
            const db = getFirestore();

            var draftId = data.draftId;
            
            var draft: ThreadDraftModel | undefined = undefined;
            if (data.draftId === undefined){
                
                
                const docRef = doc(collection(db, 'Users', data.userId, 'ThreadDrafts'));
                const jsonData = [{"insert":"\n"}]
                 draft = {
                    id: docRef.id,
                    userId: data.userId,
                    cardTitle: "",
                    cardText: "",
                    channelId: data.selectedChannelId,
                    isAnonymous:false,
                    cardRichText: JSON.stringify(jsonData),
                    attachments:[],
                    type:data.type,
                    createdAt:new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                }

                await setDoc(docRef,draft);
                draftId =  docRef.id;
                dispatch(setDraft(draft));

            }else{
                draftId = data.draftId;
            }
            const images:AttachmentModel[] = [];
            await Promise.all(
                data.images.map(async (dataUrl, index) => {
                    const documentsRef = ref(storage, 'users/'+ data.userId +'/drafts/'+ draftId + '/images/' +  data.fileNames[index]);
                    await uploadString(documentsRef, dataUrl,'data_url');
                    const imageUrl = await getDownloadURL(documentsRef);

                    const image: AttachmentModel = {
                        name: data.fileNames[index],
                        url: imageUrl,
                        type:data.fileTypes[index],
                        storageRef: 'users/'+ data.userId +'/drafts/'+ draftId + '/images/' +  data.fileNames[index]
                    };

                    images.push(image);
                })
            );

            dispatch(updateImages(images));
            
                                    
        }catch(error:any){
            return rejectWithValue(error.toString());
        }
    }
);




export const createThread = createAsyncThunk(
    'cardCreation/createThread',
    async (data: {
        card: SendCardModel,
    }, {dispatch, rejectWithValue }) => {
        try {
            const functions = getFunctions(firebaseApp, 'europe-west1');
            const addCard = httpsCallable(functions, 'addCardFunction');
            await addCard(data);
            dispatch(changeDialog({dialog:DialogType.none}));
            dispatch(setDraft(undefined));
            
            const log: AnalyticsEvent = {
                event: {timestamp: new Date().toISOString()},
                eventName: createdCardEvent
            };

            dispatch(sendEvent(log));
        } catch (error: any) {
            dispatch(visualizeErrorMessage(ErrorType.general));
            debugPrint(error.toString(), 'cardCreation/createThread');
            return rejectWithValue(error.toString());
        }
    });

    export const searchUserToTag = createAsyncThunk(
        'cardCreation/searchUserToTag',
        async (data:UserSearchModel, {rejectWithValue}) => {
            try{
                const functions = getFunctions(firebaseApp,'europe-west1');
                const searchUsers = httpsCallable(functions, 'searchUserByFullnameFunction');
                
                const results =  await searchUsers({user:data});
                const users = results.data as UserSuggestedModel[];
                const usertToTags = users.map((user) => {
                    const mentionModel:UserMentionModel = {
                        id: user.id,
                        value:user.displayName,
                        photo: user.photo,
                        belongsToChannel:user.belongsToChannel,
                    }
                    return mentionModel;
                });
    
                return {
                    users:usertToTags
                }
                
            }catch(error:any){
                return rejectWithValue(error.toString());
            }
        }
    );