import { Box, Stack, Typography, useTheme} from "@mui/material";
import { useEffect } from "react";
import { collection, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { ErrorComponent, ErrorType } from "../../../components/error-component/error_component";
import { useTranslation } from "react-i18next";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { TicketModel } from "../models/response/TicketModel";
import { fetchTickets, selectTicket, updateTicketNotification } from "../api/customer_service_action";
import { SidebarTitle } from "../../../components/sidebar_title/sidebar_title";
import { TicketActionButton } from "./ticket_action_button";
import { CustomerServiceTicketElement } from "./customer_service_ticket_element";
import { UpdateNotificationOnTicketModel } from "../models/request/delete_ticket";


export const CustomerServiceSidebar = () => {
  const theme = useTheme();
  
  const dispatch = useDispatch<AppDispatch>();
  const {t,i18n} = useTranslation();
  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  const tickets: TicketModel[] = useSelector((state: RootState) => state.customerService.tickets);
  const currentTicket: TicketModel | undefined = useSelector((state: RootState) => state.customerService.currentTicket);
  const ticketStatus = useSelector((state: RootState) => state.customerService.ticketStatus);
  useEffect(() => {
    if (user){
        
        const db = getFirestore();
        const q = query(collection(db, "Organizations",user.organizationId, "Tickets"), 
        where('user.senderId','==', user.id),
        where('isSolved','==', ticketStatus === 'open'?false:true),
        orderBy('updatedAt','desc'));
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const tickets: TicketModel[] = querySnapshot.docs.map(doc => doc.data() as TicketModel);
            dispatch(fetchTickets(tickets))
        });

        return () => {
            unsubscribe(); 
          };
    }
    
  },[dispatch,user, ticketStatus]);


  const clickOnTicket = (ticket: TicketModel) => {

    if(user === undefined) return;

    dispatch(selectTicket(ticket));
    const data: UpdateNotificationOnTicketModel = {
      organizationId: user.organizationId,
      ticketId: ticket.id
    }
    dispatch(updateTicketNotification(data));
  }

  return (
    <>
      <Box
        flexGrow={1}
        sx={{
          position: "relative",
          width: '320px',
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <SidebarTitle 
        onClick={() => dispatch(changeDialog({dialog: DialogType.createTicketDialog}))} 
        title={t('customerServicePage.sidebarTitle')}/>
        <Stack p={1} spacing={1} sx={{ maxHeight: "90%" }}>
          <TicketActionButton />
          <Stack sx={{ 
            flexGrow: 1, 
            height: "100%",
            overflowY:'scroll',
            '&::-webkit-scrollbar': {
                display: 'none', // Customize the width of the scrollbar
              } }}>
            <Box >
              <Stack spacing={1} >
                <Typography variant="subtitle2" sx={{ color: "#676667" }}>
                {t('customerServicePage.sidebarDescription')}
                </Typography>
                {tickets.map((ticket,index) => 
                  <CustomerServiceTicketElement 
                    ticket={ticket} 
                    onClick={() => clickOnTicket(ticket)} 
                    isClicked={currentTicket?.id === ticket.id}/>  
                  )
                }                
                {
                  tickets.length === 0 && 
                  <Box paddingTop={'50%'} display='flex'  height='100%'  >
                    <ErrorComponent caption='' image={ErrorType.card} width={'100%'} height={'100%'}/>
                  </Box>
                }
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};