import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { LoginModel, ResetPasswordLoginModel, TenantProviderLoginModel } from '../../../model/request/login_model'
import { OAuthProvider, getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { getFirestore, getDoc, doc, updateDoc} from "firebase/firestore"; 
import { UserModel } from '../../../model/response/user_model';
import { TenantRequestModel } from '../../../model/request/tenant_request_model';
import { TenantLoginModel } from '../../../model/response/tenant_login_model';
import { debugPrint } from '../../../utils/debug_print';


export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (loginModel: LoginModel, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const auth = getAuth();
      auth.tenantId = loginModel.tenantId;
      const response = await signInWithEmailAndPassword(auth, loginModel.email, loginModel.password);
      const { uid } = response.user;
      
      const userDocumentReference = doc(db, 'Users', uid);
      const userDocument = await getDoc(userDocumentReference);
     
      return userDocument.data() as UserModel;
      
    } catch (error:any) {
      debugPrint(error.toString(),'auth/loginUser');
      return rejectWithValue(error.message);
    }   
  }
);

export const alreadyLoggedUser = createAsyncThunk(
  'auth/alreadyLoggedUser',
  async (data, { rejectWithValue }) => {
    
    try{
    
      const db = getFirestore();
      const auth = getAuth();
      var uid = '';
      const user: UserModel | undefined = await new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) =>  {
          if (user) {
            
            const userDocumentReference = doc(db, 'Users', user.uid);
            const userDocument = await getDoc(userDocumentReference);
            
            await updateDoc(userDocumentReference,{
              'lastLogin': new Date().toISOString()
            })
            resolve(userDocument.data() as UserModel);
            
            
          } else {
            resolve(undefined);
          }
        });

      });
      return user;

    }  catch (error:any) {
      return rejectWithValue(error.message);
    } 
  });

export const retrieveTenantConfiguration = createAsyncThunk(
  'auth/retrieveTenantConfiguration',
  async (data:TenantRequestModel,{rejectWithValue}) => {
    try{

      var apiUrl = '';
      var headers = {};


      if (process.env.REACT_APP_ENV === "development"){
        apiUrl = 'https://tenant.manager.dev.syllotips.app/tenant/get-tenant-for-email';
      }else{
        apiUrl = 'https://tenant.manager.prod.syllotips.app/tenant/get-tenant-for-email';
      }

      headers = {
        'x-api-key': process.env.REACT_APP_TENANT_API_KEY_DEV,
        'Content-Type': 'application/json; charset=UTF-8',
      };
      
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers:headers,
        mode:"cors",
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const responseData = await response.json() as TenantLoginModel;
      
      if(responseData.message){
        throw new Error('user not found');
      }

      return {
        email: data.email,
        tenantId: responseData.tenantId,
        loginWithMicrosoft: responseData.hasMicrosoft,
        loginWithPassword: responseData.hasPassword,
      }
    
    
    }catch(error:any){
      return rejectWithValue(error.toString());
    }
  }
);

export const resetYourPassword = createAsyncThunk(
  'auth/resetYourPassword',
  async (data:ResetPasswordLoginModel, {rejectWithValue}) => {
    try{
      const auth = getAuth();
      auth.tenantId = data.tenantId;
      await sendPasswordResetEmail(auth, data.email);
    }catch(error:any){
      return rejectWithValue(error.toString());
    }
  }
);

export const updateUserNotification = createAction(
    'auth/updateUserNotification',
    function prepare(notificationCounter:number) {
      return {
          payload: {
            notificationCounter: notificationCounter -1
          },
      }
  });

export const logout = createAsyncThunk(
  'auth/logout',
  async (data, { dispatch, rejectWithValue }) => {
    try{

      const db = getFirestore();
      const auth = getAuth();

      await signOut(auth);
    
    }catch(error:any){
      return rejectWithValue(error.toString());
    }
  });

export const loginWithMicrosoft = createAsyncThunk(
    'auth/loginWithMicrosoft',
    async (data:TenantProviderLoginModel, {dispatch, rejectWithValue}) => {
      try{
        const provider = new OAuthProvider('microsoft.com');
        // Set custom parameters, including the scopes
    
        provider.addScope('files.read.all');
        provider.addScope('directory.read.all');
        provider.addScope('user.read.all');
        provider.addScope('group.read.all');
        
        const auth = getAuth();
        auth.tenantId = data.tenantId;
        const result = await signInWithPopup(auth, provider);
        const credential = OAuthProvider.credentialFromResult(result);

        
        if (credential){
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
          
          
          if(accessToken) dispatch(updateAccessToken(accessToken));
          
          const db = getFirestore();
          const currentUser: UserModel | undefined = await new Promise((resolve) => {
            onAuthStateChanged(auth, async (user) =>  {
              if (user) {      
                const userDocumentReference = doc(db, 'Users', user.uid);
                const userDocument = await getDoc(userDocumentReference);
                resolve(userDocument.data() as UserModel);     
              } else {
                resolve(undefined);
              }
            });  
          });
          return currentUser;
          
        } else{
          return rejectWithValue('wrong credential');
        }
        

      }catch(error:any){
        debugPrint(error.toString(),'auth/loginWithMicrosoft');
        return rejectWithValue(error.toString());
      }

    }
  );


// Share point to use in the next feature
export const loginOnSharePoint = createAsyncThunk(
  'auth/loginOnSharePoint',
  async (data:TenantProviderLoginModel, {dispatch, rejectWithValue}) => {
    try{
      
      const provider = new OAuthProvider('microsoft.com');
      // Set custom parameters, including the scopes
  
      provider.addScope('files.read.all');
      provider.addScope('directory.read.all');
      provider.addScope('user.read.all');
      provider.addScope('group.read.all');

      const auth = getAuth();
      auth.tenantId = data.tenantId;
      const result = await signInWithPopup(auth, provider);
      const credential = OAuthProvider.credentialFromResult(result);

      if (credential){
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
                
        if(accessToken) dispatch(updateAccessToken(accessToken));
        
        const db = getFirestore();
        const currentUser: UserModel | undefined = await new Promise((resolve) => {
          onAuthStateChanged(auth, async (user) =>  {
            if (user) {      
              const userDocumentReference = doc(db, 'Users', user.uid);
              const userDocument = await getDoc(userDocumentReference);
              resolve(userDocument.data() as UserModel);     
            } else {
              resolve(undefined);
            }
          });  
        });
        return currentUser;
        
      } else{
        return rejectWithValue('wrong credential');
      }
      

    }catch(error:any){
      debugPrint(error.toString(),'auth/loginWithMicrosoft');
      return rejectWithValue(error.toString());
    }

  }
);

export const updateAccessToken = createAction(
  'auth/updateAccessToken',
  function prepare(accessToken: string) {
    return {
      payload:{
        accessToken: accessToken,
      }
    };
  }

);

export const updateUserProfile = createAction(
    'auth/updateUserProfile',
    function prepare(
      bio?:string, 
      photo?:string, 
      isFirstTutorial?:boolean,
      isFirstLogin?:boolean) {
      return {
        payload:{
          bio:bio,
          photo: photo,
          isFirstTutorial:isFirstTutorial,
          isFirstLogin:isFirstLogin
        }
      };
    }
  )




