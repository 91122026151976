import { Box, List, ListItem, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { collection, getFirestore, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { CardModel, CardType } from "../../../model/response/card_model";
import InfiniteScroll from "react-infinite-scroll-component";
import { CardTile } from "../../../components/card-tile/card-tile";
import { DiscussionToggle } from "../../../components/discussion-toggle/discussion_toggle";
import { fetchFollowedThreads, loadMoreFollowedThreads } from "../api/user_followed_thread_action";
import { EmptyComponent } from "../../../components/empty-component/empty_component";
import { useTranslation } from "react-i18next";


const FollowedThreadsPage = () => {

  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  const followedThreads: CardModel[] = useSelector((state: RootState) => state.userFollowedThread.userFollowedThreads);
  const hasMore: boolean = useSelector((state: RootState) => state.userFollowedThread.scrollUserFollowedThreads);
  const currentLimit: number = useSelector((state: RootState) => state.userFollowedThread.userFollowedThreadLimit);
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const fetch = () => dispatch(loadMoreFollowedThreads(currentLimit));
  const {t} = useTranslation();
  const theme = useTheme();

  useEffect(
    () => {
      if (user != undefined) {
        const db = getFirestore();
        const q = query(
          collection(db, 'Users', user.id, 'Threads'),
          where('type', '==', CardType.discussion),
          where('isFollowed', '==', true),
          where('isClosed', '==', isClosed),
          orderBy('updatedAt', 'desc'),
          limit(currentLimit)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const feed: CardModel[] = querySnapshot.docs.map(doc => doc.data() as CardModel);
          dispatch(fetchFollowedThreads(feed));
        });

        return () => {
          unsubscribe();
        };
      }
    }, [user, currentLimit, isClosed]
  );

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      flexGrow={1}
      sx={{ backgroundColor: '#F7F9FC' }}>
        
        <Box
                display={'flex'}
                width={'60vw'}
                flexDirection={'column'}
                alignItems={'center'}>
                <Box
                    marginTop={2}
                    marginBottom={3}
                    display={'flex'}
                    width='100%'
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Typography variant='h5' color={theme.palette.textColor?.main}>
                       {t('channelSideBar.followedCaption')}
                    </Typography>
                </Box>
            </Box>
      <Box
        id={'feedScrollable'}
        display={'flex'}
        flexDirection={'column'}
        height={'90vh'}
        sx={{
          overflowY: "scroll",
          '&::-webkit-scrollbar': {
            display: 'none', // Customize the width of the scrollbar
          }
        }}>
        <InfiniteScroll
          dataLength={followedThreads.length}
          next={fetch}
          hasMore={hasMore}
          scrollableTarget={'feedScrollable'}
          loader={<></>}
        >
          <List sx={{
            width: 'inherit',
            "&:hover": {
              cursor: "pointer",
            }
          }}>
            <ListItem>
              <DiscussionToggle isClosed={isClosed}
                onActiveClick={function (): void {
                  setIsClosed(false)
                }}
                onClosedClick={function (): void {
                  setIsClosed(true)
                }} />
            </ListItem>
            {
              followedThreads.length === 0 && 
                <EmptyComponent 
                  paddingTop={25}
                  title={t('userFollowedPage.emptyTitle')} 
                  subtitle={t('userFollowedPage.emptyDescription')} />
                        }
            {
              followedThreads.map((thread) => {
                return (
                  <ListItem key={thread.id}>
                    <CardTile stretch={false} isSearchedCard={false} {...thread} />
                  </ListItem>

                )
              })
            }
          </List>
        </InfiniteScroll>
      </Box>
    </Box>
  );
}

export default FollowedThreadsPage;


