import {  Box, Button, Divider, InputBase, List,  Stack, Typography, alpha, styled, useTheme } from "@mui/material";
import { ChannelModel } from "../../../model/response/channel_model";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { UserModel } from "../../../model/response/user_model";
import { UserSearchModel } from "../../../model/request/search_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { MemberToAdd } from "./member_to_add";
import { addChannelMember, removeChannelMember, searchNewMembers } from "../api/channel_member_action";
import { SkeletonProfile, SkeletonProfiles } from "../../../components/skeleton-profile/skeleton_profile";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../../components/action-button/action_button";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(${theme.spacing(4)})`,
        width: "100%",
    },
}));

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: 20,
    backgroundColor: alpha(theme.palette.background.paper, 1),
    width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 0),
    color: theme.palette.iconColor?.main,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

export interface AddMembersProps{
    user: UserModel;
    isLoading: boolean;
    selectedChannel: ChannelModel;
    membersToAdd: UserSuggestedModel[];
    suggestedUsers: UserSuggestedModel[];
    onAddMemberClick: () => void;
}
export const AddMembers: React.FC<AddMembersProps> = (props:AddMembersProps) => {
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const [userSearch, setUserSearch] = useState<string>('');
    const userSearchDebounced = useDebounce(userSearch, 500);
    const suggestedMembersAreUploading: boolean = useSelector((state: RootState) => state.channelMember.suggestedMembersAreUploading);

    const {t} = useTranslation();

    useEffect(
        () => {
            if (userSearchDebounced.trim() !== '') {
                if (props.user) {
                    const data: UserSearchModel = {
                        fullname: userSearchDebounced,
                        organizationId: props.user.organizationId,
                        channelId: props.selectedChannel.channelId,
                    }
                    dispatch(searchNewMembers(data))
                }

            }
        }, [userSearchDebounced]);
        
    return (
        <Stack p={2} overflow={'hidden'} flexDirection={'column'} spacing={2} >
            <Box display='flex' flexDirection='row' justifyContent='space-between' >
                <Typography
                    noWrap
                    variant="h6"
                    align="center"
                    color={theme.palette.textColor?.main}>
                    {t('channelMemberPage.titleAddMember', {channelName: props.selectedChannel.channelName})}
                </Typography>
                <ActionButton onClick={props.onAddMemberClick} 
                isLoading={suggestedMembersAreUploading} 
                disabled={false} title={t('channelMemberPage.addMembersActionButton')}                    
                   
                />
            </Box>
            <Divider light />
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    value={userSearch}
                    onChange={(event) => {
                        setUserSearch(event.target.value);

                    }}
                    placeholder={t('channelMemberPage.searchMemberHelperText')}
                    inputProps={{ "aria-label": "search" }}
                />
            </Search>
            
            <Box id={'memberscrollable'}
                display={'flex'}
                flexDirection={'column'}
                height={400}
                sx={{
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {
                        display: 'none', // Customize the width of the scrollbar
                    }
                }}>
                <List sx={{
                    width: 'inherit',
                    padding: 0,

                }}>
                    {
                        props.isLoading && <SkeletonProfiles/>
                    }
                    {
                        props.suggestedUsers.map((channelMember) => {
                            return (
                                <MemberToAdd 
                                channelMember={channelMember} 
                                toAdd={props.membersToAdd.filter((member) => member.id == channelMember.id).length === 0} 
                                remove={function (): void {
                                    dispatch(removeChannelMember(channelMember));
                                } } add={function (): void {
                                    dispatch(addChannelMember(channelMember));
                                } }                                />
                            )
                        })
                    }
                </List>
            </Box>
        </Stack>
    );
}
