import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { TicketModel, TicketType } from "../models/response/TicketModel";
import { collection, deleteDoc, doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { Attachment } from "../../../model/response/card_model";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { MessageModel } from "../models/response/TicketMessageModel";
import { SendTicketModel } from "../models/request/send_ticket_model";
import { AttachmentModel } from "../../../model/response/thread_draft_model";
import { SendMessageModel } from "../models/request/send_message_model";
import { TicketSentStatus, TicketStatus } from "./customer_service_state";
import { DeleteTicketModel, MarkAsSolveTicketModel, UpdateNotificationOnTicketModel } from "../models/request/delete_ticket";
import { debugPrint } from "../../../utils/debug_print";

export const fetchTickets = createAction(
    'customerService/fetchTickets',
    function prepare(tickets: TicketModel[]) {
        return {
            payload: {
                tickets: tickets
            },
        }
    });

export const changeTicketStatus = createAction(
    'customerService/changeTicketStatus',
    function prepare(status: TicketStatus) {
        return {
            payload: {
                status: status
            },
        }
    });

export const updateTicketNotification = createAsyncThunk(
    'customerService/updateTicketNotification',
    async (data: UpdateNotificationOnTicketModel, { rejectWithValue, }) => {
        try {
            const db = getFirestore();
            const ticketQuery = doc(db, 'Organizations', data.organizationId, 'Tickets', data.ticketId);
            await updateDoc(ticketQuery, {
                chatBalance: {
                notificationCounter: 0,
                hasNotification: false,
            }});

        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    });


export const deleteTicket = createAsyncThunk(
    'customerService/deleteTicket',
    async (data: DeleteTicketModel, { rejectWithValue, }) => {
        try {
            const db = getFirestore();
            const ticketQuery = doc(db, 'Organizations', data.organizationId, 'Tickets', data.ticketId);
            await deleteDoc(ticketQuery);

        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    });

    export const markTicketAsSolved = createAsyncThunk(
        'customerService/markTicketAsSolved',
        async (data: MarkAsSolveTicketModel, { rejectWithValue, }) => {
            try {
                
                const db = getFirestore();
                const ticketQuery = doc(db, 'Organizations', data.organizationId, 'Tickets', data.ticketId);
                await updateDoc(ticketQuery, {isSolved: true});
            } catch (error: any) {
                debugPrint(error.toString(), 'customerService/markTicketAsSolved');
                return rejectWithValue(error.toString());
            }
        });


export const fetchMessages = createAction(
        'customerService/fetchMessages',
        function prepare(messages: MessageModel[]) {
            return {
                payload: {
                    messages: messages
                },
            }
        });


export const updateTicketStatus = createAction(
    'customerService/updateTicketStatus',
    function prepare(tiketStatus: TicketSentStatus) {
        return {
            payload: {
                tiketStatus: tiketStatus
            },
        }
    });


export const selectTicket = createAction(
    'customerService/selectTicket',
    function prepare(ticket: TicketModel) {
        return {
            payload: {
                ticket: ticket
            },
        }
    });

export const chooseTicketType = createAction(
    'customerService/chooseTicketType',
    function prepare(type: TicketType) {
        return {
            payload: {
                type: type
            },
        }
    });


export const sendTicket = createAsyncThunk(
    'customerService/sendTicket',
    async (data: SendTicketModel, { rejectWithValue }) => {
        try {
            
            const db = getFirestore();
            const ticketQuery = doc(collection(db, 'Organizations', data.organizationId, 'Tickets'));

            const date = new Date().toISOString();

            const ticket: TicketModel = {
                id: ticketQuery.id,
                title: data.title,
                chatBalance: {
                    hasNotification: false,
                    notificationCounter: 0
                },
                user: {
                    senderId: data.user.senderId,
                    senderDisplayName: data.user.senderDisplayName,
                    senderPhoto: data.user.senderPhoto
                },
                type: data.type,
                isSolved: false,
                updatedAt: date,
                createdAt: date
            }

            await setDoc(ticketQuery, ticket);
            const messageQuery = doc(collection(db, 'Organizations', data.organizationId, 'Tickets', ticketQuery.id, 'Messages'));
            const storage = getStorage();

            const attachments: Attachment[] = await Promise.all(
                data.attachments.map(async (attachment) => {
                    const storageRefString = 'organizations/' + data.organizationName + '/tickets/' + ticketQuery.id + '/messages/' + messageQuery.id + '/' + attachment.name;
                    const storageRef = ref(storage, storageRefString);
                    await uploadString(storageRef, attachment.url, 'data_url');
                    const filePath = await getDownloadURL(storageRef);

                    const currentAttachment: Attachment = {
                        name: attachment.name,
                        url: filePath,
                        storageRef: storageRefString,
                        type: attachment.type,
                        createdAt: date,
                        updatedAt: date
                    }
                    return currentAttachment;
                })
            );

            const message: MessageModel = {
                id: messageQuery.id,
                text: data.description,
                attachments: attachments,
                isDraft: false,
                createdAt: date,
                updatedAt: date,
                isFromSylloTips: true
            }

            await setDoc(messageQuery, message);


        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    }
);

export const addAttachments = createAction(
    'customerService/addAttachments',
    function prepare(attachments: AttachmentModel[]) {
        return {
            payload: {
                attachments: attachments
            },
        }
});

export const updateText = createAction(
    'customerService/updateText',
    function prepare(text: string) {
        return {
            payload: {
                text: text
            },
        }
});


export const sendTicketMessage = createAsyncThunk(
    'customerService/sendTicketMessage',
    async (data: SendMessageModel, { rejectWithValue }) => {
        try{
            const db = getFirestore();
            const storage = getStorage();
            const messageQuery = doc(collection(db, 'Organizations', data.organizationId, 'Tickets', data.ticketId, 'Messages'));
            
            const date = new Date().toISOString();

            const attachments: Attachment[] = await Promise.all(
                data.message.attachments.map(async (attachment) => {
                    const storageRefString = 'organizations/' + data.organizationName + '/tickets/' + data.ticketId + '/messages/' + messageQuery.id + '/' + attachment.name;
                    const storageRef = ref(storage, storageRefString);
                    await uploadString(storageRef, attachment.url, 'data_url');
                    const filePath = await getDownloadURL(storageRef);

                    const currentAttachment: Attachment = {
                        name: attachment.name,
                        url: filePath,
                        storageRef: storageRefString,
                        type: attachment.type,
                        createdAt: date,
                        updatedAt: date
                    }
                    return currentAttachment;
                })
            );
            const messageToSend:MessageModel ={
                ...data.message,
                createdAt: date,
                updatedAt: date,
                isFromSylloTips: true,
                attachments: attachments,
            }

            await setDoc(messageQuery, messageToSend )
        
        }catch(error:any){
            return rejectWithValue(error.toString());
        }
    })