import { createSlice } from "@reduxjs/toolkit";
import { UserThreadState } from "./user_thread_state";
import { fetchUserThreads, loadMoreUserThreads } from "./user_thread_action";

const initialState:UserThreadState ={
    userThreads: [],
    scrollUserThreads: true,
    userThreadLimit: 5
}
const userThreadSlice = createSlice({
    name: 'userThread',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(fetchUserThreads, (state, payload) => {
            state.scrollUserThreads = true;
            state.userThreads =  payload.payload.threads;
            if (state.userThreads.length < state.userThreadLimit){
                state.scrollUserThreads = false;
            }
        })
        .addCase(loadMoreUserThreads, (state, payload) => {
            state.userThreadLimit = state.userThreadLimit + payload.payload.limit;
        })
    }
});

export default userThreadSlice.reducer;