import {  Dialog, } from "@mui/material";
import { DialogType } from "../../dialog/dialog_state";
import { useEffect, useState } from "react";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { ChannelModel } from "../../../model/response/channel_model";
import { ChannelMemberModel, } from "../../../model/response/channel_member_model";
import { DocumentData } from "firebase/firestore";
import { changeDialog } from "../../dialog/dialog_action";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";

import { MemberList } from "../components/members_list";
import { AddMembers } from "../components/add_members";
import { addMembersFromChannel, clearChannelMembersCache, fetchMembers } from "../api/channel_member_action";

export const MemberChannelDialog = () => {

    const [addMembers, setAddMembers] = useState<boolean>(false);

    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const selectedChannel: ChannelModel | undefined = useSelector((state: RootState) => state.dialog.channel);
    const channelMembers: ChannelMemberModel[] = useSelector((state: RootState) => state.channelMember.channelMembers);
    const channelMembersAreLoading: boolean =  useSelector((state: RootState) => state.channelMember.channelMembersAreLoading);
    const membersToAdd: UserSuggestedModel[] = useSelector((state: RootState) => state.channelMember.membersToAdd);
    
    const suggestedUsers: UserSuggestedModel[] = useSelector((state: RootState) => state.channelMember.suggestedMembers);
    const suggestedUsersAreLoading: boolean = useSelector((state: RootState) => state.channelMember.suggestedMembersAreLoading);
    const lastElement: DocumentData | undefined = useSelector((state: RootState) => state.channelMember.lastMemberSeen);
    const hasMore: boolean = useSelector((state: RootState) => state.channelMember.hasMoreMembers);
    const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);

    
    const loadMore = () => {
        if (user && selectedChannel) {
            if (selectedChannel.channelId === 'AGORA') {
                dispatch(fetchMembers({
                    organizationId: user.organizationId,
                    lastElement: lastElement,
                    size: 20
                }));
            } else {
                dispatch(fetchMembers({
                    organizationId: user.organizationId,
                    channelId: selectedChannel.channelId,
                    lastElement: lastElement,
                    size: 20
                }));
            }
        }
    }
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (user && selectedChannel) {
            if (selectedChannel.channelId === 'AGORA') {
                dispatch(fetchMembers({
                    organizationId: user.organizationId,
                    lastElement: lastElement,
                    size: 20
                }));
            } else {
                dispatch(fetchMembers({
                    organizationId: user.organizationId,
                    channelId: selectedChannel.channelId,
                    lastElement: lastElement,
                    size: 20
                }));
            }
        }

    }, [user]);


    const closeDialog = () => {
        dispatch(changeDialog({ dialog: DialogType.none, }));
        dispatch(clearChannelMembersCache());
    }

    return (
        <Dialog
            PaperProps={{ sx: { borderRadius: "10px" } }}
            fullWidth
            open={dialog === DialogType.channelMembersDialog}
            sx={{
                overflowY: 'hidden'
            }}
            onClose={closeDialog}
        >
            {
                user && selectedChannel && !addMembers &&
                    <MemberList 
                    user={user} 
                    isLoading={channelMembersAreLoading}
                    selectedChannel={selectedChannel} 
                    channelMembers={channelMembers} 
                    onAddMembersClick={() => setAddMembers(true) } 
                    loadMoreMembers={loadMore} hasMore={hasMore}/>
            }
            {
                user && selectedChannel && addMembers &&
                    <AddMembers 
                    user={user}
                    isLoading={suggestedUsersAreLoading}
                    selectedChannel={selectedChannel} 
                    membersToAdd={membersToAdd} suggestedUsers={suggestedUsers} 
                    onAddMemberClick={function (): void {
                        dispatch(addMembersFromChannel(
                            {
                                organizationId: user.organizationId,
                                channelId: selectedChannel.channelId,
                                members: membersToAdd.map((member)=> member.id),
                            }
                        ));
                    }}/>
            }
        </Dialog>
    );
}