import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Theme, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';



export interface DiscussionToggleButtonProps{
    active:boolean;
    theme:Theme;
}

const StyledButton = styled(Button)((props:DiscussionToggleButtonProps) => ({
  
}));

export interface DiscussionToggleProps{
  
  isClosed:boolean;
  
  onActiveClick: () => void;
  onClosedClick: () => void;
}

export const DiscussionToggle: React.FC<DiscussionToggleProps> = (props:DiscussionToggleProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
  return (
    <Box width='60vw' display={'flex'} flexDirection={'row'} justifyContent={'center'}>
      <Button sx={{
        borderRadius: '20px', // Fully rounded ends
        border: '1px solid',
        textTransform:'none',
        borderColor: !props.isClosed ? '#5876FF' : '#D9D9D9',
        backgroundColor: !props.isClosed ? 'rgba(88, 118, 255, 0.15)' : 'transparent',
        color: !props.isClosed ? '#5876FF' : '#D9D9D9',
        marginRight:5,
        '&:hover': {
          backgroundColor: !props.isClosed ?'#5876FF' : theme.palette.grey[200],
          color: !props.isClosed ? 'white' : '#D9D9D9',
        },
      }}
        
        onClick={props.onActiveClick}   >
          {t('channelDiscussionPage.activeDiscussionButton')}
      </Button>
      <Button
        sx={{
          borderRadius: '20px', // Fully rounded ends
          border: '1px solid',
          textTransform:'none',
          borderColor: props.isClosed ? '#5876FF' : '#D9D9D9',
          backgroundColor: props.isClosed ? 'rgba(88, 118, 255, 0.15)' : 'transparent',
          color: props.isClosed ?  '#5876FF' : '#D9D9D9',
          '&:hover': {
            backgroundColor: props.isClosed ? '#5876FF' : theme.palette.grey[200],
            color: !props.isClosed ? '#D9D9D9':'white' ,
          },
        }}
        onClick={ props.onClosedClick}
      >
        {t('channelDiscussionPage.closeDiscussionButton')}
      </Button>
    </Box>
  );
};