import './answer.css';
import React, { useRef, useState } from 'react';
import '../../dependency/react-quill-dev/dist/quill.snow.css';
import { AnswerVotes } from '../answer-votes/answer-votes';
import { Comment, CommentProps } from '../comment/comment';
import { CommentEditor } from '../comment-editor/comment-editor';
import { Avatar, Box, CardHeader, Chip, Divider, IconButton, Popover, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { UserModel } from '../../model/response/user_model';
import { useTranslation } from 'react-i18next';
import { editAnswer, saveAnswerChanges } from '../../features/thread/api/thread_action';
import { SaveChangeAnswerModel } from '../../model/request/submit_answer_model';
import { DeleteAnswer } from '../../model/request/answer_model';
import ReactQuill from '../../dependency/react-quill-dev/lib';
import { fetchExpert } from '../../features/expert/api/expert_action';
import { changeDialog } from '../../features/dialog/dialog_action';
import { DialogDeleteAction, DialogType } from '../../features/dialog/dialog_state';
import { AttachmentModel } from '../../model/response/thread_draft_model';
import { CardImages } from '../card-images/card_images';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { AnswersChannelPermissions, ChannelModel } from '../../model/response/channel_model';

export interface AnswerPreferencesProps {
    onDeleteClick?: () => void;
    onEditClick?: () => void;
    onDoneClick?: () => void;
    hide: boolean;
    isEditing: boolean;
}

export const AnswerPreferences = (props: AnswerPreferencesProps) => {
    const theme = useTheme();

    const tooltipProp = {
        popper: {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, -10],
                    },
                },
            ],
        },
    }

    if (props.isEditing) {
        return (
            <Chip
                label={'Update answer'}
                color={'primary'}
                onClick={props.onDoneClick}
            />
        )
    }
    if (props.hide) {
        return (<></>)
    }

    return (
        <Box sx={{
            flexDirection: "column",
            overflow: "hidden",
            overflowY: 'auto',
        }}>
            <Stack direction='row' spacing={1}>
                <Tooltip arrow title={<Typography variant='subtitle2'>{'Edit answer'}</Typography>} slotProps={tooltipProp}>
                    <Box onClick={props.onEditClick} color={theme.palette.textColor?.main}>
                        <EditIcon />
                    </Box>
                </Tooltip>
                <Tooltip arrow title={<Typography variant='subtitle2'>{'Delete answer'}</Typography>} slotProps={tooltipProp}>
                    <Box onClick={props.onDeleteClick} color={theme.palette.textColor?.main}>
                        <DeleteOutlineIcon />
                    </Box>
                </Tooltip>
            </Stack>
        </Box>
    );
}

export interface AnswerProps {
    className?: string;
    id: string;
    answerIndex: number;
    helpingHand: boolean;
    organizationId: string;
    cardId: string;
    plainText: string;
    richText: string;
    upVotes: string[]; //list of userIds
    downVotes: string[]; //list of userIds
    totalVotes: number;
    totalComments: number;
    comments: CommentProps[];
    attachments: AttachmentModel[];
    responderUser: {
        responderId: string;
        responderDisplayName: string;
        responderPhoto: string;
    };

    createdAt: string;
    updatedAt: string;

    onUpvoteClick?: () => void;
    onDownvoteClick?: () => void;

    discussionIsClosed: boolean;
}


export const Answer: React.FC<AnswerProps> = (props: AnswerProps) => {

    const [commentEditorState, setCommentEditorState] = useState(false);
    const [showPreferences, setShowPreferences] = React.useState<boolean>(false);
    const edit: string | undefined = useSelector((state: RootState) => state.thread.editAnswer);

    const threadChannel:ChannelModel | undefined = useSelector((state: RootState) => state.thread.channel);
    const card = useSelector((state: RootState) => state.thread.card);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const channels: ChannelModel[] = useSelector((state: RootState) => state.channel.channels);
    const filteredChannels: ChannelModel[] = channels.filter((channel) => channel.channelId === threadChannel?.id );
    var disableEditor = true;

    
    if (threadChannel){
        if(threadChannel.permissions.includes(AnswersChannelPermissions.ONLY_ADMINS)){
            
            if(filteredChannels.length === 1){
                disableEditor = !filteredChannels[0].isAdmin;
            }else{
                disableEditor = true;
            }
            
        }else if(threadChannel.permissions.includes(AnswersChannelPermissions.MEMBERS)){
            if(filteredChannels.length === 1){
                disableEditor = false;
            }else{
                disableEditor = true;
            }
        }else{
            disableEditor = false;
        }
    }

    if(card){
        if(card.channel.channelId === 'AGORA'){
            disableEditor = false;
        }

        if(card.senderUser.senderId === user?.id){
            disableEditor = false;
        }
    }

    
    
    const { t, i18n } = useTranslation();
    const quillRef = useRef<any>();

    const handleReplyClick = async () => {
        setCommentEditorState(!commentEditorState)
    }


    const dispatch = useDispatch<AppDispatch>();
    
    const theme = useTheme();

    const getElapsedTime = (date: string) => {

        const creationDate = new Date(date);
        const difference = new Date().getTime() - creationDate.getTime();
        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const month = Math.floor(days / 31);
        const years = Math.floor(days / 365);
        if (seconds < 0) {
            return 'now';
        } else if (seconds < 60) {
            return `${seconds}s ago`;
        } else if (minutes < 60) {
            return `${minutes}m ago`;
        } else if (hours < 24) {
            return `${hours}h ago`;
        } else if (days < 31) {
            return `${days}d ago`;
        } else if (month < 12) {
            return `${month}m ago`
        } else {
            return `${years}y ago`;
        }
    }

    const editCurrentAnswer = () => {
        dispatch(editAnswer(props.id))
    }

    const doneEditingAnswer = () => {
        dispatch(editAnswer(undefined));
        if(user){
            if(quillRef.current){
                
                const editor = quillRef.current.getEditor();
                const delta = editor.getContents();
                const plainText = editor.getText();
                const deltaString = JSON.stringify(delta.ops);

                const data: SaveChangeAnswerModel = {
                    richText:deltaString,
                    plainText:plainText,
                    answerId: props.id,
                    cardId: props.cardId
                }
                dispatch(saveAnswerChanges(data));
            }
            
        }
    }

    const deleteCurrentAnswer = () => {
        if(user){
            const data: DeleteAnswer ={
                cardId: props.cardId,
                answerId: props.id
            }
            dispatch(changeDialog({
                dialog: DialogType.deleteDialog,
                deleteAction: DialogDeleteAction.deleteAnswer,
                cardId: props.cardId,
                answerId: props.id,
            }))
        }
    }

    const displayPreferences = () => {

        if(edit === undefined){
            setShowPreferences(user!.id === props.responderUser.responderId && props.id !== 'optimistic_answer')
        }
    }

    const hidePreferences = () => {
        if(edit === undefined){
            setShowPreferences(false)
        }
    }

    return (
        <Stack direction='row' spacing={1} width='60vw' marginTop={1}>
            <Box onClick={(event) => {
                event.stopPropagation();
                dispatch(fetchExpert({ expertId: props.responderUser.responderId }));
                dispatch(changeDialog({ dialog: DialogType.expertDialog, }));
            }}>
                <Avatar src={props.responderUser.responderPhoto} sx={{ marginTop: 1 }} />
            </Box>
            <Stack flexGrow={1}>
                <Box sx={{ backgroundColor: '#F0F2F5' }} borderRadius={'10px'} p={1} onMouseEnter={displayPreferences} onMouseLeave={hidePreferences}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                        <CardHeader
                            onClick={(event) => {
                                event.stopPropagation();
                                dispatch(fetchExpert({ expertId: props.responderUser.responderId }));
                                dispatch(changeDialog({ dialog: DialogType.expertDialog, }));
                            }}
                            sx={{
                                padding: 0,
                                margin: 0,
                                '.MuiCardHeader-title': {
                                    padding: 0,
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    color: theme.palette.textColor?.main
                                },
                                '.MuiCardHeader-subheader': {
                                    fontSize: '12px',
                                }
                            }}

                            title={props.responderUser.responderDisplayName}
                            subheader={getElapsedTime(props.createdAt)}

                        />
                        <AnswerPreferences
                            isEditing={edit !== undefined && edit === props.id}
                            hide={!showPreferences}
                            onDoneClick={doneEditingAnswer}
                            onDeleteClick={deleteCurrentAnswer}
                            onEditClick={editCurrentAnswer} />
                    </Box>
                    <ReactQuill ref={quillRef} className="custom-editor" modules={{ toolbar: false }} readOnly={!edit} value={JSON.parse(props.richText)} theme={"bubble"} />
                    
                    <Box width={'50vw'}>
                    <CardImages images={props.attachments} isDeleteable={false} />
                    </Box>
                </Box>
                <Stack direction={'row'} spacing={1} >
                    <AnswerVotes vote={props.totalVotes} onUpvoteClick={user !== undefined && props.upVotes.includes(user.id) ? props.onDownvoteClick : props.onUpvoteClick} isUpvoted={user !== undefined && props.upVotes.includes(user.id)} />
                    <Box paddingBottom={2} paddingTop={0}>
                        <Divider orientation="vertical" variant="middle" />
                    </Box>

                    <Stack direction={'row'} alignItems='center' onClick={handleReplyClick} sx={{'&:hover':{cursor:'pointer'}}}>
                        {
                            props.totalComments > 0 ? 
                            <Typography color='#848484'>
                                {t('threadPage.replyIconDescription')} • {props.totalComments} {t('threadPage.totalRepliesCaption')}
                            </Typography>
                            :
                            <Typography color='#848484'>
                                {t('threadPage.replyIconDescription')}
                            </Typography>
                        }
                    </Stack>
                </Stack>
                <Stack alignSelf={'flex-end'} display={'flex'} flexDirection={'column'} width={'100%'} spacing={1}>
                    {commentEditorState && props.comments.map((comment) => <Comment key={comment.id}  {...comment} />)}
                    {commentEditorState && !props.discussionIsClosed && !disableEditor && <CommentEditor responderPhoto={user?.photo || ''} answerIndex={props.answerIndex} />}
                </Stack>
            </Stack>
        </Stack>
    );
}