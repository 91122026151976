import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { debugPrint } from "../../../utils/debug_print";
import { DocumentData, Query, collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, startAfter, updateDoc, where } from "firebase/firestore";
import { UserModel, UserTag } from "../../../model/response/user_model";
import { FetchUserTagsModel } from "../../../model/request/fetch_profile_models";
import { TagModel } from "../../../model/response/tag_model";
import { ContentType } from "./expert_state";
import { MedalModel } from "../../../model/response/medal_model";
import { DateMode, UserChartModel } from "../../../model/request/user_chart_model";
import { getFunctions, httpsCallable } from "firebase/functions";
import { BarChartModel } from "../../../model/response/bar_chart_model";
import { firebaseApp } from "../../../utils/firebase_config";
import { SendGiftModel } from "../../../model/request/send_gift_model";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { updateUserProfile } from "../../auth/api/authAction";

export const clearExpert = createAction(
    'expert/clearExpert',
    function prepare() {
        return {
            payload: {

            }
        }
    }
);

export const addTag = createAction(
    'expert/addTag',
    function prepare(tag: UserTag) {
        return {
            payload: {
                tag: tag
            },
        }
    });


export const removeTag = createAction(
    'expert/removeTag',
    function prepare(tag: UserTag) {
        return {
            payload: {
                tag: tag
            },
        }
    });

export const updateSliderValue = createAction(
    'expert/updateSliderValue',
    function prepare(value:number) {
        return {
            payload: {
                value:value
            }
        }
    }
);



export const sendGift = createAsyncThunk(
    'expert/sendGift',
    async (data:SendGiftModel, {dispatch, rejectWithValue}) => {
        try{
            
            const functions = getFunctions(firebaseApp,'europe-west1');
            const rewardUser = httpsCallable(functions, 'rewardUserWithAccoladesFunction');
            dispatch(changeContent(ContentType.general));
            const results =  await rewardUser({user:data});

        }catch(error:any){
            return rejectWithValue(error.toString());
        }
    }
);

export const changeContent = createAction(
    'expert/fetchExpert',
    function prepare(type: ContentType) {
        return {
            payload: {
                type: type
            }
        }
    }
);

export const fetchExpert = createAsyncThunk(
    'expert/fetchExpert',
    async (data: {
        expertId: string,
    }, { rejectWithValue }) => {

        try {

            const db = getFirestore();
            const expertReference = doc(db, 'Users', data.expertId);
            const expertDoc = await getDoc(expertReference);
            const expert = expertDoc.data() as UserModel;

            return {
                expert: expert,
            }
        } catch (error: any) {
            debugPrint(error.toString(), 'expert/fetchExpertfetchExpert');
            return rejectWithValue(error.toString());
        }


    }
);


export const updateProfilePhoto = createAction(
    'expert/updateProfilePhoto',
    function prepare(
      photo?:string, 
) {
      return {
        payload:{
          photo: photo,
        }
      };
    }
  )

export const updatePicture = createAsyncThunk(
    'expert/updatePicture',
    async (data: {
        dataUrl: string;
        userId: string;
    }, { dispatch, rejectWithValue }) => {
        try {
            
            const db = getFirestore();
            const storage = getStorage();
            const documentsRef = ref(storage,'profile_pictures/' + data.userId);
            dispatch(updateProfilePhoto(data.dataUrl))
            await uploadString(documentsRef, data.dataUrl,'data_url');
            const imageUrl = await getDownloadURL(documentsRef);
            const expertReference = doc(db, 'Users', data.userId);
            await updateDoc(expertReference, {'photo':imageUrl});
            dispatch(updateUserProfile(undefined,imageUrl,undefined,undefined))

            return {
                url:imageUrl
            }
        
        }catch(error:any){
            return rejectWithValue(error.toString());
        }
            
    });

    export const updateBio = createAsyncThunk(
        'expert/updateBio',
        async (data: {
            bio: string;
            userId: string;
        }, { dispatch, rejectWithValue }) => {
            try {
                
                const db = getFirestore();
                const expertReference = doc(db, 'Users', data.userId);
                await updateDoc(expertReference, {'bio':data.bio});
                dispatch(updateUserProfile(data.bio))
            
            }catch(error:any){
                return rejectWithValue(error.toString());
            }
                
        });

export const fetchPosition = createAsyncThunk(
    'expert/fetchPosition',
    async (data: FetchUserTagsModel, { rejectWithValue }) => {
        try{
            const functions = getFunctions(firebaseApp, 'europe-west1');
            const getUserPosition = httpsCallable(functions, 'getUserPositionInLeaderboardFunction');
            const results = await getUserPosition({ user: data });
            const position = results.data as number;
            
            return {
                position:position,
            }

        }catch(error:any){
            return rejectWithValue(error.toString());
        }
    });

export const fetchAchievements = createAsyncThunk(
    'expert/fetchAchievements',
    async (data: FetchUserTagsModel, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const achievementQuery = query(collection(db, "Users", data.userId, 'UserAchievements'));
            const achievementSnapshot = await getDocs(achievementQuery);
            const medals: MedalModel[] = []
            achievementSnapshot.docs.map((doc) => {
                const medal = doc.data() as MedalModel;
                if (medal.name !== 'Encyclopedic'){
                    medals.push(doc.data() as MedalModel);
                }
            });

            return {
                medals: medals
            }
        } catch (error: any) {
            rejectWithValue(error.toString());
        }
    });

export const fetchExpertise = createAsyncThunk(
    'expert/fetchExpertise',
    async (data: FetchUserTagsModel, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            var tagQuery: Query<DocumentData>;

            if (data.fetchAllExpertise) {
                tagQuery = query(collection(db, "Users", data.userId, 'UserTags'), where('userRelativeScore', '>', 0), orderBy('userRelativeScore', 'desc'));
            } else if (data.lastElement) {
                tagQuery = query(collection(db, "Users", data.userId, 'UserTags'), limit(data.size), where('userRelativeScore', '>', 0), orderBy('userRelativeScore', 'desc'), startAfter(data.lastElement.userRelativeScore));
            } else {
                tagQuery = query(collection(db, "Users", data.userId, 'UserTags'), limit(data.size), where('userRelativeScore', '>', 0), orderBy('userRelativeScore', 'desc'));
            }


            const tagSnapshot = await getDocs(tagQuery);
            const tags: TagModel[] = []
            tagSnapshot.docs.map((doc) => {
                tags.push(doc.data() as TagModel);
            });

            if (data.fetchAllExpertise) {
                return {
                    tags: tags,
                    lastElement: undefined,
                    continueToScroll: false,
                    fetchAllExpertise: data.fetchAllExpertise,
                }

            } else if (tags.length == 0) {
                return {
                    tags: [],
                    lastElement: undefined,
                    continueToScroll: false,
                }
            } else if (tags.length < data.size) {
                return {
                    tags: tags,
                    lastElement: tagSnapshot.docs[tagSnapshot.docs.length - 1].data(),
                    continueToScroll: false,
                }
            } else {
                return {
                    tags: tags,
                    lastElement: tagSnapshot.docs[tagSnapshot.docs.length - 1].data(),
                    continueToScroll: true,
                }
            }

        } catch (error) {
            return rejectWithValue('error');
        }
    });

export const changeDateMode = createAction(
    'expert/changeDateMode',
    function prepare(dateMode: DateMode) {
        return {
            payload: {
                dateMode: dateMode
            },
        }
    }
);

export const getUserChart = createAsyncThunk(
    'expert/getUserChart',
    async (data: UserChartModel, { rejectWithValue }) => {

        try {
            const functions = getFunctions(firebaseApp, 'europe-west1');

            const getChart = httpsCallable(functions, 'getUserChartByFilterFunction');
            const results = await getChart({ user: data });

            const chartData = results.data as BarChartModel[];

            const upvotes: number[] = [];
            const openDiscussions: number[] = [];
            const accolades: number[] = [];
            const answers: number[] = [];
            const categories: string[] = [];
            const attributions: number[] = []

            chartData.map((data) => {
                upvotes.push(data.upvotes);
                openDiscussions.push(data.open_discussion);
                accolades.push(data.accolades);
                answers.push(Math.floor(data.answers));
                categories.push(data.x);
                attributions.push(data.attribution);
            });
            return {
                upvotes: upvotes,
                answers:answers,
                openDiscussions: openDiscussions,
                accolades:accolades,
                attributions: attributions,
                categories: categories
            }
        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    })