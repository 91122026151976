import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirestore, doc, updateDoc, DocumentData, query, collection, where, limit, orderBy, startAfter, getDocs, Query } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UpdateUserNotificationModel, FetchUserNotificationModel } from "../../../model/request/fetch_profile_models";
import { MarkAllAsReadModel } from "../../../model/request/notification";
import { NotificationModel } from "../../../model/response/notification_model";
import { debugPrint } from "../../../utils/debug_print";
import { firebaseApp } from "../../../utils/firebase_config";

export const updateNotification = createAsyncThunk(
    'notification/updateNotification',
    async (data: UpdateUserNotificationModel, { rejectWithValue }) => {

        try {
            const db = getFirestore();
            const notificationQuery = doc(db, 'Users', data.userId, 'Notifications', data.notificationId);

            await updateDoc(notificationQuery, { 'viewed': true });

            return {
                notificationId: data.notificationId,
                viewed: true,
            }

        } catch (error) {
            return rejectWithValue('error');
        }

    }
);

export const fetchNotifications = createAsyncThunk(
    'notification/fetchNotifications',
    async (data: FetchUserNotificationModel, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            var notificationQuery: Query<DocumentData>;

            if (data.lastElement) {
                notificationQuery = query(collection(db, "Users", data.userId, 'Notifications'), where('action', '==', 0), limit(data.size), orderBy('createdAt', 'desc'), startAfter(data.lastElement.createdAt));
            } else {
                notificationQuery = query(collection(db, "Users", data.userId, 'Notifications'), where('action', '==', 0), limit(data.size), orderBy('createdAt', 'desc'));
            }


            const notificationSnapshot = await getDocs(notificationQuery);
            const notifications: NotificationModel[] = []
            notificationSnapshot.docs.map((doc) => {
                notifications.push(doc.data() as NotificationModel);
            });

            if (notifications.length == 0) {
                return {
                    fetchedFirstTime: data.lastElement === undefined,
                    notifications: [],
                    lastElement: undefined,
                    continueToScroll: false,
                }
            } else if (notifications.length < data.size) {
                return {
                    fetchedFirstTime: data.lastElement === undefined,
                    notifications: notifications,
                    lastElement: notificationSnapshot.docs[notificationSnapshot.docs.length - 1].data(),
                    continueToScroll: false,
                }
            } else {
                return {
                    fetchedFirstTime: data.lastElement === undefined,
                    notifications: notifications,
                    lastElement: notificationSnapshot.docs[notificationSnapshot.docs.length - 1].data(),
                    continueToScroll: true,
                }
            }

        } catch (error) {
            return rejectWithValue('error');
        }

    });

export const markAllNotification = createAsyncThunk(
    'notification/markAllNotification',
    async (data:MarkAllAsReadModel, {rejectWithValue}) => {
        try{
            const functions = getFunctions(firebaseApp,'europe-west1');
            const markNotification = httpsCallable(functions, 'markAllNotificationsAsReadFunction');
            
            const results =  await markNotification({user:data});
        }catch(error:any){
            debugPrint(error.toString(),'profile/markAllNotification');
            return rejectWithValue(error.message)
        }
    }
);