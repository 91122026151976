import { createSlice } from "@reduxjs/toolkit";
import { DialogState, DialogType } from "./dialog_state";
import {  changeDialog } from "./dialog_action";

const initialState:DialogState = {
    currentDialog: DialogType.none,
    
}
const dialogSlice = createSlice({
    name: 'dialog',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(changeDialog, (state, payload) => {
            
            state.currentDialog = payload.payload.dialog;
            
            if(state.currentDialog === DialogType.expertDialog){
                if(payload.payload.expertId)
                    state.expertId =  payload.payload.expertId;
                
                if(payload.payload.alertMessage)
                    state.alertMessage = payload.payload.alertMessage;
            }

            if(state.currentDialog === DialogType.alertDialog){
                if(payload.payload.alertTitle)
                    state.alertTitle =  payload.payload.alertTitle;
                
                if(payload.payload.alertMessage)
                    state.alertMessage = payload.payload.alertMessage;
            }

            if(state.currentDialog === DialogType.channelMembersDialog){
                if(payload.payload.channel){
                    state.channel = payload.payload.channel;
                }
            }

            if(state.currentDialog === DialogType.channelSettingsDialog){
                if(payload.payload.channel){
                    state.channel = payload.payload.channel;
                }
            }

            if(state.currentDialog === DialogType.announcementDialog){
                if(payload.payload.announcement){
                    state.announcement = payload.payload.announcement;
                }
            }

            if (state.currentDialog === DialogType.deleteDialog){

                if(payload.payload.deleteAction !== undefined){
                    state.deleteAction = payload.payload.deleteAction;

                }

                if(payload.payload.messageId)
                    state.messageId = payload.payload.messageId;

                if(payload.payload.threadId)
                    state.threadId = payload.payload.threadId;
                
                if(payload.payload.cardId)
                    state.cardId = payload.payload.cardId;
                
                if(payload.payload.answerId)
                    state.answerId = payload.payload.answerId;

                if(payload.payload.channelId)
                    state.channelId = payload.payload.channelId;
            
            }

            if(state.currentDialog === DialogType.none){
                state.channel = undefined;
                state.announcement = undefined;
                state.alertTitle = undefined;
                state.alertMessage = undefined;
                state.expertId = undefined;
                state.deleteAction= undefined;
                state.messageId= undefined;
                state.threadId= undefined;
                state.cardId = undefined;
                state.answerId = undefined;
                state.channelId = undefined;
                
            }

        })
        
    }
});

export default dialogSlice.reducer;