import { Box, Button, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../app/store";
import { useDispatch } from "react-redux";
import { CardType } from "../../../model/response/card_model";
import { setCardType } from "../../card_creation/api/card_creation_action";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";

export interface GPTMessageFooterProps{
    isComplete:boolean | undefined;
}

export const GPTMessageFooter: React.FC<GPTMessageFooterProps> = (props: GPTMessageFooterProps) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch<AppDispatch>()
    
    if(props.isComplete && !props.isComplete){
        return (<></>);
    }

    const createADiscussion = () => {
        dispatch(setCardType(CardType.discussion));         
        dispatch(changeDialog({ dialog: DialogType.createDiscussionDialog }))
    }

    return (
        <CardContent>
            <Stack direction='row' alignItems='center' spacing={1} justifyContent='right'>
                <Typography color={theme.palette.textColor?.main}>
                {t('gptPage.gptCreateAQuestionDescription')}
                </Typography>
                <Box sx={{
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }}>
                    <Box onClick={createADiscussion} sx={{'&:hover':{cursor:'pointer'}}}>
                        <Typography color={theme.palette.textColor?.main} style={{ textDecoration: 'underline', }}>
                            {t('gptPage.gptCreateAQuestionButton')}
                        </Typography>
                    </Box>
                    
                </Box> 
            </Stack>
        </CardContent>   
        );
}