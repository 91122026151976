import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../../utils/firebase_config";
import { CardModel } from "../../../model/response/card_model";
import { debugPrint } from "../../../utils/debug_print";
import { ChannelDiscussionState } from "./channel_discussion_state";

export const clearChannelDiscussionState = createAction(
    'channelDiscussion/clearChannelDiscussionState',
    function prepare() {
        const initialState: ChannelDiscussionState = {
    
            discussionsForYou: [],
            limit: 5,
            hasMoreDiscussions: true,
            
            allChannelDiscussions: [],
            hasMoreAllDiscussions: true,
            pageIndex:1,
            
            allDiscussionAreLoading: true,
            discussionsForYouAreLoading:true,
        }
        return {
            payload: initialState
        }
    }
);



export const fetchDiscussionsForYou = createAction(
    'channelDiscussion/fetchDiscussionsForYou',
    function prepare(discussions: CardModel[]) {
        return {
            payload: {
                discussions: discussions
            }
        }
    }
);


export const fetchAllDiscussions = createAsyncThunk(
    'channelDiscussion/fetchAllDiscussions',
    async (data: {
        organizationId: string,
        channelId: string,
        isClosed: boolean,
        pageSize: number,
        pageIndex: number,
    }, { rejectWithValue }) => {
        try {
            const functions = getFunctions(firebaseApp, 'europe-west1');
            const feedFunction = httpsCallable(functions, 'searchFeedFunction');

            const start = performance.now();
            const results = await feedFunction({
                search: data
            });
            const end = performance.now();
            const discussions = results.data as CardModel[];

            if (discussions.length === 0) {
                return {
                    discussions: discussions,
                    scrollFeed: false
                };
            } else if (discussions.length < data.pageSize) {

                return {
                    discussions: discussions,
                    scrollFeed: false
                };
            }
            else {
                return {
                    discussions: discussions,
                    scrollFeed: true
                };
            }

        } catch (error: any) {
            debugPrint(error.toString(), 'feed/fetchFeed');
            return rejectWithValue(error.toString());
        }

    });

export const loadMoreDiscussions = createAction(
    'channelDiscussion/loadMoreDiscussions',
    function prepare(limit: number) {
        return {
            payload: {
                limit: limit
            }
        }
    }
);