import Quill, { RangeStatic } from "quill";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AttachmentModel, FileType } from "../../../model/response/thread_draft_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../../utils/firebase_config";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { UserMentionModel } from "../../../model/response/user_mention_model";
import { Box, Button, Stack } from "@mui/material";
import EditorToolbar from "./editor_toolbar";
import { CardImages } from "../../../components/card-images/card_images";
import { useDebounce } from "@uidotdev/usehooks";
import { AnswerDraftModel } from "../../../model/response/answer_draft_model";
import {  deleteAnswerImageFromFirestore, submitAnswer, updateAnswerDraft, uploadImagesOnAnswer } from "../api/thread_action";
import { SubmitAnswerModel } from "../../../model/request/submit_answer_model";
import { AnswerState } from "../api/thread_state";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { AnswersChannelPermissions, ChannelModel } from "../../../model/response/channel_model";
import { MentionOrientation } from "../../card_creation/api/card_creation_state";
import { CardModel } from "../../../model/response/card_model";


export interface AnswerEditorProps {
    channelId: string;
    cardId: string;
}

export const AnswerEditor: React.FC<AnswerEditorProps> = (props: AnswerEditorProps) => {
    const editorRef = useRef(null);
    const quillRef = useRef<Quill | undefined>(undefined);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const [answerText, setAnswerText] = useState<string>('');
    const [delta, setDelta] = useState<string>(JSON.stringify([{"insert":"\n"}]));
    const answerDraft: AnswerDraftModel | undefined = useSelector((state: RootState) => state.thread.answerDraft);
    
    const currentCursorPosition = useRef<RangeStatic | null>(null);

    const pendingFiles: string[] = useSelector((state: RootState) => state.thread.pendingFiles);

    const debouncedAnswerText = useDebounce(answerText, 100);
    const debouncedAnswerDelta = useDebounce(delta, 100);
    const answerState = useSelector((state: RootState) => state.thread.answerState);
    const disabledButton = debouncedAnswerText.trim()==='';
    const card: CardModel | undefined = useSelector((state: RootState) => state.thread.card);
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();


    const channels: ChannelModel[] = useSelector((state: RootState) => state.channel.channels);
    const threadChannel:ChannelModel | undefined = useSelector((state: RootState) => state.thread.channel);
    const filteredChannels: ChannelModel[] = channels.filter((channel) => channel.channelId === threadChannel?.id );
    var disableEditor = true;


    if (threadChannel){
        if(threadChannel.permissions.includes(AnswersChannelPermissions.ONLY_ADMINS)){
            
            if(filteredChannels.length === 1){
                disableEditor = !filteredChannels[0].isAdmin;
            }else{
                disableEditor = true;
            }
            
        }else if(threadChannel.permissions.includes(AnswersChannelPermissions.MEMBERS)){
            if(filteredChannels.length === 1){
                disableEditor = false;
            }else{
                disableEditor = true;
            }
        }else{
            disableEditor = false;
        }
    }

    if(card){
        if(card.channel.channelId === 'AGORA'){
            disableEditor = false;
        }

        if(card.senderUser.senderId === user?.id){
            disableEditor = false;
        }
    }


    const onDrop = useCallback(async acceptedFiles =>  {

        const dataUrls:string[] = [];
        const filenames:string[] = [];
        const fileTypes:FileType[] = [];

        await Promise.all(
            acceptedFiles.map(async (file) => {
                if (file && file.type.startsWith('image/')) {
                    const dataUrl = await readFileAsDataURL(file);
                    dataUrls.push(dataUrl);
                    filenames.push(file.name);
                    fileTypes.push(FileType.IMAGE);
                } else if (file) {
                    const dataUrl = await readFileAsDataURL(file);
                    dataUrls.push(dataUrl);
                    filenames.push(file.name);
                    fileTypes.push(FileType.DOCUMENT);
                }
            })
        );


        if (user && dataUrls.length > 0) {


            dispatch(uploadImagesOnAnswer(
                {
                  images: dataUrls,
                  fileNames: filenames,
                  fileTypes:fileTypes,
                  userId: user.id,
                  cardId: props.cardId,
                  draftId: answerDraft?.id,
                }))

        }
        
    }, [answerDraft]);
    const {getRootProps,} = useDropzone({onDrop,noClick:true});
    
    

    
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: "#toolbar",
            },
            magicUrl: true,

            mention: {
                onSelect: (item, insertItem) => {
                    insertItem(item);
                },
                renderItem: (data) => {
                    const div = document.createElement('div');
                    div.className = 'mention-item';

                    const img = document.createElement('img');
                    img.className = 'mention-avatar';
                    img.src = data.photo;

                    const span = document.createElement('span');
                    span.textContent = data.value;

                    div.appendChild(img);
                    div.appendChild(span);

                    return div;
                },
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                mentionDenotationChars: ["@"],
                fixMentionsToQuill: true,
                defaultMenuOrientation: 'top',
                source: async (searchTerm: string, renderList: Function, mentionChar: string) => {

                    if (searchTerm.trim() == "") {
                        renderList([], searchTerm);
                    }

                    if (user && !searchTerm.includes(" ")) {
                        const data = {
                            fullname: searchTerm,
                            organizationId: user.organizationId,
                            channelId: props.channelId,
                        };

                        const functions = getFunctions(firebaseApp, 'europe-west1');
                        const searchUsers = httpsCallable(functions, 'searchUserByFullnameFunction');

                        const results = await searchUsers({ user: data });
                        const users = results.data as UserSuggestedModel[];

                        const usertToTags = users.map((user) => {

                            const mentionModel:UserMentionModel = {
                                id: user.id,
                                value:user.displayName,
                                photo: user.photo,
                                belongsToChannel: user.belongsToChannel
                            }
                            return mentionModel;
                        
                        
                    });
                    
                    renderList(usertToTags.filter(user => user.belongsToChannel),searchTerm) 
                    
                    }

                }
            }
        };
    }, []);

    useEffect(
        () => {
            if (user  && answerState === AnswerState.done) {

                let draftId = '';
                let images: AttachmentModel[] = []

                if (answerDraft) {
                    draftId = answerDraft.id;
                    images = answerDraft.attachments;
                }

                const currentDraft: AnswerDraftModel = {
                    id: draftId,
                    cardId: props .cardId,
                    attachments: images,
                    plainText: answerText,
                    richText: delta
                }

                dispatch(updateAnswerDraft({
                    userId: user.id,
                    answerDraft: currentDraft,
                }));

            }

        }, [debouncedAnswerDelta, answerState, answerDraft?.attachments.length]
    );

    const readFileAsDataURL = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
                if (loadEvent.target?.result) {
                    resolve(loadEvent.target.result as string);
                }
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const onPaste = async (event: ClipboardEvent, quill: Quill) => {
        const clipboardData = event.clipboardData;
        let files: File[] = [];
        let dataUrls: string[] = [];
        let filenames: string[] = [];
        let fileTypes: FileType[] = [];

        if (clipboardData && clipboardData.items) {
            event.preventDefault();

            const text = clipboardData.getData("text/plain");
            if (text !== "") {
                quill.focus();
                const range = quill.getSelection();

                if (range) {
                    quill.insertText(range.index, text);
                    setTimeout(() => {
                        quill.setSelection(range.index + text.length, 0, 'api');
                    }, 1);
                }
            }

            // Prevent default paste action
            for (let i = 0; i < clipboardData.items.length; i++) {
                if (clipboardData.items[i].kind === 'file') {
                    const file = clipboardData.items[i].getAsFile();
                    if (file) files.push(file)
                }
            }

            await Promise.all(
                files.map(async (file) => {
                    if (file && file.type.startsWith('image/')) {

                        const dataUrl = await readFileAsDataURL(file);
                        dataUrls.push(dataUrl);
                        filenames.push(file.name);
                        fileTypes.push(FileType.IMAGE);
                    } else if (file) {
                        const dataUrl = await readFileAsDataURL(file);
                        dataUrls.push(dataUrl);
                        filenames.push(file.name);
                        fileTypes.push(FileType.DOCUMENT);

                    }
                })
            );


            if (user && dataUrls.length > 0) {

                dispatch(uploadImagesOnAnswer(
                    {
                      images: dataUrls,
                      fileNames: filenames,
                      fileTypes:fileTypes,
                      userId: user.id,
                      cardId: props.cardId,
                      draftId: answerDraft?.id,
                    }))

            }
        }
    };

    useEffect(() => {
        const quill = new Quill('#answer-editor', {
            theme: 'snow',
            modules: modules,
            readOnly: disableEditor,
        });

        if (quill && editorRef) {
            quillRef.current = quill;

            if (answerDraft && answerText.trim() === '') {
                
                const delta = JSON.parse(answerDraft.richText);
                quill.setContents(delta);
                const range = quill.getSelection();
                if (range) {
                    currentCursorPosition.current = range;
                }
                quill.setSelection(currentCursorPosition.current?.index || 0, currentCursorPosition.current?.length || 0, 'api')
                
                const plainText = quill.getText();
                setAnswerText(plainText);
            }else{
                quill.setSelection(currentCursorPosition.current?.index || 0, currentCursorPosition.current?.length || 0, 'api')
            }

            quill.root.addEventListener('paste', (e: ClipboardEvent) => onPaste(e, quill));
            
            quill.on('text-change', function () {
                const deltaContent = quill.getContents();


                if (deltaContent) {
                    const plainText = quill.getText();
                    const deltaString = JSON.stringify(deltaContent.ops);
                    setDelta(deltaString);
                    setAnswerText(plainText);
                }
            });
        }

        return () => {
            if (quill) {
                quill.root.removeEventListener('paste', (e: ClipboardEvent) => onPaste(e, quill));
            }
        };
    }, [editorRef, modules]);


    const onSubmit = () => {
        if (user && answerDraft) {
            const data: SubmitAnswerModel = {
                userId: user.id,
                answerDraftId: answerDraft.id
            }

            if (quillRef){
                if(quillRef.current){
                    quillRef.current.setText('');
                }
               
            }
            
            dispatch(submitAnswer({
                answer: data,
                answerDraft: answerDraft,
                responderUser: {
                    responderId: user.id,
                    responderDisplayName: user.displayName,
                    responderPhoto: user.photo,
                }
            }));
        }
    
    }


    return (
        <Box width='60vw' border={1} borderRadius={2} borderColor={'#D9D9D9'} p={1} marginTop={1} {...getRootProps()}>
            <EditorToolbar className='answer-toolbar' />
            <Box width='100%' border={1} borderRadius={'8px'} borderColor={'#D9D9D9'} sx={{ backgroundColor: '#F0F2F5' }} >
                <Stack>
                    <div ref={editorRef} id="answer-editor"></div>
                    <CardImages images={
                        answerDraft?.attachments || []} 
                        isDeleteable={true} 
                        onDelete={(image) => dispatch(deleteAnswerImageFromFirestore({
                            firebaseStorageRef: image.storageRef,
                        }))}
                        pendingFiles={pendingFiles}/>
                </Stack>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='flex-end' p={1} >
                <Button variant='contained' style={{ color: 'white', backgroundColor:!disabledButton? "#5876FF":undefined, textTransform: 'none', borderRadius: 25, padding: 6, paddingLeft: 15, paddingRight: 15 }}
                    disabled={disabledButton}
                    onClick={onSubmit}>
                   {t('threadPage.sendButton')}
                </Button>
            </Box>
        </Box>

    );
}