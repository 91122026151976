import { DocumentData } from "firebase/firestore";
import { TagModel } from "../../../model/response/tag_model";
import { UserModel, UserTag } from "../../../model/response/user_model";
import { MedalModel } from "../../../model/response/medal_model";
import { DateMode } from "../../../model/request/user_chart_model";

export enum ContentType{
    general,
    expertise,
    badges,
    dashboard,
    sendGift,
}
export interface ExpertState{
    expert: UserModel | undefined;
    positionInLeaderboard: number;
    contentType: ContentType;

    tagsToKeep: UserTag[];
    sliderValue: number;

    
    
    chartMode: DateMode;
    openDiscussions: number[];
    upvotes: number[];
    accolades:number[];
    answers: number[];
    attributions: number[];
    categories: string[];

    
    medals: MedalModel[];
    expertises: TagModel[];
    lastSeenExpertise: DocumentData | undefined;
    scrollExpertise: boolean;
    
}