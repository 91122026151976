import { Box, Button, ButtonGroup, IconButton } from "@mui/material";
import { useState } from "react";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';


export interface FilterButtonProps {
    onPersonClick: () => void;
    onTeamClick: () => void;
    isPersonClicked: boolean;
}


export const FilterButton: React.FC<FilterButtonProps> = (props: FilterButtonProps) => {


    return (
        <Box>
            <ButtonGroup sx={{ borderRadius: '25px', }}
                variant="outlined" aria-label="outlined primary button group">
                <Box color={'white'} p={'4px'}
                    onClick={props.onPersonClick}
                    sx={{
                        color:props.isPersonClicked ? '#5876FF':'#4A4A4A',
                        borderTopLeftRadius: '25px',
                        borderBottomLeftRadius: '25px',
                        border:1,
                        backgroundColor: props.isPersonClicked ? 'rgba(88, 118, 255, 0.12)' : 'white',
                        '&:hover': {
                            backgroundColor: props.isPersonClicked ? 'rgba(88, 118, 255, 0.12)': 'grey.400',
                        },
                    }}
                >
                    <PersonOutlineOutlinedIcon />
                </Box>
                <Box color={'white'} p={'4px'}
                    onClick={props.onTeamClick}
                    sx={{
                        color: !props.isPersonClicked ? '#5876FF':'#4A4A4A',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        border:1,
                        backgroundColor: !props.isPersonClicked ? 'rgba(88, 118, 255, 0.12)' : 'white',
                        '&:hover': {
                            backgroundColor: !props.isPersonClicked ? 'rgba(88, 118, 255, 0.12)': 'grey.400',
                        },
                    }}
                >
                    <GroupOutlinedIcon />
                </Box>
            </ButtonGroup>
        </Box>
    );
}