import { Box, Button, List, ListItem, Typography } from "@mui/material";
import { useEffect } from "react";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { CardModel, CardType } from "../../../model/response/card_model";
import InfiniteScroll from "react-infinite-scroll-component";
import { AnnouncementsChannelPermissions, ChannelModel } from "../../../model/response/channel_model";
import { collection, getFirestore, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { AnnouncementTile } from "../../../components/announcement-tile/announcement_tile";
import { setCardType, setChannel } from "../../card_creation/api/card_creation_action";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { fetchAnnouncements, loadMoreAnnouncements } from "../api/channel_announcement_action";
import { EmptyComponent } from "../../../components/empty-component/empty_component";
import { SkeletonCardTiles } from "../../../components/skeleton-card/skeleton_card";
import { ActionButton } from "../../../components/action-button/action_button";
import { useTranslation } from "react-i18next";

const ChannelAnnouncementPage = () => {


    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const selectedChannel: ChannelModel | null = useSelector((state: RootState) => state.channel.currentChannel);
    const currentFeed: CardModel[] = useSelector((state: RootState) => state.channelAnnouncement.channelAnnouncements);
    const announcementsAreLoading: boolean = useSelector((state: RootState) => state.channelAnnouncement.announcementsAreLoading);
    const size:number = useSelector((state: RootState) => state.channelAnnouncement.limit);
    const hasMore: boolean = useSelector((state: RootState) => state.channelAnnouncement.hasMoreAnnouncements);
    const dispatch = useDispatch<AppDispatch>();
    
    const {t} = useTranslation();

    const fetch = () => dispatch(loadMoreAnnouncements(size));
    const channelName = selectedChannel?.channelName || '';

    var disableSelectionButton = true;
    if (selectedChannel){
        if(selectedChannel.permissions.includes(AnnouncementsChannelPermissions.ONLY_ADMINS)){
            disableSelectionButton = !selectedChannel.isAdmin;

        }else{
            disableSelectionButton = false;
        }
    }

    useEffect(
        () => {
            if (user != undefined && selectedChannel !== null) {
                const db = getFirestore();
                const announcementQuery = query(
                    collection(db, "Users", user.id, 'Threads'), 
                    limit(size), 
                    where('type', '==', 0), 
                    where('channel.channelId', '==', selectedChannel.channelId), 
                    orderBy('isClosed', 'asc'), 
                    orderBy('createdAt', 'desc'));
                
                    
                
                const unsubscribe = onSnapshot(announcementQuery, (querySnapshot) => {
                    const channelAnnouncements: CardModel[] = querySnapshot.docs.map(doc => doc.data() as CardModel);
                    dispatch(fetchAnnouncements(channelAnnouncements));
                });

                return () => {
                    unsubscribe();
                };
            }
        }, [user, selectedChannel]
    );

    const remainingHeight = 145;

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            flexGrow={1}
            sx={{ backgroundColor: '#F7F9FC' }}>
                <Box 
                marginTop={2}
                marginBottom={4}
                display={'flex'}
                width='60vw'
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}>
                    <Typography variant='h5'>
                        {t('channelAnnouncementPage.title',{channelName: channelName})}
                    </Typography>
                    <ActionButton 
                    onClick={() => {
                        dispatch(setCardType(CardType.announcement));
                        if (selectedChannel){
                            dispatch(setChannel(selectedChannel));
                            dispatch(changeDialog({ dialog: DialogType.createAnnouncementDialog }));
                        }
                            
                    } } 
                    isLoading={false} 
                    disabled={disableSelectionButton} 
                    title={t('channelAnnouncementPage.actionButton')}                    
                    />
                        
                </Box>
            <Box
                id={'feedScrollable'}
                display={'flex'}
                flexDirection={'column'}
                height={`calc(100vh - ${remainingHeight}px)`}
                sx={{
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {
                        display: 'none', // Customize the width of the scrollbar
                    }
                }}>
                {
                    !announcementsAreLoading && currentFeed.length == 0 
                     && <EmptyComponent 
                        title={t('channelAnnouncementPage.emptyCaption')} 
                        subtitle={
                            t('channelAnnouncementPage.emptyDescription')
                        }
                     />
                }
                {
                    announcementsAreLoading == true && <SkeletonCardTiles/>
                }
                
                <InfiniteScroll
                    dataLength={currentFeed.length}
                    next={fetch}
                    hasMore={hasMore}
                    scrollableTarget={'feedScrollable'}
                    loader={<></>}
                >
                    <List sx={{
                        width: 'inherit',
                        "&:hover": {
                            cursor: "pointer",
                        }
                    }}>
                        {
                            currentFeed.map((feed) => {
                                return (
                                    <ListItem key={feed.id}>
                                        <AnnouncementTile {...feed} />
                                    </ListItem>

                                )
                            })
                        }
                    </List>
                </InfiniteScroll>
            </Box>
        </Box>
    );
}

export default ChannelAnnouncementPage;
