import { Dialog } from "@mui/material"
import { MouseEvent, useState } from "react";
import { ExpertiseToClick, UpdateTags } from "../components/update_tags";
import { UpdateProfileDialog } from "../components/update_profile";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { DialogType } from "../../dialog/dialog_state";



export const IntroductionTutorialDialog:React.FC = () => {
    const currentDialog: DialogType = useSelector((state:RootState) => state.dialog.currentDialog);
    const [goToSecondStep, setGoToSecondStep] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>()
    const onClose = () => {}

    

    return (
        <Dialog
            fullWidth
            PaperProps={{ sx: { borderRadius: "10px" } }}
            open={currentDialog === DialogType.introductionDialog}
            sx={{
                overflowY: 'hidden'
            }}
            onClose={onClose}
        >
            { !goToSecondStep && <UpdateProfileDialog 
                onContinueClick={() => setGoToSecondStep(true)}/> }
            { goToSecondStep && <UpdateTags onBack={() => setGoToSecondStep(false)}/> }
        </Dialog>
    )
}