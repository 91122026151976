import { Box, Popover, Stack, Typography, useTheme } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { DeleteDocumentModel } from "../../../model/request/documents_model";
import { deleteDocument } from "../api/document_action";
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { selectCurrentGPTChat, setIsSearchMessage } from "../../gpt/api/gpt_action";
import { AIConversation } from "../../../model/response/gpt_conversation";
import { useNavigate } from "react-router-dom";
import { goForward, openChannelDrawer } from "../../navigation/navigation_action";
import { Page } from "../../navigation/navigation_state";
import { useTranslation } from "react-i18next";

export interface DocumentActionProps{
    id:string;
    organizationId: string;
    url: string;
    fileName: string;
    isOwner:boolean;
}


export const DocumentAction:React.FC<DocumentActionProps> = (props: DocumentActionProps) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const onClose = () => {
        setAnchorEl(null);
    }

    const chatWithSybil = () => {
        const date = new Date().toISOString();
        const data:AIConversation = {
            id: "",
            title: "",
            document:{
                documentId:props.id,
                documentName: props.fileName,
            },
            reported: false,
            createdAt: date,
            updatedAt: date
        }
        dispatch(setIsSearchMessage(false));
        dispatch(selectCurrentGPTChat(data));
        dispatch(openChannelDrawer(false));
        dispatch(goForward(Page.GPTPage))
        navigate('/sybil');

    }

    const onDeleteClick = () => {
        const data: DeleteDocumentModel = {
            organizationId: props.organizationId,
            documentId: props.id
        }

        dispatch(deleteDocument(data))
    }

    return (
        <Box color={theme.palette.textColor?.main}
         >
            <MoreVertIcon
                onClick={(event) => setAnchorEl(event.currentTarget)}
            style={{ cursor: 'pointer' }}
        />
        <Popover open={anchorEl !== null}
            onClose={onClose}
            anchorEl={anchorEl}
            slotProps={{
                paper:{
                    sx: {
                        borderRradius: '8px',
                        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                      },
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Stack direction={'column'} p={1} spacing={1} sx={{ cursor: 'pointer' }}>
                <Stack direction='row'  spacing={1} 
                    onClick={chatWithSybil}
                >
                    <Box color={theme.palette.textColor?.main}>
                        <ForumOutlinedIcon/>
                    </Box>
                    <Typography color={theme.palette.textColor?.main} >{t('channelDocumentPage.chatDocumentCaption')}</Typography>
                </Stack>
                <Stack direction='row'  spacing={1} sx={{ cursor: 'pointer' }}
                onClick={() => window.open(props.url, '_blank')}>
                    <Box color={theme.palette.textColor?.main}>
                        <CloudDownloadIcon/>
                    </Box>
                    <Typography color={theme.palette.textColor?.main} >{t('channelDocumentPage.downloadDocumentCaption')}</Typography>
                </Stack>
                {
                    props.isOwner && 
                    <Stack direction='row' sx={{ cursor: 'pointer' }}  spacing={1}
                        onClick={onDeleteClick}
                    >
                        <Box color={theme.palette.textColor?.main}>
                            <DeleteOutlineIcon/>
                        </Box>
                        <Typography color={theme.palette.textColor?.main} >{t('channelDocumentPage.deleteDocumentCaption')}</Typography>
                    </Stack>
                }
            </Stack>
        </Popover>
        </Box>
    )
} 