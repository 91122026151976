import { createSlice } from "@reduxjs/toolkit";
import { IntroductionState } from "./introduction_state";
import { searchTags, updateBio, updatePhoto, updateProfile, updateTag, updateTagQuery } from "./introduction_action";

const initialState: IntroductionState = {
    
    organizationExperties: [],
    tagsAreLoading:false,
    tagQuery: "",
    tagsToKeep: [],
    
    bio: "",
    photo: undefined,

    profileIsUploading:false
}

const introductionSlice = createSlice({
    name: 'gptSlice',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(updateTagQuery, (state, action) => {
            state.tagQuery = action.payload.query;
        })
        .addCase(updateBio, (state, action) => {
            state.bio = action.payload.bio;
        })
        .addCase(updateTag, (state, action) => {
            const currentTags = state.tagsToKeep;

            if (currentTags.includes(action.payload.tag)){
                state.tagsToKeep = currentTags.filter((element) => element !== action.payload.tag);
            }else{
                currentTags.push(action.payload.tag);
                state.tagsToKeep = currentTags;
            }
        })
        .addCase(updatePhoto, (state, action) => {
            state.photo = action.payload.photo;
        })
        .addCase(searchTags.fulfilled, (state, action) => {
           state.organizationExperties = action.payload.tags; 
           state.tagsAreLoading = false;
        })
        .addCase(searchTags.pending, (state,) => {
            state.tagsAreLoading = true;
        })
        .addCase(searchTags.rejected, (state,) => {
            state.tagsAreLoading = false;
        })
        .addCase(updateProfile.fulfilled, (state, ) => {
            state.profileIsUploading = false;
        })
        .addCase(updateProfile.pending, (state, ) => {
            state.profileIsUploading = true;
        })
        .addCase(updateProfile.rejected, (state, ) => {
            state.profileIsUploading = false;
        })
    }
});

export default introductionSlice.reducer;