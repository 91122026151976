import { Box, Dialog, Stack, TextField, Typography, useTheme } from "@mui/material";
import { ActionButton } from "../../../components/action-button/action_button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { DialogType } from "../../dialog/dialog_state";
import { ThreadDraftModel } from "../../../model/response/thread_draft_model";
import { UserModel } from "../../../model/response/user_model";
import { CardType } from "../../../model/response/card_model";
import { SearchSimilarCardsModel } from "../../../model/request/search_model";
import { searchSimilarCards } from "../../search/api/search_action";
import { ChannelModel } from "../../../model/response/channel_model";
import { SendCardModel } from "../../../model/request/send_card_model";
import { createThread, deleteImageFromFirestore, updateThreadDraft } from "../api/card_creation_action";
import { changeDialog } from "../../dialog/dialog_action";
import { ChannelChip } from "../../../components/channel-chip/channel_chip";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from "react";
import { CardImages } from "../../../components/card-images/card_images";
import { useDebounce } from "@uidotdev/usehooks";
import { DiscussionEditorToolbar } from "./discussion_editor_toolbar";
import { QuillEditor } from "./card-editor";
import { ChooseChannelPopOver } from "./choose_channel_pop_over";
import { useTranslation } from "react-i18next";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
export interface EditorDialogProps {
    open: boolean;
    onClose: () => void;
}
export const EditorDialog: React.FC<EditorDialogProps> = (props: EditorDialogProps) => {
    const theme = useTheme();
    const similarCardLoading: boolean = useSelector((state: RootState) => state.search.similarCardLoading);
    const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);
    const draft: ThreadDraftModel | undefined = useSelector((state: RootState) => state.cardCreation.cardDraft);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const selectedChannel: ChannelModel | undefined = useSelector((state: RootState) => state.cardCreation.selectedChannel);
    const type: CardType = useSelector((state: RootState) => state.cardCreation.type);
    const channels: ChannelModel[] = useSelector((state: RootState) => state.channel.channels);
    const mutedChannels: ChannelModel[] = useSelector((state: RootState) => state.channel.mutedChannels);
    
    const totalChannels = [...channels, ...mutedChannels];
    
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [openedPopOver, setOpenedPopOver] = useState(false);

    const [openInFull, setOpenInFull] = useState(false);

    const [title, setTitle] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [delta, setDelta] = useState<string>('');
    const { t } = useTranslation();

    const debounceTitle = useDebounce(title, 300);
    const debounceText = useDebounce(text, 300);
    const debouncedDelta = useDebounce(delta, 300);
    const dispatch = useDispatch<AppDispatch>();

    const handleChannelSelectionClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenedPopOver(true);
    };

    const handleCloseChannelSelectionClick = () => {
        setAnchorEl(null);
        setOpenedPopOver(false);
    };

    const submit = () => {
        if (user && draft) {

            const channelId = selectedChannel?.channelId || '';
            if (draft.type === CardType.discussion) {

                const data: SearchSimilarCardsModel = {
                    cardTitle: title,
                    cardText: text,
                    organizationId: user.organizationId,
                    channelId: channelId,
                }
                dispatch(searchSimilarCards(data));

            } else {
                const card: SendCardModel = {
                    senderId: user.id,
                    cardDraftId: draft.id
                }
                dispatch(createThread({
                    card: card,
                }));

                dispatch(changeDialog({ dialog: DialogType.none, }));

            }
        }
    }

    useEffect(
        () => {
            const imageLength = draft?.attachments.length;
            if (debounceTitle !== '' || debounceText != '' || selectedChannel || (imageLength && imageLength >= 0)) {
                if (user) {
                    let draftId = '';
                    if (draft) {
                        draftId = draft.id;
                    }
                    const currentDraft: ThreadDraftModel = {
                        id: draftId,
                        userId: user.id,
                        cardTitle: debounceTitle,
                        cardText: debounceText,
                        cardRichText: delta,
                        channelId: selectedChannel ? selectedChannel.channelId : '',
                        isAnonymous: false,
                        attachments: draft?.attachments || [],
                        type: type,
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                    }

                    dispatch(updateThreadDraft(currentDraft));
                }

            }
        }, [debounceTitle, debouncedDelta, selectedChannel, draft?.attachments.length]
    );
    

    return (
        <Dialog
            fullWidth
            maxWidth={openInFull ? 'lg' : 'sm'}
            PaperProps={{ sx: {borderRadius: "10px" } }}
            open={props.open}
            sx={{
                overflowY: 'hidden'
            }}
            onClose={props.onClose}
        >
            <Stack p={2}  direction={'column'} spacing={2} >
                <Box display='flex' flexDirection='row' justifyContent='space-between' >
                    <Typography
                        variant="h6"
                        align="center"
                        color={theme.palette.textColor?.main}>
                        {dialog === DialogType.createDiscussionDialog ? t('cardCreationPage.discussionTitle') : t('cardCreationPage.announcementTitle')}
                    </Typography>
                    <Box display='flex' flexDirection='row' alignItems={'center'}>
                        <ActionButton
                            onClick={submit}
                            isLoading={similarCardLoading}
                            disabled={title.trim() === '' || text.trim() === '' || selectedChannel === undefined || similarCardLoading}
                            title={t('cardCreationPage.actionButton')} />
                        <Box 
                        onClick={() => setOpenInFull(!openInFull)}
                        sx={{marginLeft:'5px','&:hover':{cursor:'pointer'}}} 
                        color={theme.palette.textColor?.main}>
                            {openInFull?<CloseFullscreenOutlinedIcon/>:<OpenInFullIcon/>}
                        </Box>
                    </Box>
                    
                </Box>
                <Box 
                    p={selectedChannel === undefined ? 1 : 0}
                    border={selectedChannel === undefined ? 1 : 0}
                    borderRadius={selectedChannel === undefined ? 2 : 0}
                    borderColor={selectedChannel === undefined ? '#D9D9D9' : 'white'}
                    onClick={(event) => handleChannelSelectionClick(event)}
                    sx={{ width: 'min-content', '&:hover': { cursor: 'pointer' } }}>
                    {
                        selectedChannel === undefined ?
                            <Stack direction='row' spacing={1}>
                                <Typography noWrap>
                                    {t('cardCreationPage.selectChannelButton')}
                                </Typography>
                                <KeyboardArrowDownIcon />
                            </Stack> :
                            <ChannelChip
                                channel={selectedChannel} />
                    }
                </Box>
                <TextField 
                InputProps={{ sx: { borderRadius: '10px' } }}

                placeholder={t('cardCreationPage.cardTitlePlaceHolder')} value={title} onChange={(event) => setTitle(event.target.value)} />
                <Box border={1} borderRadius={2} borderColor={'#D9D9D9'}
                    sx={{
                        '&:hover': { border: '1px solid #000', }, '&:focus': { borderColor: 'blue', },
                    }}>
                    <QuillEditor setText={setText} setDelta={setDelta} expand={openInFull} />
                    <CardImages images={draft?.attachments || []} isDeleteable={true} onDelete={(image) => dispatch(deleteImageFromFirestore({
                        firebaseStorageRef: image.storageRef,
                    }))} />
                    <DiscussionEditorToolbar />
                </Box>
                <ChooseChannelPopOver
                    open={openedPopOver}
                    channels={totalChannels}
                    anchor={anchorEl}
                    onClose={() => handleCloseChannelSelectionClick()} />
            </Stack>
        </Dialog>
    )
}