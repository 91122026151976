import {createAction} from "@reduxjs/toolkit";
import { Page } from "./navigation_state";

export const goForward = createAction('navigation/goForward', 
function prepare(currentPage: Page,){
    return {
        payload: {
          currentPage: currentPage
        },
      } 
});


export const openChannelDrawer = createAction('navigation/openChannelDrawer', 
function prepare(open:boolean){
    return {
        payload: {
          open:open
        },
      } 
});

export const goBackward = createAction('navigation/goBackward',
function prepare(currentPage: Page){
    return {
        payload: {
          currentPage: currentPage
        },
      }
});
