import {  Box,  List, ListItem, Typography, useTheme } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { useEffect } from "react";
import { fetchLeaderboard } from "../api/leaderboard_action";
import { FetchLeaderboardModel } from "../../../model/request/fetch_leaderboard_model";
import { LeaderboardModel } from "../../../model/response/leaderboard_model";
import InfiniteScroll from "react-infinite-scroll-component";
import { DocumentData } from "firebase/firestore";
import { LeaderboardUser } from "../components/leaderboard_user";
import { LeaderboardTop } from "../components/leaderboard_top";
import { useTranslation } from "react-i18next";


const LeaderboardPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const leaderboad: LeaderboardModel[] = useSelector((state: RootState) => state.leaderboard.leaderboard);
    const scrollLeaderboard: boolean = useSelector((state: RootState) => state.leaderboard.scrollLeaderboard);
    const lastSeenUser: DocumentData | undefined = useSelector((state: RootState) => state.leaderboard.lastSeenUser);
    const theme = useTheme();
    const {t} = useTranslation();
    useEffect(
        () => {

            if (user && leaderboad.length === 0) {
                const data: FetchLeaderboardModel = {
                    organizationId: user.organizationId,
                    size: 20
                }
                dispatch(fetchLeaderboard(data));
            }

        }, [user]
    );

    const fetch = () => {
        if (user !== undefined) {
            const data: FetchLeaderboardModel = {
                organizationId: user.organizationId,
                size: 20,
                lastElement: lastSeenUser,
            }
            dispatch(fetchLeaderboard(data));
        }

    }


    return (
        
            <Box 
            id={'laderboardScrollable'}
            alignContent={'center'}
            display={'flex'} flexDirection={'column'} 
            height={'70vh'}
            width={500}
            p={1}
            sx={{
                
                overflowY: "scroll",
                '&::-webkit-scrollbar': {
                    display: 'none', // Customize the width of the scrollbar
                }
            }}>
                <Box display={'flex'} flexDirection='row' justifyContent={'space-between'} padding={2} alignItems={'center'} >
                    <Typography variant='h5' fontWeight={'600'} color={theme.palette.textColor?.main}>{t('leaderboardPopOver.title')}</Typography>
                </Box>
                <InfiniteScroll

                    dataLength={leaderboad.length}
                    next={fetch}
                    hasMore={scrollLeaderboard}
                    scrollableTarget={'laderboardScrollable'}
                    loader={<></>}
                >
                
                        {
                            leaderboad.map((doc, index) =>
                                <ListItem onClick={
                                    () => { }}>
                                    <LeaderboardUser {...doc} position={index + 1} />
                                </ListItem>)
                        }
                    
                </InfiniteScroll>
            </Box>
    )
}

export default LeaderboardPage;