import { CardType } from "./card_model";

export enum FileType{
    IMAGE=0,
    DOCUMENT = 1
}
export interface AttachmentModel {
    name: string;
    type: FileType;
    url: string;
    storageRef: string;
}

export interface ThreadDraftModel {
    id: string;
    userId: string;
    cardTitle: string;
    cardText: string;
    cardRichText: string;
    channelId: string;
    suggestedUsers?: string[];
    isAnonymous: boolean;
    attachments: AttachmentModel[];
    type: CardType;
    createdAt: string;
    updatedAt: string;
}