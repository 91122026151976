import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { SearchModel, SearchSimilarCardsModel, UserSearchModel } from "../../../model/request/search_model";
import { getFunctions, httpsCallable } from "firebase/functions";
import {firebaseApp} from "../../../utils/firebase_config";
import { SearchOptionModel, SearchResponceModel } from "../../../model/response/search_responce_model";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { clearGPTCache, setIsSearchMessage } from "../../gpt/api/gpt_action";
import { CardModel } from "../../../model/response/card_model";
import { AnalyticsEvent, sentSearch } from "../../../model/request/analytics_model";
import { sendEvent } from "../../analitycs/analitycs_action";

export const clearCache = createAction(
    'search/clearCache',
    function prepare () {
        return {
            payload: {
                
            },
          } 
    }
);

export const setSearchQuery = createAction(
    'search/setSearchQuery',
    function prepare (query: string) {
        return {
            payload: {
                query:query
            },
          } 
    }
);

export const setTabIndex = createAction(
    'search/setTabIndex',
    function prepare (index: number) {
        return {
            payload: {
                index:index
            },
          } 
    }
);

export const addOrRemoveFilter = createAction(
    'search/addOrRemoveFilter',
    function prepare (filter: string) {
        return {
            payload: {
                filter:filter
            },
          } 
    }
);
export const autocompleteSearchQuery = createAsyncThunk(
    'search/autocompleteSearchQuery',
    async (data:SearchModel, { rejectWithValue }) => {
        try {
            const functions = getFunctions(firebaseApp,'europe-west1');
            const searchFunction = httpsCallable(functions, 'searchFunction');
            
            const doc = await searchFunction({search:data});
            const searchResults = doc.data as SearchResponceModel;
            const options: SearchOptionModel[] = [];
            
            searchResults.allRankedCards.map( (card) => {
                const option: SearchOptionModel = {
                    id:card.cardId,
                    title: card.cardTitle
                }
                options.push(option);
                
            });
            return {
                options: options
            }
        
        } catch (error) {
            return rejectWithValue(error);
        }
});

export const computeSemanticSearch = createAsyncThunk(
    'search/computeSemanticSearch',
    async (data:SearchModel, { dispatch, rejectWithValue }) => {
        try {
            
            const functions = getFunctions(firebaseApp,'europe-west1');
            const searchFunction = httpsCallable(functions, 'searchFunction');
            const doc = await searchFunction({search:data});
            dispatch(setTabIndex(0));
            dispatch(setSearchQuery(data.query));
            dispatch(clearGPTCache());
            dispatch(setIsSearchMessage(true));
            
            const isError = typeof doc.data === 'string';
            
            if (isError) {
                return rejectWithValue(doc.data);
            }
            
            const searchResults = doc.data as SearchResponceModel;

            const log: AnalyticsEvent = {
                event: {timestamp: new Date().toISOString()},
                eventName: sentSearch
            };

            dispatch(sendEvent(log));
                
            return {
                searchResults
            }
        
        } catch (error:any) {
            return rejectWithValue(error.toString());
        }
});


export const searchSimilarCards = createAsyncThunk(
    'search/searchSimilarCards',
    async (data:SearchSimilarCardsModel, { rejectWithValue }) => {
        try {
            const functions = getFunctions(firebaseApp,'europe-west1');
            const searchFunction = httpsCallable(functions, 'checkSimilarCardsFunction');
            const doc = await searchFunction({card:data});
            const searchResults = doc.data as CardModel[];

            return searchResults;
        
        } catch (error) {
            return rejectWithValue(error);
        }
});

export const searchUsers = createAsyncThunk(
    'search/searchUsers',
    async (data:UserSearchModel, {rejectWithValue}) => {
        try{
            const functions = getFunctions(firebaseApp,'europe-west1');
            const searchUsers = httpsCallable(functions, 'searchUserByFullnameFunction');
            
            const results =  await searchUsers({user:data});
            const users = results.data as UserSuggestedModel[];

            return {
                users:users
            }
            
        }catch(error:any){
            return rejectWithValue(error.toString());
        }
    }
);

