import { Box, Button, List, ListItem, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { CardModel, CardType } from "../../../model/response/card_model";
import InfiniteScroll from "react-infinite-scroll-component";
import { CardTile } from "../../../components/card-tile/card-tile";
import { ChannelModel } from "../../../model/response/channel_model";
import {  collection, getFirestore, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { setCardType, setChannel } from "../../card_creation/api/card_creation_action";
import { SkeletonCardTiles } from "../../../components/skeleton-card/skeleton_card";
import { fetchAllDiscussions, fetchDiscussionsForYou, loadMoreDiscussions } from "../api/channel_discussion_action";
import { DiscussionToggle } from "../../../components/discussion-toggle/discussion_toggle";
import { EmptyComponent } from "../../../components/empty-component/empty_component";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../../components/action-button/action_button";

const ChannelDiscussionPage = () => {


    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const selectedChannel: ChannelModel | null = useSelector((state: RootState) => state.channel.currentChannel);
    
    
    const discussions: CardModel[] = useSelector((state: RootState) => state.channelDiscussion.discussionsForYou);
    const currentLimit: number = useSelector((state: RootState) => state.channelDiscussion.limit);
    const hasMore: boolean = useSelector((state: RootState) => state.channelDiscussion.hasMoreDiscussions);
    const discussionsForYouAreLoading: boolean = useSelector((state: RootState) => state.channelDiscussion.discussionsForYouAreLoading);

    const allDiscussions: CardModel[] = useSelector((state: RootState) => state.channelDiscussion.allChannelDiscussions);
    const hasMoreAllDiscussions: boolean = useSelector((state: RootState) => state.channelDiscussion.hasMoreAllDiscussions);
    const pageIndex: number = useSelector((state: RootState) => state.channelDiscussion.pageIndex);
    const allDiscussionAreLoading:boolean = useSelector((state: RootState) => state.channelDiscussion.allDiscussionAreLoading);
    
    const [tab, setTab] = useState(0);
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const {t} = useTranslation();

    const handleTabChange = (event: React.SyntheticEvent, tabNumber: number) => {
        setTab(tabNumber);
    };



    const fetch = () => dispatch(loadMoreDiscussions(currentLimit));

    const fetchDiscussions = () => dispatch(fetchAllDiscussions({
        organizationId: user?.organizationId || '',
        channelId: selectedChannel?.channelId || '',
        pageSize: 20,
        pageIndex: pageIndex,
        isClosed: false,
    }));

    const channelName = selectedChannel?.channelName || '';
    useEffect(
        () => {
           
            if (user != undefined && selectedChannel !== null) {
                if (tab === 0) {
                    const db = getFirestore();
                    const q = query(
                        collection(db, 'Users', user.id, 'Threads'),
                        where('type', '==', CardType.discussion),
                        where('channel.channelId', '==', selectedChannel.channelId),
                        where('isClosed', '==', isClosed),
                        orderBy('createdAt', 'desc'),
                        limit(currentLimit)
                    );

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        const discussionsForU: CardModel[] = querySnapshot.docs.map(doc => doc.data() as CardModel);
                        dispatch(fetchDiscussionsForYou(discussionsForU));
                    });

                    return () => {
                        unsubscribe();
                    };

                } else {
                    
                    if(allDiscussions.length === 0)
                        dispatch(fetchAllDiscussions({
                            organizationId: user.organizationId,
                            channelId: selectedChannel.channelId,
                            pageSize: 20,
                            pageIndex: pageIndex,
                            isClosed: false,
                        }));
                    
                }

            }
        }, [user, selectedChannel, tab, currentLimit,isClosed]
    );

    const remainingHeight = 200;

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            flexGrow={1}
            sx={{ backgroundColor: '#F7F9FC' }}>
            <Box
                display={'flex'}
                width={'60vw'}
                flexDirection={'column'}
                alignItems={'center'}>
                <Box
                    marginTop={2}
                    marginBottom={4}
                    display={'flex'}
                    width='100%'
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Typography variant='h5'>
                        {t('channelDiscussionPage.title',{channelName:channelName })}
                    </Typography>
                    <ActionButton 
                    isLoading={false} 
                    disabled={false} title={t('channelDiscussionPage.actionButton')}
                    onClick={() => {
                        dispatch(setCardType(CardType.discussion));
                        if (selectedChannel){
                            dispatch(setChannel(selectedChannel))
                            dispatch(changeDialog({ dialog: DialogType.createDiscussionDialog }))
                        }       
                    }}                    
                    />
                </Box>
                <Tabs value={tab} onChange={handleTabChange} centered sx={{ width: '100%', marginBottom: 2 }}
                >
                    <Tab label={t('channelDiscussionPage.discussionForYouTab')} sx={{ textTransform: 'none', flexGrow: '1' }} />
                    <Tab label={t('channelDiscussionPage.allDiscussionTab')}sx={{ textTransform: 'none', flexGrow: '1' }} />

                </Tabs>
            </Box>
            <Box
                id={'feedScrollable'}
                display={'flex'}
                flexDirection={'column'}
                height={`calc(100vh - ${remainingHeight}px)`}
                sx={{
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {
                        display: 'none', // Customize the width of the scrollbar
                    }
                }}>
                {
                    tab === 0 &&
                    <InfiniteScroll
                        dataLength={discussions.length}
                        next={fetch}
                        hasMore={hasMore}
                        scrollableTarget={'feedScrollable'}
                        loader={<SkeletonCardTiles/>}
                    >
                        <List sx={{
                            width: 'inherit',
                            "&:hover": {
                                cursor: "pointer",
                            }
                        }}>
                            <ListItem>
                                <DiscussionToggle isClosed={isClosed}
                                    onActiveClick={function (): void {
                                    setIsClosed(false)
                                    }}
                                    onClosedClick={function (): void {
                                    setIsClosed(true)
                                    }} />
                                </ListItem>

                            {
                                discussions.map((feed) => {
                                    return (
                                        <ListItem key={feed.id}>
                                            <CardTile isSearchedCard={false} {...feed} stretch={false} />
                                        </ListItem>

                                    )
                                })
                            }
                        </List>
                    </InfiniteScroll>
                }
                {
                    tab === 0 && !discussionsForYouAreLoading &&  discussions.length == 0 
                     && <EmptyComponent title={t('channelDiscussionPage.emptyCaption')}
                     subtitle={isClosed
                        ? t('channelDiscussionPage.emptyDescriptionCloseDiscussion')
                        :t('channelDiscussionPage.emptyDescriptionActiveDiscussion')
                        }/>
                }
                {
                    tab === 0 && discussionsForYouAreLoading &&  <SkeletonCardTiles/>
                }
                {
                    tab === 1 && allDiscussionAreLoading && <SkeletonCardTiles/>
                }
                {
                    tab === 1 && !allDiscussionAreLoading &&
                    <InfiniteScroll
                        dataLength={allDiscussions.length}
                        next={fetchDiscussions}
                        hasMore={hasMoreAllDiscussions}
                        scrollableTarget={'feedScrollable'}
                        loader={<SkeletonCardTiles/>}
                    >
                        <List sx={{
                            width: 'inherit',
                            "&:hover": {
                                cursor: "pointer",
                            }
                        }}>
                            {
                                allDiscussions.map((feed) => {
                                    return (
                                        <ListItem key={feed.cardId}>
                                            <CardTile isSearchedCard={true} stretch={false} {...feed} />
                                        </ListItem>

                                    )
                                })
                            }
                        </List>
                    </InfiniteScroll>
                }
                {
                    tab === 1 && !allDiscussionAreLoading &&  allDiscussions.length == 0 
                     && <EmptyComponent 
                     title={t('channelDiscussionPage.emptyCaptionAllChannel')} 
                     subtitle={isClosed?
                        t('channelDiscussionPage.emptyDescriptionActiveDiscussionAllChannel')
                        :
                        t('channelDiscussionPage.emptyDescriptionCloseDiscussionAllChannel')
                    }
                     />
                }
                
                
            </Box>
        </Box>
    );
}

export default ChannelDiscussionPage;


