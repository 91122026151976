import * as React from 'react';
import { Typography, Avatar, Stack, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ChatOptions } from './channel_chat_options';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { deleteMessage, selectChannelMessage } from '../api/channel_chat_action';
import './channel_chat_bubble.css';
import { CardImages } from '../../../components/card-images/card_images';
import { ChannelMessageModel, ResponseToMessageModel } from '../../../model/response/channel_message_model';
import ImageIcon from '@mui/icons-material/Image';
import { Reactions } from './channel_chat_reactions';
import { changeDialog } from '../../dialog/dialog_action';
import { DialogDeleteAction, DialogType } from '../../dialog/dialog_state';
import { useTranslation } from 'react-i18next';
import { ChannelChatBubbleEditor } from './channel_chat_bubble_editor';
import { Attachment } from '../../../model/response/card_model';
const UserName = styled(Typography)({
  fontWeight: 'bold',
  '&:hover': { textDecoration: 'underline', cursor: 'pointer' }
});


const MessageTimestamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  marginLeft: 'auto',
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
}));

const ResponceUserAvatar = styled(Avatar)(({ theme }) => ({
  width: 15,
  height: 15,
}));


export interface ChannelChatBubbleProps {
  isOwner: boolean;
  isAnswering: boolean;
  isEditing: boolean;
  isOptimistic: boolean;
  id: string;
  writerId: string;
  index: number;
  attachments: Attachment[];
  author: string;
  photo: string;
  timestamp: string;
  isLeading: boolean;
  reactions: {
    [key: string]: string[];
  }
  text: string;
  plainText: string;
  messageToResponce?: ResponseToMessageModel;
}

export const ChannelChatBubble: React.FC<ChannelChatBubbleProps> = (props: ChannelChatBubbleProps) => {

  const user = useSelector((state: RootState) => state.auth.user);
  const selectedChannel = useSelector((state: RootState) => state.channel.currentChannel);
  const {t} = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [displayOptions, setDisplayOptions] = React.useState(false);
  function convertIsoToHoursMinutes(isoString: string): string {
    const date = new Date(isoString);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  const buildResponce = () => {
    if (props.messageToResponce) {
      return (
        <Box display={'flex'} flexDirection={'row'}>
          <Stack direction={'row'} justifyItems={'center'} >
            <Box sx={{
              marginLeft: '26px',
              marginTop: '10px',
              marginRight: '12px',
              width: 25,
              height: 10,
              position: 'relative',
              borderTopLeftRadius: '5px',
              borderLeft: '1px solid', // Adjust the left border style
              borderTop: '1px solid', // Adjust the top border style
              borderColor: theme.palette.textColor?.main,
            }} />
            <Stack direction={'row'}  spacing={'5px'} alignItems={'center'}>
              <ResponceUserAvatar src={props.messageToResponce.writerPhoto} />
              <Typography textOverflow='ellipsis' variant='caption' color={theme.palette.textColor?.main} fontWeight={600}>
                {props.messageToResponce.writerDisplayName}
              </Typography>
              <Typography
                maxWidth={'calc(100vw - 600px)'}
                overflow={'hidden'}
                whiteSpace={'nowrap'}
              textOverflow='ellipsis' variant='caption' color={theme.palette.textColor?.main}>
                {props.messageToResponce.text}
              </Typography>

              {props.messageToResponce.hasAttachments &&
                <Box color={theme.palette.textColor?.main}>
                  <ImageIcon fontSize={'small'} />
                </Box>
              }
            </Stack>
          </Stack>
        </Box>
      );

    } else {
      return (<></>);
    }
  }

  const onMouseEnter = () => {
    setDisplayOptions(true);
    const message: ChannelMessageModel = {
      writerId: props.writerId,
      writerDisplayName: props.author,
      writerPhoto: props.photo,
      rtdbMessageId: '',
      updatedAt: props.timestamp,
      createdAt: props.timestamp,
      id: props.id,
      reactions: props.reactions,
      text: props.text,
      isDraft: false,
      plainText: props.plainText,
      attachments: props.attachments,
    }
    dispatch(selectChannelMessage(message));
  }

  const onMouseLeave = () => {
    setDisplayOptions(false);
    dispatch(selectChannelMessage(undefined));
  }
  const handleDelete = () => {
    if (user && selectedChannel) {
      dispatch(changeDialog(
        {
          dialog: DialogType.deleteDialog,
          deleteAction: DialogDeleteAction.deleteChatMessage,
          messageId: props.id,
        }
      ))
    }
  }


  const isEditingStyle = {
    borderRadius: '5px',
    padding: '5px',
    backgroundColor: 'white',
  }


  return (
    <Stack direction='column' position={'relative'}>
      {buildResponce()}
      <Stack paddingLeft={1}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          backgroundColor: (displayOptions || props.isAnswering) ? '#F0F2F5' : null
        }}
        direction="row" spacing={2} paddingTop={props.isLeading ? 0.5 : 0}>
        {
          props.isLeading ?
            <Box onClick={() => dispatch(changeDialog({
              dialog: DialogType.expertDialog,
              expertId: props.writerId
            }))}>
              <UserAvatar src={props.photo} sx={{ "&:hover": { cursor: 'pointer' } }} />
            </Box>
            : <Box width={40}></Box>

        }

        <Stack spacing={0}>
          {
            props.isLeading &&
            <Stack direction="row" alignItems="center" spacing={2}>
              <UserName onClick={() => dispatch(changeDialog({
                dialog: DialogType.expertDialog,
                expertId: props.writerId
              }))} variant="subtitle2">{props.author}</UserName>
              <MessageTimestamp>{convertIsoToHoursMinutes(props.timestamp)}</MessageTimestamp>
            </Stack>
          }
          <Box maxWidth={'calc(100vw - 500px)'} sx={props.isEditing ? isEditingStyle : undefined}>
            <ChannelChatBubbleEditor 
            text={props.text} 
            index={props.index} 
            isEditing={props.isEditing}
            isOptimistic={props.isOptimistic}/>
          </Box>
          {props.isEditing && <Typography variant='caption' color={theme.palette.textColor?.main}>{t('channelChatPage.modifyMessageHelperText')}</Typography>}
          <Reactions reactions={props.reactions} />
          <CardImages images={props.attachments} isDeleteable={false} />

        </Stack>
      </Stack>
      {(displayOptions && !props.isEditing) && <ChatOptions
        isOwner={props.isOwner}
        onHover={onMouseEnter}
        onLeave={onMouseLeave}
        onDelete={handleDelete}
      />}
    </Stack>
  );
};

