import { useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import {  loadThread, setCardId, } from "../api/thread_action";
import { RetrieveThreadModel } from "../../../model/request/retrieve_thread_model";
import { AnswerModel } from '../../../model/response/answer_model';
import { CardModel } from "../../../model/response/card_model";
import { UserModel } from "../../../model/response/user_model";
import './thread_page.css';
import { NotFound } from "../components/not_found";
import { ThreadComponent } from '../components/thread_component';
import { openChannelDrawer } from "../../navigation/navigation_action";

const ThreadPage = () => {

    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const card: CardModel | undefined = useSelector((state: RootState) => state.thread.card);
    const currentURL = window.location.pathname;

    const drawerStatus: boolean = useSelector((state: RootState) => state.navigation.drawerIsOpen)
    const threadIsLoading: boolean = useSelector((state: RootState) => state.thread.threadIsLoading);

    const dispatch = useDispatch<AppDispatch>();


    useEffect(() => {
        if (user) {
            const parts = currentURL.split('/'); 
            const stringVariable = parts[parts.length - 1];

            var currentThread:string | undefined = undefined;
            var currentCard:string | undefined = undefined;

            if (stringVariable.includes('thread=')) {
                const threadUrl = stringVariable.split('=');
                currentThread = threadUrl[threadUrl.length - 1];
            }

            if (stringVariable.includes('card=')) {
                const threadUrl = stringVariable.split('=');
                currentCard = threadUrl[threadUrl.length - 1];
            }

            const data: RetrieveThreadModel = {
                cardId: currentCard || '',
                threadId: currentThread,
                userId: user.id
            }
            const fetchData = async () => {
                await dispatch(loadThread(data));
            };

            fetchData();
            return () => {
                dispatch(setCardId(''));
            }
        }

    }, [user, currentURL]);


    if (card !== undefined && !threadIsLoading) {
        return (
            <ThreadComponent card={card}/>
        );
    } else if (threadIsLoading) {
        return (
            <></>
        );
    } else {
        return (
            <NotFound />
        );
    }
}

export default ThreadPage;
