import { Box, Card, Stack } from "@mui/material";
import { AnswerProps, Answer } from "../../../components/answer/answer";
import { CardThread } from "../../../components/card/card";
import { ErrorComponent, ErrorType } from "../../../components/error-component/error_component";
import { VotingModel, VotingType } from "../../../model/request/voting_model";
import { addVote } from "../api/thread_action";
import { AnswerEditor } from "./answer_editor";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { AnswerModel } from "../../../model/response/answer_model";
import { CardModel } from "../../../model/response/card_model";
import { UserModel } from "../../../model/response/user_model";

export interface ThreadComponentProps{
    card: CardModel;
}

export const ThreadComponent: React.FC<ThreadComponentProps> = (props: ThreadComponentProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const remainingHeight = 120;

    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);

    const answers: AnswerModel[]  = useSelector((state: RootState) => state.thread.answers);
    

    return(
        <Box position={'relative'} display={'flex'} justifyContent={'center'} flexGrow={1} sx={{ backgroundColor: '#F7F9FC' }} >
                <Card
                    sx={{
                        
                        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                        width: '62vw',
                        height: `calc(100vh - ${remainingHeight}px)`,
                        borderRadius: 5,
                        marginTop: 2,
                        padding: 2,
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box overflow='scroll' >
                        <Stack>
                            <CardThread  {...props.card} />
                            {
                                !props.card.isClosed && answers.length == 0 &&
                                <ErrorComponent caption='Be the first to contribute!' image={ErrorType.comment} width={'25%'} height={'25%'} />
                            }
                            {answers.map(
                                (answer, index) => {

                                    const answerInput: AnswerProps = {
                                        ...answer,
                                        answerIndex: index,
                                        comments: answer.comments ? answer.comments : [],
                                        onDownvoteClick: () => {
                                            if (user) {
                                                const vote: VotingModel = {
                                                    answerId: answer.id,
                                                    answerIndex: index,
                                                    type: VotingType.downVote,
                                                    userId: user?.id,
                                                    cardId: props.card.cardId
                                                };
                                                if (user.id !== answer.responderUser.responderId)
                                                    dispatch(addVote(vote));
                                            }
                                        },
                                        onUpvoteClick: () => {
                                            if (user) {
                                                const vote: VotingModel = {
                                                    answerId: answer.id,
                                                    answerIndex: index,
                                                    type: VotingType.upVote,
                                                    userId: user?.id,
                                                    cardId: props.card.cardId
                                                };
                                                if (user.id !== answer.responderUser.responderId)
                                                    dispatch(addVote(vote));
                                            }
                                        },
                                        discussionIsClosed: props.card.isClosed
                                    }
                                    return <Answer key={answer.id} {...answerInput} />
                                }
                            )}
                            {
                                !props.card.isClosed &&
                                <AnswerEditor channelId={props.card.channel.channelId} cardId={props.card.cardId}/>
                            }
                        </Stack>
                    </Box>
                </Card>
            </Box>
    );
}