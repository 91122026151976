import { Stack } from "@mui/material";
import { GPTBody } from "../components/gpt_body";
import { GPTChats } from "../components/gpt_chats";

const GPTPage = () => {
    return (
        <>
        <Stack direction="row"  sx={{ width: "100%",height:'100%' }}>
            <GPTChats/>
            <GPTBody/>
        </Stack>
        </>
    )
}


export default GPTPage;