import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ChannelMessageModel } from "../../../model/response/channel_message_model";
import { collection, deleteDoc, doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { AttachmentModel } from "../../../model/response/thread_draft_model";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { Attachment } from "../../../model/response/card_model";
import { debugPrint } from "../../../utils/debug_print";
import { ChannelChatAction } from "./channel_chat_state";
import { DialogType } from "../../dialog/dialog_state";
import { changeDialog } from "../../dialog/dialog_action";

export const fetchChannelChatMessages = createAction(
    'channelChat/fetchChannelChatMessages',
    function prepare(messages: ChannelMessageModel[]) {
        return {
            payload: {
                messages: messages,
            }
        }
    }
);

export const changeChannelChatAction = createAction(
    'channelChat/changeChannelChatAction',
    function prepare(action: ChannelChatAction) {
        return {
            payload: {
                action: action,
            }
        }
    }
);

export const pullToRefreshMessages = createAction(
    'channelChat/pullToRefreshMessages',
    function prepare(scrollHeight: number) {
        return {
            payload: scrollHeight
        }
    }
);

export const updateChatNotificationCounter = createAsyncThunk(
    'channelChat/updateChatNotificationCounter',
    async (data: {
        userId: string;
        channelId: string;
    }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const documentReference = doc(db, 'Users', data.userId, 'ChannelSubscriptions', data.channelId);
            await updateDoc(documentReference, {
                chatBalance: {
                    hasNotifications: false,
                    notificationCounter: 0,
                }

            });

        } catch (error: any) {
            rejectWithValue(error.toString());
        }
    });

export const selectChannelMessage = createAction(
    'channelChat/selectChannelMessage',
    function prepare(message: ChannelMessageModel | undefined) {
        return {
            payload: {
                message: message,
            }
        }
    }

);

export const selectMessageToAnswer = createAction(
    'channelChat/selectMessageToAnswer',
    function prepare(message: ChannelMessageModel | undefined) {
        return {
            payload: {
                message: message,
            }
        }
    }
);

export const selectMessageToEdit = createAction(
    'channelChat/selectMessageToEdit',
    function prepare(message: ChannelMessageModel | undefined) {
        return {
            payload: {
                message: message,
            }
        }
    }
);

export const sendReaction = createAsyncThunk(
    'channelChat/sendReaction',
    async (data: {
        messageId: string;
        reactions:{
            [key: string]: string[];
        };
        organizationId: string;
        channelId: string;
        channelChatId: string;
    }, { dispatch,rejectWithValue }) => {
        try {
            const db = getFirestore();
            const messageRef = doc(db, 'Organizations', data.organizationId, 'Channels', data.channelId, 'ChannelChats', data.channelChatId, 'ChannelMessages', data.messageId);
            dispatch(changeChannelChatAction(ChannelChatAction.react))
            await updateDoc(messageRef, {
                reactions: data.reactions
            });

        } catch (error: any) {
            debugPrint(error.toString(), 'error in send reaction action');
            rejectWithValue(error.toString());
        }
    }
);

export const deleteMessage = createAsyncThunk(
    'channelChat/deleteMessage',
    async (data: {
        messageId: string;
        organizationId: string;
        channelId: string;
        channelChatId: string;
    }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(changeDialog({dialog: DialogType.none}))
            const db = getFirestore();
            dispatch(changeChannelChatAction(ChannelChatAction.delete));
            const messageRef = doc(db, 'Organizations', data.organizationId, 'Channels', data.channelId, 'ChannelChats', data.channelChatId, 'ChannelMessages', data.messageId);
            await deleteDoc(messageRef);
            

        } catch (error: any) {
            console.log(error.toString(), 'error in delete message action');
            rejectWithValue(error.toString());
        }
    });

export const uploadAttachment = createAction(
    'channelChat/uploadAttachment',
    function prepare(attachments: Attachment[]) {
        return {
            payload: {
                attachments: attachments,
            }
        }
    }
);

export const clearChannelChatCache = createAction(
    'channelChat/clearChannelChatCache',
    function prepare() {
        return {
            payload: {}
        }
    });

export const removeAttachment = createAction(
    'channelChat/removeAttachment',
    function prepare(attachment: AttachmentModel) {
        return {
            payload: {
                attachment: attachment,
            }
        }
    }
);

export const sendChatMessageOptimisticUpdate = createAction(
    'channelChat/sendChatMessageOptimisticUpdate',
    function prepare(data: {
        message: ChannelMessageModel;
    }) {
        return {
            payload: {
                message: data.message,
            }
        }
    }
);

export const sendChatMessage = createAsyncThunk(
    'channelChat/sendChatMessage',
    async (data: {
        attachments: AttachmentModel[];
        message: ChannelMessageModel;
        messageToAnswer: ChannelMessageModel | undefined;
        organizationId: string;
        channelId: string;
        channelChatId: string;
    }, { dispatch, rejectWithValue }) => {
        try {
            const db = getFirestore();
            const storage = getStorage();
            const messageRef = doc(collection(db, 'Organizations', data.organizationId, 'Channels', data.channelId, 'ChannelChats', data.channelChatId, 'ChannelMessages'));
            const date = new Date().toISOString();
            dispatch(changeChannelChatAction(ChannelChatAction.none));

            const optimisticMessage: ChannelMessageModel = {
                ...data.message,
                ...(data.messageToAnswer ? {
                    responseToMessage: {
                        writerDisplayName: data.messageToAnswer.writerDisplayName,
                        messageId: data.messageToAnswer.id,
                        writerId: data.messageToAnswer.writerId,
                        text: data.messageToAnswer.plainText,
                        writerPhoto: data.messageToAnswer.writerPhoto,
                        hasAttachments: data.messageToAnswer.attachments.length > 0,
                    }
                } : {}),
                attachments: data.attachments.map((attachment) => {
                    return {
                        name: attachment.name,
                        url: attachment.url,
                        storageRef: attachment.storageRef,
                        type: attachment.type,
                        createdAt: date,
                        updatedAt: date
                    }
                }),
                id: 'optimistic'
            }

            dispatch(sendChatMessageOptimisticUpdate({ message: optimisticMessage }));
            const attachments: Attachment[] = await Promise.all(
                data.attachments.map(async (attachment) => {
                    const storageRefString = 'organizations/' + data.organizationId + '/channels/' + data.channelId +'/channelchats/'  + data.channelChatId + '/messages/' +  messageRef.id + '/' + attachment.name;
                    const storageRef = ref(storage, storageRefString);
                    await uploadString(storageRef, attachment.url, 'data_url');
                    const filePath = await getDownloadURL(storageRef);

                    const currentAttachment: Attachment = {
                        name: attachment.name,
                        url: filePath,
                        storageRef: storageRefString,
                        type: attachment.type,
                        createdAt: date,
                        updatedAt: date
                    }
                    return currentAttachment;
                })
            );


            const message: ChannelMessageModel = {
                ...data.message,
                ...(data.messageToAnswer ? {
                    responseToMessage: {
                        writerDisplayName: data.messageToAnswer.writerDisplayName,
                        messageId: data.messageToAnswer.id,
                        writerId: data.messageToAnswer.writerId,
                        text: data.messageToAnswer.plainText,
                        writerPhoto: data.messageToAnswer.writerPhoto,
                        hasAttachments: data.messageToAnswer.attachments.length > 0,
                    }
                } : {}),
                attachments: attachments,
                id: messageRef.id
            }

            await setDoc(messageRef, message);
            dispatch(selectMessageToAnswer(undefined));

        } catch (error: any) {
            debugPrint(error.toString(), 'error in send message action');
            rejectWithValue(error.toString());
        }

    });

export const editMessage = createAsyncThunk(
    'channelChat/editMessage',
    async (data: {
        messageId: string;
        plainText: string;
        text: string;
        organizationId: string;
        channelId: string;
        channelChatId: string;
    }, { rejectWithValue, dispatch }) => {
        try {
            const db = getFirestore();
            const messageRef = doc(db, 'Organizations', data.organizationId, 'Channels', data.channelId, 'ChannelChats', data.channelChatId, 'ChannelMessages', data.messageId);
            dispatch(changeChannelChatAction(ChannelChatAction.edit));
            await updateDoc(messageRef, {
                plainText: data.plainText,
                text: data.text,
                updatedAt: new Date().toISOString()
            });

            dispatch(selectMessageToEdit(undefined));



        } catch (error: any) {
            debugPrint(error.toString(), 'error in edit message action');
            rejectWithValue(error.toString());
        }
    });