import { createSlice } from "@reduxjs/toolkit";
import { FeedState, SortType } from "./feed_state";
import { fetchFeed, filterByChannel, loadMore, sortByCondition } from "./feed_action";

const initialState:FeedState ={
    feed: [],
    limit: 5,
    hasMore: true,
    feedIsLoading: true,
    filterByChannels: [],
    firstChannelSelected: undefined,
    sortBy: SortType.mostRecent
}

const feedSlice = createSlice({
    name: 'feed',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchFeed, (state, payload) => {
            state.feed =  payload.payload.feed;
            state.feedIsLoading = false;
            if (state.feed.length < state.limit){
                state.hasMore = false;
            }
        })
        .addCase(loadMore, (state, payload) => {
            state.limit = state.limit + payload.payload.limit;
        })
        .addCase(filterByChannel,  (state, action) => {
            const currentFilters = state.filterByChannels;

            if(currentFilters.length === 0){
                state.firstChannelSelected = action.payload.channelName
            }

            if(currentFilters.includes(action.payload.channelId)){
                const filteredChannel = currentFilters.filter((channel) => channel != action.payload.channelId);

                if(filteredChannel.length === 0){
                    state.firstChannelSelected = undefined;
                }
                
                state.filterByChannels = filteredChannel;
            }else{
                currentFilters.push(action.payload.channelId);
                state.filterByChannels = currentFilters;
            }
        })
        .addCase(sortByCondition, (state, action) => {
            state.sortBy = action.payload.sortType;
        })
    }
});

export default feedSlice.reducer;