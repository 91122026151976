import { createSlice } from "@reduxjs/toolkit";
import { ChatState } from "./chat_state";
import { clearCharCache, clearSearchDirectMessages, fetchChats, fetchCurrentChat, searchChat, searchUserToChat, selectCurrentChat, selectDocumentToSend, sendChatMessage, sendMessageOptimisticUpdate, updateMessageText, updateNotification } from "./chat_action";
const initialState: ChatState = {
    chats:[],
    messages:[],

    searchedUsers: [],    
    searchedUserAreLoading: false,

    currentChat: null,
    
    query:'',
    messageText:'',
    isFirstMessage:false,
    isSending:false,
    fileIsSelected:false,
    filePath:''
}
const chatSlice = createSlice({
    name: 'chat',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchChats,(state, payload) => {
            state.chats = payload.payload.chats;
        })
        .addCase(selectCurrentChat.fulfilled,(state, payload) => {
            state.currentChat = payload.payload.selectedChat;
            state.messages = [];

            if( payload.payload.selectedChat.id === ''){
                state.isFirstMessage = true;    
            }
           
        })
        .addCase(fetchCurrentChat, (state, payload) => {
            state.messages = payload.payload.messages;
        })
        .addCase(searchChat,(state, payload) => {
            state.query = payload.payload.query;
        })
        .addCase(clearCharCache, (state, payload) => {
            
            if (payload.payload.clear){
                state.currentChat = null;
                state.chats=[];
                state.messages=[];
                state.query='';
                state.messageText='';
                state.fileIsSelected=false;
                state.filePath='';
            }
        })
        .addCase(updateMessageText, (state, payload) => {
            state.messageText = payload.payload.message;
        })
        .addCase(sendMessageOptimisticUpdate, (state, payload) => {
            state.messages.push(payload.payload.message);
        })
        .addCase(sendChatMessage.fulfilled, (state, payload) => {
            state.currentChat = payload.payload.chat;
            state.isSending = false;
            state.isFirstMessage = false;
        })
        .addCase(sendChatMessage.pending, (state, payload) => {
            state.isSending = true;
        })
        .addCase(sendChatMessage.rejected, (state, payload) => {
            state.isSending = false;
        })
        .addCase(clearSearchDirectMessages,(state, payload) => {
            state.searchedUsers = []
        })
        .addCase(searchUserToChat.fulfilled, (state, payload) => {
            state.searchedUsers = payload.payload.users;
            state.searchedUserAreLoading = false;
        })
        .addCase(searchUserToChat.pending, (state) => {
            state.searchedUserAreLoading = true;
        })
        .addCase(searchUserToChat.rejected, (state) => {
            state.searchedUserAreLoading = false;
        })
        .addCase(selectDocumentToSend, (state, payload) => {
            state.fileIsSelected = payload.payload.fileIsSelected;
            state.filePath = payload.payload.filePath;
        })
        .addCase(updateNotification.fulfilled,(state, payload) => { 
        })
    }
});

export default chatSlice.reducer;