export enum ChannelMembersType {
    ADMIN = 0,
    MEMBER = 1,
}

export interface ChannelMemberModel{
    id: string;
    userId: string;
    memberDisplayName: string;
    memberPhoto: string;
    memberBio: string;
    role: ChannelMembersType;
    totalPointsEarned: number;
    createdAt: string;

}