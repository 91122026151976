import { Box, LinearProgress, Typography, styled, useTheme } from "@mui/material";

export interface ExpertiseProps{
    userRelativeScore: number;
    tagName: string;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 10,
    width: '85%',
    '& .MuiLinearProgress-bar': {
        borderRadius: 10,
        background: theme.palette.sliderBarColor!.main, // Customize the gradient colors
      },
  }));

export const Expertise:React.FC<ExpertiseProps> = (props: ExpertiseProps) =>{ 
    const theme = useTheme();
    
    return (
        <Box width={'100%'} >
            <Typography color={theme.palette.sliderBarColor!.main} >{props.tagName}</Typography>
            <Box display={'flex'} alignItems={'center'} justifyContent='space-between'>
                <BorderLinearProgress sx={{ width: '85%', color:'red' }} value={props.userRelativeScore*100} variant="determinate" />
                <Typography color={theme.palette.sliderBarColor!.main}>{(props.userRelativeScore*100).toFixed() + '%'}</Typography>
            </Box>     
        </Box>
    );
}