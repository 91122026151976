import './comment-editor.css';
import React, { useState } from 'react';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import IconButton from '@mui/material/IconButton';
import { SendCommentModel } from '../../model/request/send_comment_model';
import { UserModel } from '../../model/response/user_model';
import { useDispatch, useSelector } from 'react-redux';
import { CardModel } from '../../model/response/card_model';
import { AnswerModel } from '../../model/response/answer_model';
import { AppDispatch, RootState } from '../../app/store';
import { displayOptimisticComment, sendComment } from '../../features/thread/api/thread_action';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Stack } from '@mui/material';

export interface CommentEditorProps {
    className?: string;
    answerIndex?:number;
    responderPhoto: string;
}

export const CommentEditor: React.FC<CommentEditorProps> = (props: CommentEditorProps) => {
    

    const [commentText, setCommentText] = useState('');
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const card: CardModel | undefined = useSelector((state: RootState) => state.thread.card);
    const answers: AnswerModel[] | undefined = useSelector((state: RootState) => state.thread.answers);
    
    const dispatch = useDispatch<AppDispatch>();
    const {t,i18n} = useTranslation();
    
    const handleCommentTextChange = () => {
        if (card && user){
            const currentDate = new Date().toISOString();
            const sendCommentModel:SendCommentModel = {
                id: uuidv4(),
                cardId: card.cardId,
                isAnonymous:card.isAnonymous && (user.id === card.senderUser.senderId),
                answerId: props.answerIndex !== undefined?answers[props.answerIndex].id:undefined,
                answerIndex:props.answerIndex,
                plainText: commentText,
                user: {
                    responderId: user.id,
                    responderDisplayName: user.displayName,
                    responderPhoto: user.photo!
                },
                createdAt: currentDate,
                updatedAt: currentDate,
            }
            setCommentText('');
            dispatch(displayOptimisticComment(sendCommentModel));
            dispatch(sendComment(sendCommentModel));
        }
    }
    
    
    return (
        <Stack direction='row' width='100%' spacing={2}>
            <Box>
                <Avatar src={props.responderPhoto}/>
            </Box>
        
            <Box display= 'flex'
            flexGrow={1}
            margin-top= '10px'
            borderRadius= '10px'
            border='solid 1px #dedede'
            padding= '8px'
            alignItems='center'>
                <textarea value={commentText} className="input-container" placeholder={t('threadPage.replyCaption')} onChange={(e) => {setCommentText(e.target.value);}}/>
                <IconButton onClick={handleCommentTextChange}>
                    <SendOutlinedIcon />
                </IconButton>
            </Box>
        </Stack>
    );

}

