import { UserModel } from "../../../model/response/user_model";
import {  useDispatch, useSelector } from "react-redux";
import {  AppDispatch, RootState } from "../../../app/store";
import { Box, CardMedia, Stack, Typography, useTheme } from "@mui/material";
import { AIConversation, ConversationTurn } from "../../../model/response/gpt_conversation";
import { useEffect } from "react";
import { fetchCurrentGPTChat } from "../api/gpt_action";
import { GPTMessage } from "./gpt_message";
import logo from '../../../assets/Logox3.png';

import { useTranslation } from "react-i18next";
export const GPTChatRoom = () => {
    
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const theme = useTheme();
    const messages: ConversationTurn[] = useSelector((state: RootState) => state.gpt.turns);
    const currentConversation: AIConversation | null = useSelector((state: RootState) => state.gpt.currentConversation);
    const {t,i18n} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    useEffect(
        () => {
            if (user !== undefined && currentConversation !== null && currentConversation.id !== ''){
                const data = {
                    userId: user.id,
                    conversationId: currentConversation.id
                };

                dispatch(fetchCurrentGPTChat(data));
            }
        },[user,currentConversation]
    );
 
    
    if (messages.length === 0){
        return (
            <Box display='flex' height='100%'alignItems='center' justifyContent='center' flexDirection='column' >
                    <Stack alignItems='center' justifyContent='center' spacing={2}>
                        <Box width='20%'>
                        <CardMedia
                            component="img"
                            image={logo}
                            sx={{
                                objectFit: 'contain', // Fit the image to the dimensions while maintaining aspect ratio
                            }}
                        />
                        </Box>
                        
                        <Typography variant='h6' color={theme.palette.textColor!.main} sx={{
                            textAlign: 'center'
                        }}>{t('gptPage.gptHelperText')}</Typography>
                    </Stack>
                </Box>
        )
    }else{
        return (
        
            <Stack  height={'vh'} spacing={1} marginTop={1}>
            {   
                messages.map((message,index) => {
                    return (
                        <GPTMessage 
                            userPhoto={user!.photo} 
                            key={index} {...message} 
                            currentIndex={index} 
                            conversationLength={messages.length - 1}/>
                    )
                })
            }
            </Stack>
        )
    }
    
    
}