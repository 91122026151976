import { createSlice } from "@reduxjs/toolkit";
import { UserFollowedThreadState } from "./user_followed_thread_state";
import { fetchFollowedThreads, loadMoreFollowedThreads } from "./user_followed_thread_action";

const initialState:UserFollowedThreadState ={
    userFollowedThreads: [],
    scrollUserFollowedThreads: true,
    userFollowedThreadLimit: 5
}
const userFollowedThreadSlice = createSlice({
    name: 'userFollowedThread',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(fetchFollowedThreads, (state, action) => {
            state.userFollowedThreads =  action.payload.threads;
            if (state.userFollowedThreads.length < state.userFollowedThreadLimit){
                state.scrollUserFollowedThreads = false;
            }
        })
        .addCase(loadMoreFollowedThreads, (state, action) => {
            state.userFollowedThreadLimit = state.userFollowedThreadLimit + action.payload.limit;
        })
    }
});

export default userFollowedThreadSlice.reducer;