import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { changeChannelType } from "../api/create_channel_action";
export interface ChannelTypeButtonProps{
    icon: React.ReactNode;
    title: string;
    description: string;
    color: string;
    onClick?: () => void;
}



export const ChannelTypeButton: React.FC<ChannelTypeButtonProps> = (props: ChannelTypeButtonProps) => {

    const theme = useTheme();

    return (
        <Box p={1}
        onClick={props.onClick}
        borderRadius='5px' 
        sx={{
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
            '&:hover':{cursor:'pointer'}}}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Box color={props.color}>
                    {props.icon}
                </Box>
                <Stack direction='column'>
                    <Typography color={props.color} variant="body1" fontWeight={600}>
                        {props.title}
                    </Typography>
                    <Typography color={theme.palette.textColor?.main} variant="body2">
                        {props.description}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    )
}

export const ChannelType: React.FC = () => {

    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Stack p={2} spacing={2} >
            <Typography color={theme.palette.textColor?.main} variant="h5" fontWeight={600}>
                {t('createChannelPage.channelTypeTitle')}
            </Typography>
            <ChannelTypeButton 
                icon={<PublicOutlinedIcon style={{ fontSize: 40 }}/>} 
                title={t('createChannelPage.publicChannelGroupButton')} 
                description={t('createChannelPage.publicChannelDescription')} 
                color={"#5876FF"} 
                onClick={() => dispatch(changeChannelType(false))}/>
            <ChannelTypeButton 
                icon={<LockOutlinedIcon style={{ fontSize: 40 }}/>} 
                title={t('createChannelPage.privateChannelGroupButton')} 
                description={t('createChannelPage.privateChannelDescription')} 
                color={"#8C1A6A"} 
                onClick={() => dispatch(changeChannelType(true))}/>
        </Stack>
    )
}
