import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GPTMessage, GPTMessageAPI, } from "../../../model/request/GPT_message";
import { AIConversation, ConversationTurn } from "../../../model/response/gpt_conversation";
import {   collection,  doc,  getDocs, getFirestore, orderBy, query, setDoc } from "firebase/firestore";
import { DeleteChatModel } from "../../../model/request/delete_chat";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../../utils/firebase_config";
import { sendEvent } from "../../analitycs/analitycs_action";
import { AnalyticsEvent, sentGptPrompt } from "../../../model/request/analytics_model";

export const updateQuery = createAction(
    'gpt/updateQuery',
    function prepare(query: string,) {
        return {
            payload: {
                query: query
            },
        }

    });

    export const clearGPTCache = createAction(
        'gpt/clearGPTCache',
        function prepare() {
            return {
                payload: {
                },
            }
    
        });

export const setIsSearchMessage = createAction(
        'gpt/setIsSearchMessage',
        function prepare(isSearchMessage: boolean,) {
            return {
                payload: {
                    isSearchMessage: isSearchMessage
                },
            }
    
        });

export const fetchGPTChats = createAction(
    'gpt/fetchGPTChats',
    function prepare(chats: AIConversation[]) {
        return {
            payload: {
                chats: chats
            },
        }
    });

export const fetchCurrentGPTChat = createAsyncThunk(
    'gpt/fetchCurrentGPTChat',
    async (data: {
        userId:string;
        conversationId: string;
    }, { rejectWithValue }) =>  {
       try{
        
        const db = getFirestore();
        const q = query(collection(db, "Users",data.userId, "AIConversations",data.conversationId, "ConversationTurns",),orderBy('createdAt','asc'),);
        const querySnapshot = await getDocs(q);
        
        const messages:ConversationTurn[] = querySnapshot.docs.map(message => {
            const doc:ConversationTurn = message.data() as ConversationTurn;
            const turn:ConversationTurn = {
                ...doc,
                isComplete:true,
            };
            return turn;
        });
        
        return {
            messages:messages
        }
        }catch(error:any){
            return rejectWithValue(error.message);
       }
    });


export const selectCurrentGPTChat = createAction(
    'gpt/selectCurrentGPTChat',
    function prepare(chat: AIConversation | null) {
        return {
            payload: {
                currentChat: chat
            },
        }
    });

export const addGPTResponse = createAction(
    'gpt/addGPTResponse',
    function prepare(turn: ConversationTurn){
        return {
            payload:{
                turn:turn
            }
        }
    }
);

export const updateGPTResponse = createAction(
    'gpt/updateGPTResponse',
    function prepare(turn: ConversationTurn){
        return {
            payload:{
                turn:turn
            }
        }
    }
);

export const deleteGPTChat = createAsyncThunk(
    'gpt/deleteGPTChat',
    async (data:DeleteChatModel, {rejectWithValue}) => {
        try{
            const functions = getFunctions(firebaseApp,'europe-west1');
            const deleteChat = httpsCallable(functions, 'deleteAIConversationForUserFunction');
            
            const results =  await deleteChat({user:data});
        }catch(error:any){
            return rejectWithValue(error.message)
        }
    }
);


export const sendGPTFetchMessage = createAsyncThunk(
    'gpt/sendGPTFetchMessage',
    async (data: GPTMessage , { dispatch, rejectWithValue }) => {
        try {
            const abortController = new AbortController();
            const timeoutDuration = 300000;
            const date = new Date().toISOString();
            var updatedConversationId = data.conversationId;
            
            dispatch(updateQuery(''));
            
            if (data.conversationId=== ''){
                
                const db = getFirestore();
                const query = doc(collection(db,"Users",data.userId, "AIConversations",data.conversationId));
                
                var newConversation: AIConversation  = {
                    id: query.id,
                    title: data.message,
                    reported: false,
                    createdAt: date,
                    updatedAt: date
                }
                if(data.document){
                    newConversation = {
                        id: query.id,
                        title: data.message,
                        document: data.document,
                        reported: false,
                        createdAt: date,
                        updatedAt: date
                    }
                }
                
                await setDoc(query, newConversation);
                updatedConversationId = query.id;
                dispatch(selectCurrentGPTChat(newConversation));
            }
            
            const turn:ConversationTurn = {
                query: data.message,
                context: '',
                answer: '',
                createdAt: date,
                updatedAt: date
            }
            dispatch(addGPTResponse(turn));
            
            var url = process.env.REACT_APP_LOPEZ_URL_PROD + 'generate-stream'
            
            if(data.channelIds){
                url =  process.env.REACT_APP_LOPEZ_URL_PROD + 'generate-stream';

                if (process.env.REACT_APP_ENV === "development"){
                    url = process.env.REACT_APP_LOPEZ_URL_DEV + 'generate-stream';
                }
            }

            if(data.document){
                url = process.env.REACT_APP_LOPEZ_URL_PROD + 'chat-with-documents-stream';

                if (process.env.REACT_APP_ENV === "development"){
                    url = process.env.REACT_APP_LOPEZ_URL_DEV  + 'chat-with-documents-stream';
                }
            }

            let apiKey: string | undefined;
            
            if (process.env.REACT_APP_ENV === "development"){
                apiKey = process.env.REACT_APP_LOPEZ_API_KEY_DEV
            }else{
                apiKey = process.env.REACT_APP_LOPEZ_API_KEY_PROD
            }


            if(data.channelIds === undefined && data.document === undefined){
                return rejectWithValue('channel ids and document ids are undefined')
            }

            const fetchData:GPTMessageAPI = {
                ...(data.document !== undefined && { documentIds: [data.document.documentId] }),
                conversationId: updatedConversationId,
                message: data.message,
                userId: data.userId,
                channelIds:data.channelIds
            }

            if(apiKey === undefined ){
                return rejectWithValue('api key is undefined');
            }
            
            const response = await fetch(url, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "Accept": 'text/event-stream',
                    "x-api-key": apiKey,
                },
                method: 'POST',
                mode:"cors",
                body: JSON.stringify(fetchData),
                signal: abortController.signal,
            });
            // Set a timeout to cancel the request after the specified duration
            const timeoutId = setTimeout(() => {
                abortController.abort(); // Abort the request if it hasn't completed within the timeout
            }, timeoutDuration);

            if (!response.ok) {
                throw new Error('Failed to response');
            }

            const reader = response.body!.getReader();
            var answer = '';
            while (true) {
                const { done, value } = await reader.read();
                const text = new TextDecoder().decode(value);
                answer += text
                const updatedTurn: ConversationTurn = {
                    ...turn, // Copy the existing turn properties
                    answer: answer, // Update the answer property
                  };

                dispatch(updateGPTResponse(updatedTurn));
                if (done) {
                    const GPTMessages= {
                        userId: data.userId,
                        conversationId: updatedConversationId

                    };
                    dispatch(fetchCurrentGPTChat(GPTMessages));
                    break;
                }
            }

            const log: AnalyticsEvent = {
                event: {timestamp: new Date().toISOString()},
                eventName: sentGptPrompt
            };

            dispatch(sendEvent(log));
        
        } catch (error: any) {
            const date = new Date().toISOString();
            const turn:ConversationTurn = {
                query: data.message,
                context: '',
                answer: 'I apologize, but due to server overload, I am temporarily unable to provide an answer. Please try again in a few minutes.',
                createdAt: date,
                updatedAt: date
            }
            
            dispatch(updateGPTResponse(turn));
            return rejectWithValue(error.message);
        }
    }
);

export const updateProgressBar = createAction(
    'gpt/updateProgressBar',
    function prepare(progress: number){
        return {
            payload:{
                progress:progress
            }
        }
    }
)

