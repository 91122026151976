import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material"
import { AppDispatch, RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { DialogDeleteAction, DialogType } from "../../features/dialog/dialog_state";
import { changeDialog } from "../../features/dialog/dialog_action";
import { ActionButton } from "../action-button/action_button";
import { deleteMessage } from "../../features/channel_chat/api/channel_chat_action";
import { leaveChannel, selectChannel } from "../../features/channel/api/channel_action";
import { closeThread, deleteAnswer, deleteThread, leaveThread } from "../../features/thread/api/thread_action";
import { UserModel } from "../../model/response/user_model";
import { ChannelModel } from "../../model/response/channel_model";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Page } from "../../features/navigation/navigation_state";
import { goForward } from "../../features/navigation/navigation_action";
import { deleteAnnouncement } from "../../features/announcement/api/announcement_action";

export const DeleteDialog = () => {
    const currentDialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);
    const action: DialogDeleteAction | undefined = useSelector((state: RootState) => state.dialog.deleteAction);
    const messageId: string | undefined = useSelector((state: RootState) => state.dialog.messageId);
    const threadId: string | undefined = useSelector((state: RootState) => state.dialog.threadId);
    const cardId: string | undefined = useSelector((state: RootState) => state.dialog.cardId);
    const answerId: string | undefined = useSelector((state: RootState) => state.dialog.answerId);
    const channelId: string | undefined = useSelector((state: RootState) => state.dialog.channelId);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const channel: ChannelModel | null = useSelector((state: RootState) => state.channel.currentChannel);


    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const selectTitle = () => {
        switch (action) {
            case DialogDeleteAction.deleteChannel:
                return t('deleteDialog.deleteChannelTitle')
            case DialogDeleteAction.deleteChatMessage:
                return t('deleteDialog.deleteMessageTitle')
            case DialogDeleteAction.deleteDiscussion:
                return t('deleteDialog.deleteDiscussionTitle')
            case DialogDeleteAction.leaveDiscussion:
                return t('deleteDialog.leaveDiscussionTitle')
            case DialogDeleteAction.closeDiscussion:
                return t('deleteDialog.closeDiscussionTitle')
            case DialogDeleteAction.deleteAnswer:
                return t('deleteDialog.deleteAnswerTitle')
            case DialogDeleteAction.deleteAnnouncement:
                return t('deleteDialog.deleteAnnouncementTitle')

            default:
                return t('deleteDialog.deleteMessageTitle')
        }
    }
    const selectDescription = () => {
        switch (action) {
            case DialogDeleteAction.deleteChannel:
                return t('deleteDialog.deleteChannelDescription')
            case DialogDeleteAction.deleteChatMessage:
                return t('deleteDialog.deleteMessageDescription')
            case DialogDeleteAction.deleteDiscussion:
                return t('deleteDialog.deleteDiscussionDescription')
            case DialogDeleteAction.leaveDiscussion:
                return t('deleteDialog.leaveDiscussionDescription')
            case DialogDeleteAction.closeDiscussion:
                return t('deleteDialog.closeDiscussionDescription')
            case DialogDeleteAction.deleteAnswer:
                return t('deleteDialog.deleteAnswerDescription')
            case DialogDeleteAction.deleteAnnouncement:
                return t('deleteDialog.deleteAnnouncementDescription')

            default:
                return t('deleteDialog.deleteMessageDescription')
        }
    }

    const selectAction = () => {
        switch (action) {
            case DialogDeleteAction.deleteChannel:
                return t('deleteDialog.deleteChannelAction')
            case DialogDeleteAction.deleteChatMessage:
                return t('deleteDialog.deleteMessageAction')
            case DialogDeleteAction.deleteDiscussion:
                return t('deleteDialog.deleteDiscussionAction')
            case DialogDeleteAction.leaveDiscussion:
                return t('deleteDialog.leaveDiscussionAction')
            case DialogDeleteAction.closeDiscussion:
                return t('deleteDialog.closeDiscussionAction')
            case DialogDeleteAction.deleteAnswer:
                return t('deleteDialog.deleteAnswerAction')
            case DialogDeleteAction.deleteAnnouncement:
                return t('deleteDialog.deleteAnnouncementAction')
            default:
                return t('deleteDialog.deleteMessageAction')
        }

    }


    const onClick = async () => {

        switch (action) {
            case DialogDeleteAction.deleteChannel:
                if (user && channelId)
                    dispatch(leaveChannel(
                        {
                            userId: user.id,
                            channelId: channelId
                        }
                    ));
                if (channel) {
                    if (channel.id === channelId) {
                        dispatch(selectChannel({ channel: null, clearCache: true }));
                        dispatch(goForward(Page.HomePage));
                        navigate('/home');
                    }
                }
                break;
            case DialogDeleteAction.deleteChatMessage:
                if (messageId && user && channel)
                    dispatch(deleteMessage(
                        {
                            messageId: messageId,
                            organizationId: user.organizationId,
                            channelId: channel.channelId,
                            channelChatId: channel.chatChannelId,
                        }
                    ));
                break;
            case DialogDeleteAction.deleteDiscussion:
                if (user && threadId) {
                    await dispatch(deleteThread(
                        {
                            userId: user.id,
                            threadId: threadId,
                        }
                    ));

                    navigate(-1)
                }
                break;
            case DialogDeleteAction.leaveDiscussion:
                if (user && threadId)
                    dispatch(leaveThread(
                        {
                            userId: user.id,
                            threadId: threadId,
                        }
                    ));
                break;
            case DialogDeleteAction.deleteAnswer:
                if (cardId && answerId)
                    dispatch(deleteAnswer(
                        {
                            cardId: cardId,
                            answerId: answerId,
                        }
                    ));
                break;
            case DialogDeleteAction.deleteAnnouncement:
                if (user && threadId)
                    dispatch(deleteAnnouncement(
                        {
                            userId: user.id,
                            threadId: threadId,
                        }));
                break;
            case DialogDeleteAction.closeDiscussion:
                if (user && threadId)
                    dispatch(closeThread({
                            userId: user.id,
                            threadId: threadId,
                        }));
                break;
            default:
                break;
        }
    }

    return (
        <Dialog
            PaperProps={{ sx: { borderRadius: "10px" } }}
            open={currentDialog === DialogType.deleteDialog} onClose={() => {
                dispatch(changeDialog(
                    { dialog: DialogType.none, }
                ))
            }}>
            <DialogTitle>
                {selectTitle()}
            </DialogTitle>
            <DialogContent>
                {selectDescription()}
            </DialogContent>
            <Box display={'flex'} flexDirection={'row'} p={2}>
                <Box display={'flex'} flexGrow={1} />
                <ActionButton
                    color="error"
                    isLoading={false}
                    disabled={false}
                    title={selectAction()}
                    onClick={onClick} />
            </Box>

        </Dialog>
    )
}


