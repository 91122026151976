import { Avatar, Box, CardHeader, Chip, List, ListItem, Stack, TextField, Typography, useTheme } from "@mui/material";
import { ActionButton } from "../../../components/action-button/action_button";
import { removeSuggestedMember, addSuggestedMember, createChannel } from "../api/create_channel_action";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { UserSearchModel } from "../../../model/request/search_model";
import { searchUsers } from "../../search/api/search_action";
import { UserModel } from "../../../model/response/user_model";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { CreateChannelModel } from "../models/request/create_channel_model";
import { AnnouncementsChannelPermissions, AnswersChannelPermissions, DiscussionsChannelPermissions, DocumentsChannelPermissions } from "../../../model/response/channel_model";

export const SelectMembers: React.FC = () => {
    const theme = useTheme();
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const suggestedUsers:  UserSuggestedModel[] = useSelector((state: RootState) => state.search.searchedUsers);
    const membersToAdd:  UserSuggestedModel[] = useSelector((state: RootState) => state.createChannel.membersToAdd);
    
    const [userSearch, setUserSearch] = useState<string>('');
    const userSearchDebounced = useDebounce(userSearch,500);
    const title:string = useSelector((state: RootState) => state.createChannel.title);
    const discussionPermission:DiscussionsChannelPermissions = useSelector((state: RootState) => state.createChannel.discussionPermission);
    const answerPermission:AnswersChannelPermissions = useSelector((state: RootState) => state.createChannel.answerPermission);
    const announcementPermission:AnnouncementsChannelPermissions = useSelector((state: RootState) => state.createChannel.announcementPermission);
    const documentPermission:DocumentsChannelPermissions = useSelector((state: RootState) => state.createChannel.documentPermission);
    const isPrivate:boolean = useSelector((state: RootState) => state.createChannel.isPrivate);
    
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();

    const onClick = () => {
        if(user){
            const data: CreateChannelModel = {
                organizationId: user.organizationId,
                creatorId: user.id,
                description: '',
                name: title,
                isPrivate: isPrivate,
                members: membersToAdd.map((member) => member.id),
                permissions: [
                    discussionPermission,
                    answerPermission,
                    announcementPermission,
                    documentPermission
                ]
            }
            dispatch(createChannel(data));
            dispatch(changeDialog({dialog: DialogType.none,}));
        }
        
    }
    useEffect(
        () => {
            if(userSearchDebounced.trim() !== ''){
                if(user){
                    const data:UserSearchModel = {
                        fullname: userSearchDebounced,
                        organizationId: user.organizationId,
                        channelId: 'AGORA',
                    }
                    dispatch(searchUsers(data))
                }
                
            }
        },[userSearchDebounced]);
    
    return (
        <Stack p={2} overflow={'hidden'} flexDirection={'column'} spacing={2} >
            <Box display='flex' flexDirection='row' justifyContent='space-between' >
                <Typography
                    variant="h6"
                    align="center"
                    color={theme.palette.textColor?.main}>
                    {t('createChannelPage.selectMemberTitle')}
                </Typography>
                <ActionButton
                    disabled={membersToAdd.length === 0}
                    onClick={() => onClick()}
                    isLoading={false}
                    title={t('createChannelPage.createChannelButton')} />
            </Box>
            <TextField
                placeholder={t('createChannelPage.selectMemberFieldHelperText')}
                value={userSearch}
                onChange={(event) => setUserSearch(event.target.value)} />

            <Box>
                {
                    membersToAdd.map((member) => {
                        return (
                            <Chip key={member.id} variant="outlined"
                                sx={{ margin: 0.5 }}
                                onDelete={() => dispatch(removeSuggestedMember(member))}
                                avatar={
                                    <Avatar src={member.photo} />
                                } label={member.displayName} />
                        );
                    })
                }
            </Box>
            <Box height='200px'
                display={'flex'}
                flexDirection={'column'}
                sx={{
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {
                        display: 'none', // Customize the width of the scrollbar
                    }
                }}>
                <List>
                    {
                        suggestedUsers.filter((suggestedUser) => suggestedUser.id !== user?.id).map((user) => {
                            return (
                                <ListItem key={user.id}>
                                    <CardHeader
                                        onClick={() => dispatch(addSuggestedMember(user))}
                                        sx={{
                                            padding: 0,
                                            '.MuiCardHeader-title': {
                                                fontWeight: 600,
                                                color: theme.palette.textColor?.main
                                            },
                                            '.MuiCardHeader-avatar': {
                                                width: 24, // Adjust the width as needed
                                                height: 24,
                                            },
                                            '&:hover': {
                                                cursor: 'pointer'
                                            }
                                        }}
                                        avatar={
                                            <Avatar sx={{ width: 24, height: 24 }}
                                                src={user.photo} aria-label="recipe" />
                                        }
                                        title={user.displayName}
                                    />
                                </ListItem>
                            );
                        })
                    }
                </List>
            </Box>

        </Stack>
    );
}