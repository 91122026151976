import { createSlice } from "@reduxjs/toolkit";
import { DocumentModel } from "../../../model/response/document_model";
import { DocumentState } from "./document_state";
import { deleteDocument, fetchDocuments, updateDocumentQuery, uploadDocuments } from "./document_action";


const initialState: DocumentState = {

    waitingForDocumentUpload: false,
    files: [],
    documentQuery: '',
    selectedFiles: [],
}


const documentSlice = createSlice({
    name: 'document',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDocuments.fulfilled, (state, payload) => {
            state.files = payload.payload.documents;
        })
        .addCase(uploadDocuments.fulfilled, (state,payload) => {
            const currentDocuments: DocumentModel[] = state.files;
            payload.payload.documents.map((document) => currentDocuments.push(document));
           
            state.files = currentDocuments;
            state.waitingForDocumentUpload = false;
        })
        .addCase(uploadDocuments.pending, (state) => {
            state.waitingForDocumentUpload = true;
        })
        .addCase(uploadDocuments.rejected, (state,payload) => {
            state.waitingForDocumentUpload = false;
        })
        .addCase(deleteDocument.fulfilled, (state, payload) => {
            if (payload.payload.documentId){
                const filteredDocuments = state.files.filter((file) => file.id !== payload.payload.documentId);
                state.files = filteredDocuments;
            }
        })
        .addCase(updateDocumentQuery, (state,payload) => {
            state.documentQuery = payload.payload.query;
        })
        
    }
});

export default documentSlice.reducer;
