import { Box, Stack, Typography, useTheme } from "@mui/material"
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import { useTranslation } from "react-i18next";

export interface MedalsProps {
    bronzePositions: number;
    goldPositions: number;
    silverPositions: number;
}
export const Medals: React.FC<MedalsProps> = (props: MedalsProps) => {

    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <Box p={2} sx={{
            borderRadius: '10px',
            backgroundColor: '#F7F9FC',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
        }}>
            <Stack spacing={2}>
                <Typography fontWeight='600' color={theme.palette.textColor?.main}>
                    {t('expertPage.medalTitle')}
                </Typography>
                <Box display='flex' flexDirection='row' justifyContent='space-between'>
                    <Stack direction={'row'}  alignItems='center' spacing={1}>
                        <Box color='#E2CF29' >
                            <EmojiEventsOutlinedIcon fontSize='large'/>
                        </Box>
                        <Typography color={theme.palette.textColor?.main}>
                            {props.goldPositions}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems='center' spacing={1}>
                        <Box color='#848484'>
                            <EmojiEventsOutlinedIcon fontSize='large'/>
                        </Box>
                        <Typography color={theme.palette.textColor?.main}>
                            {props.silverPositions}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'}  alignItems='center' spacing={1}>
                        <Box color='#E28229'>
                            <EmojiEventsOutlinedIcon fontSize='large'/>
                        </Box>
                        <Typography color={theme.palette.textColor?.main}>
                            {props.bronzePositions}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}