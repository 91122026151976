import {  Transition, motion } from 'framer-motion';

const loadingContainer = {
    width: '2rem',
    display: 'flex',
    justifyContent: 'space-around',
  };
  
  const loadingCircle = {
    display: 'block',
    width: '0.5rem',
    height: '0.5rem',
    backgroundColor: 'grey',
    borderRadius: '0.25rem',
  };
  
  const loadingContainerVariants = {
    start: {},
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  
  const loadingCircleVariants = {
    start: {
      y: '-100%', // Adjust the starting position to 0%
    },
    end: {
      y: '100%', // Adjust the ending position to 200% (to create a wave effect)
    },
  };
  
  const loadingCircleTransition:Transition = {
    duration: 0.5,
    repeatType: "mirror",
    repeat: Infinity, // Infinite loop
    ease: 'easeInOut',
  };
  
  export const LoadingDots =  () => {
    return (
      <motion.div
        style={loadingContainer}
        variants={loadingContainerVariants}
        initial="start"
        animate="end"
      >
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
      </motion.div>
    );
  }