import './card.css';
import React, { useRef, useState } from 'react';
import '../../dependency/react-quill-dev/dist/quill.snow.css';
import { CardTags } from '../card-tags/card-tags';
import { TagProps } from '../tag/tag';
import { IconDescription, IconType } from '../icon-description/icon-description';
import { Avatar, Box, CardHeader, Chip, Divider, InputBase, Popover, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { AppDispatch, RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { deleteThread, editThread, followThread, leaveThread, saveThreadChanges, unfollowThread } from '../../features/thread/api/thread_action';
import { useTranslation } from 'react-i18next';
import { DeleteThread, FollowThread, UnFollowThread } from '../../model/request/thread_model';
import { UserModel } from '../../model/response/user_model';
import { useNavigate } from 'react-router-dom';
import { SaveChangeCardModel } from '../../model/request/send_card_model';
import ReactQuill from '../../dependency/react-quill-dev/lib';
import { CardChannelModel } from '../../model/response/card_channel_model';
import { channelColors } from '../../utils/channel_theme';
import { CardImages } from '../card-images/card_images';
import { Attachment } from '../../model/response/card_model';
import { debugPrint } from '../../utils/debug_print';
import { fetchExpert } from '../../features/expert/api/expert_action';
import { changeDialog } from '../../features/dialog/dialog_action';
import { DialogDeleteAction, DialogType } from '../../features/dialog/dialog_state';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

export interface CardPreferencesProps {
    onDeleteClick?: () => void;
    onEditClick?: () => void;
    onDoneEditClick?: () => void;
    onBookmarkClick?: () => void;
    isOwner: boolean;
    isEditing: boolean;
    isFollowed: boolean;
}

export const CardPreferences = (props: CardPreferencesProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [openPreferences, setOpenPreferences] = useState<boolean>(false);
    const [anchor, setAnchor] = useState<HTMLDivElement | null>();

    const onEditClick = () => {
        props.onEditClick?.();
        setOpenPreferences(false);
    }

    const onDeleteClick = () => {
        props.onDeleteClick?.();
        setOpenPreferences(false);
    }

    const openPreferencesPopOver = (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchor(event.currentTarget);
        setOpenPreferences(true);
    }

    const closePopOver = () => {
        setAnchor(null);
        setOpenPreferences(false);
    }

    const tooltipProp = {
        popper: {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, -10],
                    },
                },
            ],
        },
    }

    if (props.isEditing) {
        return (
            <Chip sx={{
                padding: 0,
            }}
                label={t('threadPage.saveButton')}
                color={'primary'}
                onClick={props.onDoneEditClick} />
        )
    }

    if (props.isOwner) {
        return (
            <Box display='flex' flexDirection={'row'}>
                {
                    !props.isOwner && 
                    <Tooltip arrow title={props.isFollowed ? t('threadPage.unfollowThread') : t('threadPage.followThread')} slotProps={tooltipProp}  color={theme.palette.textColor?.main}>
                        <Box onClick={props.onBookmarkClick}>
                            {props.isFollowed ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                        </Box>
                    </Tooltip>
                }
                <Box onClick={(event) => openPreferencesPopOver(event)} color={theme.palette.textColor?.main}>
                    <MoreVertIcon />
                </Box> 
                <Popover
                elevation={1}
                onClose={closePopOver}
                slotProps={{
                    paper:{
                        sx: {
                            borderRradius: '8px',
                            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                          },
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                anchorEl={anchor}
                open={openPreferences}>
                    <Stack direction={'column'} p={1} spacing={1}>
                        <Stack sx={{'&:hover':{cursor:'pointer'}}} direction='row' onClick={onEditClick} spacing={1}>
                            <Box color={theme.palette.textColor?.main}>
                                <EditIcon/>
                            </Box>
                            <Typography color={theme.palette.textColor?.main} >{t('threadPage.editThread')}</Typography>
                        </Stack>
                        <Stack  sx={{'&:hover':{cursor:'pointer'}}} direction='row' onClick={onDeleteClick} spacing={1}>
                            <Box color={theme.palette.error.main}>
                                <DeleteOutlineIcon />    
                            </Box>
                            <Typography color={theme.palette.error.main} >{t('threadPage.deleteThread')}</Typography>
                        </Stack>       
                    </Stack>
                </Popover>
            </Box>
        );

    } else {
        return (
            <Box sx={{
                flexDirection: "column",
                overflow: "hidden",
                overflowY: 'auto',
            }}>
                <Tooltip arrow title={props.isFollowed ? t('threadPage.unfollowThread') : t('threadPage.followThread')} slotProps={tooltipProp}  color={theme.palette.textColor?.main}>
                    <Box onClick={props.onBookmarkClick}>
                        {props.isFollowed ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                    </Box>
                </Tooltip>

            </Box>
        );
    }
}
export interface CardProps {
    className?: string;

    id: string;
    cardId: string;
    organizationId: string;
    cardTitle: string;
    cardText: string;
    cardRichText: string;
    createdAt: string;

    tags: TagProps[];
    senderUser: {
        senderId: string;
        senderDisplayName: string;
        senderPhoto: string;
    };

    isFollowed?: boolean;

    attachments?: Attachment[];
    channel: CardChannelModel;

    totalAnswers: number;
    totalContributor: number


}

export const CardThread: React.FC<CardProps> = (props: CardProps) => {

    const [cardTitle, setCardTitle] = useState(props.cardTitle);
    const quillRef = useRef<any>();
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const edit: boolean = useSelector((state: RootState) => state.thread.editThread);

    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    var delta: any = [{ "insert": "\n" }];

    try {
        delta = JSON.parse(props.cardRichText)
    } catch (error: any) {
        debugPrint(error.toString(), 'card-thread-component');
    }

    const deleteCard = async () => {
        const data: DeleteThread = {
            userId: props.senderUser.senderId,
            threadId: props.id
        }

        dispatch(changeDialog({
            dialog: DialogType.deleteDialog,
            deleteAction: DialogDeleteAction.deleteDiscussion,
            threadId: props.id,
        }))

    }


    const editCard = () => {
        dispatch(editThread(true));
    }

    const onDoneEditingCard = () => {
        dispatch(editThread(false));

        if (quillRef) {
            if (quillRef.current) {

                const editor = quillRef.current.getEditor();
                const delta = editor.getContents();
                const plainText = editor.getText();
                const deltaString = JSON.stringify(delta.ops);

                const data: SaveChangeCardModel = {
                    cardTitle: cardTitle,
                    cardRichText: deltaString,
                    cardText: plainText,
                    senderId: props.senderUser.senderId,
                    threadId: props.id
                }
                dispatch(saveThreadChanges(data));
            }
        }
    }

    const followCard = () => {

        if (user) {
            if (props.isFollowed !== undefined) {
                if (props.isFollowed === true) {
                    if (props.id) {
                        const data: UnFollowThread = {
                            threadId: props.id,
                            userId: user.id
                        }
                        dispatch(unfollowThread(data));
                    }

                } else {
                    const data: FollowThread = {
                        senderId: props.senderUser.senderId,
                        cardId: props.cardId,
                        userId: user.id
                    }
                    dispatch(followThread(data));

                }
            }

        }
    }



    const getElapsedTime = (date: string) => {

        const creationDate = new Date(date);
        const difference = new Date().getTime() - creationDate.getTime();
        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const month = Math.floor(days / 31);
        const years = Math.floor(days / 365);
        if (seconds < 0) {
            return 'now';
        } else if (seconds < 60) {
            return `${seconds}s ago`;
        } else if (minutes < 60) {
            return `${minutes}m ago`;
        } else if (hours < 24) {
            return `${hours}h ago`;
        } else if (days < 31) {
            return `${days}d ago`;
        } else if (month < 12) {
            return `${month}m ago`
        } else {
            return `${years}y ago`;
        }
    }


    const firstLetter = props.channel.channelName[0];
    const channelColor = channelColors[firstLetter];




    return (
        <Stack spacing={1} width='60vw'>
            <Box 
                display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                <CardHeader
                    onClick={(event) => {
                        event.stopPropagation();
                        dispatch(fetchExpert({ expertId: props.senderUser.senderId }));
                        dispatch(changeDialog({ dialog: DialogType.expertDialog, }));
                    }}
                    sx={{
                        padding: 0,
                        margin: 0,
                        '.MuiCardHeader-title': {
                            padding: 0,
                            fontWeight: 600,
                            fontSize: '14px',
                            color: theme.palette.textColor?.main
                        },
                        '.MuiCardHeader-subheader': {
                            fontSize: '12px',
                        }
                    }}
                    avatar={<Avatar src={props.senderUser.senderPhoto} />}
                    title={props.senderUser.senderDisplayName}
                    subheader={getElapsedTime(props.createdAt)}
                />
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Chip variant="outlined" avatar={<Avatar
                        sx={{
                            bgcolor: channelColor,
                        }}>
                        <Typography color={'white'}>
                            {firstLetter}
                        </Typography>
                    </Avatar>} label={props.channel.channelName} />

                    <CardPreferences
                        isEditing={edit}
                        isOwner={user !== undefined && props.senderUser.senderId === user.id}
                        isFollowed={props.isFollowed || false}
                        onDeleteClick={deleteCard}
                        onDoneEditClick={onDoneEditingCard}
                        onBookmarkClick={followCard}
                        onEditClick={editCard}
                    />
                    <Box>
                    </Box>



                </Stack>

            </Box>
            <InputBase fullWidth value={cardTitle} onChange={(event) => setCardTitle(event.target.value)}
                readOnly={!edit}
                multiline
                sx={{
                    fontWeight: 600,
                    fontSize: '24px',
                    color: theme.palette.textColor?.main,

                    '& .MuiInputBase-input': {
                        padding: 0,
                        margin: 0, // Further adjust for the inner input element if necessary
                    },

                }} />
            {
                edit ?

                    <ReactQuill ref={quillRef} className="custom-editor" modules={{ toolbar: false }} readOnly={!edit} value={delta} theme={"bubble"} />
                    :
                    <ReactQuill className="custom-editor" modules={{ toolbar: false }} readOnly={!edit} value={delta} theme={"bubble"} />

            }
            <CardTags tags={props.tags} />
            <CardImages images={props?.attachments || []} isDeleteable={false}/>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <IconDescription iconType={IconType.ANSWER} counter={props.totalAnswers} text={t('threadPage.answersIconDescription')} />
                <Box></Box>
            </Box>
            <Divider light />
        </Stack>
    );
}