import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { logout } from "../../auth/api/authAction";
import { useTranslation } from "react-i18next";
import { fetchExpert } from "../../expert/api/expert_action";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { UserModel } from "../../../model/response/user_model";
import { useNavigate } from "react-router-dom";



export interface ProfilePopOverProps{
    onClose: () => void;
}

export const ProfilePopOver:React.FC<ProfilePopOverProps> = (props:ProfilePopOverProps) => {

    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch<AppDispatch>();
    const {t,i18n} = useTranslation();
    
    return (
        <Box sx={{
            flexDirection: "column",
            overflow: "hidden",
            overflowY: 'auto',
            

        }} width={'10vw'}>
            <List>
                <ListItem onClick={() => {
                    if(user){
                        dispatch(fetchExpert({expertId:user.id}));
                        props.onClose();
                        dispatch(changeDialog({dialog: DialogType.expertDialog,}));
                    }
                    
                }}
                    sx={{
                        '&:hover':{
                            cursor:'pointer'
                        }
                    }}
                >
                    <Stack  direction='row' spacing={2}>
                        <PersonIcon/>
                        <Typography>{t('profilePopOver.profileTitle')}</Typography>
                    </Stack>
                </ListItem>
                <ListItem sx={{
                        '&:hover':{
                            cursor:'pointer'
                        }
                    }} onClick={
                    () => {
                        dispatch(logout());
                    }
                }>
                <Stack  direction='row' spacing={2}>
                        <LogoutIcon/>
                        <Typography>Logout</Typography>
                    </Stack>
                </ListItem>
            </List>  
        </Box>
    );

}