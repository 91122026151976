import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, Box, CardHeader, Divider, LinearProgress, Popover, Stack, styled, useTheme } from '@mui/material';
import { CardModel } from '../../model/response/card_model';
import ReactQuill from '../../dependency/react-quill-dev/lib';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { viewAnnouncement } from '../../features/announcement/api/announcement_action';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserModel } from '../../model/response/user_model';
import { ChangeChatModel } from '../../model/request/change_chat_model';
import { selectCurrentChat } from '../../features/chat/api/chat_action';
import { goForward, openChannelDrawer } from '../../features/navigation/navigation_action';
import { Page } from '../../features/navigation/navigation_state';
import { useNavigate } from 'react-router-dom';
import { ChatModel } from '../../model/response/chat_model';
import { useTranslation } from 'react-i18next';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { DialogDeleteAction, DialogType } from '../../features/dialog/dialog_state';
import { changeDialog } from '../../features/dialog/dialog_action';

export interface AnnouncementActionProps {
    anchorEl: HTMLButtonElement | HTMLDivElement | null;
    open: boolean;
    threadId: string;
    onClose: () => void;
}

export const AnnouncementAction: React.FC<AnnouncementActionProps> = (props: AnnouncementActionProps) => {
    
    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    
    const handleDeleteClick= () => {
        props.onClose();
        dispatch(changeDialog({ 
            dialog: DialogType.deleteDialog,
            deleteAction: DialogDeleteAction.deleteAnnouncement,
            threadId: props.threadId,
        }));
    }
    return (
        <Popover
            anchorEl={ props.anchorEl}
            open={props.open}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <Stack direction='column' justifyContent={'center'} p={1}>
                <Stack 
                sx={{'&:hover': {cursor: 'pointer'}}}
                onClick={handleDeleteClick}
                direction='row' alignItems={'center'}>
                    <Box color={theme.palette.error.main}>
                        <DeleteOutline />
                    </Box>
                    <Typography color={theme.palette.error.main}>{t('announcementPopover.deleteActionButton')}</Typography>
                </Stack>
                
            </Stack>

        </Popover>
    )

}



export interface AnnouncementDialogProps {
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 10,
    width: '93%',
    '& .MuiLinearProgress-bar': {
        borderRadius: 10,
        background: theme.palette.sliderBarColor!.main, // Customize the gradient colors
      },
  }));

export const AnnouncementDialog: React.FC<AnnouncementDialogProps> = (props: AnnouncementDialogProps) => {

    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {t} = useTranslation();
    
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);
    const announcement: CardModel | undefined = useSelector((state: RootState) => state.dialog.announcement);
    var isOwned = false;
    const isOpened = dialog === DialogType.announcementDialog;
    
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | HTMLDivElement | null>(null);
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    if(announcement === undefined){
        return <></>;
    }
    
    if (user) {
        isOwned = user.id === announcement.senderUser.senderId;
    }

    return (
    <Dialog fullWidth open={isOpened} onClose={() => dispatch(changeDialog({ dialog: DialogType.none }))} 
    PaperProps={{ sx: { borderRadius: "10px" } }}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} >
            
                <CardHeader sx={{
                    paddingBottom: 0,
                    '.MuiCardHeader-title': {
                        fontWeight: 600,
                        color: theme.palette.textColor?.main
                    }
                }}
                    avatar={<Avatar src={announcement.senderUser.senderPhoto} />}
                    title={announcement.senderUser.senderDisplayName}

                />
                {
                   isOwned &&  <Box
                    onClick={handleClick}
                    sx={{'&:hover': {cursor: 'pointer'}}} 
                    display='flex'
                    color= {theme.palette.textColor?.main} padding={1} paddingTop={2}>
                        <MoreVertIcon/>
                    </Box>
                }
               
                <AnnouncementAction 
                anchorEl={anchorEl} 
                open={Boolean(anchorEl)}
                threadId={announcement.id} 
                onClose={() => setAnchorEl(null)} />
            </Box>
            <DialogContent style={{ paddingLeft: '15px', paddingTop: 6, paddingBottom: 0 }} >
                <Typography fontWeight={600}
                    variant={'h6'}>
                    {announcement.cardTitle}
                </Typography >
                <ReactQuill className="custom-editor" modules={{ toolbar: false }} readOnly={true} value={JSON.parse(announcement.cardRichText || '')} theme={"bubble"} />
            </DialogContent>
            <DialogContent style={{ paddingLeft: '15px', paddingTop: 12, paddingBottom: 0,paddingRight:'15px'}}>
                <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <BorderLinearProgress value={announcement.totalViews*100} variant="determinate"/>
                    <Typography color={'primary'}>{Math.floor(announcement.totalViews*100)}%</Typography>
                </Box>
            </DialogContent>
            
            <DialogActions >
                <Box width='100%' display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
                    <Button fullWidth disabled={announcement.isClosed || isOwned}
                        onClick={() => {
                            if (user) {
                                dispatch(viewAnnouncement({
                                    userId: user.id,
                                    threadId: announcement.id
                                }))
                            }
                        }}
                        variant='contained' color="primary" sx={{ textTransform: 'none', borderRadius: 8, margin: 1 }}>
                        Got it!
                    </Button>
                    <Button disabled={isOwned}
                        onClick={() => {
                            const currentDate = new Date().toISOString();
                            if (user) {
                                const chat: ChatModel = {
                                    id: '',
                                    organizationId: user.organizationId,
                                    lastMessage: '',
                                    chatBalance: {
                                        hasNotifications: false,
                                        notificationCounter: 0
                                    },
                                    user: {
                                        responderDisplayName: announcement.senderUser.senderDisplayName,
                                        responderId: announcement.senderUser.senderId,
                                        responderPhoto: announcement.senderUser.senderPhoto
                                    },
                                    updatedAt: currentDate,
                                    createdAt: currentDate
                                }

                                const data: ChangeChatModel = {
                                    chat: chat,
                                    userId: user.id
                                }
                                dispatch(selectCurrentChat(data));
                                dispatch(openChannelDrawer(false));
                                dispatch(goForward(Page.ChatPage));
                                navigate('/chat');
                                dispatch(changeDialog({ dialog: DialogType.none }));
                            }
                        }}

                        fullWidth variant='contained' color="primary" sx={{ backgroundColor: '#4A4A4A', textTransform: 'none', borderRadius: 8, margin: 1 }}>
                        {t('announcementPopover.contactActionButton',{value: announcement.senderUser.senderDisplayName})}
                       
                    </Button>
                </Box>
            </DialogActions> 


        </Dialog>
    );
}



