import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { useState } from "react";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { ActionButton } from "../../../components/action-button/action_button";
import { BackButton } from "../../../components/back-button/back_button";
import { changeAnnouncementPermission, changeAnswerPermission, changeChannelStep, changeChannelTitle, changeDiscussionPermission, changeDocumentPermission } from "../api/create_channel_action";
import { ChannelCreationStep } from "../api/create_channel_state";
import { AnnouncementsChannelPermissions, AnswersChannelPermissions, DiscussionsChannelPermissions, DocumentsChannelPermissions } from "../../../model/response/channel_model";

export const ChannelSettings: React.FC = () => {
    
    const theme = useTheme();
    const { t } = useTranslation();
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const isPrivate: boolean = useSelector((state: RootState) => state.createChannel.isPrivate);
    const discussionPermission: DiscussionsChannelPermissions = useSelector((state: RootState) => state.createChannel.discussionPermission);
    const answerPermission: AnswersChannelPermissions = useSelector((state: RootState) => state.createChannel.answerPermission);
    const announcementPermission: AnnouncementsChannelPermissions = useSelector((state: RootState) => state.createChannel.announcementPermission);
    const documentPermission: DocumentsChannelPermissions = useSelector((state: RootState) => state.createChannel.documentPermission);
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Stack spacing={2}>
            <Stack
                onClick={() => setShowSettings(!showSettings)}
                direction='row' spacing={1} sx={{ '&:hover': { cursor: 'pointer' } }}>
                <Typography color={theme.palette.textColor?.main}>
                    {t('createChannelPage.channelSettingButton')}
                </Typography>
                <Box color={theme.palette.textColor?.main}>
                    {!showSettings ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowUpOutlinedIcon />}
                </Box>
            </Stack>
            {
                showSettings &&
                <Stack>

                    <FormControl>
                        <FormLabel>
                            <Typography color={theme.palette.textColor?.main} fontWeight='600'>
                                {t('createChannelPage.discussionSettingsTitle')}
                            </Typography>
                        </FormLabel>
                        <RadioGroup value={discussionPermission} onChange={(event) => dispatch(changeDiscussionPermission(event.target.value as DiscussionsChannelPermissions))}>
                            <FormControlLabel value={DiscussionsChannelPermissions.ONLY_ROUTED} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.automaticRoutingOptionTitle')}
                                    </Typography>
                                } />
                            <FormControlLabel value={DiscussionsChannelPermissions.ONLY_ADMINS} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.adminRoutingOptionTitle')}
                                    </Typography>
                                    } />
                            <FormControlLabel value={DiscussionsChannelPermissions.EVERYONE} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.allMembersRoutingOptionTitle')}
                                    </Typography>
                                } />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            <Typography color={theme.palette.textColor?.main} fontWeight='600'>
                                {t('createChannelPage.answerSettingsTitle')}
                            </Typography>
                        </FormLabel>
                        <RadioGroup value={answerPermission} onChange={(event) => dispatch(changeAnswerPermission(event.target.value as AnswersChannelPermissions))}>
                            <FormControlLabel value={AnswersChannelPermissions.ONLY_ADMINS} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.adminAnswerSettingOption')}
                                    </Typography>
                                } />
                            <FormControlLabel value={AnswersChannelPermissions.MEMBERS} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.allMembersAnswerSettingOption')}
                                    </Typography>
                                } />
                            {
                                !isPrivate &&
                                <FormControlLabel value={AnswersChannelPermissions.EVERYONE} control={<Radio size="small" />}
                                    label={
                                        <Typography color={theme.palette.textColor?.main}>
                                            {t('createChannelPage.everyoneAnswerSettingOption')}
                                        </Typography>
                                    } />
                            }
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            <Typography color={theme.palette.textColor?.main} fontWeight='600'>
                                {t('createChannelPage.announcementSettingsTitle')}
                            </Typography>
                        </FormLabel>
                        <RadioGroup value={announcementPermission} onChange={(event) => dispatch(changeAnnouncementPermission(event.target.value as AnnouncementsChannelPermissions))}>
                            <FormControlLabel value={AnnouncementsChannelPermissions.ONLY_ADMINS} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.adminAnnouncementSettingOption')}
                                    </Typography>
                                } />
                            <FormControlLabel value={AnnouncementsChannelPermissions.EVERYONE} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.allMembersAnnouncementSettingOption')}
                                    </Typography>
                                } />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            <Typography color={theme.palette.textColor?.main} fontWeight='600'>
                                {t('createChannelPage.documentSettingsTitle')}
                            </Typography>
                        </FormLabel>
                        <RadioGroup value={documentPermission} onChange={(event) => dispatch(changeDocumentPermission(event.target.value as DocumentsChannelPermissions))}>
                            <FormControlLabel value={DocumentsChannelPermissions.ONLY_ADMINS} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.adminDocumentSettingOption')}
                                    </Typography>
                                } />
                            <FormControlLabel value={DocumentsChannelPermissions.EVERYONE} control={<Radio size="small" />}
                                label={
                                    <Typography color={theme.palette.textColor?.main}>
                                        {t('createChannelPage.allMembersDocumentSettingOption')}
                                    </Typography>
                                } />
                        </RadioGroup>
                    </FormControl>
                </Stack>
            }

        </Stack>
    );

}



export const ChannelDetails: React.FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const title: string = useSelector((state: RootState) => state.createChannel.title);

    const isPrivate: boolean = useSelector((state: RootState) => state.createChannel.isPrivate);
    return (
        <Stack p={2} spacing={2} >
            <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems='center'>
                <Typography color={theme.palette.textColor?.main} variant="h5" fontWeight={600}>
                    {t('createChannelPage.channelSettingTitle')}
                </Typography>
                {isPrivate &&
                    <Box display='flex' color={"#8C1A6A"}  >
                        <LockOutlinedIcon style={{ fontSize: 40 }} />
                    </Box>
                }
                {!isPrivate &&
                    <Box display='flex' color={"#5876FF"} >
                        <PublicOutlinedIcon style={{ fontSize: 40 }} />
                    </Box>
                }


            </Box>
            <Stack>
                <Typography color={theme.palette.textColor?.main} fontWeight={600}>
                    {t('createChannelPage.channelName')}
                </Typography>
                <TextField
                    value={title}
                    onChange={(event) => dispatch(changeChannelTitle(event.target.value))}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                />
            </Stack>
            <ChannelSettings />
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                <BackButton onClick={ () => dispatch(changeChannelStep(ChannelCreationStep.ChannelType))}/>
                <ActionButton 
                    isLoading={false} 
                    disabled={title.trim() === ""} 
                    title={"Recap"} onClick={() => dispatch(changeChannelStep(ChannelCreationStep.Recap))}/>
            </Box>
        </Stack>
    );
};