import { Stack } from "@mui/material";
import { CardTile } from "../../../components/card-tile/card-tile";
import { CardModel } from "../../../model/response/card_model";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { goForward } from "../../navigation/navigation_action";
import { Page } from "../../navigation/navigation_state";
import { setCardId, setThreadId } from "../../thread/api/thread_action";
import { useNavigate } from "react-router-dom";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { clearCache } from "../../search/api/search_action";

export interface SimilarCardProps {
    similarResults: CardModel[];
    user: UserModel;
}

export const SimilarCard: React.FC<SimilarCardProps> = (props: SimilarCardProps) => {

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    return (
        <Stack direction='column' spacing={1} >
            {
                props.similarResults.map((card) =>
                    <CardTile isSearchedCard={true} stretch={true} {...card}
                        onProfileClick={(event) => { event.stopPropagation() }}
                        onCardClick={(event) => {
                            event.stopPropagation();
                            if (props.user) {
                                dispatch(goForward(Page.ThreadPage));
                                if (card.id) {
                                    dispatch(setThreadId(card.id));
                                    navigate('/thread/thread=' + card.id);
                                } else {
                                    dispatch(setCardId(card.cardId));
                                    navigate('/thread/card=' + card.cardId);
                                }
                            }
                            dispatch(changeDialog({ dialog: DialogType.none, }));
                            dispatch(clearCache());
                        }}
                    />
                )
            }
        </Stack>
    );
}