import { Box, CardContent, Link, Stack, Typography, useTheme } from "@mui/material";
import { DocReference } from "../../../model/response/gpt_conversation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useState } from "react";

export interface DocumentData {
    pages: number[];
    url: string;
    source: string;
}

export interface UniqueDocRefence {
    [key: string]: DocumentData;
}

export interface GPTReferenceProps {
    docsReferences: DocReference[] | undefined;
}

export const GPTReference: React.FC<GPTReferenceProps> = (props: GPTReferenceProps) => {

    const [showReference, setShowReference] = useState<boolean>(false);

    const filterReference = (docReferences: DocReference[] | undefined) => {
        const uniqueRefs: UniqueDocRefence = {}

        if (docReferences) {
            docReferences.map((doc) => {
                if (doc.file_name in uniqueRefs) {
                    if (!uniqueRefs[doc.file_name].pages.includes(doc.page + 1)) {
                        uniqueRefs[doc.file_name].pages.push(doc.page + 1);
                    }
                } else {
                    if (doc.source === 'syllotips thread') {
                        const data: DocumentData = {
                            pages: [doc.page + 1],
                            url: doc.doc_id,
                            source: doc.source
                        }

                        uniqueRefs[doc.file_name] = data;
                    } else {
                        const data: DocumentData = {
                            pages: [doc.page + 1],
                            url: doc.url,
                            source: doc.source
                        }

                        uniqueRefs[doc.file_name] = data;
                    }


                }
            });
        }
        return uniqueRefs;
    }

    const displayDropdownIcon = () => {
        if(showReference)
            return (
            <Box 
            color={theme.palette.textColor?.main}>
                <KeyboardArrowUpOutlinedIcon/>
            </Box>
            );
        
        return (
            <Box 
                color={theme.palette.textColor?.main}>
                <KeyboardArrowDownOutlinedIcon/>
            </Box>
            );
    }

    const uniqueRefs = filterReference(props.docsReferences);
    const keys = Object.keys(uniqueRefs);
    const theme = useTheme();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();


    if (props.docsReferences && props.docsReferences.length > 0 && showReference) {
        return (
            <Stack spacing={1} paddingLeft={'40px'}>
                <Stack 
                sx={{'&:hover': {cursor: 'pointer'}}}
                onClick={() => setShowReference(!showReference)} 
                direction={'row'} spacing={0}>
                    <Typography fontWeight={600} color={theme.palette.textColor?.main}>{t('gptPage.gptMessageReferences')}</Typography>
                    {displayDropdownIcon()}
                </Stack>
                <Stack>
                    {
                        keys.map((doc, index) =>
                            <Stack key={index} direction={'row'} spacing={1}>
                                    {
                                        uniqueRefs[doc].source === 'syllotips thread' ?
                                            <Box color={theme.palette.textColor?.main}>
                                                <ForumOutlinedIcon/>
                                            </Box> :
                                            <Box color={theme.palette.textColor?.main}>
                                                <FilePresentIcon/>
                                            </Box>
                                    }
                                    <Link onClick={() => {
                                        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
                                        const isUrl = urlPattern.test(uniqueRefs[doc].url);
                                        if (isUrl) {
                                            window.open(uniqueRefs[doc].url, '_blank')
                                        } else {
                                            navigate('/thread/card=' + uniqueRefs[doc].url)
                                        }


                                    }} underline="hover">
                                        {doc}
                                    </Link>
                                {
                                    uniqueRefs[doc].source != 'syllotips thread' && <Typography color={theme.palette.textColor?.main}>{uniqueRefs[doc].pages.length > 1?'(pages: ' + uniqueRefs[doc].pages.join(', ') + ')':'(page: ' + uniqueRefs[doc].pages.join(', ') + ')'}</Typography>
                                }
                            </Stack>
                        )
                    }
                </Stack>
            </Stack>
        )
    } else if(props.docsReferences && props.docsReferences.length > 0 && !showReference){
        return (
            <Stack spacing={1} paddingLeft={'40px'}>
                <Stack 
                sx={{'&:hover': {cursor: 'pointer'}}}
                onClick={() => setShowReference(!showReference)} 
                direction={'row'} spacing={0}>
                    <Typography fontWeight={600} color={theme.palette.textColor?.main}>{t('gptPage.gptMessageReferences')}</Typography>
                    {displayDropdownIcon()}
                </Stack>
            </Stack>
        );
    }else{
        return (<></>);
    }

}