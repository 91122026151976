import { Avatar, Box, CardHeader, Dialog, InputBase, List, ListItem, Stack, TextField, Typography, useTheme } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { clearSearchDirectMessages, searchUserToChat, selectCurrentChat } from "../api/chat_action";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { UserSearchModel } from "../../../model/request/search_model";
import { UserModel } from "../../../model/response/user_model";
import { UserSuggestedModel } from "../../../model/response/user_suggested_model";
import { ChangeChatModel } from "../../../model/request/change_chat_model";
import { ChatModel } from "../../../model/response/chat_model";
import { useTranslation } from "react-i18next";
import { DialogType } from "../../dialog/dialog_state";
import { changeDialog } from "../../dialog/dialog_action";
import { useDebounce } from "@uidotdev/usehooks";
import { SkeletonProfiles } from "../../../components/skeleton-profile/skeleton_profile";

export const NewMessageDialog = () => {

    const theme = useTheme();
    const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);
    const user: UserModel | undefined = useSelector((state:RootState) => state.auth.user);
    const userToChat: UserSuggestedModel[] = useSelector((state:RootState) => state.chat.searchedUsers);
    const searchedUserAreLoading: boolean = useSelector((state:RootState) => state.chat.searchedUserAreLoading);
    const dispatch = useDispatch<AppDispatch>();
    const [searchText, setSearchText] = useState('');
    const userSearchDebounced = useDebounce(searchText, 500);
    const {t,i18n} = useTranslation();

    useEffect(
        () => {
            if (userSearchDebounced.trim() !== '') {
                if (user) {
                    const data: UserSearchModel = {
                        fullname: userSearchDebounced,
                        organizationId: user.organizationId,
                        channelId: 'AGORA',
                    }

                    dispatch(searchUserToChat(data))
                    
                }

            }
        }, [userSearchDebounced]);
    
    return(
        <Dialog
            fullWidth
            maxWidth={'xs'}
            open={dialog === DialogType.directMessageDialog}
            sx={{
                overflowY:'hidden'
            }}
            onClose={() => {
                dispatch(changeDialog({dialog: DialogType.none}));
                dispatch(clearSearchDirectMessages(true));
            }}
        >
            <Stack p={2} overflow={'hidden'} display={'flex'} fontWeight={600} flexDirection={'column'} alignItems={'center'} >
                <Typography 
                    variant="h6" 
                    align="center"
                    color={theme.palette.textColor?.main}>
                    {t('chatPage.dialogTitle')}
                </Typography>
                <Box width={'100%'}  >
                    < InputBase 
                    placeholder={t('chatPage.dialogSearchHelperText')}
                    onChange={ (event) => {
                        setSearchText(event.target.value); 
                    }}
                    value={searchText} 
                    startAdornment={<SearchIcon/>}
                    fullWidth/>
                </Box>
                <Box width={'100%'}   height={'40vh'} overflow={'scroll'}>
                    
                    {
                        searchedUserAreLoading && <SkeletonProfiles/>
                    }
                    
                    <List sx={{
                        padding:0,
                        '&:hover':{
                            cursor:'pointer'
                        }}}>
                        {userToChat.map((suggestedUser,index) => 
                        <ListItem key={index} sx={{padding:0}} onClick=
                        {
                            async () => {
                                
                                if(user){
                                    const currentDate = new Date().toISOString();
                                    const currentChat: ChatModel ={
                                        id: "",
                                        organizationId: user.organizationId,
                                        lastMessage: "",
                                        chatBalance: {
                                            hasNotifications: false,
                                            notificationCounter: 0
                                        },
                                        user: {
                                            responderDisplayName: suggestedUser.displayName,
                                            responderId: suggestedUser.id,
                                            responderPhoto: suggestedUser.photo
                                        },
                                        updatedAt: currentDate,
                                        createdAt: currentDate
                                    }
                                    const data:ChangeChatModel ={
                                        chat: currentChat,
                                        userId: user.id
                                    }
                                    await dispatch(selectCurrentChat(data));
                                    dispatch(clearSearchDirectMessages(true));
                                    dispatch(changeDialog({dialog: DialogType.none}));
                                }
                                
                            }
                        }>
                            <CardHeader
                                avatar={<Avatar src={suggestedUser.photo}/>}
                                title={suggestedUser.displayName}
                                subheader={suggestedUser.bio}
                            />
                        </ListItem>)}
                    </List>
                </Box> 
            </Stack>
        </Dialog>
    )
}