import { Box, Stack, Typography, useTheme } from "@mui/material"
import { TagModel } from "../../../model/response/tag_model";
import { Expertise } from "../../../components/expertise/expertise";
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { changeContent } from "../api/expert_action";
import { ContentType } from "../api/expert_state";
import { useTranslation } from "react-i18next";
export interface ProfileExpertiseProps {
    expertises: TagModel[];

}
export const ProfileExpertise: React.FC<ProfileExpertiseProps> = (props: ProfileExpertiseProps) => {

    const theme = useTheme();
    const {t} = useTranslation();
    const contentType: ContentType = useSelector((state: RootState) => state.expert.contentType);
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Box p={2} sx={{
            borderRadius: '10px',
            backgroundColor: '#F7F9FC',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
        }}
            onClick={() => dispatch(changeContent(ContentType.expertise))}
        >
            <Stack>
                <Box display='flex' flexDirection='row' justifyContent='space-between'>
                    <Typography fontWeight='600' color={theme.palette.textColor?.main}>
                        {contentType === ContentType.general ? t('expertPage.bestExpertiseTitle') : t('expertPage.expertiseTitle')}
                    </Typography>
                    {contentType === ContentType.general ? <Box sx={{'&:hover':{cursor:'pointer'}}}><NavigateNextOutlinedIcon /></Box> : <></>}

                </Box>
                {
                    contentType === ContentType.general ?
                        <Stack>
                            {
                                props.expertises.slice(0,3).map((tag) => <Expertise key={tag.id} {...tag} />)
                            }
                        </Stack>
                        :
                        <Box
                            id={'feedScrollable'}
                            display={'flex'}
                            flexDirection={'column'}
                            maxHeight={'300px'}
                            sx={{
                                overflowY: "scroll",
                                '&::-webkit-scrollbar': {
                                    display: 'none', // Customize the width of the scrollbar
                                }
                            }}>
                            
                                {
                                    props.expertises.map((tag) => <Expertise key={tag.id} {...tag} />)
                                }

                        </Box>
                }

            </Stack>
        </Box>
    )
}