import { Popover } from "@mui/material"
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { sendReaction } from "../api/channel_chat_action";
import { ChannelMessageModel } from "../../../model/response/channel_message_model";
import { UserModel } from "../../../model/response/user_model";
import { ChannelModel } from "../../../model/response/channel_model";

export interface ChannelChatReactionPopOverProps {
    open: boolean;
    anchorEl: HTMLButtonElement | HTMLDivElement | null;
    handleClose: () => void;
}

export const ChannelChatReactionPopOver: React.FC<ChannelChatReactionPopOverProps> = (props: ChannelChatReactionPopOverProps) => {


    const currentMessage: ChannelMessageModel | undefined = useSelector((state: RootState) => state.channelChat.currentMessage);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const selectedChannel: ChannelModel | null = useSelector((state: RootState) => state.channel.currentChannel);
    const dispatch = useDispatch<AppDispatch>();
    const onEmojiClick = (emojiObject: EmojiClickData, event: any) => {

        if (currentMessage && user && selectedChannel) {

            const currentReactions = currentMessage.reactions;

            if (emojiObject.emoji in currentReactions) {
                const reactionArray = currentReactions[emojiObject.emoji];

                if (reactionArray.includes(user.id)) {
                    props.handleClose();
                    return;
                } else {
                    const users = [...reactionArray, user.id];
                    
                    const updatedReactions = {
                        ...currentReactions,
                        [emojiObject.emoji]: users
                    }
                    dispatch(sendReaction({
                        messageId: currentMessage.id,
                        reactions:updatedReactions,
                        organizationId: user.organizationId,
                        channelId: selectedChannel.channelId,
                        channelChatId: selectedChannel.chatChannelId
                    }));
                    props.handleClose();
                }
            } else {
                const users = [ user.id];
                    
                    const updatedReactions = {
                        ...currentReactions,
                        [emojiObject.emoji]: users
                    }

                dispatch(sendReaction({
                    messageId: currentMessage.id,
                    reactions: updatedReactions,
                    organizationId: user.organizationId,
                    channelId: selectedChannel.channelId,
                    channelChatId: selectedChannel.chatChannelId
                }));
                props.handleClose();
            }

        }
    }

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            style={{ height: 500 }}
            elevation={1}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: {
                        borderRradius: '8px',
                        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                    },
                }
            }}

        >
            <EmojiPicker onEmojiClick={onEmojiClick} />
        </Popover>
    )
}