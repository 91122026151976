import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { UserModel } from "../../../model/response/user_model";
import { debugPrint } from "../../../utils/debug_print";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";


export const updateUser = createAction(
    'profile/updateUser',
    function prepare(user: UserModel) {
        return {
            payload: {
                user: user,
            }
        }
    }
);


export const updateUserChangelog = createAsyncThunk(
    'profile/updateUserChangelog',
    async (data:{userId: string}, { dispatch, rejectWithValue }) => {
        try{
            const db = getFirestore();
            const documentRef = doc(db, 'Users', data.userId);
            await updateDoc(documentRef, {seenChangelog: true});
            dispatch(changeDialog({dialog: DialogType.none}))

        }catch(error:any){
            debugPrint(error.toString(), 'profile/updateUserChangelog');
            rejectWithValue(error.toString());
        }
    }
);
