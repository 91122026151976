import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { DocumentData } from "firebase/firestore";
import { useEffect } from "react";
import { FetchUserTagsModel } from "../../../model/request/fetch_profile_models";
import { Box, Button, List, ListItem, Stack, Typography, useTheme } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { Expertise } from "../../../components/expertise/expertise";
import { TagModel } from "../../../model/response/tag_model";
import { ErrorComponent, ErrorType } from "../../../components/error-component/error_component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchYourExpertise } from "../api/expertise_action";
import { debugPrint } from "../../../utils/debug_print";
export const ExpertisePopover = () => {

    const dispatch = useDispatch<AppDispatch>();
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const lastSeenExpertise: DocumentData | undefined = useSelector((state: RootState) => state.expertise.lastSeenExpertise);

    const scrollYourExpertise: boolean = useSelector((state: RootState) => state.expertise.scrollExpertise);
    const yourExpertise: TagModel[] = useSelector((state: RootState) => state.expertise.expertise);
    const theme = useTheme();
    const {t,i18n} = useTranslation();
    const navigate = useNavigate();
    const renderLoading = () => {
        return (
            <span>Loading</span>
        );
    }


    useEffect(() => {
        if (user !== undefined
            && yourExpertise.length === 0) {
            const data: FetchUserTagsModel = {
                userId: user.id,
                size: 9,
            }
            dispatch(fetchYourExpertise(data));

        }
    }, []);

    


    const fetchYourExpertises = () => {
        if (user !== undefined) {
            const data: FetchUserTagsModel = {
                userId: user.id,
                size: 50,
                lastElement: lastSeenExpertise,
            }

            dispatch(fetchYourExpertise(data));
        }
    }

    

    return (
        <Stack  width={500}>
            <Box display={'flex'} flexDirection='row' justifyContent={'space-between'} padding={2} alignItems={'center'} >
                <Typography variant='h5' fontWeight={'600'} color={theme.palette.textColor?.main}>{t('expertisePopOver.title')}</Typography>
                <Button onClick={() => {navigate('/expertise')}}>
                    {t('expertisePopOver.showAll')}
                </Button>
            </Box>
            {
              yourExpertise.length == 0 ?
                <ErrorComponent caption='Once you gain new expertise it will be shown here'  image={ErrorType.card}/>
                :
                <Box   >
                <InfiniteScroll

                    dataLength={yourExpertise.length}
                    next={fetchYourExpertises}
                    hasMore={scrollYourExpertise}
                    height={'50vh'}       
                    loader={renderLoading()}
                >
                    <List>
                        {
                            yourExpertise.map((tag, index) => {
                                return (
                                    <ListItem key={index}>
                                        <Expertise key={tag.id} {...tag} />
                                    </ListItem>

                                );
                            })
                        }
                    </List>
                </InfiniteScroll>
            </Box>

            }

            
        </Stack>
    );
}

