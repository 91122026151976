import { createAction } from "@reduxjs/toolkit";
import { CardModel } from "../../../model/response/card_model";

export const fetchUserThreads = createAction(
    'userThread/fetchUserThreads',
    function prepare(threads:CardModel[]){
        return{
            payload:{
                threads:threads
            }
        }
    }
);

export const loadMoreUserThreads = createAction(
    'userThread/loadMoreUserThreads',
    function prepare(limit:number){
        return {
            payload: {
                limit:limit
            }
        }
    }
);