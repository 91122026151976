import { Avatar, Box, Card, CardHeader, Stack, Typography, useTheme } from "@mui/material"
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";

export interface LeaderboardUser {
    displayName: string;
    id: string;
    photo: string;
    position: number;
    totalPointsEarned: number;
    userId: string;
}

export const LeaderboardUser = (props: LeaderboardUser) => {
    const theme = useTheme();
    const dispatch= useDispatch<AppDispatch>();

    const defineBoxShadow = () => {
        var shadow = '0px 0px 2px 0px rgba(0, 0, 0, 0.25)';
        switch (props.position) {
            case 1:
                shadow = '0px 0px 4px 0px rgba(253, 199, 23, 1)'
                break;
            case 2:
                shadow = '0px 0px 4px 0px rgba(170, 193, 211, 1)'
                break;
            case 3:
                shadow = '0px 0px 4px 0px rgba(215, 151, 93, 1)'
                break;
        
            default:
                break;
        }

        return shadow;
    }

    return (
            <Box
                onClick={() => dispatch(changeDialog({dialog: DialogType.expertDialog, expertId: props.userId}))}
                width={'100%'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                    boxShadow: defineBoxShadow(),
                    borderRadius: '8px',
                    padding:'10px',
                    '&:hover': {cursor: 'pointer'}
                }}
            >
                <Stack direction='row' alignItems={'center'} spacing={1.5}>
                    <Box >
                        <Typography variant="body1" color={theme.palette.textColor!.main} >
                            {props.position}°
                        </Typography>
                    </Box>
                    <CardHeader
                    sx={{padding:0}}
                    avatar={
                        <Avatar sx={{
                        }}
                            src={props.photo} aria-label="recipe" />
                    }
                    title={
                        <Typography variant="body1" fontWeight={600} color={theme.palette.textColor!.main} >
                            {props.displayName}
                        </Typography>
                    }

                    />
                </Stack>
                
                <Typography variant="body1" color={theme.palette.textColor!.main} >
                    {Math.floor(props.totalPointsEarned) + ' pts'}
                </Typography>

            </Box>
    )
}