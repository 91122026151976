import { Box, Stack, Typography, useTheme } from "@mui/material";
import { changeContent } from "../api/expert_action";
import { ContentType } from "../api/expert_state";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import currency from "../../../assets/currency.png";
import podium from "../../../assets/podium.png";
import ribbon from "../../../assets/ribbon.png";
import { useTranslation } from "react-i18next";

export interface AttributeComponentProps{
   title: string;
   value: number;
   assetPhoto: string; 
}


export const AttributeComponent:React.FC<AttributeComponentProps> = (props: AttributeComponentProps) => {
    
    const theme = useTheme();
    
    return (
        <Stack direction='row' spacing={1}>
            <img src={props.assetPhoto} width={40} height={40}/>
            <Stack direction='column'>
                <Typography color={theme.palette.textColor?.main} fontWeight='600'>
                    {Math.floor(props.value)}
                </Typography>
                <Typography color={theme.palette.textColor?.main} >
                    {props.title}
                </Typography>
            </Stack>
        </Stack>
    )
}




export interface ProfileGeneralProps{
    points: number;
    latestPosition: number;
    timesInTopThreePositions: number;
}

export const ProfileGeneral: React.FC<ProfileGeneralProps> =(props: ProfileGeneralProps) => {
    
    const dispatch = useDispatch<AppDispatch>();
    const contentType: ContentType = useSelector((state: RootState) => state.expert.contentType);

    const theme = useTheme();
    const {t} = useTranslation();
    
    return (
        <Box p={2} sx={{
            borderRadius: '10px',
            backgroundColor: '#F7F9FC',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
        }}
        onClick={() => dispatch(changeContent(ContentType.dashboard))}
        >
            <Stack>
                <Box display='flex' flexDirection='row' justifyContent='space-between'>
                    <Typography fontWeight='600' color={theme.palette.textColor?.main}>
                        {t('expertPage.dashboardTitle')}
                    </Typography>
                    {contentType === ContentType.general && <Box sx={{'&:hover':{cursor:'pointer'}}}><NavigateNextOutlinedIcon /></Box>}
                </Box>
                <Box display='flex' flexDirection={'row'} justifyContent='space-between'>
                    <AttributeComponent title={t('expertPage.dashboardPointDescription')} value={props.points} assetPhoto={currency}/>
                    <AttributeComponent title={t('expertPage.dashboardPositionDescription')} value={props.latestPosition} assetPhoto={podium}/>
                    <AttributeComponent title={t('expertPage.dashboardTrophyDescription')} value={props.timesInTopThreePositions} assetPhoto={ribbon}/>
                </Box>
            </Stack>
            
        </Box>
    );
}