import { createSlice } from "@reduxjs/toolkit";
import { ChannelAnnouncementState } from "./channel_announcement_state";
import { clearChannelAnnouncements, fetchAnnouncements, loadMoreAnnouncements } from "./channel_announcement_action";

const initialState:ChannelAnnouncementState = {
    channelAnnouncements: [],
    limit: 10,
    hasMoreAnnouncements: true,
    announcementsAreLoading: true,
}


const channelAnnouncementSlice = createSlice({
    name: 'channelAnnouncement',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(clearChannelAnnouncements, (state) => {
            state.channelAnnouncements = [];
            state.announcementsAreLoading = true;
            state.hasMoreAnnouncements = true;
            state.limit = 10;
        })
        .addCase(fetchAnnouncements, (state, action) => {
            state.channelAnnouncements =  action.payload.announcements;
            state.announcementsAreLoading = false;
            if (state.channelAnnouncements.length < state.limit){
                state.hasMoreAnnouncements = false;
            }
        })
        .addCase(loadMoreAnnouncements, (state, action) => {
            state.limit = state.limit + action.payload.limit;
        })
    }
});

export default channelAnnouncementSlice.reducer;