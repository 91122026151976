import { ChannelBubbleModel, ChannelMessageModel } from "../../../model/response/channel_message_model";
import { AttachmentModel } from "../../../model/response/thread_draft_model";

export enum ChannelChatAction{
    pull,
    send,
    react,
    edit,
    delete,
    none
}

export interface ChannelChatState{
    messages:ChannelBubbleModel[];
    currentMessage: ChannelMessageModel | undefined;

    attachmentsToSend: AttachmentModel[];
    messageToAnswer: ChannelMessageModel | undefined;
    messageToEdit: ChannelMessageModel | undefined;

    isRefreshing: boolean;
    scrollHeight: number;
    limit: number;
    isFirstMessage: boolean;

    action: ChannelChatAction;
}