import { Avatar, Box, Button, CardHeader, InputBase, Stack, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFilePicker } from "use-file-picker";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { ImageFileSizeValidator } from "../../../utils/file_picker_validator/image_picker_validator";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { changeContent, updateBio, updatePicture } from "../api/expert_action";
import { ContentType } from "../api/expert_state";
import React, { useState } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import EditIcon from '@mui/icons-material/Edit';

export interface EditProfileComponentProps{
    isVisible: boolean;
}
export const EditProfileComponent: React.FC<EditProfileComponentProps> = (props: EditProfileComponentProps) => {
    if(props.isVisible){
        return (
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
                sx={{
                    position: 'absolute',
                    width: '40px',
                    height: '40px',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Customize the icon's background
                    borderRadius: '50%', // Make the icon circular
                    cursor: 'pointer',
                    zIndex: 1, // Ensure the icon is above other content
                }}
            >
                <EditIcon />
            </Box>
        )
    }else{
        return (
        <>
        </>)
    }
    
}



export interface ProfileAvatarProps {
    isUser: boolean;
    user: UserModel;
}
export const ProfileAvatar: React.FC<ProfileAvatarProps> = (props: ProfileAvatarProps) => {

    const contentType: ContentType = useSelector((state: RootState) => state.expert.contentType);
    const [edit, setEdit] = useState<boolean>(false);
    const [editedBio, setEditedBio] = useState<string>(props.user.bio!);
    const [isHovered, setIsHovered] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();

    const [openFileSelector, { filesContent, loading }] = useFilePicker({
        accept: ['image/*',],
        readAs: "DataURL",
        limitFilesConfig: { min: 1, max: 1 },
        validators: [
            new ImageFileSizeValidator()
        ],
        onFilesRejected: (data) => {
            const maxLimitExceeded = data.errors[0].maxLimitExceeded;
            const fileSize = data.errors[0].fileSizeToolarge;
            if (props.user) {
                if (maxLimitExceeded) {
                    dispatch(changeDialog(
                        {
                            dialog: DialogType.alertDialog,
                            alertTitle: 'Oops, it appears there was an issue!',
                            alertMessage: 'You have selected too many pictures. Please limit your selection to a maximum of 10 pictures at a time.',
                        }
                    ));
                } else if (fileSize) {
                    dispatch(changeDialog(
                        {
                            dialog: DialogType.alertDialog,
                            alertTitle: 'Oops, it appears there was an issue!',
                            alertMessage: 'The total size of  pictures exeeds the maximum size allowed (2Mb)',
                        }
                    ));

                }
            }

        },
        onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
            const dataUrls: string[] = [];
            filesContent.map((fileContent, index) => {
                dataUrls.push(fileContent.content);
            });
            if (props.user && dataUrls.length === 1) {
                dispatch(updatePicture(
                    { userId: props.user.id, dataUrl: dataUrls[0] }
                ))
            }
        },
    });

    const showEditIcon = (value: boolean) => {
        if (props.isUser) {
            setIsHovered(value);
        }
    }


    return (
        <Stack spacing={1}>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <CardHeader sx={{
                    padding: 0,
                    '.MuiCardHeader-title': {
                        fontWeight: 600,
                        color: theme.palette.textColor?.main
                    }
                }}
                    avatar={
                        <Box onClick={props.isUser ? openFileSelector : undefined}
                            onMouseEnter={() => showEditIcon(true)}
                            onMouseLeave={() => showEditIcon(false)}>
                            <EditProfileComponent isVisible={isHovered}/>
                            <Avatar
                                
                                src={props.user!.photo} />
                        </Box>
                    }
                    title={props.user!.displayName || ''}
                />
                {
                    contentType !== ContentType.general &&
                    <Button
                        onClick={() => dispatch(changeContent(ContentType.general))}
                        variant='outlined' sx={{ textTransform: 'none', borderRadius: '10px' }}>Go back</Button>
                }
            </Box>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <InputBase fullWidth value={editedBio} onChange={(event) => setEditedBio(event.currentTarget.value)} readOnly={!edit} />
                {props.isUser &&
                    <Box onClick={edit ? () => {
                        setEdit(!edit);
                        dispatch(updateBio({ userId: props.user.id, bio: editedBio }));
                    } : () => setEdit(!edit)}>
                        {(edit) ? <DoneOutlinedIcon /> : <EditOutlinedIcon />}
                    </Box>
                }
            </Box>
        </Stack>
    )
}
