import {  Popover, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { sortByCondition } from "../api/feed_action";
import { SortType } from "../api/feed_state";
import { useTranslation } from "react-i18next";

export interface SortOptionPopOverPopOverProps {
    open: boolean;
    anchor: HTMLSpanElement | null;
    onClose: () => void;
}

export const SortOptionPopOver: React.FC<SortOptionPopOverPopOverProps> = (props: SortOptionPopOverPopOverProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();
    const clickOnOption = (sortBy: SortType) => {
        dispatch(sortByCondition(sortBy));
        props.onClose();
    }
    
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={props.onClose}
            slotProps={{
                paper:{
                    sx: {
                        borderRradius: '8px',
                        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                      },
                }
            }}
        >
            <Stack spacing={2} p={2}>
                <Typography sx={{
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }}
                onClick={() => clickOnOption(SortType.mostRecent)}
                >
                    {t('feedPage.sortFirstOption')}
                   
                </Typography>
                <Typography sx={{
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }}
                onClick={() => clickOnOption(SortType.mostDiscussed)}>
                    {t('feedPage.sortSecondOption')}
                </Typography>
            </Stack>
        </Popover>
    );
}