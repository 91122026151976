import { Avatar, Box, Card, CardHeader, Divider, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { CardTile } from "../../../components/card-tile/card-tile";
import { SearchResponceModel } from "../../../model/response/search_responce_model";
import { GPTBody } from "../../gpt/components/gpt_body";
import { SkeletonCardTiles } from "../../../components/skeleton-card/skeleton_card";
import { CardTags } from "../../../components/card-tags/card-tags";
import { DialogType } from "../../dialog/dialog_state";
import { fetchExpert } from "../../expert/api/expert_action";
import { changeDialog } from "../../dialog/dialog_action";
import { CardType } from "../../../model/response/card_model";
import { AnnouncementTile } from "../../../components/announcement-tile/announcement_tile";
import { SarchOnSelectedChannelComponent } from "../components/search_on_selected_channel";
import { setTabIndex } from "../api/search_action";
import { DocumentAction } from "../../channel_document/components/document_action";
import { UserModel } from "../../../model/response/user_model";
import { useTranslation } from "react-i18next";

const SearchPage = () => {
    const searchResults: SearchResponceModel | null = useSelector((state: RootState) => state.search.searchResults);
    const searchResultsIsLoading: boolean = useSelector((state: RootState) => state.search.searchResultsIsLoading);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);

    const tab: number = useSelector((state: RootState) => state.search.tabIndex);
    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    

    const handleTabChange = (event: React.SyntheticEvent, tabNumber: number) => {
        dispatch(setTabIndex(tabNumber));
    };

    const numberOfAnnouncementsFound = searchResults?.allRankedCards.filter((card) => card.type === CardType.announcement).length || 0;
    const numberOfCardsFound = searchResults?.allRankedCards.filter((card) => card.type === CardType.discussion).length || 0;
    const numberOfUserFound = searchResults?.allRankedUsers.length || 0;

    const numberOfColleaguesFound = searchResults?.colleagues.length || 0;
    const numberOfDocumentsFound = searchResults?.documents.length || 0;
    const remainingHeight = 120;

    return (
        <Box display={'flex'} justifyContent={'center'} flexGrow={1} sx={{ backgroundColor: '#F7F9FC' }}>
            <Card elevation={0}
                sx={{
                    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                    width: '65vw',
                    height: `calc(100vh - ${remainingHeight}px)`,
                    borderRadius: 5,
                    marginTop: 2,
                    padding: 2,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                {
                    searchResultsIsLoading && <SkeletonCardTiles />
                }
                {
                    !searchResultsIsLoading && <SarchOnSelectedChannelComponent/>
                }
                {
                    !searchResultsIsLoading &&
                    <Tabs value={tab} onChange={handleTabChange} centered sx={{ width: '100%', marginBottom: 2 }}
                    >
                        <Tab label={ t('searchPage.discussionTab', {value:numberOfCardsFound})} sx={{ textTransform: 'none', flexGrow: '1' }} />
                        <Tab label={ t('searchPage.announcementTab', {value:numberOfAnnouncementsFound})} sx={{ textTransform: 'none', flexGrow: '1' }} />
                        <Tab label="Sybil" sx={{ textTransform: 'none', flexGrow: '1' }} />
                        <Tab label={t('searchPage.expertTab', {value:numberOfUserFound})} sx={{ textTransform: 'none', flexGrow: '1' }} />
                        <Tab label={t('searchPage.documentTab', {value:numberOfDocumentsFound})} sx={{ textTransform: 'none', flexGrow: '1' }} />
                        <Tab label={t('searchPage.colleaguesTab', {value:numberOfColleaguesFound})} sx={{ textTransform: 'none', flexGrow: '1' }} />
                    </Tabs>


                }
                {
                   tab == 1 && !searchResultsIsLoading && searchResults !== null &&
                   <Box overflow='scroll' p={1}>
                       <Stack spacing={1}>
                           {searchResults.allRankedCards.filter((card) => card.type === CardType.announcement).map((card, index) =>
                               <AnnouncementTile key={card.id} {...card} />
                           )}
                       </Stack>
                   </Box> 
                }

                {tab == 0 && !searchResultsIsLoading && searchResults !== null &&
                    <Box overflow='scroll' p={1}>
                        <Stack spacing={1}>
                            {searchResults.allRankedCards.filter((card) => card.type === CardType.discussion).map((card, index) =>
                                <CardTile stretch={false} isSearchedCard={true} key={card.id} {...card} />
                            )}
                        </Stack>
                    </Box>

                }
                {tab == 2 && !searchResultsIsLoading &&  
                <Box display='flex' sx={{height:`calc(100vh - ${remainingHeight + 75}px)`}}>
                    <GPTBody />
                </Box>
                
                }
                {
                    tab == 3 && !searchResultsIsLoading && searchResults !== null &&
                    <Box width={'100%'} overflow='scroll'>
                        <Stack>
                            {searchResults.allRankedUsers.map((user, index) =>
                                <Stack key={user.userId} spacing={2} p={1} paddingLeft={'5%'}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                    paddingRight={'5%'}>
                                    <CardHeader sx={{
                                        p: 0,
                                        '.MuiCardHeader-title': {
                                            fontWeight: 600,
                                            color: theme.palette.textColor?.main
                                        }
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        dispatch(fetchExpert({expertId:user.userId}));
                                        dispatch(changeDialog({dialog: DialogType.expertDialog,}));
                                    }}
                                        avatar={<Avatar src={user.photo} />}
                                        title={user.displayName}
                                        subheader={user.bio}
                                    />
                                    <CardTags tags={user.tags} />
                                    <Divider light />
                                </Stack>

                            )}
                        </Stack>
                    </Box>

                }
                {
                    tab == 4 && !searchResultsIsLoading && searchResults !== null && 
                    <Box width={'100%'} overflow='scroll'>
                        <Stack>
                            {searchResults.documents.map((document, index) =>
                                <Stack key={index} spacing={1} p={1} paddingLeft={'5%'}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                paddingRight={'5%'}>
                                    <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Stack >
                                            <Typography fontWeight={600} color={theme.palette.textColor?.main}>
                                                {document.fileName}
                                            </Typography>
                                            <Typography variant="body2" color={theme.palette.textColor?.main}>
                                                {t('searchPage.documentDescription', {value:document.uploaderUser.uploaderDisplayName})}
                                            </Typography>
                                        </Stack>
                                        <DocumentAction 
                                            id={document.documentId} 
                                            organizationId={user!.organizationId} 
                                            url={document.url} 
                                            fileName={document.fileName} 
                                            isOwner={false}/>
                                    </Box>
                                    <Divider sx={{ opacity: 0.6 }}/>
                                </Stack>
                            )}
                        </Stack>
                    </Box>
                }
                {tab == 5 && !searchResultsIsLoading && searchResults !== null &&
                    <Box width={'100%'} overflow='scroll'>
                        <Stack >
                            {searchResults.colleagues.map((colleague, index) =>
                                <Stack key={colleague.userId} spacing={2} p={1} paddingLeft={'5%'} paddingRight={'5%'}>
                                    <CardHeader
                                    sx={{
                                        p: 0,
                                        '&:hover': {
                                            cursor: 'pointer'
                                        },
                                        '.MuiCardHeader-title': {
                                            fontWeight: 600,
                                            color: theme.palette.textColor?.main
                                        }
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        dispatch(fetchExpert({expertId:colleague.userId}));
                                        dispatch(changeDialog({dialog: DialogType.expertDialog,}));
                                    }}
                                    avatar={<Avatar src={colleague.photo} />}
                                    title={colleague.displayName}
                                    subheader={colleague.bio}
                                />
                                <Divider light />
                                </Stack>
                                
                            )}
                        </Stack>
                    </Box>
                }
            </Card>
        </Box>
    );
}

export default SearchPage;
