
export enum Page{
    HomePage = "home",
    ChannelPage = "channel",
    ChatPage = "direct",
    DocumentPage = "document",
    LeaderboardPage = "leaderboard",
    GPTPage = "gpt",
    ThreadPage = "thread",
    SearchPage  = "search",
    LoadingPage = "loading",
    YourThreadPage = "your_thread",
    FollowedThreadPage = "followed",
    CustomerServicePage = "customer_service",
    none = "none"
}

export interface NavigationState{
    currentPage: Page;
    previousPage: Page;
    drawerIsOpen: boolean;
}