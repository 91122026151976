import { createSlice } from "@reduxjs/toolkit";
import { AnnouncementState } from "./announcement_state";
import { fetchAnnouncements, loadMore, viewAnnouncemntInNotification } from "./announcement_action";
import { CardModel } from "../../../model/response/card_model";


const initialState: AnnouncementState = {
    announcements: [],
    hasMore: true,
    limit:20,
}

const announcementSlice = createSlice({
    name: 'announcement',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder            
            .addCase(fetchAnnouncements, (state, payload) => {
                state.announcements =  payload.payload.feed;
                if (state.announcements.length < state.limit){
                    state.hasMore = false;
                }
            
            })
            .addCase(loadMore, (state, payload) => {
                state.limit = state.limit + payload.payload.limit;
            })

            .addCase(viewAnnouncemntInNotification, (state, payload) => {
                const filteredAnnouncements = state.announcements.map((announcement) => {
                    if (announcement.id === payload.payload.threadId) {
                        const data: CardModel = {
                            ...announcement,
                            isClosed: true,
                        }
                        return data;
                    } else {
                        return announcement;
                    }

                });

                const sortedAnnouncements = filteredAnnouncements.sort((a, b) => {
                    if (a.isClosed !== b.isClosed) {
                      return a.isClosed ? 1 : -1; 
                    }
                  
                    const dateA = new Date(a.createdAt);
                    const dateB = new Date(b.createdAt);
                    return dateB.getTime() - dateA.getTime(); 
                  });

                state.announcements = sortedAnnouncements;
            })

    }
});

export default announcementSlice.reducer;