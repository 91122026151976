import './App.css';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './app/store';
import { useEffect } from 'react';
import { alreadyLoggedUser } from './features/auth/api/authAction';
import Router from "./routes";
import { ThemeProvider } from '@mui/material';
import  appTheme  from './utils/app_theme';
import './utils/quill_config';
import { registerServiceWorker } from './utils/service_worker';
import {app} from '@microsoft/teams-js';

registerServiceWorker();

function App() {
  const dispatch = useDispatch<AppDispatch>();
  
  useEffect(()=> {
    const initializeMicrosoftTeams = async () => {
      try{
        await app.initialize();
      }catch(error:any){
        console.log(error.toString());
      }
    }
    
    const fetchData = async () => {
      await dispatch(alreadyLoggedUser());
    };

    initializeMicrosoftTeams();
    fetchData();
    
  },[dispatch]);


  return (
    <>
    <ThemeProvider theme={appTheme}>
      <Router />
    </ThemeProvider>
    </>
    
  );
}

export default App;
