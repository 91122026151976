import './card-tags.css';
import React from 'react';
import { Tag, TagProps } from '../tag/tag';
import { Box } from '@mui/material';

export interface CardTagsProps {
    className?: string;
    tags: TagProps[];
}

export const CardTags: React.FC<CardTagsProps> = (props: CardTagsProps) => (
    <Box display={'flex'}  sx={{
        overflowY:'scroll',
        '&::-webkit-scrollbar': {
            display:'none'
          }
     }}>
        {
            props.tags.map((tag) => <Tag key ={tag.id} id = {tag.id} tagName = {tag.tagName} />)
        }
    </Box>
);