import { DocumentData } from "firebase/firestore";
import { CardModel } from "../../../model/response/card_model";

export enum SortType{
    mostDiscussed,
    mostRecent
}
export interface FeedState{
    feed: CardModel[];
    limit: number;
    hasMore: boolean;
    feedIsLoading: boolean;
    
    filterByChannels:string[];
    firstChannelSelected: string | undefined;
    sortBy: SortType;

}