import { Box, Stack, Typography, useTheme } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import { SortOptionPopOver } from "./sort_pop_over";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { SortType } from "../api/feed_state";
import { useTranslation } from "react-i18next";

export const SortComponent: React.FC = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const sortType: SortType = useSelector((state: RootState) => state.feed.sortBy);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [anchorEl, setAnchor] = useState<HTMLDivElement | null>(null);

    
    const openPopOver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsOpen(true);
        setAnchor(event.currentTarget);
    };

    const closePopOver = () => {
        setIsOpen(false);
        setAnchor(null);
    }

    return (
        <Stack direction='row' spacing={2}>
            <Typography color={theme.palette.textColor?.main}>
                {t('feedPage.sortButton')}
            </Typography>
            <Stack direction='row' spacing={1} onClick={(event) => openPopOver(event)}>
                <Typography fontWeight={600} color={theme.palette.textColor?.main}>
                    {sortType === SortType.mostRecent?t('feedPage.sortFirstOption'):t('feedPage.sortSecondOption')}
                </Typography>
                <Box display={'flex'} color={theme.palette.textColor?.main}>
                    <KeyboardArrowDownIcon />
                </Box>
            </Stack>
            <SortOptionPopOver anchor={anchorEl} open={isOpen} onClose={() => closePopOver()}/>
        </Stack>
    );
}