import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ChannelModel, ChannelPermissions } from "../../../model/response/channel_model";
import {collection, deleteDoc, doc, getDoc, getDocs, getFirestore,query,updateDoc, where } from "firebase/firestore";
import { debugPrint } from "../../../utils/debug_print";
import { clearChannelDiscussionState } from "../../channel_discussion/api/channel_discussion_action";
import { clearChannelMembersCache } from "../../channel_members/api/channel_member_action";
import { clearChannelAnnouncements } from "../../channel_announcement/api/channel_announcement_action";
import { clearChannelChatCache } from "../../channel_chat/api/channel_chat_action";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";

export const displaySearchComponent = createAction(
    'channel/displaySearchComponent',
    function prepare(display: boolean) {
        return {
            payload: {
                display
            }
        }
    });

    export const changeSearchQuery = createAction(
        'channel/changeSearchQuery',
        function prepare(query: string) {
            return {
                payload: {
                    query
                }
            }
        });


export const fetchChannels = createAction(
    'channel/fetchChannels',
    function prepare(channels: ChannelModel[]) {
        return {
            payload: {
                channels: channels
            },
        }
    });
export const fetchCurrentChannel = createAsyncThunk(
    'channel/fetchCurrentChannel',
    async (data:{
        userId: string;
        channelId: string;
    }, {dispatch, rejectWithValue}) => {
        try{
            const db = getFirestore();
            const query =  doc(db, 'Users', data.userId, 'ChannelSubscriptions',data.channelId);
            const documentData = await getDoc(query);
            const selectedChannel = documentData.data() as ChannelModel;
            dispatch(selectChannel({channel: selectedChannel, clearCache:true}));

            return {
                channel: selectedChannel
            }
        }catch(error:any){
            debugPrint(error.toString(),'channel/fetchCurrentChannel')
            rejectWithValue(error.toString());
        }
    }
);

export const fetchLastChannel = createAsyncThunk(
    'channel/fetchLastChannel',
    async (data:{
        userId: string;
        channelId: string;
    }, {dispatch, rejectWithValue}) => {
        try{
            const db = getFirestore();
            const channelQuery =  query(collection(db,'Users',data.userId, 'ChannelSubscriptions'),where('channelId','==',data.channelId));
            const documentData = await getDocs(channelQuery);
            if (documentData.docs.length > 0){
                const selectedChannel = documentData.docs[0].data() as ChannelModel;
                dispatch(selectChannel({channel: selectedChannel, clearCache:true}));
            }
        }catch(error:any){
            debugPrint(error.toString(),'channel/fetchCurrentChannel')
            rejectWithValue(error.toString());
        }
    }
);


export const selectChannel = createAsyncThunk(
    'channel/selectChannel',
    (data: {
        channel: ChannelModel | null;
        clearCache: boolean;
    }, {dispatch}) => {
        if(data.clearCache){
            dispatch(clearChannelDiscussionState());
            dispatch(clearChannelMembersCache());
            dispatch(clearChannelAnnouncements());
            dispatch(clearChannelChatCache());
        }
        
        return {
            channel: data.channel,
        }
    });

export const viewChannelAnnouncement = createAction(
    'channel/viewChannelAnnouncement',
    function prepare(threadId: string) {
        return {
            payload: {
                threadId,
            }
        }
    }
);

export const activateChannelNotification = createAsyncThunk(
    'channel/activateChannelNotification',
    async (data: {
        userId: string;
        channelId: string;
        isMuted: boolean;
    }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const documentReference = doc(db, 'Users', data.userId, 'ChannelSubscriptions', data.channelId);
            
            await updateDoc(documentReference, {
                isMuted: data.isMuted
            });

        } catch (error: any) {
            debugPrint(error.toString(),'channel/activateChannelNotification');
            rejectWithValue(error.toString());
        }
    });

    export const leaveChannel = createAsyncThunk(
        'channel/leaveChannel',
        async (data: {
            userId: string;
            channelId: string;
        }, { rejectWithValue, dispatch }) => {
            try {
                dispatch(changeDialog({dialog: DialogType.none}))
                const db = getFirestore();
                const documentReference = doc(db, 'Users', data.userId, 'ChannelSubscriptions', data.channelId);
                await deleteDoc(documentReference);
                
            } catch (error: any) {
                rejectWithValue(error.toString());
            }
        });


export const changeChannelSettings = createAsyncThunk(
    'channel/changeChannelSettings',
    async (data: {
        organizationId: string;
        channelId: string;
        permissions: ChannelPermissions[];
    }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const documentReference = doc(db, 'Organizations', data.organizationId, 'Channels', data.channelId);
            
            await updateDoc(documentReference, {
                permissions: data.permissions,
            });
        
        } catch (error: any) {
            rejectWithValue(error.toString());
        }
    });


