import { Avatar, Badge, Box, IconButton, MenuItem, Stack, Typography, styled, useTheme } from "@mui/material";
import { ChatModel } from "../../../model/response/chat_model";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";


const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export interface ChatHeaderProps{
  userPhoto: string;
  userDisplayName: string;
}

export const ChatHeader = (props: ChatHeaderProps) => {
  
  const theme = useTheme();
  const currentChat: ChatModel | null = useSelector((state: RootState) => state.chat.currentChat);


  return (
    <>
      <Box
        p={2}
        width={"100%"}
        
      >
        <Stack
          alignItems={"center"}
          direction={"row"}
          sx={{ width: "100%", height: "100%" }}
          justifyContent="space-between"
        >
          <Stack
            onClick={() => {
            }}
            spacing={2}
            direction="row"
          >
            <Box>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar src={currentChat!== null? currentChat.user.responderPhoto:props.userPhoto}
                />
              </StyledBadge>
            </Box>
            <Box>
            <Typography variant="subtitle2">
                {currentChat!== null? currentChat.user.responderDisplayName:props.userDisplayName}
              </Typography>
              <Typography variant="caption">
                online
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>    
    </>
  );
};

