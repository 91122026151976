import { Box, Stack } from "@mui/material"
import { MessageModel, TicketMessageModel } from "../models/response/TicketMessageModel"
import { AppDispatch, RootState } from "../../../app/store"
import { useDispatch, useSelector } from "react-redux"
import { TicketModel } from "../models/response/TicketModel"
import { useEffect } from "react"
import { OrganizationModel } from "../../../model/response/organization_model"
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore"
import { CustomerServiceChatBubble } from "./customer_service_chat_bubble"
import { UserModel } from "../../../model/response/user_model"
import { CustomerServiceChatDate } from "./customer_service_chat_date"
import { fetchMessages } from "../api/customer_service_action"
import logo from '../../../assets/logo.svg';
export const CustomerServiceChatRoom:React.FC = () => {
    
    const messages:TicketMessageModel[] = useSelector((state: RootState) => state.customerService.messages);
    const currentTicket: TicketModel | undefined = useSelector((state: RootState) => state.customerService.currentTicket);
    const organization: OrganizationModel | null = useSelector((state: RootState) => state.org.currentOrganization);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(
        () => {
            if(organization && currentTicket){
                const db = getFirestore();
                const q = query(collection(db, "Organizations",organization.id, "Tickets",currentTicket.id, "Messages",),orderBy('createdAt','asc'),);
                
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    dispatch(fetchMessages(querySnapshot.docs.map(message => message.data() as MessageModel)))
                });
    
            return () => {
                unsubscribe(); 
              };
            }

        },[currentTicket]
    );
    return(
        <Box display='flex' flexGrow={1} p={2}
        
        sx={{
            position: "relative",
            flexGrow: 1,
            overflowY:'scroll',
            '&::-webkit-scrollbar': {
                display: 'none', // Customize the width of the scrollbar
            },}}>
            <Stack spacing={0} width={'100%'}>
                {messages.map((message, index) => {
                    var isLeading = true;
                    
                    if (index >0){
                        const previousMessage = messages[index - 1];
                        const previousMessageDate = new Date(previousMessage.createdAt);
                        const currentMessageDate = new Date(message.createdAt); 

                        const difference = currentMessageDate.getTime() - previousMessageDate.getTime();
                        const seconds = Math.floor(difference / 1000);
                        
                    }
                    if(!message.isDate){
                        return <CustomerServiceChatBubble 
                        key={index} isLeading={isLeading} 
                        attachments={message.attachments}
                        author={!message.isFromSylloTips?'SylloTips':user!.displayName} 
                        timestamp={message.createdAt} 
                        text={message.text} 
                        photo={!message.isFromSylloTips?logo:user!.photo} />
                    }else{
                        return <CustomerServiceChatDate key={index} date={message.createdAt}/>
                    }
                    
                })}
            </Stack>
        </Box>
    )
}