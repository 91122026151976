import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import { AppDispatch, RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { DialogType } from "../../features/dialog/dialog_state";
import { changeDialog } from "../../features/dialog/dialog_action";

export const AlertDialog = () => {
    const currentDialog: DialogType = useSelector((state:RootState) => state.dialog.currentDialog);
    const title: string | undefined = useSelector((state:RootState) => state.dialog.alertTitle);
    const message: string | undefined = useSelector((state:RootState) => state.dialog.alertMessage);
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Dialog 
        PaperProps={{ sx: { borderRadius: "10px" } }}
        open={currentDialog === DialogType.alertDialog} onClose={() => {
            dispatch(changeDialog(
                {
                    dialog: DialogType.none,
                }
            ))
        }}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
        </Dialog>
    )
}


