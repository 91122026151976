import { Box, useTheme } from "@mui/material";
import React from "react";
import { FilterComponent } from "./filter";
import { SortComponent } from "./sort";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
export const FilterAndSort: React.FC = () => {
    const theme = useTheme();
    const currentFilters: string[] = useSelector((state:RootState) => state.feed.filterByChannels )

    return (
        <Box width={'60vw'}display='flex' justifyContent='space-between'>
            <FilterComponent/>
            <SortComponent/>   
        </Box>
    );
}