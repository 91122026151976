import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ChannelModel } from '../../../model/response/channel_model';
import { AppDispatch, RootState } from "../../../app/store";
import { ChannelList } from "../components/channel_list";
import { DialogType } from "../../dialog/dialog_state";
import { changeDialog } from "../../dialog/dialog_action";
import { t } from "i18next";
import { ChannelSidebarTitle } from "../components/channel_sidebar_title";
import { displaySearchComponent } from "../api/channel_action";
import { ChannelButton } from "../components/channel_button";
import { goForward } from "../../navigation/navigation_action";
import { Page } from "../../navigation/navigation_state";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ChannelSidebar: React.FC = () => {

    const displaySearch: boolean = useSelector((state: RootState) => state.channel.displaySearch);
    const channels: ChannelModel[] = useSelector((state: RootState) => state.channel.channels);
    const mutedChannels: ChannelModel[] = useSelector((state: RootState) => state.channel.mutedChannels);
    const page: Page = useSelector((state: RootState) => state.navigation.currentPage);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {t} = useTranslation();


    const goToYourDiscussion = () => {
        dispatch(goForward(Page.YourThreadPage));
        navigate('/your-threads');
    }
    const goToFollowed = () => {
        dispatch(goForward(Page.FollowedThreadPage));
        navigate('/followed-threads');
    }
        
    return (
        <Box
            sx={{
            position: "relative",
            width: '320px',
            minWidth:'320px',
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            zIndex: 2,
            }}
        >
            <ChannelSidebarTitle 
            onFilterClick={()=> dispatch(displaySearchComponent(!displaySearch))}
            onClick={()=>dispatch(changeDialog({dialog: DialogType.createChannelDialog}))} 
            title={t('channelSideBar.title')}/>
            <Box height='90%'
            sx={{ overflowY: "scroll", '&::-webkit-scrollbar': {display: 'none',}}}>
                <ChannelButton title={t('channelSideBar.yourDiscussionCaption')} onClick={goToYourDiscussion} isClicked={page === Page.YourThreadPage}/>
                <ChannelButton title={t('channelSideBar.followedCaption')} onClick={goToFollowed} isClicked={page === Page.FollowedThreadPage}/>
                <ChannelList isMuted={false} channels={channels}/>
                <ChannelList isMuted={true} channels={mutedChannels}/>
            </Box>
        </Box>
    );
}

