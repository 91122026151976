import { Box, IconButton, Stack, TextField, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilePicker } from "use-file-picker";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { MessageModel } from "../models/response/TicketMessageModel";
import { useDebounce } from "@uidotdev/usehooks";
import { addAttachments, sendTicketMessage, updateText } from "../api/customer_service_action";
import { CardImages } from "../../../components/card-images/card_images";
import { AttachmentModel, FileType } from "../../../model/response/thread_draft_model";
import { SendMessageModel } from "../models/request/send_message_model";
import { UserModel } from "../../../model/response/user_model";
import { OrganizationModel } from "../../../model/response/organization_model";
import { TicketModel } from "../models/response/TicketModel";

const StyledInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-input": {
      paddingTop: "12px !important",
      paddingBottom: "12px !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "90px",
    }
  }));
export const CustomerServiceFooter: React.FC = () => {

    const [text, setText] = useState<string>('');
    const debouncedText = useDebounce(text, 100);
    
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const organization: OrganizationModel | null = useSelector((state: RootState) => state.org.currentOrganization);
    const ticket: TicketModel | undefined = useSelector((state: RootState) => state.customerService.currentTicket);
    const messageDraft: MessageModel | undefined = useSelector((state: RootState) => state.customerService.messageDraft);
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();
    
    const [openFileSelector, { filesContent, loading }] = useFilePicker({
        readAs: "DataURL",
        limitFilesConfig: { min: 1 },
        
        onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
        
          const attachments:AttachmentModel[] = [];
          
          filesContent.map((fileContent, index) => {
            const fileName = plainFiles[index].name;
            const dataUrl = fileContent.content;

            const attachment: AttachmentModel = {
              name: fileName,
              type: fileContent.content.startsWith('data:image/')?FileType.IMAGE:FileType.DOCUMENT,
              url: dataUrl,
              storageRef: ""
            }

            attachments.push(attachment)
          });

          dispatch(addAttachments(attachments))
          
        },
      });

    useEffect(
      () => {
        dispatch(updateText(debouncedText))
      },[debouncedText]
    );

    useEffect(
      () => {
        if(messageDraft === undefined){
          setText(''); 
        }
      }, [messageDraft] 
    );

    const sendMessage = () => {
      
      if(messageDraft && user && organization && ticket){
        const data: SendMessageModel = {
          organizationId: user.organizationId,
          organizationName: organization.name,
          ticketId: ticket.id,
          message: messageDraft
        };

        dispatch(sendTicketMessage(data));
        setText(''); 
      }
    }

    if(ticket && ticket.isSolved) return <></>;


    return(
    <Box
      p={2}
      maxWidth={"100%"}
      sx={{
        boxShadow: "0px -4px 2px -4px rgba(0, 0, 0, 0.25)",
      }}
    >
      {messageDraft &&  <CardImages images={messageDraft.attachments} isDeleteable={false}/>}
      <Stack direction="row" alignItems={"center"} spacing={3}>
        <Stack sx={{ width: "100%" }}>
          <StyledInput
            onChange={(event) => setText(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                sendMessage();
              }
            }}
            fullWidth
            placeholder={t('chatPage.chatFooterHelperText')}
            variant="filled"
            value={text}
            InputProps={{
              value:text,
              disableUnderline: true,
              sx: { borderRadius: '10px' },
              startAdornment:
                <IconButton onClick={() => openFileSelector()}>
                  <AttachFileIcon />
                </IconButton>,
            }
          }
          />
        </Stack>
        <Box
          sx={{
            height: 48,
            width: 48,
            borderRadius: 1.5,
          }}
        >
          <Stack
            sx={{ height: "100%" }}
            alignItems={"center"}
            justifyContent="center"
          >
            
            <IconButton disabled={text.trim() === ''}
            onClick={sendMessage}
              >
              <SendIcon />
            </IconButton>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}