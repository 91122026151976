import { Avatar, Box, CardHeader, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { AppDispatch } from '../../app/store';
import { fetchExpert } from '../../features/expert/api/expert_action';
import { useDispatch } from 'react-redux';
import { changeDialog } from '../../features/dialog/dialog_action';
import { DialogType } from '../../features/dialog/dialog_state';

export interface CommentProps {
    className?: string;
    id: string;
    plainText: string;
    user: {
        responderId: string;
        responderDisplayName: string;
        responderPhoto: string;
    };
    createdAt: string;
    updatedAt: string;
}

export const Comment: React.FC<CommentProps> = (props: CommentProps) => {

    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const getElapsedTime = (date: string) => {

        const creationDate = new Date(date);
        const difference = new Date().getTime() - creationDate.getTime();
        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const years = Math.floor(days / 365);
        if (seconds < 0) {
            return 'now';
        } else if (seconds < 60) {
            return `${seconds}s ago`;
        } else if (minutes < 60) {
            return `${minutes}m ago`;
        } else if (hours < 24) {
            return `${hours}h ago`;
        } else if (days < 365) {
            return `${days}d ago`;
        } else {
            return `${years}y ago`;
        }
    }

    return (
        <Stack direction={'row'} spacing={2}>
            <Box onClick={(event) => {
                            event.stopPropagation();
                            dispatch(fetchExpert({ expertId: props.user.responderId }));
                            dispatch(changeDialog({ dialog: DialogType.expertDialog, }));
                        }}>
                <Avatar
                 src={props.user.responderPhoto} aria-label="recipe" sx={{ marginTop: 1 }} />
            </Box>
            <Box sx={{ backgroundColor: '#F0F2F5' }} borderRadius={'10px'} p={1} flexGrow={1}>
                <Stack direction={'column'}>
                    <CardHeader
                        onClick={(event) => {
                            event.stopPropagation();
                            dispatch(fetchExpert({ expertId: props.user.responderId }));
                            dispatch(changeDialog({ dialog: DialogType.expertDialog, }));
                        }}
                        sx={{
                            padding: 0,
                            margin: 0,
                            '.MuiCardHeader-title': {
                                padding: 0,
                                fontWeight: 600,
                                fontSize: '14px',
                                color: theme.palette.textColor ?.main
                        },
                            '.MuiCardHeader-subheader': {
                                fontSize: '12px',
                            }
                        }}
                        title={props.user.responderDisplayName}
                        subheader={getElapsedTime(props.createdAt)}
                    />
                    <Typography color={theme.palette.textColor ?.main}>{props.plainText}</Typography>
                </Stack>
            </Box>
        </Stack>
    );
}

