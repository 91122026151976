import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import './editor_toolbar.css'
import { useFilePicker } from 'use-file-picker';
import { ImageFileSizeValidator } from '../../../utils/file_picker_validator/image_picker_validator';
import { changeDialog } from '../../dialog/dialog_action';
import { DialogType } from '../../dialog/dialog_state';
import { uploadImagesOnAnswer } from '../api/thread_action';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { UserModel } from '../../../model/response/user_model';
import { CardModel } from '../../../model/response/card_model';
import { AnswerDraftModel } from '../../../model/response/answer_draft_model';
import { FileType } from '../../../model/response/thread_draft_model';

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

export interface ToolBarProps {
  id?: string;
  className?: string;
}

// Quill Toolbar component
export const EditorToolbar = (props: ToolBarProps) => {

  const dispatch = useDispatch<AppDispatch>();
  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  const answerDraft: AnswerDraftModel | undefined = useSelector((state: RootState) => state.thread.answerDraft);
  const card: CardModel | undefined = useSelector((state: RootState) => state.thread.card);
    
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: ['.pdf','image/*'],
    readAs: "DataURL",
    limitFilesConfig: { min: 1 },
    validators: [
      new ImageFileSizeValidator()
    ],
    onFilesRejected: (data) => {
      const maxLimitExceeded = data.errors[0].maxLimitExceeded;
      const fileSize = data.errors[0].fileSizeToolarge;
      if (user) {
        if (maxLimitExceeded) {
          dispatch(changeDialog(
            {
              dialog: DialogType.alertDialog,
              alertTitle: 'Oops, it appears there was an issue!',
              alertMessage: 'You have selected too many pictures. Please limit your selection to a maximum of 10 attachments at a time.',
            }
          ));
        } else if (fileSize) {
          dispatch(changeDialog(
            {
              dialog: DialogType.alertDialog,
              alertTitle: 'Oops, it appears there was an issue!',
              alertMessage: 'The total size of  pictures exeeds the maximum size allowed (10Mb)',
            }
          ));

        }
      }

    },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      const dataUrls: string[] = [];
      const names: string[] = [];
      const sizes: string[] = [];
      const fileTypes: FileType[] = [];
      filesContent.map((fileContent, index) => {

        const fileSize = (fileContent.size / (1024 * 1024)).toFixed(2);
        sizes.push(fileSize);
        names.push(plainFiles[index].name);
        dataUrls.push(fileContent.content);

        if (fileContent.content.startsWith('data:image/')) {
          fileTypes.push(FileType.IMAGE);
      }else{
          fileTypes.push(FileType.DOCUMENT);
      }
      });

     

      if (user && card) {
        
        dispatch(uploadImagesOnAnswer(
          {
            images: dataUrls,
            fileNames: names,
            fileTypes:fileTypes,
            userId: user.id,
            cardId: card.id,
            draftId: answerDraft?.id,
          }))
        
      }
    },
  });

  return (

    <div id={props.id ? props.id : "toolbar"} className='answer-toolbar'>
      <script src="https://cdn.quilljs.com/1.3.6/quill.js"></script>
      <link href="https://cdn.quilljs.com/1.3.7/quill.snow.css" rel="stylesheet"></link>
      <span className="ql-div">
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
        </span>
        <span className="ql-formats">
          <button className="ql-blockquote" />
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
          
            <button onClick={() => openFileSelector()}>
              <AttachFileIcon />
            </button>
        </span>
        <span className="ql-formats">
          <button className="ql-code-block" />
        </span>
      </span>


    </div>
  );

}

export default EditorToolbar;
