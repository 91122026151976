import { Stack } from "@mui/material";
import { CustomerServiceFooter } from "./customer_service_footer";
import { CustomerServiceChatRoom } from "./customer_service_chat_room";
import { CustomerServiceChatHeader } from "./customer_service_chat_header";
import { NoTicketSelected } from "./no_ticket_selected";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { TicketModel } from "../models/response/TicketModel";
export const CustomerServiceComponent:React.FC = () => {

    const currentTicket: TicketModel | undefined = useSelector((state: RootState) => state.customerService.currentTicket);
    
    if(currentTicket)
    return (
        <Stack
            height={"100%"}
            maxHeight={"100vh"}
            width={`calc(96vw - 320px )`}
            maxWidth={`calc(96vw - 320px )`}
        >
            <CustomerServiceChatHeader/>
            <CustomerServiceChatRoom/>     
            <CustomerServiceFooter/>
        </Stack>
    );

    return (
        <Stack
            height={"100%"}
            maxHeight={"100vh"}
            width={`calc(96vw - 320px )`}
            maxWidth={`calc(96vw - 320px )`}
        >
             <NoTicketSelected/>
        </Stack>
       
    )

};

