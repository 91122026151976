import {
  Stack,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";

export enum ChatBubbleType{
  IsFirst,
  IsInTheMiddle,
  IsLast,
  IsAlone
}

export interface TextMessageProps {
  incoming: boolean;
  message:string;
  bubble: ChatBubbleType;
}


export const TextMessage = ( message:TextMessageProps ) => {
  const theme = useTheme();

  const computeBubbleBox = () => {
    var borderTopLeftRadius = 24;
    var borderTopRightRadius = 24;
    var borderBottomLeftRadius = 24;
    var borderBottomRightRadius = 24;
    
    if (message.incoming){
      switch(message.bubble){
        case ChatBubbleType.IsAlone:
          break;
        case ChatBubbleType.IsFirst:
          borderBottomLeftRadius = 2;
          break;
        case ChatBubbleType.IsInTheMiddle:
          borderTopLeftRadius = 2;
          borderBottomLeftRadius = 2;
          break;
        case ChatBubbleType.IsLast:
          borderTopLeftRadius = 2;
      }
    }else{
      switch(message.bubble){
        case ChatBubbleType.IsAlone:
          break;
        case ChatBubbleType.IsFirst:
          borderBottomRightRadius = 2;
          break;
        case ChatBubbleType.IsInTheMiddle:
          borderTopRightRadius = 2;
          borderBottomRightRadius = 2;
          break;
        case ChatBubbleType.IsLast:
          borderTopRightRadius = 2;
      }
    }

    return {
      backgroundColor: message.incoming
        ? theme.palette.chatIncomingBubbleColor!.main
        : theme.palette.chatOngoingBubbleColor!.main,
      borderTopLeftRadius: borderTopLeftRadius,
      borderTopRightRadius: borderTopRightRadius,
      borderBottomLeftRadius:borderBottomLeftRadius,
      borderBottomRightRadius:borderBottomRightRadius,
      
      width: "max-content",
      maxWidth: '40%'
    }
  }

  return (
    <Stack direction="row" justifyContent={message.incoming ? "start" : "end" }>
      <Box
        px={1.5}
        py={1}
        sx={computeBubbleBox()}
      >
        <Typography
          color={message.incoming?theme.palette.textColor!.main:'white'}
          variant="body2"
        >
          {message.message}
        </Typography>
      </Box>
    </Stack>
  );
};

export interface MediaMessageProps {
  incoming: boolean;
  message?:string;
  photo: string;
}

const MediaMsg = (message: MediaMessageProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row" justifyContent={message.incoming ? "start" : "end"}>
      <Box
        px={1.5}
        py={1.5}
        sx={{
          backgroundColor: message.incoming
            ? theme.palette.chatIncomingBubbleColor!.main
            : theme.palette.chatOngoingBubbleColor!.main,
          borderRadius: 1.5,
          width: "max-content",
        }}
      >
        <Stack spacing={1}>
          <img
            src={message.photo}
            alt={message.message}
            style={{ maxHeight: 210, borderRadius: "10px" }}
          />
          <Typography
            variant="body2"
            color={message.incoming?theme.palette.textColor!.main:'white'}
          >
            {message.message}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

const Timeline = ({ el }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems={"center"} justifyContent="space-between">
      <Divider  />
      <Typography variant="caption">
        {el.text}
      </Typography>
      <Divider />
    </Stack>
  );
};

export { Timeline, MediaMsg };