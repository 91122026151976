import { CardType } from "../../../model/response/card_model";
import { ChannelModel } from "../../../model/response/channel_model";
import { AttachmentModel, ThreadDraftModel } from "../../../model/response/thread_draft_model";
import { UserMentionModel } from "../../../model/response/user_mention_model";

export enum ErrorType {
    INVALID_ARGUMENT
}

export enum MentionOrientation{
    LEFT,
    RIGHT
}

export interface CardCreationState {
    
    cardDraft: ThreadDraftModel | undefined;
    selectedChannel: ChannelModel | undefined;
    images: AttachmentModel[];
    cardDraftIsLoading: boolean;
    type: CardType;

    searchedUsersToTag: UserMentionModel[];



    errorType: ErrorType | undefined;

}