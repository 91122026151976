import { createSlice } from "@reduxjs/toolkit";
import { CardCreationState, ErrorType, MentionOrientation } from "./card_creation_state";
import { addImage, clearCardCreationCache, createThread, createThreadDraft, deleteImage, loadThreadDraft, saveImages, searchUserToTag, setCardType, setChannel, setDraft, updateImages, updateThreadDraft } from "./card_creation_action";
import { CardType } from "../../../model/response/card_model";

const initialState:CardCreationState ={
    cardDraft: undefined,
    selectedChannel: undefined,
    cardDraftIsLoading: true,
    errorType: undefined,
    images: [],
    searchedUsersToTag:[],
    type: CardType.discussion,
}

const cardCreation = createSlice({
    name: 'cardCreation',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(clearCardCreationCache, (state, payload) => {
            state.cardDraft = undefined;
            state.selectedChannel = undefined;
            state.images = [];
            state.searchedUsersToTag = [];
            state.type = CardType.discussion;
        })
        .addCase(setChannel, (state, payload) => {
            state.selectedChannel = payload.payload.channel;
        })
        .addCase(setDraft, (state, payload) => {
            state.cardDraft = payload.payload.draft;
        })
        .addCase(setCardType, (state, payload) => {
            state.type = payload.payload.type;
        })
        
        .addCase(loadThreadDraft.fulfilled, (state, payload) => {
            state.cardDraft = payload.payload.draft;
            if (state.cardDraft) {
                state.images = state.cardDraft.attachments;
            }
            state.cardDraftIsLoading = false;
        })
        .addCase(loadThreadDraft.pending, (state, payload) => {
            state.cardDraftIsLoading = true;
        })
        .addCase(loadThreadDraft.rejected, (state, payload) => {
            state.cardDraftIsLoading = false;
        })
        .addCase(createThreadDraft.fulfilled, (state, payload) => {
            state.cardDraft = payload.payload.draft;
        })
        .addCase(createThread.fulfilled, (state, payload) => {
            state.cardDraft = undefined;
            state.images = [];
        })
        
        .addCase(updateThreadDraft.fulfilled, (state, payload) => {
            state.errorType =undefined;
        })
        
        .addCase(updateThreadDraft.rejected, (state, payload) => {
            const error:string = payload.payload as string;

            if (error.includes('invalid-argument')){
                state.errorType = ErrorType.INVALID_ARGUMENT
            }
        })
        .addCase(addImage, (state, payload) => {
            const currentImages = state.images.concat(payload.payload.img);
            state.images = currentImages;
        })
        .addCase(deleteImage,(state, payload) => {

            const currentDraft = state.cardDraft;
            if(currentDraft){
                
                const images =  currentDraft.attachments.filter((image) => image.storageRef !== payload.payload.imageRef);
                currentDraft.attachments = images;

                state.cardDraft = currentDraft;
            }

        })
        .addCase(updateImages, (state, payload) => {
            const currentDraft = state.cardDraft;
            
            if(currentDraft){
                const images = currentDraft.attachments;
                payload.payload.images.map((image) =>  images.push(image));
                currentDraft.attachments = images;

                state.cardDraft = currentDraft
            }
        })
        .addCase(saveImages.fulfilled, (state, payload) => {
        })
        .addCase(searchUserToTag.fulfilled,(state,payload) => {
            state.searchedUsersToTag = payload.payload.users;
          })
    }
});

export default cardCreation.reducer;