import { Avatar, Box, CardHeader, Popover, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { channelColors } from "../../../utils/channel_theme";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChannelModel, ChannelsType } from "../../../model/response/channel_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { activateChannelNotification, leaveChannel, selectChannel } from "../api/channel_action";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ChannelPreferences } from "./channel_preferences";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogDeleteAction, DialogType } from "../../dialog/dialog_state";
import { goForward } from "../../navigation/navigation_action";
import { Page } from "../../navigation/navigation_state";
import CircleIcon from '@mui/icons-material/Circle';
export interface ChannelAvatarActionProps {
    onClick: () => void;
    isSelected: boolean;
    title: string;
    badge?: number;
}

export const ChannelAvatarAction: React.FC<ChannelAvatarActionProps> = (props: ChannelAvatarActionProps) => {

    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box

            onClick={props.onClick}
            borderRadius={'10px'} p={0.5} paddingLeft={1}
            display='flex'
            flexDirection={'row'}
            justifyContent={'space-between'}
            sx={{
                backgroundColor: (props.isSelected) ? 'rgba(245, 245, 245, 1)' : undefined,
                '&:hover': {
                    backgroundColor: 'rgba(245, 245, 245, 1)', cursor: 'pointer'
                }
            }}
        >
            <Typography color={theme.palette.textColor?.main} variant='body2'>
                {props.title}
            </Typography>
            {
                (props.badge !== undefined && props.badge > 0) && 
                <Box 
                display='flex'
                minWidth={'30px'}
                justifyContent={'center'}
                borderRadius={'15px'} 
                sx={{backgroundColor:'#4A4A4A'}} paddingLeft='2px' paddingRight='2px'>
                    <Typography color={'white'} variant='body2'>
                        {props.badge} 
                    </Typography>
                </Box>
            }

        </Box>
    )
}

export interface ChannelAvatarProps {
    className?: string;
    channel: ChannelModel;
    userId: string
    isSelected: boolean;
    hasNotification: boolean;
}

export enum ChannelActions {
    chat,
    announcements,
    discussions,
    documents,
}


export const ChannelAvatar: React.FC<ChannelAvatarProps> = (props: ChannelAvatarProps) => {


    const page: Page = useSelector((state: RootState) => state.navigation.currentPage)
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [action, setAction] = useState<ChannelActions | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [anchorEl, setAnchor] = useState<HTMLDivElement | null>(null);
    const selectedChannel = useSelector((state: RootState) => state.channel.currentChannel);


    useEffect(
        () => {
            if(page === Page.YourThreadPage || page === Page.FollowedThreadPage){
                setAction(null);
            }
        },[page]
    )
    
    const openPopOver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsOpen(true);
        setAnchor(event.currentTarget);
    };

    const closePopOver = () => {
        setIsOpen(false);
        setAnchor(null);
    };

    const deleteChannel = () => {
        closePopOver();
        dispatch(changeDialog({
            dialog: DialogType.deleteDialog,
            deleteAction: DialogDeleteAction.deleteChannel,
            channelId: props.channel.id

        }));
        
        
    };

    const openSettings = () => {
        closePopOver();
        dispatch(changeDialog({dialog: DialogType.channelSettingsDialog, channel:props.channel}));
        
        
    };

    const openChannelMembers = () => {
        dispatch(changeDialog({dialog: DialogType.channelMembersDialog, channel:props.channel}));
    }
    
    const firstLetter = props.channel.channelName[0];
    const channelColor = channelColors[firstLetter];



    const handleActionClick = (currentAction: ChannelActions) => {
        
        
        if(!props.isSelected || action!== currentAction){
            
            if(!props.isSelected)
                dispatch(selectChannel({ channel: props.channel, clearCache: true }));
            
            
            switch (currentAction) {
                case ChannelActions.chat:
                    navigate('channel/' + props.channel.id + '/chat');
                    dispatch(goForward(Page.none));
                    setAction(currentAction)
                    break;
                case ChannelActions.announcements:
                    navigate('channel/' + props.channel.id + '/announcements');
                    dispatch(goForward(Page.none));
                    setAction(currentAction)
                    break;
                case ChannelActions.discussions:
                    navigate('channel/' + props.channel.id + '/discussions');
                    dispatch(goForward(Page.none));
                    setAction(currentAction)
                    break;
                case ChannelActions.documents:
                    navigate('channel/' + props.channel.id + '/documents');
                    dispatch(goForward(Page.none));
                    setAction(currentAction)
                    break;
                default:
                    break;
            }
        } 
    }


    const displayActions = () => {

        if (!isVisible) return (<></>);

        return (
            <Box paddingLeft={8}>
                <Stack direction='column' >
                    {
                        props.channel.channelId !== 'AGORA' && 
                        <ChannelAvatarAction 
                            onClick={() => handleActionClick(ChannelActions.chat)} 
                            isSelected={(props.isSelected && action === ChannelActions.chat)} 
                            title={t('channelSideBar.chatTitle')}
                            badge={props.channel.chatBalance?.notificationCounter}
                            />
                    }
                    <ChannelAvatarAction 
                        onClick={() => handleActionClick(ChannelActions.announcements)} 
                    isSelected={(props.isSelected && action === ChannelActions.announcements)} 
                    title={t('channelSideBar.announcementTitle')}/>
                    <ChannelAvatarAction 
                        onClick={() => handleActionClick(ChannelActions.discussions)} 
                    isSelected={(props.isSelected && action === ChannelActions.discussions)} 
                    title={t('channelSideBar.discussionTitle')}
                    badge={props.channel.discussionBalance.notificationCounter}/>
                    <ChannelAvatarAction 
                        onClick={() => handleActionClick(ChannelActions.documents)} 
                    isSelected={(props.isSelected && action === ChannelActions.documents)} 
                    title={t('channelSideBar.documentTitle')}/>
                </Stack>
            </Box>
        );
    }

    const displayHasNotification = () => {
        if(props.hasNotification){
            return (
                <Box color={theme.palette.textColor?.main} display='flex' alignItems='center' justifyContent='center'>
                    <CircleIcon style={{width:10, height:10}}/>
                </Box>
            );
        }
    }

    return (
        <Stack p={1} paddingRight={0} spacing={1} marginLeft={1} >
            <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'>
                <Stack direction='row' alignItems='center' spacing={1} sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => setIsVisible(!isVisible)}>
                    <Box color={theme.palette.textColor?.main} display='flex'>
                        {isVisible ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </Box>
                    {displayHasNotification()}
                    <Avatar
                        sx={{ bgcolor: channelColor, width: '25px', height: '25px' }}
                        aria-label="recipe" >
                        <Typography variant='body2'
                            color={'white'}
                        >
                            {firstLetter}
                        </Typography>

                    </Avatar>
                    <Stack direction='row' spacing={0} alignItems={'center'} >
                        {props.channel.type === ChannelsType.PRIVATE && <LockOutlinedIcon />}
                        <Box display={'flex'} maxWidth={props.channel.type === ChannelsType.PRIVATE?'170px':'180px'}
                        >
                            <Typography  noWrap variant='body2'
                                fontWeight={props.hasNotification?600:undefined}
                                color={theme.palette.textColor!.main}
                            >
                                {props.channel.channelName}
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
                <Box 
                sx={{'&:hover':{cursor:'pointer'}}}
                color={theme.palette.textColor!.main} onClick={openPopOver}>
                    <MoreHorizIcon/>
                </Box>
                <ChannelPreferences 
                    open={isOpen}
                    muteChannel={() => dispatch(activateChannelNotification({userId:props.userId, channelId: props.channel.id, isMuted: !props.channel.isMuted}))}
                    deleteChannel={deleteChannel}
                    openChannelSettings={openSettings}
                    openChannelMembers={openChannelMembers}
                    isMuted={props.channel.isMuted} 
                    isAgora={props.channel.channelId === 'AGORA'} 
                    anchorEl={anchorEl}
                    
                    onClose={closePopOver}/>
            </Box>
            {displayActions()}
        </Stack>
    );
};