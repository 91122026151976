import { Stack } from "@mui/material";
import { CustomerServiceSidebar } from "../components/customer_service_sidebar";
import { CustomerServiceComponent } from "../components/customer_service_body";

const CustomerServicePage = () => {
    
    return (
        <>
        <Stack direction="row" sx={{ width: "100%",height:'100%' }}>
            <CustomerServiceSidebar/>
            <CustomerServiceComponent/>
        </Stack>
        </>
    )
}


export default CustomerServicePage;