import Styled from '../../../utils/styled_component_config';
import { Login } from '../components/login/login';
import { LoginFirstStep } from '../components/login-first-step/login_fitst_step';
import { useSelector } from 'react-redux';
import { LoginStep } from '../api/authentication_state';
import { RootState } from '../../../app/store';

const LoginPageContainer = Styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
`;

const LoginView = () => {
  const loginStep:LoginStep = useSelector((state:RootState) => state.auth.loginStep);
  return (
    <LoginPageContainer>
      {
        loginStep === LoginStep.first && <LoginFirstStep/>
      }
      
      {
         loginStep === LoginStep.second && <Login/>
      }
    </LoginPageContainer>
  );
};

export default LoginView;