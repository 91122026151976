import { createAction } from "@reduxjs/toolkit";
import { DialogDeleteAction, DialogType } from "./dialog_state";
import { ChannelModel } from "../../model/response/channel_model";
import { CardModel } from "../../model/response/card_model";



export const changeDialog = createAction(
    'dialog/changeDialog',
    function prepare(
        data:{
            dialog:DialogType,
            channel?: ChannelModel,
            announcement?: CardModel,
            expertId?:string,
            
            alertTitle?:string,
            alertMessage?:string,

            deleteAction?: DialogDeleteAction,
            messageId?: string,
            threadId?:string,
            cardId?: string,
            answerId?: string,
            channelId?: string,



        }
    ){
        return {
            payload: {
                dialog: data.dialog,
                channel:data.channel,
                announcement:data.announcement,
                expertId: data.expertId,
                alertTitle: data.alertTitle,
                alertMessage: data.alertMessage,
                deleteAction: data.deleteAction,
                messageId: data.messageId,
                threadId: data.threadId,
                cardId: data.cardId,
                answerId: data.answerId,
                channelId: data.channelId,

            }
        }

    }
);