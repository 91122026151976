import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { clearCardCreationCache, createThread, } from "../api/card_creation_action";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { clearCache } from "../../search/api/search_action";
import { SendCardModel } from "../../../model/request/send_card_model";
import { CardModel } from "../../../model/response/card_model";
import {  ThreadDraftModel } from "../../../model/response/thread_draft_model";
import { UserModel } from "../../../model/response/user_model";
import { SimilarCardDialog } from '../components/similar_card_dialog';
import { EditorDialog } from '../components/editor_dialog';



export const CreateCardDialog = () => {

        
    const [showSimilarResult, setShowSimilarResults] = useState(false);
    const similarResults: CardModel[] | undefined = useSelector((state: RootState) => state.search.similarResults);
    const similarCardLoading: boolean = useSelector((state: RootState) => state.search.similarCardLoading);
    const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);

    const draft: ThreadDraftModel | undefined = useSelector((state: RootState) => state.cardCreation.cardDraft);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (similarResults && !similarCardLoading) {
            if (
                similarResults.length > 0) {
                setShowSimilarResults(true);
            } else {
                if (user && draft) {
                    const card: SendCardModel = {
                        senderId: user.id,
                        cardDraftId: draft.id
                    }
                    dispatch(createThread({
                        card: card,
                    }));
                    dispatch(clearCache())
                    dispatch(changeDialog({ dialog: DialogType.none, }));
                }

            }
        }
    }, [similarResults, similarCardLoading]);

    if (showSimilarResult && similarResults) {
        return <SimilarCardDialog 
        open={showSimilarResult} 
        similarResults={similarResults} 
        onClose={function (): void {
            dispatch(clearCardCreationCache());
            dispatch(clearCache());
            dispatch(changeDialog({ dialog: DialogType.none, }));
        } }/>
    }

    return (
        <EditorDialog 
        open={dialog === DialogType.createDiscussionDialog
            || dialog === DialogType.createAnnouncementDialog} 
        onClose={() => {
                dispatch(clearCardCreationCache());
                dispatch(changeDialog({ dialog: DialogType.none, }));
            }}       />
    );

}
