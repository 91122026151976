import { Box, Button, ListItemButton, ListItemIcon, Stack, Typography, styled, useTheme, } from "@mui/material";
import { useEffect } from "react";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { AIConversation } from "../../../model/response/gpt_conversation";
import { fetchGPTChats, selectCurrentGPTChat } from "../api/gpt_action";
import { GPTElement } from "./gpt_element";
import { ErrorComponent, ErrorType } from "../../../components/error-component/error_component";
import { useTranslation } from "react-i18next";
import { DocumentUploadStatus } from "../api/gpt_state";
import AddIcon from '@mui/icons-material/Add';
import { SidebarTitle } from "../../../components/sidebar_title/sidebar_title";
export const StyledNavItem = styled(ListItemButton)({
  height: 40,
  borderRadius: 5,
});

export const StyledNavItemIcon = styled(ListItemIcon)((theme) => ({

  color: '#4a4a4a',
}));


export const GPTChats = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const { t, i18n } = useTranslation();

  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  const chats: AIConversation[] = useSelector((state: RootState) => state.gpt.conversations);

  const documentUploadStatus: DocumentUploadStatus = useSelector((state: RootState) => state.gpt.documentUploadStatus);
  const uploadProgress: number = useSelector((state: RootState) => state.gpt.uploadProgress);




  useEffect(() => {
    if (user !== undefined) {

      const db = getFirestore();
      const q = query(collection(db, "Users", user.id, "AIConversations"), orderBy('updatedAt', 'desc'));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const chats: AIConversation[] = querySnapshot.docs.map(doc => doc.data() as AIConversation);
        dispatch(fetchGPTChats(chats))
      });
      return () => {
        unsubscribe();
      };
    }
  }, [dispatch, user])

  return (
    <>
      <Box
        flexGrow={1}
        sx={{
          position: "relative",
          width: '320px',
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <SidebarTitle 
        onClick={() => dispatch(selectCurrentGPTChat(null))} 
        title={'Sybil'}/>
        <Stack p={1} spacing={2} sx={{ height: "90%" }}>
          <Box height={'100%'}
            sx={{
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none', // Customize the width of the scrollbar
              }
            }}>
            <Stack spacing={0} >
              <Typography variant="subtitle2" sx={{ color: "#676667" }}>
                {t('gptPage.allChatTitle')}
              </Typography>

              {
                user && chats.length > 0 && chats.map((chat, index) =>
                  <GPTElement title={chat.title} caption={chat.document?.documentName} key={index} userId={user.id} id={chat.id}
                    onClick={() => {
                      dispatch(selectCurrentGPTChat(chat))
                    }} />
                )
              }

              {
                chats.length === 0 &&
                <Box paddingTop='50%' display='flex' height='100%'>
                  <ErrorComponent caption='' image={ErrorType.card} width={'100%'} height={'100%'} />
                </Box>
              }

            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};