export const channelColors = {
    'A': '#FF5A5A',
    'B': '#FF7F50',
    'C': '#FFA07A',
    'D': '#FFD700',
    'E': '#FFCF47',
    'F': '#FF8C00',
    'G': '#FF4500',
    'H': '#008B8B',
    'I': '#87CEEB',
    'J': '#00BFFF',
    'K': '#1E90FF',
    'L': '#4169E1',
    'M': '#0000FF',
    'N': '#0000CD',
    'O': '#00008B',
    'P': '#FFE4B5',
    'Q': '#FFF5DE',
    'R': '#FFDEB8',
    'S': '#FF5F9E',
    'T': '#FF66CD',
    'U': '#FF0080',
    'V': '#FFDAB9',
    'W': '#FF00CE',
    'X': '#FF228B',
    'Y': '#4682B4',
    'Z': '#8A2BE2',
  };