import { Avatar, Box, Button, CardHeader, Divider, List, ListItem, Stack, Typography, useTheme } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { ChannelMemberModel, ChannelMembersType } from "../../../model/response/channel_member_model";
import { ChannelModel } from "../../../model/response/channel_model";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { useState } from "react";
import { UserModel } from "../../../model/response/user_model";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { promoteOrDemoteUser, removeMembersFromChannel } from "../api/channel_member_action";
import { SkeletonProfiles } from "../../../components/skeleton-profile/skeleton_profile";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../../components/action-button/action_button";
export interface MemberListProps {
    user: UserModel;
    isLoading: boolean;
    selectedChannel: ChannelModel;
    channelMembers: ChannelMemberModel[];
    onAddMembersClick: () => void;
    loadMoreMembers: () => void;
    hasMore: boolean;
}

export const MemberList: React.FC<MemberListProps> = (props: MemberListProps) => {
    
    const theme = useTheme();
    const [memberIsHovered, setMemberIsHovered] = useState<string>('');
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();

    const memberSize:number = props.channelMembers.length;


    return (
        <Stack p={2} overflow={'hidden'} flexDirection={'column'} spacing={2} >
            <Box display='flex' flexDirection='row' justifyContent='space-between' >
                <Typography
                    noWrap
                    variant="h6"
                    align="center"
                    color={theme.palette.textColor?.main}>
                        {t('channelMemberPage.title',{channelName:props.selectedChannel.channelName})}
                </Typography>
                <ActionButton
                    disabled={!props.selectedChannel?.isAdmin}
                    onClick={props.onAddMembersClick} 
                    isLoading={false} 
                    title={t('channelMemberPage.actionButton')}                    
                />
                
            </Box>
            <Divider light />
            <Box id={'memberscrollable'}
                display={'flex'}
                flexDirection={'column'}
                height={400}
                sx={{
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {
                        display: 'none', // Customize the width of the scrollbar
                    }
                }}>
                {
                    props.isLoading && <SkeletonProfiles/>
                }
                <InfiniteScroll
                    dataLength={props.channelMembers.length}
                    next={props.loadMoreMembers}
                    hasMore={props.hasMore}
                    scrollableTarget={'memberscrollable'}
                    loader={<></>}
                >
                    <List sx={{
                        width: 'inherit',
                        "&:hover": {
                            cursor: "pointer",
                        }
                    }}>
                        {
                            props.channelMembers.map((channelMember, index) => {

                                var isLastAdmin = false;

                                if(index + 1 < memberSize){
                                    isLastAdmin = channelMember.role === ChannelMembersType.ADMIN && 
                                    props.channelMembers[index + 1].role === ChannelMembersType.MEMBER;
                                }

                                return (
                                    <ListItem key={channelMember.id} sx={{ padding: 0 }}
                                        onMouseEnter={
                                            () => setMemberIsHovered(channelMember.id)
                                        }
                                        onMouseLeave={() => {
                                            setMemberIsHovered('')
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            dispatch(changeDialog({ dialog: DialogType.expertDialog, expertId:channelMember.userId}));
                                        }}>
                                        <Stack width='100%'>
                                        <Box width='100%' display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                                <CardHeader sx={{ padding: 1 }}
                                                    avatar={<Avatar src={channelMember.memberPhoto} />}
                                                    title={channelMember.memberDisplayName} />
                                                {
                                                    channelMember.role === ChannelMembersType.ADMIN ?
                                                        <Box color={theme.palette.textColor?.main}><AdminPanelSettingsIcon /></Box> :
                                                        <></>
                                                }
                                            </Stack>

                                            {
                                                channelMember.userId !== props.user.id && props.selectedChannel.isAdmin && memberIsHovered === channelMember.id &&
                                                <Stack direction={'row'}>
                                                    <Box
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            dispatch(promoteOrDemoteUser(
                                                                {
                                                                    organizationId: props.user.organizationId,
                                                                    channelMemberId: channelMember.id,
                                                                    channelId: props.selectedChannel.channelId,
                                                                    role: channelMember.role === ChannelMembersType.ADMIN ?
                                                                        ChannelMembersType.MEMBER : ChannelMembersType.ADMIN
                                                                }
                                                            ));
                                                        }}
                                                        color={theme.palette.textColor?.main}>
                                                        {
                                                            channelMember.role === ChannelMembersType.ADMIN ?
                                                                <RemoveModeratorIcon /> : <AddModeratorIcon />
                                                        }
                                                    </Box>
                                                    <Box color={theme.palette.textColor?.main}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            dispatch(removeMembersFromChannel(
                                                                {
                                                                    organizationId: props.user.organizationId,
                                                                    channelId: props.selectedChannel.channelId,
                                                                    members: [channelMember.userId]
                                                                }
                                                            ));
                                                        }}>
                                                        <DeleteOutlineIcon />
                                                    </Box>
                                                </Stack>
                                            }
                                        </Box>
                                        {isLastAdmin && <Divider light/>}
                                        </Stack>
                                    </ListItem>

                                )
                            })
                        }
                    </List>
                </InfiniteScroll>
            </Box>
        </Stack>
    );
}