import { Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ErrorType } from "../api/error_state";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { visualizeErrorMessage } from "../api/error_actions";

export interface SnackbarErrorProps {
    milliseconds: number;

}


export const SnackbarError:React.FC<SnackbarErrorProps> = (props: SnackbarErrorProps) => {

    const {t} = useTranslation();
    const error: ErrorType = useSelector((state: RootState) => state.error.errorType);
    const dispatch = useDispatch<AppDispatch>();
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        
        dispatch(visualizeErrorMessage(ErrorType.none));

    };
    
    return (
        <Snackbar
            open={error === ErrorType.general}
            autoHideDuration={props.milliseconds}
            onClose={handleClose}
            message={t('snackBar.snackbarErrorMessage')}
        />
    );

};