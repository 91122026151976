import { createSlice } from "@reduxjs/toolkit";
import { DocumentUploadStatus, GPTState } from "./gpt_state"
import { addGPTResponse, clearGPTCache, fetchCurrentGPTChat, fetchGPTChats, selectCurrentGPTChat, sendGPTFetchMessage, setIsSearchMessage, updateGPTResponse, updateProgressBar, updateQuery } from "./gpt_action";
import { AIConversation, ConversationTurn } from "../../../model/response/gpt_conversation";

const initialState: GPTState = {
    isSearchMessage:false,
    conversations: [],
    turns:[],
    currentConversation: null,
    query:'',
    waitingForSylloBot: false,
    uploadProgress: 0,
    documentUploadStatus:DocumentUploadStatus.none,
}

const gptSlice = createSlice({
    name: 'gptSlice',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(clearGPTCache, (state,) => {
            state.isSearchMessage = false;
            state.conversations= [];
            state.turns= [];
            state.currentConversation=  null;
            state.query= '';
            state.waitingForSylloBot=  false;
            state.uploadProgress=  0;
            state.documentUploadStatus= DocumentUploadStatus.none;
        })
        .addCase(fetchGPTChats, (state, payload) => {
            state.conversations = payload.payload.chats;
        })
        .addCase(selectCurrentGPTChat, (state, payload) => {
            state.currentConversation = payload.payload.currentChat;
            state.turns = []
            
        })
        .addCase(fetchCurrentGPTChat.fulfilled, (state, payload) => {
            if(payload.payload.messages.length > 0){
                state.turns = payload.payload.messages;
            }     
        })
        .addCase(updateQuery, (state, payload) => {
            state.query = payload.payload.query;
        })
        .addCase(setIsSearchMessage, (state, payload) => {
            state.isSearchMessage = payload.payload.isSearchMessage;
            state.turns = []
        })
        .addCase(addGPTResponse, (state, payload) => {
            const currentTurns = state.turns;
            currentTurns.push(payload.payload.turn);
            state.turns = currentTurns;
        })
        .addCase(updateGPTResponse, (state,payload) => {
            const currentTurns = state.turns;
            currentTurns[currentTurns.length -1] = payload.payload.turn;
            state.turns = currentTurns;
            state.waitingForSylloBot = false;
        })
        .addCase(sendGPTFetchMessage.fulfilled, (state, payload) => {
            state.waitingForSylloBot = false;       
        })
        .addCase(sendGPTFetchMessage.pending, (state) => {
            state.waitingForSylloBot = true;       
        })
        .addCase(sendGPTFetchMessage.rejected, (state) => {
            state.waitingForSylloBot = false;

        })
        .addCase(updateProgressBar, (state, payload) => {
            state.documentUploadStatus = DocumentUploadStatus.uploading;
            state.uploadProgress = payload.payload.progress;
            
        })
    }
});

export default gptSlice.reducer;