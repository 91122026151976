import { Dialog, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { ChannelType } from "../components/channel_type";
import { ChannelDetails, } from "../components/channel_details";
import { ChannelCreationStep } from "../api/create_channel_state";
import { SelectMembers } from "../components/select_members";
import { clearCreateChannelState } from "../api/create_channel_action";
import { ChannelRecap } from "../components/channel_recap";

export const CreateChannelDialog = () => {
    
    const step: ChannelCreationStep = useSelector((state: RootState) => state.createChannel.currentStep);
    const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);
    const dispatch = useDispatch<AppDispatch>();

    const renderCreationStep = () => {
        if(step === ChannelCreationStep.ChannelType){
            return(
                <ChannelType/>
            )
        }else if(step === ChannelCreationStep.ChannelDetails){
            return(
                <ChannelDetails/>
            );
        }else if(step === ChannelCreationStep.SelectMembers){
            return(
                <SelectMembers/>
            );
        }else{
            return(
                <ChannelRecap/>
            );
        }
    }

    const onClose = () =>{
        dispatch(changeDialog({dialog: DialogType.none,}));
        dispatch(clearCreateChannelState());
    }

    return (
        <Dialog
            PaperProps={{ sx: { borderRadius: "10px" } }}
            fullWidth
            open={dialog === DialogType.createChannelDialog}
            sx={{
                overflowY: 'hidden'
            }}
            onClose={onClose}
        >
            {renderCreationStep()}
        </Dialog>
    )
}
