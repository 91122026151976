import { createSlice } from "@reduxjs/toolkit";
import { ErrorState, ErrorType } from "./error_state";
import { visualizeErrorMessage } from "./error_actions";


const initialState:ErrorState = {
    errorType: ErrorType.none,
    
}
const errorSlice = createSlice({
    name: 'error',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder)  => {
        builder
        .addCase(visualizeErrorMessage, (state, payload) => {
            state.errorType = payload.payload.errorType;
        })
        
    }
});

export default errorSlice.reducer;