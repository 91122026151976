import { createSlice } from "@reduxjs/toolkit";
import { CustomerServiceState, TicketSentStatus, TicketStatus } from "./customer_service_state";
import { addAttachments, changeTicketStatus, chooseTicketType, deleteTicket, fetchMessages, fetchTickets, markTicketAsSolved, selectTicket, sendTicket, sendTicketMessage, updateText, updateTicketStatus } from "./customer_service_action";
import { TicketType } from "../models/response/TicketModel";
import { MessageModel, TicketMessageModel } from "../models/response/TicketMessageModel";

const initialState:CustomerServiceState = {
    tickets: [],
    ticketStatus: TicketStatus.open,
    ticketSentStatus: TicketSentStatus.none,
    currentTicket: undefined,
    messageDraft: undefined,
    messages: [],
    ticketType: TicketType.GENERAL,
}

const customerServiceSlice = createSlice({
    name: 'customerService',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchTickets,(state, action) => {
            state.tickets = action.payload.tickets;
        })
        .addCase(changeTicketStatus, (state, action) => {
            state.ticketStatus = action.payload.status;
            state.currentTicket = undefined;
            state.messages = [];
        })
        .addCase(selectTicket, (state, action) => {
            state.currentTicket = action.payload.ticket;
        })
        .addCase(deleteTicket.fulfilled, (state, action) => {
            state.currentTicket = undefined;
            state.messages = [];
        })
        .addCase(markTicketAsSolved.fulfilled, (state, action) => {
            state.currentTicket = undefined;
            state.messages = [];
        })
        .addCase(updateTicketStatus, (state, action) => {
            state.ticketSentStatus = action.payload.tiketStatus;
        })
        .addCase(fetchMessages, (state, action) => {
            if(action.payload.messages.length > 0){
                const messages:TicketMessageModel[] = [];
                
                const initialDate:TicketMessageModel = {
                    ...action.payload.messages[0],
                    isDate: true
                }
                const initialMessage:TicketMessageModel = {
                    ...action.payload.messages[0],
                    isDate: false
                }
                messages.push(initialDate);
                messages.push(initialMessage);

                for (var i = 1; i < action.payload.messages.length; i++){
                    const previousMessage = action.payload.messages[i -1];
                    const currentMessage = action.payload.messages[i];
                    
                    const previousDate = new Date(previousMessage.createdAt);
                    const currentDate = new Date(currentMessage.createdAt);

                    if(
                        previousDate.getFullYear() === currentDate.getFullYear() &&
                        previousDate.getMonth() === currentDate.getMonth() &&
                        previousDate.getDate() === currentDate.getDate()
                    ){
                        const extendedMessage:TicketMessageModel = {
                            ...currentMessage,
                            isDate: false
                        }
                        messages.push(extendedMessage);
                    }else{
                        const currentDate:TicketMessageModel = {
                            ...currentMessage,
                            isDate: true
                        }
                        const extendedMessage:TicketMessageModel = {
                            ...currentMessage,
                            isDate: false
                        }
                        messages.push(currentDate);
                        messages.push(extendedMessage);

                    }
                    
                }
                state.messages = messages; 
            }
        })
        .addCase(chooseTicketType, (state, action) => {
            state.ticketType = action.payload.type;
        })
        .addCase(sendTicket.fulfilled, (state) => {
            state.ticketSentStatus = TicketSentStatus.sent;
            state.ticketType = TicketType.GENERAL;
        })
        .addCase(sendTicket.pending, (state) => {
            state.ticketSentStatus = TicketSentStatus.pending;
        })
        .addCase(sendTicket.rejected, (state) => {
            state.ticketSentStatus = TicketSentStatus.error;
        })
        .addCase(addAttachments, (state, action) => {
            if(state.messageDraft){
                const currentAttachments = state.messageDraft.attachments;
                currentAttachments.push(...action.payload.attachments);

                const messageDraft:MessageModel = {
                    ...state.messageDraft,
                    attachments: currentAttachments,
                }

                state.messageDraft = messageDraft;
            
            }else{
                const date = new Date().toISOString();
                
                const messageDraft:MessageModel = {
                    id: "",
                    text: "",
                    attachments: action.payload.attachments,
                    isDraft: false,
                    isFromSylloTips: true,
                    createdAt: date,
                    updatedAt: date
                };

                state.messageDraft = messageDraft;
            }
        })
        .addCase(updateText, (state, action) => {
            if(state.messageDraft){
                const messageDraft:MessageModel = {
                    ...state.messageDraft,
                    text: action.payload.text,
                }

                state.messageDraft = messageDraft;
            
            }else{
                const date = new Date().toISOString();
                
                const messageDraft:MessageModel = {
                    id: "",
                    text: action.payload.text,
                    attachments: [],
                    isDraft: false,
                    isFromSylloTips: false,
                    createdAt: date,
                    updatedAt: date
                };

                state.messageDraft = messageDraft;
            }
        })
        .addCase(sendTicketMessage.fulfilled, (state) => {
            state.messageDraft = undefined;
        })
    }
});

export default customerServiceSlice.reducer;