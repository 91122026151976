import { Avatar, Box, Chip, Popover, Typography } from "@mui/material";
import { channelColors } from "../../../utils/channel_theme";
import { setChannel } from "../api/card_creation_action";
import { AnnouncementsChannelPermissions, ChannelModel } from "../../../model/response/channel_model";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { ChannelChip } from "../../../components/channel-chip/channel_chip";
import { CardType } from "../../../model/response/card_model";
import { filterByChannel } from "../../feed/api/feed_action";

export interface ChooseChannelPopOverProps {
    open: boolean;
    channels: ChannelModel[];
    anchor: HTMLDivElement | null;
    onClose: () => void;
}

export const ChooseChannelPopOver: React.FC<ChooseChannelPopOverProps> = (props: ChooseChannelPopOverProps) => {

    const dispatch = useDispatch<AppDispatch>();
    const type:CardType =  useSelector((state: RootState) => state.cardCreation.type);
    let fliteredChannel: ChannelModel[] = [];
    
    if(type === CardType.announcement){
        for(let i = 0; i < props.channels.length; i++){
           if(props.channels[i].permissions.includes(AnnouncementsChannelPermissions.ONLY_ADMINS)){
               if(props.channels[i].isAdmin){
                    fliteredChannel.push(props.channels[i]);
               }
           }else{
                fliteredChannel.push(props.channels[i]);
           }
        }
    }else{
        fliteredChannel = [...props.channels];
    }
    
    const sortedChannels = [...fliteredChannel].sort((a, b) => a.channelName.localeCompare(b.channelName));

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            slotProps={{
                paper:{
                    sx: {
                        borderRradius: '8px',
                        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', // Custom box-shadow
                      },
                }
            }}
            onClose={props.onClose}
        >
            <Box display='flex' flexDirection='column' p={2} overflow='hidden'
                sx={{
                    overflowY: 'scroll',
                    maxHeight: '250px'
                }}>
                {
                    sortedChannels.map((channel) => {
                        return (
                            <ChannelChip key={channel.id} 
                            channel={channel} 
                            onClick={() => {
                                dispatch(setChannel(channel));
                                props.onClose();
                            }}/>
                        );
                    })
                }
            </Box>
        </Popover>
    );
}