export const registerServiceWorker = async () => {
    let scriptURL = "firebase-messaging-sw.js";
    
    if(process.env.REACT_APP_ENV === "development"){
        scriptURL += `?apiKey=${process.env.REACT_APP_FIREBASE_API_KEY_DEV}`;
        scriptURL += `&authDomain=${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV}`;
        scriptURL += `&projectId=${process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV}`;
        scriptURL += `&storageBucket=${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV}`;
        scriptURL += `&messagingSenderId=${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV}`;
        scriptURL += `&appId=${process.env.REACT_APP_FIREBASE_APP_ID_DEV}`;
        scriptURL += `&measurementId=${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_DEV}`;
    }else{
        scriptURL += `?apiKey=${process.env.REACT_APP_FIREBASE_API_KEY_PROD}`;
        scriptURL += `&authDomain=${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD}`;
        scriptURL += `&projectId=${process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD}`;
        scriptURL += `&storageBucket=${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD}`;
        scriptURL += `&messagingSenderId=${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PROD}`;
        scriptURL += `&appId=${process.env.REACT_APP_FIREBASE_APP_ID_PROD}`;
        scriptURL += `&measurementId=${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PROD}`;
    }
    
    
  
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(scriptURL);
        if (registration.installing) {
          console.log("Service worker installing");
        } else if (registration.waiting) {
          console.log("Service worker installed");
        } else if (registration.active) {
          console.log("Service worker active");
        }
      } catch (error) {
        console.error(`Registration failed with ${error}`);
      }
    }
  };