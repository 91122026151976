import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { DiscussionsChannelPermissions, AnswersChannelPermissions, AnnouncementsChannelPermissions, DocumentsChannelPermissions } from "../../../model/response/channel_model";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { ChannelTypeButton } from "./channel_type";
import { useTranslation } from "react-i18next";
import { BackButton } from "../../../components/back-button/back_button";
import { ActionButton } from "../../../components/action-button/action_button";
import { changeChannelStep } from "../api/create_channel_action";
import { ChannelCreationStep } from "../api/create_channel_state";

export interface ChannelRecapOptionsProps{
    title: string;
    description:string;
}

export const ChannelRecapOptions: React.FC<ChannelRecapOptionsProps> = (props: ChannelRecapOptionsProps) => {
    const theme = useTheme();
    
    return (
        <Stack spacing={0} >
            <Typography color={theme.palette.textColor?.main} variant='body1' >
                {props.title}
            </Typography>
            <Typography color={theme.palette.textColor?.main} variant='h6' fontWeight='600'>
                {props.description}
            </Typography>
        </Stack>
    );

}

export const ChannelRecap: React.FC = () => {
    
    const discussionPermission: DiscussionsChannelPermissions = useSelector((state: RootState) => state.createChannel.discussionPermission);
    const answerPermission: AnswersChannelPermissions = useSelector((state: RootState) => state.createChannel.answerPermission);
    const announcementPermission: AnnouncementsChannelPermissions = useSelector((state: RootState) => state.createChannel.announcementPermission);
    const documentPermission: DocumentsChannelPermissions = useSelector((state: RootState) => state.createChannel.documentPermission);
    
    const title: string = useSelector((state: RootState) => state.createChannel.title);
    const isPrivate: boolean = useSelector((state: RootState) => state.createChannel.isPrivate);

    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const renderDiscussionOption = () => {
        const optionTitle = t('createChannelPage.discussionSettingsTitle')
        var description = t('createChannelPage.automaticRoutingOptionTitle');
        switch (discussionPermission) {
            case DiscussionsChannelPermissions.ONLY_ROUTED:
                description = t('createChannelPage.automaticRoutingOptionTitle');
                break;
            case DiscussionsChannelPermissions.ONLY_ADMINS:
                description = t('createChannelPage.adminRoutingOptionTitle');
                break;
            case DiscussionsChannelPermissions.EVERYONE:
                description = t('createChannelPage.allMembersRoutingOptionTitle');
                break;
        
            default:
                break;
        }

        return (
            <ChannelRecapOptions title={optionTitle} description={description}/>
        )
    }

    const renderAnswerOption = () => {
        const optionTitle = t('createChannelPage.answerSettingsTitle')
        var description = t('createChannelPage.adminAnswerSettingOption');
        switch (answerPermission) {
            case AnswersChannelPermissions.ONLY_ADMINS:
                description = t('createChannelPage.adminAnswerSettingOption');
                break;
            case AnswersChannelPermissions.EVERYONE:
                description = t('createChannelPage.allMembersAnswerSettingOption');
                break;
        
            default:
                break;
        }

        return (
            <ChannelRecapOptions title={optionTitle} description={description}/>
        )
    }

    const renderAnnouncementOption = () => {
        const optionTitle = t('createChannelPage.announcementSettingsTitle')
        var description = t('createChannelPage.adminAnnouncementSettingOption');
        switch (announcementPermission) {
            case AnnouncementsChannelPermissions.ONLY_ADMINS:
                description = t('createChannelPage.adminAnnouncementSettingOption');
                break;
            case AnnouncementsChannelPermissions.EVERYONE:
                description = t('createChannelPage.allMembersAnnouncementSettingOption');
                break;
        
            default:
                break;
        }

        return (
            <ChannelRecapOptions title={optionTitle} description={description}/>
        )
    }

    const renderDocumentOption = () => {
        const optionTitle = t('createChannelPage.documentSettingsTitle')
        var description = t('createChannelPage.adminDocumentSettingOption');
        switch (documentPermission) {
            case DocumentsChannelPermissions.ONLY_ADMINS:
                description = t('createChannelPage.adminDocumentSettingOption');
                break;
            case DocumentsChannelPermissions.EVERYONE:
                description = t('createChannelPage.allMembersDocumentSettingOption');
                break;
        
            default:
                break;
        }

        return (
            <ChannelRecapOptions title={optionTitle} description={description}/>
        )
    }

    const renderChannelType = () => {
        if (isPrivate){
            return (
                <ChannelTypeButton 
                icon={<LockOutlinedIcon style={{ fontSize: 40 }}/>} 
                title={t('createChannelPage.privateChannelGroupButton')} 
                description={t('createChannelPage.privateChannelDescription')} 
                color={"#8C1A6A"} 
                />
            )

        }else{
            return (
                <ChannelTypeButton 
                icon={<PublicOutlinedIcon style={{ fontSize: 40 }}/>} 
                title={t('createChannelPage.publicChannelGroupButton')} 
                description={t('createChannelPage.publicChannelDescription')} 
                color={"#5876FF"} 
               />
            )
        }
    }

    const renderChannelSettingDisclaimer = () => {
        return (
            <Stack spacing={0} >
                <Typography color={theme.palette.textColor?.main} variant='h6' fontWeight='600'>
                    {t('createChannelPage.channelSettingButton')}
                </Typography>
                <Typography color={theme.palette.textColor?.main} variant='body2' fontStyle={'italic'}>
                    {t('createChannelPage.channelSettingsDisclaimer')}
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack spacing={2} p={2}>
            <Typography color={theme.palette.textColor?.main} variant='h5' fontWeight='600'>
                {title}
            </Typography>
            {renderChannelType()}
            {renderChannelSettingDisclaimer()}
            {renderDiscussionOption()}
            <Divider light/>
            {renderAnswerOption()}
            <Divider light/>
            {renderAnnouncementOption()}
            <Divider light/>
            {renderDocumentOption()}
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                <BackButton onClick={ () => dispatch(changeChannelStep(ChannelCreationStep.ChannelDetails))}/>
                <ActionButton 
                    isLoading={false} 
                    disabled={title.trim() === ""} 
                    title={t('createChannelPage.selectMembersActionButton')} onClick={() => dispatch(changeChannelStep(ChannelCreationStep.SelectMembers))}/>
            </Box>
        </Stack>
    );
};