import { Box, Card, CardContent, Checkbox, Chip, IconButton, InputBase, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, alpha, styled, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from "react";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { DeleteserTagsModel, FetchUserTagsModel } from "../../../model/request/fetch_profile_models";
import { TagModel } from "../../../model/response/tag_model";
import { Expertise } from "../../../components/expertise/expertise";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { cancelSelection, changeExpertisePageNumber, changeExpertiseRowNumber, deleteExpertise, fetchYourExpertise, selectExpertise, updateExpertiseSearch } from "../api/expertise_action";


const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        width: "100%",
    },
}));

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: 20,
    backgroundColor: alpha(theme.palette.background.paper, 1),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

export const ExpertiseTable = () => {

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const yourExpertise: TagModel[] = useSelector((state: RootState) => state.expertise.expertise);
    const expertiseSelected: string[] = useSelector((state: RootState) => state.expertise.expertiseSelected);
    const expertiseSearch: string = useSelector((state: RootState) => state.expertise.expertiseSearch);
    const page = useSelector((state: RootState) => state.expertise.expertiseTablePage);
    const rowsPerPage = useSelector((state: RootState) => state.expertise.expertiseTableNumberOfRows);
    const TABLE_HEAD = [
        { id: 'chackbox', label: '', alignRight: false },

        { id: 'name', label: t('expertisePopOver.expertiseNameTableLabel'), alignRight: false },

    ];

    useEffect(
        () => {
            if (user) {
                const data: FetchUserTagsModel = {
                    userId: user.id,
                    size: 500,
                    fetchAllExpertise: true
                }
                dispatch(fetchYourExpertise(data));
            }

        }, [user]
    );

    return (
        <>
            <Box overflow={'scroll'} height={'85vh'} padding={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom color={theme.palette.textColor?.main}>
                        {t('expertisePopOver.title')}
                    </Typography>
                </Stack>
                <Card>

                    <CardContent>
                        <Box sx={{ width: "100%" }} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    onChange={(event) => {
                                        dispatch(changeExpertisePageNumber(0));
                                        dispatch(updateExpertiseSearch(event.target.value));
                                        
                                    }}
                                    placeholder={t('expertisePopOver.searchHelperText')}
                                    inputProps={{ "aria-label": "search" }}
                                    value={expertiseSearch}
                                />
                            </Search>
                            {
                                expertiseSelected.length >0 && <Stack direction='row' alignItems={'center'}>
                                <Chip
                                    size="medium"
                                    label={<Typography>{expertiseSelected.length + t('expertisePopOver.multipleSelection')}</Typography>}
                                    deleteIcon={<ClearIcon sx={{ color: 'white' }} />}
                                    onDelete={() => {
                                        if (user) {
                                            dispatch(cancelSelection(true));
                                        }

                                    }}
                                />
                                <IconButton onClick={() => {
                                    if(user){
                                        const data:DeleteserTagsModel = {
                                            userId: user.id,
                                            selectedExpertise: expertiseSelected
                                        };
                                        dispatch(deleteExpertise(data));
                                    }
                                    }}>
                                    <DeleteIcon/>
                                </IconButton>

                            </Stack>
                            }

                        </Box>
                    </CardContent>
                    <TableContainer >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {

                                        TABLE_HEAD.map((headCell) => (
                                            <TableCell color={theme.palette.textColor?.main}
                                                key={headCell.id}
                                            >
                                                {headCell.label}
                                            </TableCell>
                                        ))

                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    yourExpertise.filter((doc) => doc.tagName.includes(expertiseSearch)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((expetise, index) => {
                                        const { id, tagName, userRelativeScore } = expetise;
                                        return (
                                            <TableRow hover key={id} tabIndex={-1}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox disableRipple checked={expertiseSelected.includes(id)} onChange={
                                                        () => {
                                                            dispatch(selectExpertise(id));

                                                        }
                                                    } />
                                                </TableCell>
                                                <TableCell >
                                                    <Expertise {...expetise} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={yourExpertise.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, page) => {
                            dispatch(changeExpertisePageNumber(page));
                        }}
                        onRowsPerPageChange={(event) => {
                            dispatch(changeExpertisePageNumber(0));
                            dispatch(changeExpertiseRowNumber(parseInt(event.target.value, 10)));
                        }}
                    />
                </Card>
            </Box>
        </>
    );
}

