import { AIConversation, ConversationTurn } from "../../../model/response/gpt_conversation";
export enum DocumentUploadStatus{
    rejected,
    uploading,
    uploaded,
    none
}
export interface GPTState {
    isSearchMessage: boolean;
    conversations: AIConversation[];
    currentConversation: AIConversation | null;
    turns: ConversationTurn[];
    query: string;
    waitingForSylloBot:boolean;
    uploadProgress:number;
    documentUploadStatus: DocumentUploadStatus;
}