import { Box, Button, Dialog, Stack, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { CardModel } from "../../../model/response/card_model";
import { DialogType } from "../../dialog/dialog_state";
import { SendCardModel } from "../../../model/request/send_card_model";
import { changeDialog } from "../../dialog/dialog_action";
import { clearCache } from "../../search/api/search_action";
import { createThread } from "../api/card_creation_action";
import { UserModel } from "../../../model/response/user_model";
import { ThreadDraftModel } from "../../../model/response/thread_draft_model";
import { SimilarCard } from "./similar_cards";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../../components/action-button/action_button";

export interface SimilarCardDialogProps {
    open: boolean;
    similarResults: CardModel[];
    onClose: () => void;
}

export const SimilarCardDialog: React.FC<SimilarCardDialogProps> = (props: SimilarCardDialogProps) => {

    const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const draft: ThreadDraftModel | undefined = useSelector((state: RootState) => state.cardCreation.cardDraft);
    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const submitAnyway = () => {
        if (user && draft) {
            const card: SendCardModel = {
                senderId: user.id,
                cardDraftId: draft.id
            }
            dispatch(createThread({
                card: card,
            }));
            dispatch(clearCache())
            dispatch(changeDialog({ dialog: DialogType.none, }));
        }
    }
    

    return (
        <Dialog
            PaperProps={{ sx: { borderRadius: "10px" } }}
            fullWidth
            maxWidth={'sm'}
            open={props.open}
            sx={{
                overflowY: 'hidden',
            }}
            onClose={props.onClose}
        >
            <Stack p={2} overflow={'hidden'} flexDirection={'column'} spacing={2} >
                <Box display='flex' flexDirection='row' justifyContent='space-between' >
                    <Typography
                        variant="h6"
                        align="center"
                        color={theme.palette.textColor ?.main}>
                        {dialog === DialogType.createDiscussionDialog ? t('cardCreationPage.discussionTitle') : t('cardCreationPage.announcementTitle')}
                    </Typography>
                    <ActionButton
                        onClick={submitAnyway} 
                        isLoading={false} 
                        disabled={false} 
                        title={t('cardCreationPage.submitAnywayActionButton')}/>
                </Box>
                {user && <SimilarCard similarResults={props.similarResults} user={user}/>}
            </Stack>
        </Dialog>

    );
}