import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ChannelModel } from "../../../model/response/channel_model";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";

export const ChannelChatPlaceHolder: React.FC = () => {

    const theme = useTheme();
    const {t} = useTranslation();
    const currentChannel: ChannelModel | null = useSelector((state: RootState) => state.channel.currentChannel);
    return (
        <Box p={1} >
            <Stack spacing={2}>
            <Typography variant='h4' fontWeight={600} color={theme.palette.textColor?.main}>
                {t('channelChatPage.welcomeMessage',{channelName:currentChannel?.channelName})}
            </Typography>
            <Typography variant='body1'  color={theme.palette.textColor?.main}>
                {t('channelChatPage.welcomeMessageDescription')}
            </Typography>
            </Stack>
            
        </Box>
    );
};