import { Box, Button } from "@mui/material";
import { UserModel } from "../../../model/response/user_model";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { ChatModel } from "../../../model/response/chat_model";
import { ChangeChatModel } from "../../../model/request/change_chat_model";
import { selectCurrentChat } from "../../chat/api/chat_action";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { clearExpert, changeContent } from "../api/expert_action";
import { ContentType } from "../api/expert_state";
import { useNavigate } from "react-router-dom";
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useTranslation } from "react-i18next";
import { goForward, openChannelDrawer } from "../../navigation/navigation_action";
import { Page } from "../../navigation/navigation_state";
export interface ProfileButtonProps {
    isVisible: boolean;
    expert: UserModel;
    userId: string;
}

export const ProfileButton: React.FC<ProfileButtonProps> = (props: ProfileButtonProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {t} = useTranslation();
    
    
    const chatWithExpert = async () => {
        const currentDate = new Date().toISOString();
        const currentChat: ChatModel = {
            id: "",
            organizationId: props.expert.organizationId,
            lastMessage: "",
            chatBalance: {
                hasNotifications: false,
                notificationCounter: 0
            },
            user: {
                responderDisplayName: props.expert.displayName,
                responderId:  props.expert.id,
                responderPhoto:  props.expert.photo
            },
            updatedAt: currentDate,
            createdAt: currentDate
        }
        const data: ChangeChatModel = {
            chat: currentChat,
            userId:  props.userId
        }
        await dispatch(selectCurrentChat(data));
        dispatch(clearExpert());
        dispatch(changeDialog({ dialog: DialogType.none, }));
        dispatch(openChannelDrawer(false))
        dispatch(goForward(Page.ChatPage));
        navigate('/chat');
    }

    if(props.isVisible){
        return (
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Button style={{ color: 'white', backgroundColor: "#5876FF", textTransform: 'none', width: '49.5%', borderRadius: '10px' }}
                    onClick={chatWithExpert}
                    startIcon={<SendOutlinedIcon />} variant='contained' >{t('expertPage.contactButton')}</Button>
                <Button
                    onClick={() => dispatch(changeContent(ContentType.sendGift))}
                    startIcon={<CardGiftcardOutlinedIcon />} variant='contained' style={{ textTransform: 'none', width: '49.5%', borderRadius: '10px', backgroundColor: "#5876FF" }}>{t('expertPage.sendRewardButton')}</Button>
            </Box>
        );

    }else{
        return (<></>);
    }

    
}