import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { DialogType } from "../../features/dialog/dialog_state";
import ReactMarkdown from "react-markdown";
import { updateUserChangelog } from "../../features/profile/api/profile_action";
import { UserModel } from "../../model/response/user_model";



export const ChangelogDialog: React.FC = () => {

    const dialog: DialogType = useSelector((state: RootState) => state.dialog.currentDialog);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);

    const date = new Date(Date.now());
    const month = date.getMonth(); 
    const year = date.getFullYear();
    const day = date.getDate();

    


    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();

    const monthNames = [
        t('date.jan'), // January
        t('date.feb'), // February
        t('date.mar'), // March
        t('date.apr'), // April
        t('date.may'), // May
        t('date.jun'), // June
        t('date.jul'), // July
        t('date.aug'), // August
        t('date.sep'), // September
        t('date.oct'), // October
        t('date.nov'), // November
        t('date.dec'), // December
      ];

    const onClose = async () => {
        if (user){
            await dispatch(updateUserChangelog({userId: user.id}));
        }
    }

    return (
        <Dialog open={dialog === DialogType.changelogDialog} onClose={onClose} aria-labelledby="custom-dialog-title">
            <DialogTitle id="custom-dialog-title">
                <Typography variant="h6" component="h2">
                    What’s new in Syllotips
                </Typography>
                <Typography variant="caption" display="block">
                    {monthNames[month]} {day}, {year}
                </Typography>
            </DialogTitle>
            <DialogContent >
                <Box display={'flex'} flexDirection={'row'} alignItems='center' justifyItems='center'  justifyContent={'center'} >
                    <Typography  variant="subtitle1" color={theme.palette.info.main}>
                        {t('changelogDialog.releaseCaption')}
                    </Typography>
                    <Box flexGrow={1} marginLeft={2}>
                        <Divider flexItem  color={theme.palette.info.main}/>
                    </Box>
                </Box>
                <ReactMarkdown>{t('changelogDialog.description')}</ReactMarkdown>
            </DialogContent>
            <Box flexGrow={1} marginLeft={3} marginRight={3}>
                    <Divider flexItem  color={theme.palette.textColor?.main}/>
                </Box>
            <DialogActions>
                <Box display={'flex'} flexDirection={'row'} flexGrow={1} justifyContent={'center'} >
                    <Button onClick={onClose} color="primary" sx={{textTransform:'none'}}>
                    {t('changelogDialog.buttonCaption')}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}