import { Box, Stack, Typography, useTheme } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { ChannelChatReactionPopOver } from "./channel_chat_reaction_pop_over";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { selectMessageToAnswer, selectMessageToEdit } from "../api/channel_chat_action";
import { useTranslation } from "react-i18next";

export interface ChatOptionsProps {
    onHover?: () => void;
    onLeave?: () => void;
    onDelete?: () => void;
    isOwner: boolean;
}

export const ChatOptions: React.FC<ChatOptionsProps> = (props: ChatOptionsProps) => {

    const theme = useTheme();
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
    
    const dispatch = useDispatch<AppDispatch>();
    const currentMessage = useSelector((state: RootState) => state.channelChat.currentMessage);
    
    const handelSelectMessageToAnswer = () => {
        dispatch(selectMessageToAnswer(currentMessage)); 
    }

    const handelSelectMessageToEdit = () => {
        dispatch(selectMessageToEdit(currentMessage)); 
    }

    return (
        <Box display={'flex'}
            onMouseEnter={props.onHover}
            onMouseLeave={props.onLeave}
            sx={{
                backgroundColor: 'white',
                borderRadius: '5px',
                border: '1px solid rgba(231, 231, 231, 1)',
               
            }}
            padding={1}
            position={'absolute'}
            right={0}
            top={-20}>
            <Stack direction={'row'} spacing={1}>
            <Stack spacing={0.5} onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{'&:hover':{cursor:'pointer'}}}
                direction='row' justifyContent={'center'}>
                    <Box 
                    color={theme.palette.textColor?.main}>
                        <InsertEmoticonIcon fontSize="small" />
                    </Box>
                    <Typography
                        color={theme.palette.textColor?.main}
                        variant={'caption'}>{t('channelChatPage.reactButton')}</Typography>
                </Stack>
                <Stack spacing={0.5} onClick={handelSelectMessageToAnswer}
                sx={{'&:hover':{cursor:'pointer'}}}
                direction='row'>
                    <Box color={theme.palette.textColor?.main}>
                        <ReplyIcon fontSize="small" />
                    </Box>
                    <Typography
                        color={theme.palette.textColor?.main}
                        variant={'caption'}>
                        {t('channelChatPage.replyButton')}</Typography>
                </Stack>
                { 
                    props.isOwner && 
                        <Stack spacing={0.5}
                        onClick={handelSelectMessageToEdit}
                        sx={{'&:hover':{cursor:'pointer'}}}
                        direction='row' justifyContent={'center'}>
                            <Box color={theme.palette.textColor?.main}>
                                <EditOutlinedIcon fontSize="small" />
                            </Box>
                            <Typography
                                color={theme.palette.textColor?.main}
                                variant={'caption'}>{t('channelChatPage.editButton')}</Typography>
                        </Stack>
                    
                }
                { props.isOwner && 
                    <Stack spacing={0.5}
                    onClick={props.onDelete}
                    sx={{'&:hover':{cursor:'pointer'}}}
                    direction='row' justifyContent={'center'}>
                        <Box color={'red'}>
                            <DeleteOutline fontSize="small" />
                        </Box>
                        <Typography
                            color={'red'}
                            variant={'caption'}>{t('channelChatPage.deleteButton')}</Typography>
                    </Stack>
                }
            </Stack>
            <ChannelChatReactionPopOver 
            open={anchorEl !== null} 
            anchorEl={anchorEl} 
            handleClose={function (): void {
                setAnchorEl(null);
                props.onLeave && props.onLeave();
            } }/>
        </Box>
    );
}