import { Box, IconButton, Stack, Typography, useTheme} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { InputBase } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from "react";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { ChatModel } from "../../../model/response/chat_model";
import { fetchChats, searchChat, selectCurrentChat, updateNotification } from "../api/chat_action";
import { ChatElement } from "./chat_element";
import { ChangeChatModel } from "../../../model/request/change_chat_model";
import TelegramIcon from '@mui/icons-material/Telegram';
import { ErrorComponent, ErrorType } from "../../../components/error-component/error_component";
import { useTranslation } from "react-i18next";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";
import { SidebarTitle } from "../../../components/sidebar_title/sidebar_title";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)})`,
      width: "100%",
    },
  }));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 20,
  backgroundColor: alpha(theme.palette.background.paper, 1),
  marginLeft: 0,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    color:theme.palette.iconColor?.main,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));



export const Chats = () => {
  const theme = useTheme();
  
  const dispatch = useDispatch<AppDispatch>();
  const {t,i18n} = useTranslation();
  const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
  const chats: ChatModel[] = useSelector((state: RootState) => state.chat.chats);
  const chatQuery: string = useSelector((state: RootState) => state.chat.query);
  const currentChat: ChatModel | null = useSelector((state: RootState) => state.chat.currentChat);

  useEffect(() => {
    if (user !== undefined){
        
        const db = getFirestore();
        const q = query(collection(db, "Users",user.id, "Chats"),orderBy('updatedAt','desc'));
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const chats: ChatModel[] = querySnapshot.docs.map(doc => doc.data() as ChatModel);
            dispatch(fetchChats(chats))
        });

        return () => {
            unsubscribe(); 
          };
    }
    
  },[dispatch,user]);

 

  return (
    <>
      <Box
        flexGrow={1}
        sx={{
          position: "relative",
          width: '320px',
          maxWidth: '320px',
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <SidebarTitle 
        onClick={() => dispatch(changeDialog({dialog: DialogType.directMessageDialog}))} 
          title={t('chatPage.sideBarChatTitle')}/>
        <Stack p={1} spacing={2} sx={{ maxHeight: "85%" }}>
          
          <Stack sx={{ width: "100%" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={chatQuery}
                onChange={(event) => {
                  dispatch(searchChat(event.target.value));
                }}
                placeholder={t('chatPage.sideBarSearchHelperText')}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Stack>
          <Stack sx={{ 
            flexGrow: 1, 
            height: "100%",
            overflowY:'scroll',
            '&::-webkit-scrollbar': {
                display: 'none', // Customize the width of the scrollbar
              } }}>
            <Box >
              <Stack spacing={0}>
                <Typography variant="subtitle2" sx={{ color: "#676667" }}>
                {t('chatPage.sideBarChatDescription')}
                </Typography>
                {chatQuery === '' && chats.map((chat,index) => <ChatElement key={chat.id}{...chat} isSelected={currentChat !== null && chat.id === currentChat.id} onClick={() => {
                  if(user){
                    const data:ChangeChatModel = {
                      chat: chat,
                      userId: user.id,
                    }
                    dispatch(selectCurrentChat(data));
                    dispatch(updateNotification({
                      userId: user!.id,
                      chat: chat
                    }));
                  }
                  
                  }}/>)}
                {chatQuery !== '' && chats.filter(chat => chat.user.responderDisplayName.toLowerCase().includes(chatQuery)).map((chat,index) => <ChatElement key={chat.id}{...chat} isSelected={currentChat !== null && chat.id === currentChat.id} onClick={() => {
                  if(user){
                    const data:ChangeChatModel = {
                      chat: chat,
                      userId: user.id,
                    }
                    dispatch(selectCurrentChat(data));
                    dispatch(updateNotification({
                      userId: user!.id,
                      chat: chat
                    }));
                  } 
                  }}/>)}
                
                {
                  chats.length === 0 && 
                  <Box paddingTop={'50%'} display='flex'  height='100%'  >
                    <ErrorComponent caption='' image={ErrorType.card} width={'100%'} height={'100%'}/>
                  </Box>
                }
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};