import { Box, Stack, Typography, useTheme } from "@mui/material";


export interface EmptyComponentProps{
    title: string;
    subtitle: string;
    paddingTop?:number;
}
export const EmptyComponent: React.FC<EmptyComponentProps> = (props:EmptyComponentProps) => {
    const theme = useTheme();
    return (
        <Box height='100%'display={'flex'} justifyContent={'center'} alignItems={'center'} paddingTop={props.paddingTop}>
            <Stack alignItems={'center'}>
                <Typography  variant='h4'  color={theme.palette.textColor?.main}>
                    {props.title}
                </Typography>
                <Typography color={theme.palette.textColor?.main}>
                    {props.subtitle}
                </Typography>
            </Stack>
        </Box>
    );
}