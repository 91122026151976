import { Box, Stack, Typography, useTheme } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { ChannelModel } from "../../../model/response/channel_model";
import { useState } from "react";
import { SearchOnSelectedChannelPopOver } from "./search_on_selected_channels_pop_over";
import { useTranslation } from "react-i18next";

export const SarchOnSelectedChannelComponent: React.FC = () => {
    const theme = useTheme();
    const {t} = useTranslation();    

    const channels: ChannelModel[] = useSelector((state:RootState) => state.channel.channels )
    const currentFilters: string[] = useSelector((state:RootState) => state.search.filters )

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [anchorEl, setAnchor] = useState<HTMLSpanElement | null>(null);

    
    const openPopOver = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        setIsOpen(true);
        setAnchor(event.currentTarget);
    };

    const closePopOver = () => {
        setIsOpen(false);
        setAnchor(null);
    }


    return (
        <Box display='flex' width='100%' flexDirection={'row'}>
            <Stack direction='row' spacing={2}>
                <Typography color={theme.palette.textColor?.main}>
                    {t('searchPage.filterButton')}
                </Typography>
                <Stack direction='row' spacing={1}>
                    <Typography fontWeight={600} color={theme.palette.textColor?.main} 
                    onClick={(event) => openPopOver(event)}>
                        {t('searchPage.filterButtonCaption')}
                    </Typography>
                    <Box display={'flex'} color={theme.palette.textColor?.main}>
                        <KeyboardArrowDownIcon />
                    </Box>
                </Stack>
                <SearchOnSelectedChannelPopOver
                anchor={anchorEl} 
                channels={channels} 
                open={isOpen}
                currentFilters={currentFilters}
                onClose={() => closePopOver()}/>
            </Stack>
        </Box>
    );
}