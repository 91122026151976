import React from 'react';
import TurnLeftOutlinedIcon from '@mui/icons-material/TurnLeftOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import { Stack, Typography, useTheme } from '@mui/material';
export enum IconType {
    ANSWER,
    CONTRIBUTORS

}

export interface IconDescriptionProps {
    className?: string;
    iconType: IconType;
    text: string;
    counter?: number;
    onClick?: () => void;
}

export const IconDescription: React.FC<IconDescriptionProps> = (props: IconDescriptionProps) => {
    
    const theme = useTheme();
    
    return (
        <Stack spacing={0.5} direction={'row'} justifyContent={'center'} alignItems={'center'} onClick = {props.onClick} >
            {props.iconType === IconType.ANSWER && <TurnLeftOutlinedIcon sx={{color:theme.palette.textColor?.main}}/>}
            {props.iconType === IconType.CONTRIBUTORS && <Groups2OutlinedIcon sx={{color:theme.palette.textColor?.main}}/>}

            <Typography variant='caption' color={theme.palette.textColor?.main}>{props.counter} {props.text}</Typography>
        </Stack>
    );
}