import { Avatar, Box, Chip, Typography } from "@mui/material";
import { channel } from "diagnostics_channel";
import { setChannel } from "../../features/card_creation/api/card_creation_action";
import { channelColors } from "../../utils/channel_theme";
import { ChannelModel } from "../../model/response/channel_model";

export interface ChannelChipProps {
    channel: ChannelModel;
    onClick?: () => void;
}

export const ChannelChip: React.FC<ChannelChipProps> = (props: ChannelChipProps) => {
    const firstLetter = props.channel.channelName[0];
    const channelColor = channelColors[firstLetter];
    return (
        <Box key={props.channel.id} p={0.5} >
            <Chip variant="outlined"
                onClick={props.onClick}
                sx={{
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }}
                avatar={
                    <Avatar sx={{ bgcolor: channelColor, }}>
                        <Typography color={'white'}>
                            {firstLetter}
                        </Typography>
                    </Avatar>} label={props.channel.channelName} />
        </Box>
    );
}