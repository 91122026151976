import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RetrieveThreadModel } from "../../../model/request/retrieve_thread_model";
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../../utils/firebase_config";
import { CardModel } from "../../../model/response/card_model";
import { AnswerModel } from "../../../model/response/answer_model";
import { CommentModel } from "../../../model/response/comment_model";
import { SaveChangeAnswerModel, SubmitAnswerModel } from "../../../model/request/submit_answer_model";
import { SendCommentModel } from "../../../model/request/send_comment_model";
import { SwiperModel } from "../../../model/response/swiper_model";
import { UserModel } from "../../../model/response/user_model";
import { SaveChangeCardModel } from "../../../model/request/send_card_model";
import { VotingModel } from "../../../model/request/voting_model";
import { DeleteThread, FollowThread, UnFollowThread } from "../../../model/request/thread_model";
import { DeleteAnswer } from "../../../model/request/answer_model";
import { debugPrint } from "../../../utils/debug_print";
import { AnswerDraftModel } from "../../../model/response/answer_draft_model";
import { deleteObject, getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { FileType, AttachmentModel } from "../../../model/response/thread_draft_model";
import { AnalyticsEvent, createdAnswerEvent } from "../../../model/request/analytics_model";
import { sendEvent } from "../../analitycs/analitycs_action";
import { ChannelModel } from "../../../model/response/channel_model";
import { MentionOrientation } from "../../card_creation/api/card_creation_state";
import { changeDialog } from "../../dialog/dialog_action";
import { DialogType } from "../../dialog/dialog_state";


export const setCardId = createAction(
    'thread/setCardId',
    function prepare(cardId: string) {
        return {
            payload: {
                cardId: cardId
            },
        }
    });

export const setThreadId = createAction(
    'thread/setThreadId',
    function prepare(threadId: string) {
        return {
            payload: {
                threadId: threadId
            },
        }
    });


export const leaveThread = createAsyncThunk(
    'thread/leaveThread',
    async (data:{
        userId: string;
        threadId:string;
    }, {dispatch, rejectWithValue}) => {
        try{

            const db = getFirestore();
            const threadQuery = doc(db, "Users", data.userId, "Threads", data.threadId);
            await updateDoc(threadQuery, {'hasLeftDiscussion':true})
            dispatch(changeDialog({dialog: DialogType.none}));


        }catch(error:any){
            rejectWithValue(error.toString());
        }
    }
);

export const closeThread = createAsyncThunk(
    'thread/closeThread',
    async (data:{
        userId: string;
        threadId:string;
    }, { dispatch, rejectWithValue}) => {
        try{

            const db = getFirestore();
            const threadQuery = doc(db, "Users", data.userId, "Threads", data.threadId);
            await updateDoc(threadQuery, {'isClosed':true})
            dispatch(changeDialog({dialog: DialogType.none}));

        }catch(error:any){
            rejectWithValue(error.toString());
        }
    }
);

export const displayOptimisticAnswer = createAction(
    'thread/displayOptimisticAnswer',
    function prepare(data: {
        answerDraft:AnswerDraftModel;
        responderUser: {
            responderId: string;
            responderDisplayName: string;
            responderPhoto: string;
        }
    }) {
        const currentDate = new Date().toISOString();
        const answer: AnswerModel = {
            ...data.answerDraft,
            id: 'optimistic_answer',
            organizationId: '',
            upVotes: [],
            downVotes: [],
            totalVotes: 0,
            totalComments: 0,
            createdAt: currentDate,
            updatedAt: currentDate,
            helpingHand: false,
            responderUser: data.responderUser,
            attachments: data.answerDraft.attachments
        }

        return {
            payload: {
                answer: answer
            },
        }
    }
);

export const displayOptimisticComment = createAction(
    'thread/displayOptimisticComment',
    function prepare(data: SendCommentModel) {
        var responderUser = data.user;

        if (data.isAnonymous) {
            responderUser = {
                responderDisplayName: 'Organization Member',
                responderPhoto: 'https://firebasestorage.googleapis.com/v0/b/syllotips-prod.appspot.com/o/utils%2FAnonymus.png?alt=media&token=a13183d5-1d82-41ee-8f3f-0bb023a697c5&_gl=1*1qg3wka*_ga*NTI1NDIxNTMuMTY3NjU1MjEwMA..*_ga_CW55HF8NVT*MTY5Nzc0OTk2OC4zNjkuMS4xNjk3NzUxMDYzLjU1LjAuMA..',
                responderId: data.user.responderId,

            }
        }
        const comment: SendCommentModel = {
            answerId: data.answerId,
            answerIndex: data.answerIndex,
            id: data.id,
            cardId: data.cardId,
            plainText: data.plainText,
            user: responderUser,
            isAnonymous: data.isAnonymous,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt
        }
        return {
            payload: {
                comment: comment
            },
        };
    }
);

export const addVote = createAsyncThunk(
    'thread/addVote',
    async (data: VotingModel, {dispatch, rejectWithValue}) => {
        try{
            const functions = getFunctions(firebaseApp, 'europe-west1');
            const addVoteFunction = httpsCallable(functions, 'addVoteOnAnswerFunction');
            
            dispatch(addOptimisticVote(data))
            await addVoteFunction({'vote': data})
        }catch(error:any){
            debugPrint(error.toString(), 'thread/addVote')
            rejectWithValue(error.toString());
        }
    }
)
export const addOptimisticVote = createAction(
    'thread/addOptimisticVote',
    function prepare(data: VotingModel) {
        return {
            payload: {
                vote: data
            },
        };
    }
);


export const loadThread = createAsyncThunk(
    'thread/loadThread',
    async (data: RetrieveThreadModel, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            var thread: CardModel | undefined;
            var cardId: string;

            if (data.threadId) {
                const threadQuery = doc(db, "Users", data.userId, "Threads", data.threadId);

                const threadStarted = performance.now();
                const document = await getDoc(threadQuery);
                const threadLoaded = performance.now();
                
                await updateDoc(threadQuery,{
                    notificationBalance: {
                        hasNotificationComments: false,
                        hasNotificationAnswers: false,
                        notificationCounter: 0,
                      }
                });
                thread = document.data() as CardModel;
                cardId = thread.cardId;
            } else {

                const threadQuery = query(collection(db, 'Users', data.userId, 'Threads'), where('cardId', '==', data.cardId));
                const threadSnapshot = await getDocs(threadQuery);

                if (threadSnapshot.docs.length == 1) {

                    thread = threadSnapshot.docs[0].data() as CardModel;
                    cardId = data.cardId;

                } else {
                    const functions = getFunctions(firebaseApp, 'europe-west1');
                    const findThreadByCardId = httpsCallable(functions, 'findThreadByCardIdFunction');

                    const threadStarted = performance.now();
                    const threadDoc = await findThreadByCardId({ card: data });
                    const threadLoaded = performance.now();

                    thread = threadDoc.data as CardModel;
                    cardId = data.cardId;

                }

            }

            const restStarted = performance.now();

            // Execute these queries in parallel using Promise.all()
            const [commentQueryResults, answerQueryResult, swiperQueryResult, senderQueryResult, answerDraftQueryResult, channelResult] = await Promise.all([
                // Execute answerQuery
                (async () => {
                    const commentQuery = query(collection(db, "Cards", cardId, 'CommentOnCards'), orderBy('createdAt'));
                    const queryCommentSnapshot = await getDocs(commentQuery);
                    const commentsOnThread: CommentModel[] = []
                    queryCommentSnapshot.docs.map((doc, index) => {
                        return commentsOnThread.push(doc.data() as CommentModel);
                    });
                    return commentsOnThread;
                })(),
                (async () => {
                    const answerQuery = query(collection(db, "Cards", cardId, 'Answers'), orderBy('createdAt', 'asc'));
                    const querySnapshot = await getDocs(answerQuery);

                    const answersPromises = querySnapshot.docs.map(async (doc, index) => {
                        const answer = doc.data() as AnswerModel;
                        const commentsOnAnswer: CommentModel[] = [];

                        if (answer.totalComments > 0) {
                            const commentQuery = query(collection(db, "Cards", cardId, 'Answers', doc.id, 'Comments'), orderBy('createdAt'));
                            const queryCommentSnapshot = await getDocs(commentQuery);

                            queryCommentSnapshot.docs.map((doc, index) => {
                                return commentsOnAnswer.push(doc.data() as CommentModel);
                            });
                        }

                        answer.comments = commentsOnAnswer;
                        return answer;
                    });

                    return Promise.all(answersPromises);
                })(),

                // Execute swiperQuery
                (async () => {
                    const swiperQuery = query(collection(db, "Cards", cardId, 'Swipers'), where('swipeType', '==', 1));
                    const querySwiperSnapshot = await getDocs(swiperQuery);
                    const swipers: SwiperModel[] = [];

                    querySwiperSnapshot.docs.map((doc) => {
                        swipers.push(doc.data() as SwiperModel);
                    });

                    return swipers;
                })(),

                // Execute senderQuery
                (async () => {
                    const senderQuery = doc(db, "Users", thread!.senderUser.senderId);
                    const senderDoc = await getDoc(senderQuery);
                    const senderUser = senderDoc.data() as UserModel;
                    return senderUser;
                })(),
                (async () => {
                    const answerQuery = query(collection(db, "Users",data.userId,'AnswerDrafts'),where('cardId','==',thread!.cardId));
                    const answerDoc = await getDocs(answerQuery);
                    if (answerDoc.docs.length == 1){
                      return  answerDoc.docs[0].data() as AnswerDraftModel;    
                    }                
                })(),
                (async () => {
                    const channelQuery = doc(db, "Organizations",thread!.organizationId,'Channels',thread!.channel.channelId);
                    const channelDoc = await getDoc(channelQuery);
                    const channel = channelDoc.data() as ChannelModel;
                    return channel;
                })(),
            ]);
            const endTime = performance.now();


            thread.comments = commentQueryResults;

            return {
                cardId: cardId,
                thread: thread,
                answers: answerQueryResult,
                swipers: swiperQueryResult,
                senderUser: senderQueryResult,
                channel: channelResult,
                draft: answerDraftQueryResult,
            };
        } catch (error: any) {
            debugPrint(error.toString(), 'thread/loadThread');
            return rejectWithValue(error.toString());
        }
    }
);

export const updateAnswerDraft = createAsyncThunk(
    'thread/updateAnswerDraft',
    async (data: {
        answerDraft: AnswerDraftModel;
        userId: string;
    }, { dispatch, rejectWithValue }) => {
        try {
            const db = getFirestore();

            if (data.answerDraft.id === '') {

                const docRef = doc(collection(db, "Users", data.userId, "AnswerDrafts"));
                const draft: AnswerDraftModel = {
                    ...data.answerDraft,
                    id: docRef.id
                }


                dispatch(setDraft(draft));
                await setDoc(docRef, draft);


                return {}

            }
            const docRef = doc(db, "Users", data.userId, "AnswerDrafts", data.answerDraft.id);

            await updateDoc(docRef, {
                'plainText': data.answerDraft.plainText,
                'cardId': data.answerDraft.cardId,
                'richText': data.answerDraft.richText,
                'attachments': data.answerDraft.attachments,
            });

            dispatch(setDraft(data.answerDraft));

            
        } catch (error: any) {
            debugPrint(error.toString(), 'cardCreation/updateThreadDraft');
            return rejectWithValue(error.toString());
        }
    });

export const setDraft = createAction(
    'thread/setDraft',
    function prepare(draft: AnswerDraftModel | undefined) {
        return {
            payload: {
                draft
            }
        }
    }
);

export const uploadImagesOnAnswer = createAsyncThunk(
    'thread/uploadImagesOnAnswer',
    async (data:{
        images: string[];
        fileNames:string[];
        fileTypes:FileType[],
        userId: string;
        cardId:string;
        draftId: string | undefined;
    }, {dispatch, rejectWithValue}) => {
        try{
            const storage = getStorage();
            const db = getFirestore();

            var draftId = data.draftId;
            
            var draft: AnswerDraftModel | undefined = undefined;
            if (data.draftId === undefined){
                
                
                const docRef = doc(collection(db, 'Users', data.userId, 'AnswerDrafts'));
                const jsonData = [{"insert":"\n"}]
                 draft = {
                    id: docRef.id,
                    cardId: data.cardId,
                    attachments:[],
                    plainText: '',
                    richText: JSON.stringify(jsonData),
                }

                await setDoc(docRef,draft);
                draftId =  docRef.id;
                dispatch(setDraft(draft));

            }else{
                draftId = data.draftId;
            }
            const images:AttachmentModel[] = [];

            dispatch(waitForAttachmentsToBeUploaded(data.images));
            
            await Promise.all(
                data.images.map(async (dataUrl, index) => {
                    const documentsRef = ref(storage, 'users/'+ data.userId +'/drafts/'+ draftId + '/images/' +  data.fileNames[index]);
                    await uploadString(documentsRef, dataUrl,'data_url');
                    const imageUrl = await getDownloadURL(documentsRef);

                    const image: AttachmentModel = {
                        name: data.fileNames[index],
                        url: imageUrl,
                        storageRef: 'users/' + data.userId + '/drafts/' + draftId + '/images/' + data.fileNames[index],
                        type: data.fileTypes[index]
                    };
                    images.push(image)
                })
            );
            

            dispatch(updateImagesOnAnswer(images));
            
                                    
        }catch(error:any){
            debugPrint(error.toString(),'cardCreation/uploadImagesOnAnswer');
            return rejectWithValue(error.toString());
        }
    }
);
export const deleteAnswerImage = createAction(
    'thread/deleteAnswerImage',
    function prepare(imageRef: string){

        return {
            payload: {
                imageRef
            }
        }
    }
);

export const deleteAnswerImageFromFirestore = createAsyncThunk(
    'thread/deleteAnswerImageFromFirestore',
    async (data:{
        firebaseStorageRef: string;
    }, {dispatch, rejectWithValue}) => {
        try{
            const storage = getStorage();
            const documentsRef = ref(storage,data.firebaseStorageRef);

            dispatch(deleteAnswerImage(data.firebaseStorageRef))

            await deleteObject(documentsRef);

        }catch(error:any){
            debugPrint(error.toString(),'cardCreation/deleteImageFromFirestore');
            rejectWithValue(error.toString());
        }
    });

export const waitForAttachmentsToBeUploaded = createAction(
    'thread/waitForAttachmentsToBeUploaded',
    function prepare(filesInLoading: string[]){

        return {
            payload: {
                filesInLoading
            }
        }
    }
);

export const updateImagesOnAnswer = createAction(
    'thread/updateImagesOnAnswer',
    function prepare(images: AttachmentModel[]){

        return {
            payload: {
                images
            }
        }
    }
);

export const submitAnswer = createAsyncThunk(
    'thread/submitAnswer',
    async (data: {
        answer:SubmitAnswerModel;
        answerDraft: AnswerDraftModel;
        responderUser: {
            responderId: string;
            responderDisplayName: string;
            responderPhoto: string;
        };
        
    }, { dispatch,rejectWithValue }) => {
        try {

            const functions = getFunctions(firebaseApp, 'europe-west1');
            const sendAnswerFunction = httpsCallable(functions, 'addAnswerFunction');
            
            dispatch(displayOptimisticAnswer({
                answerDraft:data.answerDraft,
                responderUser: data.responderUser
            }))
            dispatch(setDraft(undefined))
            await sendAnswerFunction({ answer: data.answer });

            const log: AnalyticsEvent = {
                event: {timestamp: new Date().toISOString()},
                eventName: createdAnswerEvent
            };

            dispatch(sendEvent(log));
            
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const sendComment = createAsyncThunk(
    'thread/sendComment',
    async (data: SendCommentModel, { rejectWithValue }) => {
        try {

            const db = getFirestore();
            var responderUser = data.user;

            if (data.isAnonymous) {
                responderUser = {
                    responderDisplayName: 'Organization Member',
                    responderPhoto: 'https://firebasestorage.googleapis.com/v0/b/syllotips-prod.appspot.com/o/utils%2FAnonymus.png?alt=media&token=a13183d5-1d82-41ee-8f3f-0bb023a697c5&_gl=1*1qg3wka*_ga*NTI1NDIxNTMuMTY3NjU1MjEwMA..*_ga_CW55HF8NVT*MTY5Nzc0OTk2OC4zNjkuMS4xNjk3NzUxMDYzLjU1LjAuMA..',
                    responderId: data.user.responderId,

                }
            }

            if (data.answerId) {
                const docRef = doc(collection(db, "Cards", data.cardId, "Answers", data.answerId, "Comments"));

                const commentToSend: CommentModel = {
                    id: docRef.id,
                    plainText: data.plainText,
                    user: responderUser,
                    createdAt: data.createdAt,
                    updatedAt: data.updatedAt
                }
                await setDoc(docRef, commentToSend)
                return {}
            } else {
                const docRef = doc(collection(db, "Cards", data.cardId, "CommentOnCards"));

                const commentToSend: CommentModel = {
                    id: docRef.id,
                    plainText: data.plainText,
                    user: responderUser,
                    createdAt: data.createdAt,
                    updatedAt: data.updatedAt
                }

                await setDoc(docRef, commentToSend)
                return {}
            }

        } catch (error: any) {
            debugPrint(error.toString(), 'thread/sendComment');
            return rejectWithValue(data);
        }
    }
);



export const deleteThread = createAsyncThunk(
    'thread/deleteThread',
    async (data: DeleteThread, { rejectWithValue, dispatch }) => {
        try {
            dispatch(changeDialog({dialog: DialogType.none}))
            const db = getFirestore();
            const docRef = doc(db, 'Users', data.userId, 'Threads', data.threadId);
            await deleteDoc(docRef);
        } catch (error: any) {
            debugPrint(error.toString(), 'thread/deleteThread');
            return rejectWithValue(error.toString());
        }
    });

    export const followThread = createAsyncThunk(
        'thread/followThread',
        async (data: FollowThread, { rejectWithValue }) => {
            try {
                const functions = getFunctions(firebaseApp, 'europe-west1');
                const followFunction = httpsCallable(functions, 'followCardFunction');
                const result = await followFunction({ follow: data });
            } catch (error: any) {
                return rejectWithValue(error.toString());
            }
        });
    
        export const unfollowThread = createAsyncThunk(
            'thread/unfollowThread',
            async (data: UnFollowThread, { rejectWithValue }) => {
                try {
                    const db = getFirestore();
                    const threadQuery = doc(db, "Users", data.userId, "Threads", data.threadId);
                    await updateDoc(threadQuery, {isFollowed:false})
                } catch (error: any) {
                    return rejectWithValue(error.toString());
                }
            });

export const editThread = createAction(
    'thread/editThread',
    function prepare(edit: boolean) {
        return {
            payload: {
                edit: edit
            }
        }
    }
);

export const editThreadOptimisticUpdate = createAction(
    'thread/editThreadOptimisticUpdate',
    function prepare(thread: SaveChangeCardModel) {
        return {
            payload: {
                thread
            }
        }
    }
);


export const saveThreadChanges = createAsyncThunk(
    'thread/saveThreadChanges',
    async (data: SaveChangeCardModel, { dispatch, rejectWithValue }) => {
        try {
            dispatch(editThreadOptimisticUpdate(data));
            const db = getFirestore();
            const docRef = doc(db, 'Users', data.senderId, 'Threads', data.threadId);
            await updateDoc(docRef, {
                'cardRichText': data.cardRichText,
                'cardTitle': data.cardTitle,
                'cardText': data.cardText,
            });

            return data;
        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    });

export const saveAnswerChanges = createAsyncThunk(
    'thread/saveAnswerChanges',
    async (data: SaveChangeAnswerModel, { dispatch, rejectWithValue }) => {
        try {
            dispatch(editAnswerOptimisticUpdate(data));
            const db = getFirestore();
            const docRef = doc(db, 'Cards', data.cardId, 'Answers', data.answerId);
            await updateDoc(docRef, {
                'richText': data.richText,
                'plainText': data.plainText,
            });

            return data;
        } catch (error: any) {
            return rejectWithValue(error.toString());
        }
    });

export const editAnswerOptimisticUpdate = createAction(
    'thread/editAnswerOptimisticUpdate',
    function prepare(answer: SaveChangeAnswerModel) {
        return {
            payload: {
                answer
            }
        }
    }
);


export const deleteAnswer = createAsyncThunk(
    'thread/deleteAnswer',
    async (data: DeleteAnswer, { rejectWithValue, dispatch }) => {
        try {
            dispatch(changeDialog({dialog: DialogType.none}))
            const db = getFirestore();
            const docRef = doc(db, 'Cards', data.cardId, 'Answers', data.answerId);
            await deleteDoc(docRef);

            return data;
        } catch (error: any) {
            debugPrint(error.toString(), 'thread/deleteAnswer');
            return rejectWithValue(error.toString());
        }
    });

export const editAnswer = createAction(
    'thread/editAnswer',
    function prepare(edit: string | undefined) {
        return {
            payload: {
                edit: edit
            }
        }
    }
)

