import { Box, Stack, Typography, useTheme } from "@mui/material";

export interface ReactionsProps {
    reactions:{
        [key: string]: string[];
    }
}

export const Reactions: React.FC<ReactionsProps> = (props: ReactionsProps) => {

    const theme = useTheme();

    return(
        <Stack direction='row' spacing={1}>
            {
                props.reactions && Object.keys(props.reactions).map((reaction, index) => {
                    return(
                        <Box paddingTop={'1px'} paddingLeft={'3px'} paddingRight={'3px'} border={'solid 1px #dedede'} borderRadius={'10px'} key={index} display='flex' flexDirection='row'>
                            <Typography variant={'body2'} color={theme.palette.textColor?.main}>
                            {reaction} {props.reactions[reaction].length}
                            </Typography>
                        </Box>
                    );
                })
            }
        </Stack>
    );
};