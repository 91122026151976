import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { UserModel } from "../../../model/response/user_model";
import { DocumentData } from "firebase/firestore";
import { useEffect } from "react";
import { FetchUserNotificationModel, UpdateUserNotificationModel } from "../../../model/request/fetch_profile_models";
import { Avatar, Box, CardHeader, IconButton, List, ListItem, Stack, Typography, alpha, useTheme } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { NotificationModel, NotificationType } from "../../../model/response/notification_model";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useNavigate } from "react-router-dom";
import { goForward } from "../../navigation/navigation_action";
import { Page } from "../../navigation/navigation_state";
import { setCardId, setThreadId } from "../../thread/api/thread_action";
import { updateUserNotification } from "../../auth/api/authAction";
import { ErrorComponent, ErrorType } from "../../../components/error-component/error_component";
import { useTranslation } from "react-i18next";
import { MarkAllAsReadModel } from "../../../model/request/notification";
import { fetchNotifications, markAllNotification, updateNotification } from "../api/notification_action";
import { DialogType } from "../../dialog/dialog_state";
import { changeDialog } from "../../dialog/dialog_action";
import logo from '../../../assets/logo.svg';
export const NotificationPage = () => {

    const dispatch = useDispatch<AppDispatch>();
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const lastSeenNotification: DocumentData | undefined = useSelector((state: RootState) => state.notification.lastSeenNotification);

    const scrollYourNotification: boolean = useSelector((state: RootState) => state.notification.scrollNotifications);
    const yourNotification: NotificationModel[] = useSelector((state: RootState) => state.notification.notifications);

    const theme = useTheme();
    const navigate = useNavigate();
    const {t,i18n} = useTranslation();
    const renderLoading = () => {
        return (
            <span>Loading</span>
        );
    }

    useEffect(() => {
        if (user !== undefined
            ) {
            const data: FetchUserNotificationModel = {
                userId: user.id,
                size: 20,
            }

            dispatch(fetchNotifications(data));

        }
    }, []);

    const fetchYourNotifications = () => {
        if (user !== undefined) {
            const data: FetchUserNotificationModel = {
                userId: user.id,
                size: 50,
                lastElement: lastSeenNotification,
            }

            dispatch(fetchNotifications(data));
        }
    }

    const clickOnNotification = (notification: NotificationModel) => {

        switch (notification.type) {
            case NotificationType.dm:
                dispatch(goForward(Page.ChatPage));
                navigate('/chat');
                break;
            case NotificationType.answers:
                dispatch(goForward(Page.ThreadPage));
                dispatch(setThreadId(notification.threadId));
                navigate('/thread/thread=' + notification.threadId);
                break;
            case NotificationType.commentOnAnswers:
                dispatch(goForward(Page.ThreadPage));
                dispatch(setThreadId(notification.threadId));
                navigate('/thread/thread=' + notification.threadId);
                break;
            case NotificationType.tag:
                dispatch(goForward(Page.ThreadPage));
                if(notification.cardId){
                    dispatch(setCardId(notification.cardId));
                    navigate('/thread/card=' + notification.cardId);
                }
                break;
            case NotificationType.upVotes:
                dispatch(goForward(Page.ThreadPage));
                dispatch(setThreadId(notification.threadId));
                navigate('/thread/thread=' + notification.threadId);
                break;
            case NotificationType.upVotes:
                dispatch(goForward(Page.ThreadPage));
                dispatch(setThreadId(notification.threadId));
                navigate('/thread/thread=' + notification.threadId);
                break;
            case NotificationType.badges:
                if(user) dispatch(changeDialog({dialog: DialogType.expertDialog, expertId: user.id}));
                break;

            default:
                break;
        }
    }

    return (
        <Stack  width={500}>
            <Box display={'flex'} flexDirection='row' justifyContent={'space-between'} padding={2} alignItems={'center'} >
                <Typography variant='h5' fontWeight={'600'} color={theme.palette.textColor?.main}>{t('notificationPopOver.title')}</Typography>
                <IconButton onClick = {() => {
                    if(user){
                        const data: MarkAllAsReadModel = {
                            userId: user.id
                        }
                        dispatch(markAllNotification(data));
                    }
                }
            }>
                    <DoneAllIcon />
                </IconButton>
            </Box>
            {
                yourNotification.length == 0 ?
                <ErrorComponent caption='Here you will find all the notifications' image={ErrorType.card}/>
                :
                <Box>
                <InfiniteScroll

                    dataLength={yourNotification.length}
                    next={fetchYourNotifications}
                    hasMore={scrollYourNotification}
                    height={'50vh'}
                    loader={renderLoading()}
                >
                    <List sx={
                        {
                            padding: 0
                        }
                    }>
                        {
                            yourNotification.map((notification, index) => {
                                var actorPhoto = notification.actorPhoto;
                                if(actorPhoto === ''){
                                    actorPhoto = logo;
                                }
                                return (
                                    <ListItem key={index} sx={{
                                        backgroundColor:!notification.viewed? alpha(theme.palette.primary.main, 0.5): null
                                    }}>
                                        <CardHeader 
                                        onClick = {() => {
                                            const data:UpdateUserNotificationModel={
                                                userId: user!.id,
                                                notificationId: notification.id
                                            }
                                            dispatch(updateNotification(data));
                                            if(!notification.viewed){
                                                dispatch(updateUserNotification(user!.notificationBalance.notificationCounter));
                                            }
                                            
                                            clickOnNotification(notification);
                                        }}
                                        sx={{
                                            padding: 0,
                                            
                                            '&:hover': {
                                                cursor: 'pointer'
                                            },
                                            '.MuiCardHeader-title': {
                                                color: theme.palette.textColor?.main,
                                                padding: 0,

                                            },
                                            '.MuiCardHeader-content': {
                                                color: 'red'
                                            },
                                            '.MuiCardHeader-subheader': {
                                                padding: 0, /* Remove top padding */
                                            }
                                        }}
                                            avatar={
                                                <Avatar
                                                    src={actorPhoto} aria-label="recipe" sx={{
                                                        width: '60px',
                                                        height: '60px'
                                                    }} />
                                            }
                                            title={notification.body.replace(/\*/g, '')}

                                        />
                                    </ListItem>

                                );
                            })
                        }
                    </List>
                </InfiniteScroll>
            </Box>
            
            }
            
        </Stack>
    );
}

