import {  createSlice } from '@reduxjs/toolkit'
import {  goBackward, goForward, openChannelDrawer } from './navigation_action'
import { NavigationState, Page } from './navigation_state'

const initialState: NavigationState = {
    currentPage: Page.HomePage,
    previousPage: Page.none,
    drawerIsOpen: false,
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(goForward, (state, action) => {
        state.previousPage = state.currentPage;
        state.currentPage = action.payload.currentPage;
        
      })
      .addCase(goBackward, (state, action) => {
        state.currentPage = action.payload.currentPage;
      })
      .addCase(openChannelDrawer, (state,action) => {
        state.drawerIsOpen = action.payload.open;  
      })
      .addDefaultCase(() => {})
  },
});

export default navigationSlice.reducer;