import { Box, CircularProgress, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { UserModel } from '../../../model/response/user_model';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { ChannelModel } from '../../../model/response/channel_model';
import { collection, getFirestore, limit, limitToLast, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { ChannelBubbleModel, ChannelMessageModel } from '../../../model/response/channel_message_model';
import {ChannelChatBubble} from './channel_chat_bubble';
import { fetchChannelChatMessages, pullToRefreshMessages, updateChatNotificationCounter } from '../api/channel_chat_action';
import { ChannelChatDate } from './channel_chat_date';
import { ChannelChatPlaceHolder } from './channel_chat_place_holder';
import { ChannelChatAction } from '../api/channel_chat_state';

export const ChannelChatBody: React.FC = () => {

    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const selectedChannel: ChannelModel | null = useSelector((state: RootState) => state.channel.currentChannel);
    const messageToAnswer: ChannelMessageModel | undefined = useSelector((state: RootState) => state.channelChat.messageToAnswer);
    const messageToEdit: ChannelMessageModel | undefined = useSelector((state: RootState) => state.channelChat.messageToEdit);
    
    const channelChatMessages: ChannelBubbleModel[] = useSelector((state: RootState) => state.channelChat.messages)
    const isRefreshing: boolean = useSelector((state: RootState) => state.channelChat.isRefreshing);
    const currentLimit: number = useSelector((state: RootState) => state.channelChat.limit);
    const action: ChannelChatAction = useSelector((state: RootState) => state.channelChat.action);
    const isFirstMessage: boolean = useSelector((state: RootState) => state.channelChat.isFirstMessage);
    const scrollHeight: number = useSelector((state: RootState) => state.channelChat.scrollHeight);
    const dispatch = useDispatch<AppDispatch>();
    
    const conversationRef = useRef<any>(null);
    
    

    useEffect(() => {
        
        if (conversationRef !== null && action === ChannelChatAction.send){
            conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
        }

        if (conversationRef !== null && action === ChannelChatAction.pull){
            conversationRef.current.scrollTop = conversationRef.current.scrollHeight - scrollHeight;
        }
    },[
        channelChatMessages.length > 0 ?channelChatMessages[channelChatMessages.length -1].id:undefined , 
        channelChatMessages.length > 0 ?channelChatMessages[0].id:undefined , 
        
        action]);

    
    const pullToRefresh = () => {
        if (conversationRef.current) {
            const { scrollTop } = conversationRef.current;

            if (scrollTop === 0 && !isRefreshing) {
                const scrollHeight = conversationRef.current.scrollHeight as number;
                dispatch(pullToRefreshMessages(scrollHeight));
            }
        }
    };

    useEffect(() => {
        const element = conversationRef.current;
        if (element) {
            element.addEventListener('scroll', pullToRefresh);
        }
        return () => {
            if (element) {
                element.removeEventListener('scroll', pullToRefresh);
            }
        };
    }, [isRefreshing]);


    useEffect(
        () => {
            if (user && selectedChannel && selectedChannel.channelId !== 'AGORA') {
                const db = getFirestore();
                const q = query(
                    collection(db, 
                        "Organizations", 
                        user.organizationId, 
                        "Channels", 
                        selectedChannel.channelId, 
                        "ChannelChats", 
                        selectedChannel.chatChannelId, 
                        'ChannelMessages'),
                        where('isDraft','==',false),
                        limitToLast(currentLimit),
                        orderBy('createdAt', 'asc'),);

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    dispatch(fetchChannelChatMessages(querySnapshot.docs.map(message => message.data() as ChannelMessageModel)))
                    
                    dispatch(updateChatNotificationCounter({
                        userId: user.id,
                        channelId: selectedChannel.id
                    }))
                });

                return () => {
                    unsubscribe();
                };
            }

        }, [user, selectedChannel,currentLimit]
    );

    return (
        <Box p={1} ref={conversationRef} display='flex' flexGrow={1}  height={"inherit"}
        
            sx={{
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                    display: 'none', // Customize the width of the scrollbar
                },
            }}>
            <Stack spacing={0} width={'100%'}>
                <Box flexGrow={1} display={'flex'}>
                </Box>
                {isFirstMessage && <ChannelChatPlaceHolder/>}
                {channelChatMessages.map((message, index) => {
                    var isLeading = true;
                    
                    if (index >0){
                        const previousMessage = channelChatMessages[index - 1];
                        const previousMessageDate = new Date(previousMessage.createdAt);
                        const currentMessageDate = new Date(message.createdAt); 

                        const difference = currentMessageDate.getTime() - previousMessageDate.getTime();
                        const seconds = Math.floor(difference / 1000);
                        if(!previousMessage.isDate && previousMessage.writerId === message.writerId && seconds < 300){
                            isLeading = false
                            
                        }
                    }
                    const isAnswering = messageToAnswer && messageToAnswer.id === message.id ? true : false;
                    const isEditing = messageToEdit && messageToEdit.id === message.id ? true : false;
                    if(!message.isDate){
                        return <ChannelChatBubble
                            id={message.id}
                            isOptimistic={message.isOptimistic}
                            isAnswering={isAnswering}
                            index={index}
                            messageToResponce={message.responseToMessage}
                            attachments={message.attachments}
                            key={message.id}
                            isLeading={isLeading || message.responseToMessage !== undefined}
                            author={message.writerDisplayName}
                            timestamp={message.createdAt}
                            text={message.text}
                            photo={message.writerPhoto}
                            plainText={message.plainText}
                            isOwner={message.writerId === user?.id}
                            writerId={message.writerId}
                            reactions={message.reactions} 
                            isEditing={isEditing}/>
                    }else{
                        return <ChannelChatDate  key={index} date={message.createdAt}/>
                    }
                    
                })}
            </Stack>
        </Box>
    );
};

