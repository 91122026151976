import { Box, Card, IconButton, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import ClearIcon from '@mui/icons-material/Clear';
import { ChatDocumentModel } from "../../../model/request/chat_document_model";
import { selectDocumentToSend, sendChatMessage, sendMessageOptimisticUpdate, updateMessageText } from "../api/chat_action";
import SendIcon from '@mui/icons-material/Send';
import { MessageModel, MessageType, MirrorType } from "../../../model/response/message";
import { UserModel } from "../../../model/response/user_model";
import { v4 as uuidv4 } from 'uuid';
import { ChatModel } from "../../../model/response/chat_model";
export const ChatPicker = () => {
    
    const filePath:string = useSelector((state: RootState) => state.chat.filePath);
    const user: UserModel | undefined = useSelector((state: RootState) => state.auth.user);
    const currentChat: ChatModel | null = useSelector((state: RootState) => state.chat.currentChat);
    const chats:ChatModel[] = useSelector((state: RootState) => state.chat.chats);

    const dispatch = useDispatch<AppDispatch>();
    const textMessage:string = useSelector((state: RootState) => state.chat.messageText);

    
    const sendImage = () => {
        if (user !== undefined && currentChat !== null){
            const currentDate = new Date().toISOString();
            const message:MessageModel={
                id: uuidv4(),
                organizationId: user.organizationId,
                text: textMessage,
                messageType: MessageType.Image,
                receiverId: currentChat.user.responderId,
                writerId: user.id,
                photo: filePath,
                mirrorStatus: MirrorType.Real,
                createdAt:currentDate,
                updatedAt: currentDate,
            }
            
            dispatch(sendMessageOptimisticUpdate(message));
            
            dispatch(sendChatMessage({
                message:message,
                chat: currentChat
            }));
            
            const documentData: ChatDocumentModel = {
                fileIsSelected: false,
                filePath: '',
              };

            dispatch(selectDocumentToSend(documentData));
            dispatch(updateMessageText(''));
        }    
    }

    return(
        <>
            <Stack
            height={"100%"}
            maxHeight={"90vh"}
            alignItems={'center'}
            width={`calc(96vw - 320px )`}
            maxWidth={`calc(96vw - 320px )`}
            spacing={8}
        >
            <Box alignSelf={'start'}>
                <IconButton onClick={
                    () => {
                        const data: ChatDocumentModel = {
                            fileIsSelected: false,
                            filePath: '',
                          };

                        dispatch(selectDocumentToSend(data));
                    }
                }>
                    <ClearIcon/>
                </IconButton>
            </Box>
            <Box height='70%' width='90%' display='flex' justifyContent={'center'} >
                <Card sx={{
                    width:'90%',
                    padding:0,
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <img src={filePath} alt={''} width={'70%'} height={'auto'}/>     
                </Card>
            </Box>
            
            
            <Box width={'80%'}>
                <TextField
                onChange={(event) => dispatch(updateMessageText(event.target.value))}
                fullWidth 
                value={textMessage}
                InputProps={{
                    endAdornment: 
                    <IconButton onClick={sendImage}>
                        <SendIcon/>
                    </IconButton>
                }}/>
            </Box>
        </Stack>
        </>
    );
}