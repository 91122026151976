import { createSlice } from "@reduxjs/toolkit";
import { OrganizationState } from "./organization_state"
import { loadCurrentOrganization } from "./organization_actions";

const initialState: OrganizationState = {
    currentOrganization: null
}
  
  
  const introductionSlice = createSlice({
      name: 'org',
      initialState: initialState,
      reducers: {
      },
      extraReducers: (builder) => {
        builder
        .addCase(loadCurrentOrganization.fulfilled, (state, payload) => {
            state.currentOrganization = payload.payload.currentOrganization;
        })
      }
  });

export default introductionSlice.reducer;