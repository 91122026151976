import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { SearchTagModel } from "../../../model/request/search_model";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../../utils/firebase_config";
import { TagModel } from "../../../model/response/tag_model";
import { IntroductionState } from "./introduction_state";
import { FirstTagSelectionModel } from "../../../model/request/user_introduction_model";
import { updateUserProfile } from "../../auth/api/authAction";
import { debugPrint } from "../../../utils/debug_print";
import { RootState } from "../../../app/store";
import { DialogType } from "../../dialog/dialog_state";
import { changeDialog } from "../../dialog/dialog_action";

export const updateTag = createAction(
    'introduction/updateTag',
    function prepare (tag:string) {
      return {
          payload: {
            tag: tag
          },
        } 
  });

export const updateBio = createAction(
    'introduction/updateBio',
    function prepare (bio:string) {
      return {
          payload: {
            bio: bio
          },
        } 
  });
  
export const updatePhoto = createAction(
    'introduction/updatePhoto',
    function prepare (photo:string) {
      return {
          payload: {
            photo: photo
          },
        } 
  });

  export const updateTagQuery = createAction(
    'introduction/updateTagQuery',
    function prepare (query:string) {
      return {
          payload: {
            query: query
          },
        } 
  });

export const updateProfile = createAsyncThunk(
  'introduction/updateProfile',
  async (data:{
    userId: string;
    organizationId: string;
  }, {getState,dispatch,rejectWithValue}) => {
      try{
          const currentState:RootState = getState() as RootState;
          const introductionState = currentState.introduction as IntroductionState;
          const db = getFirestore();
          const storage = getStorage();

          const documentRef = doc(db, 'Users', data.userId);
          const imageRef = ref(storage, 'profile_pictures/' + data.userId);
          
          let url: string | undefined;
          if(introductionState.photo){
            await uploadString(imageRef, introductionState.photo,'data_url');
            url = await getDownloadURL(imageRef);
            await updateDoc(documentRef, {'photo': url, 'bio': introductionState.bio, 'isFirstLogin': false})
          }else{
            await updateDoc(documentRef, {'bio': introductionState.bio,'isFirstLogin': false})
          }

          const functions = getFunctions(firebaseApp,'europe-west6');
          const tagFunction = httpsCallable(functions, 'firstTagSelectionFunction');
          
          const tagData: FirstTagSelectionModel ={
            userId: data.userId,
            organizationId: data.organizationId,
            tagSelected: introductionState.tagsToKeep,
          }
          
          await tagFunction({tag:tagData});

          dispatch(changeDialog({dialog: DialogType.none}))

          dispatch(updateUserProfile(
            introductionState.bio,
            url,
            undefined,
            false
            ));
         
      }catch(error:any){
        debugPrint(error.toString(),'introduction/updateProfile');
          return rejectWithValue(error.toString());
      }
  }
);

export const  updateTutorial = createAsyncThunk(
  'introduction/updateTutorial',
  async (data:{
    userId: string;
  }, {dispatch,rejectWithValue}) => {
    try{
      const db = getFirestore();
      const documentRef = doc(db, 'Users', data.userId);
      dispatch(updateUserProfile(
        undefined,
        undefined,
        false,
        undefined
        ));
      await updateDoc(documentRef, {'isFirstTutorial': false});
      
    }catch(error:any){
      return rejectWithValue(error.toString());
    }

  });

export const searchTags = createAsyncThunk(
  'introduction/searchTags',
  async (data:SearchTagModel, {rejectWithValue}) => {
      try{
        const functions = getFunctions(firebaseApp,'europe-west6');
        const tagFunction = httpsCallable(functions, 'searchTagFunction');
        const doc = await tagFunction({tag:data});
        
        const searchTagResults = doc.data as TagModel[];
        const tags: string[] = [];
        searchTagResults.map((element) => tags.push(element.tagName));
        return {
          tags:tags
        }
      }catch(error:any){
          return rejectWithValue(error.toString());
      }
  }
);

