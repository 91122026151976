import { Button, CircularProgress } from "@mui/material";

export interface ActionButtonProps {
    isLoading: boolean;
    disabled: boolean;
    title: string;
    color?: 'error' | undefined
    onClick: () => void;
}

export const ActionButton: React.FC<ActionButtonProps> = (props: ActionButtonProps) => {

    const actionButtonStyle = { 
        borderRadius: 24, 
        textTransform: 'none',
        whiteSpace: 'nowrap',
        boxShadow: 'none' }


    return (
        <Button
            startIcon={props.isLoading ?
                <CircularProgress size={20} sx={{ color: 'white' }}
                    thickness={4} /> : null}
            disabled={props.disabled}
            variant='contained'
            disableElevation
            sx={actionButtonStyle}
            color={props.color}
            onClick={props.onClick}>
            {props.title}
        </Button>
    );
}