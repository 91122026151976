import { Box, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { AttachmentModel } from '../../model/response/thread_draft_model';


export interface ImageDialogProps {
    images: AttachmentModel[];
    open: boolean;
    close: () => void;
}
export const ImageDialog: React.FC<ImageDialogProps> = (props: ImageDialogProps) => {
    return (
        <Dialog open={props.open} fullWidth onClose={props.close} maxWidth={'md'}>
            {
                props.images.length > 1 ?
                    <Slide>
                        {
                            props.images.map((image, index) =>
                                <Box key={index} style={{ 'height': '90vh' }}>
                                    <img
                                        src={image.url}
                                        style={{ width: '100%', height: '100%', objectFit: 'contain', }}
                                    />
                                </Box>
                            )
                        }
                    </Slide> :
                    <Box style={{ 'height': '90vh' }}>
                        <img
                            src={props.images[0].url}
                            style={{ width: '100%', height: '100%', objectFit: 'contain', }}
                        />
                    </Box>
            }
        </Dialog>
    );

};