export enum VotingType{
    upVote = 1,
    downVote = -1
}

export interface VotingModel{
    
    answerId: string;
    answerIndex: number;
    type: VotingType;
    userId: string;
    cardId: string;
}